import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantsData(key = false, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_TENANT+params?.propertyId+params.page+key, () => apis.getTenantApi(params), key, revalidate)

    return {
        data, error, loading, mutate
    }
}