import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'
import { useUserData } from './useUserData'


export function useComplaintData(q, params) {
    const {organisation} = useUserData(true)

    const {data, error, loading, mutate} = useGetData(URIS.GET_COMPLAINTS+q, () => apis.getComplaintsApi({propertyId: q, }), q)

    return {
        data, error, loading, mutate
    }
}