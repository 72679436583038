import { Box, Flex, FormControl, Input, Select, HStack, Button, Thead, Tbody, Tr, Td, Th, Table, Menu, MenuButton, MenuList, MenuItem, useDisclosure, Text } from "@chakra-ui/react";
import React from "react";
import { useReducer } from "react";
import { useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { BiFoodMenu } from "react-icons/bi";
import { BsDeviceSsd } from "react-icons/bs";
import { FormReducer } from '../../utils/FormReducer'
import { useUserData } from "../../services/api/useUserData";
import { Link, useNavigate } from "react-router-dom";
import { LoadingContainer } from "../../ui/LoadingContainer";
import { EmptyPage } from "../../ui/EmptyPage";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { FiCompass } from "react-icons/fi";
import { AddMessModal } from "./AddMessModal";
import { URIS } from "../../services/api";
import { useMessData, useMessDeviceData, useMessPropertyData } from "../../services/api/useMessData1";
import { ActionHeader } from "../../ui/ActionHeader"
import { CustomContainer } from '../../ui/CustomContainer'
import { AddIcon } from "@chakra-ui/icons"
import { useGetProperties } from "../../services/api/useGetProperties";
import { useStaffData } from "../../services/api/useStaffData";




export const MessList = () => {

    const navigate = useNavigate()

    const { data: allStaff } = useStaffData(true)
    const { data: allProperties } = useGetProperties(true);

    const [filterIndex, changeFilterIndex] = useState(0)
    const [filters, changeFilters] = useReducer(FormReducer, {})
    const [statusvalue, setStatusValue] = useReducer(FormReducer, { isActive: null })
    const { organisation } = useUserData(true)

    const { data: allMessData, loading, mutate } = useMessData(URIS.GET_PROPERTY_MESS + filterIndex, { ...filters, organisationId: organisation.id, staffAccessDetails: 1 }, true)
    const { data: messDeviceData, mutate: deviceMutate } = useMessDeviceData(URIS.MESS_DEVICE_ALL, { organisationId: organisation.id }, true)
    const { data: allMessPropertyData } = useMessPropertyData(URIS.MESS_PROPERTY_ALL, { organisationId: organisation.id }, true)

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate('/mess')
    }

    const handleChangeFilter = (type, value) => {
        if (value === 'Active' ? value = '1' : value === 'InActive' ? value = '0' : value)
            changeFilters({ type, value })
        if (value === '1' ? value = 'Active' : value === '0' ? value = 'InActive' : null)
            setStatusValue({ type, value })
    }

    const handleReset = () => {
        changeFilterIndex(0)
        changeFilters({ type: 'reset', value: {} })
        setStatusValue({ type: 'reset', value: {} })
    }

    //edit mess modal open & close handler 
    const [messData, setMessData] = useState(null)
    const { isOpen: addMessModalIsOpen, onOpen: addMessModalOnOpen, onClose: addMessModalOnClose } = useDisclosure()

    function handleAddMess() {
        addMessModalOnOpen()
    }

    const editMessHandler = (data) => {
        setMessData(data)
        addMessModalOnOpen()
    }

    const addMessModalCloseHandler = () => {
        setMessData(null)
        addMessModalOnClose()
    }

    const resetMess = () => {
        mutate()
    }

    // Body of the Component

    return <Box>
        <ActionHeader title='Mess'>
            <HStack>
                <Button leftIcon={<AddIcon />} onClick={handleAddMess}>Add Mess</Button>
            </HStack>
        </ActionHeader>
        <br />
        <CustomContainer>
            <form key={filterIndex} onSubmit={handleFilter}>
                <Flex p={3} wrap={'wrap'} mb={2}>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <Input onChange={e => handleChangeFilter('name', e.target.value)} value={filters?.name} placeholder="Name" />
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <Select onChange={e => handleChangeFilter('isActive', e.target.value)} value={statusvalue?.isActive} placeholder="Status" >
                                <option value={'Active'}>Active</option>
                                <option value={'InActive'}>InActive</option>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <HStack>
                            <Button type="submit" variant={'outline'}>Apply</Button>
                            <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                        </HStack>
                    </Box>
                </Flex>
            </form>

            <LoadingContainer loading={loading}>
                {allMessData?.length > 0 ?
                    <Box>
                        <br />
                        <Box minWidth={200} overflow='auto'>
                            <Table className='responsiveTable' variant={'simple'} >
                                <Thead bgColor="gray.200">
                                    <Tr>
                                        <Th>Sr.NO.</Th>
                                        <Th>Name</Th>
                                        <Th>Incharge</Th>
                                        {/* <Th>Status</Th> */}
                                        <Th>Remark</Th>
                                        <Th>Properties</Th>
                                        <Th>Biometric Device</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {allMessData?.length > 0 ?
                                        allMessData?.map((messData, i) => {
                                            let messDevice = messDeviceData?.find(data => data?.messId === messData?.id)
                                            let incharge = allStaff?.find(data => data?.staffId === messData?.inchargeId)
                                            return <Tr key={messData?.id}>
                                                <Td>{i + 1}</Td>
                                                <Td>
                                                    <Link to={`/manage-mess/${messData?.id}`}>
                                                        <Text color="blue.500" cursor="pointer" _hover={{ textDecoration: "underline" }} >{messData?.name}</Text>
                                                    </Link>
                                                </Td>
                                                <Td>{incharge?.staff?.name}</Td>
                                                <Td>{messData?.remark}</Td>
                                                <Td>
                                                    <Box>
                                                        {allMessPropertyData?.filter(messProperty => messProperty?.messId === messData?.id)?.map((messProperty) => {
                                                            let propertyData = allProperties?.find(property => property?.id === messProperty?.propertyId)
                                                            return <Text mt={1}>{propertyData?.name}</Text>
                                                        })}
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box>
                                                        <Text>{messDevice?.name}</Text>
                                                        <Text mt={1}>{messDevice?.deviceIdNo}</Text>
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton
                                                            as={Button}
                                                            aria-label='Options'
                                                            rightIcon={<ChevronDownIcon fontSize={20} />}
                                                            variant='ghost'
                                                        >Actions</MenuButton>
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} onClick={() => editMessHandler(messData)}>
                                                                Edit
                                                            </MenuItem>
                                                            {/* <MenuItem icon={<FiCompass />} onClick={() => assignPropertyHandler()}>
                                                    Manage Properties
                                                </MenuItem>
                                                <MenuItem icon={<BiFoodMenu />} onClick={() => foodMenuHandler()}>
                                                    Food Menu
                                                </MenuItem>
                                                <MenuItem icon={<BsDeviceSsd />} onClick={() => assignPropertyHandler()}>
                                                    Biometric Device
                                                </MenuItem> */}
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        }) : <Tr><Td>No Data Found</Td></Tr>
                                    }
                                </Tbody>
                            </Table>
                        </Box>
                        <br />
                    </Box>
                    :
                    <EmptyPage message='No mess available' />}
            </LoadingContainer>
        </CustomContainer>
        {addMessModalIsOpen && <AddMessModal isOpen={addMessModalIsOpen} onClose={addMessModalCloseHandler} messData={messData} resetMess={resetMess} allStaff={allStaff} />}
    </Box >
}

