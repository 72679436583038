import React, { useEffect, useState } from 'react'
import { Box, Button, Center, Checkbox, Flex, FormLabel, HStack, List, ListItem } from '@chakra-ui/react'
import _, { filter, find, findIndex, map, union } from 'lodash'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { floorName } from '../../utils/Helper'
import { FormLabelX } from '../AddNewProperty/BasicDetails'
import { CheckCircleIcon } from '@chakra-ui/icons'

const COLORS = [ 'blue', 'pink', 'cyan', 'orange', 'purple', 'teal', 'red' ]
export const SelectFloors = ({ nextStep, setFloors, currentProperty, floorsCount }) => {
    const [selectedFloors, setSelectedFloors] = useState([])

    const allFloors = Array.from({ length: 11 }, (d, i) => --i).map(d => ({ floorNo: d, id: d }))

    useEffect(() => {
        if (floorsCount)
            setSelectedFloors(Array.from({ length: floorsCount }, (d, i) => i))
    }, [floorsCount])

    const handleChange = (floor) => {
        let floors = [...selectedFloors];
        if(find(floors, f => f === floor.floorNo ))
            floors = filter(floors, f => f !== floor.floorNo );
        else
            floors = union(floors, [floor.floorNo])
        if (floors.length > parseInt(floorsCount)) {
            floors = floors.slice(1, parseInt(floorsCount) + 1)
        } 

        setSelectedFloors(floors)
    }

    const handleNext = () => {
        setFloors(selectedFloors)
        nextStep(3)
    }

    return (
        <Box>
            
            <FormLabel fontSize={'xl'} color='gray.500' fontWeight={'bold'} mb={4}>
                Select floors present in your property/hostel
            </FormLabel>
            <Flex mt={6} wrap='wrap'>
                {map( allFloors , (floor, index) => {
                    const selected = findIndex(selectedFloors, f => f === floor.floorNo) !== -1
                    return (
                        <Box p={2} borderRadius={5} w={{ base: '33%', md: '25%', lg: '20%' }} key={floor.id} fontSize={20}>
                            <Box borderRadius={5} role='group' onClick={() => handleChange(floor)} cursor='pointer' overflow='hidden' align={'start'} pos='relative'>
                                <Box py={14} 
                                backgroundColor={  COLORS[index % COLORS.length ] +  ( selected ? '.400' : '.50' ) }
                                border='2px solid'
                                borderColor={  COLORS[index % COLORS.length ] + '.200' }
                                >

                                </Box>
                                <Center pos='absolute' top='0px' w='100%' h='100%' pt={2} pb={4} px={4} fontSize={18} color='white' bg={'#00000099'}>
                                    <Box>
                                        <Box fontSize={{ base: 'lg', lg: 'xl' }} color='white' fontWeight={'bold'} textAlign='center'>{floorName(floor.floorNo)}</Box>
                                    </Box>
                                </Center> 
                                <Center pos='absolute' w="50px" h='50px' bg={ selected ? 'white' : 'white' } borderRadius={'50%'} top='-15px' left='-15px'>
                                    {
                                        selected &&
                                        <CheckCircleIcon color='pink.500'/>
                                    }
                                </Center>
                            </Box>
                        </Box>
                    )
                }
                )}
            </Flex>
            <br /><br />
            <HStack justifyContent={['center', 'center', 'right']}>
                <Button _focus={{ boxShadow: 'none' }} disabled={ selectedFloors.length !== parseInt(floorsCount) } onClick={handleNext} rightIcon={<AiOutlineDoubleRight />}>
                    Next
                </Button>
            </HStack>
        </Box>
    )
}