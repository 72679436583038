import { Box, useDisclosure } from "@chakra-ui/react"
import { useApiRequest } from "../../../services/api/useApiRequest";
import { URIS } from "../../../services/api";
import { useEffect, useState } from "react";
import { ErrorAlert } from "../../../Constants/Helper";
import { TenantLogin } from "./TanantLogin";
import { TenantPaymentProfile } from "./TenantPaymentProfile1";
import { TenantsModal } from "./TenantsModal";
import { size } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingContainer } from "../../../ui/LoadingContainer";



export const TenantPayment = () => {

    const [allTenants, setAllTenants] = useState([])
    const [tenant, setTenant] = useState(null)
    const [tenantAuth, setTenantAuth] = useState(null)
    const { isOpen, onClose, onOpen } = useDisclosure()

    const navigate = useNavigate();
    const location = useLocation()
    const query = location.search ? location.search.split('=')[1] : '';
    let token = window.localStorage.getItem('authToken')

    const { request, loading } = useApiRequest(URIS.TENANT_PAYMENT_LOGIN, {
        onCompleted: (d) => {
            loginSucessHandler(d)
        },
        onError: (e) => {
            ErrorAlert(e.message);
        }
    });

    useEffect(() => {
        if (token) {
            request({ data: { rtoken: token } })
        }
    }, [])


    useEffect(() => {
        if (query && size(allTenants)) {
            const selectedTenantData = allTenants.find(t => t.tenantId === query)
            if (selectedTenantData) {
                setTenant(selectedTenantData)
            }
        }
        else {
            if (size(allTenants) === 1) {
                setTenant(allTenants[0])
            }
            else if (size(allTenants) > 1) {
                onOpen()
            }
        }
    }, [allTenants, onOpen, query])

    function loginSucessHandler(d) {
        setTenantAuth(d)
        if (d.isTenant && d.tenants?.length) { setAllTenants(d.tenants) }
        if (d.token) {
            window.localStorage.setItem('authToken', d.token)
        }
    }

    const _selectTenant = (t) => {
        navigate(`/tenant/payment?id=${t.tenantId}`)
        setTenant(t)
        onClose()
    }

    const logout = () => {
        setTenantAuth(null)
        setAllTenants([])
        setTenant(null)
        window.localStorage.removeItem('authToken')
        navigate(`/tenant/payment`)
    }

    return <Box>
        {tenant ? <Box>
            <LoadingContainer loading={loading} >
                <TenantPaymentProfile
                    tenant={tenant}
                    onOpen={onOpen}
                    allTenants={allTenants}
                    logout={logout}
                />
            </LoadingContainer>
        </Box>
            :
            <TenantLogin
                request={request}
                loading={loading}
                tenantAuth={tenantAuth}
            />
        }
        {isOpen && <TenantsModal
            isOpen={isOpen}
            onClose={onClose}
            allTenants={allTenants}
            _selectTenant={_selectTenant}
            selectedTenant={tenant}
        />}
    </Box>
}