import { extendTheme, withDefaultColorScheme, withDefaultSize, theme as baseTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { themes } from "../Constants";
const Text = {
    baseStyle: {
        fontSize: 'sm'
    }
}

const FormLabel = {
    baseStyle: {
        fontSize: 'sm'
    }
}

const Table = {
    defaultProps: {
        colorScheme: 'gray',
        variant: 'striped'
    }
}

const Modal = {
    defaultProps: {
        size: 'xl'
    }
}

const Tooltip = {
    defaultProps: {
        placement: 'top'
    }
}

const colorScheme = 'pink'

const themeColors = themes[colorScheme]

export const customTheme = extendTheme(

    withDefaultSize({
        size: 'sm',
    }),
    withDefaultColorScheme({
        colorScheme
    }),
    {
        colors: {
            defaultColor: themeColors,
            secondary: '#718096'
        },
        fontSizes: {
            extraLarge: baseTheme.fontSizes.xl,
            large: baseTheme.fontSizes.lg,
            default: baseTheme.fontSizes.md,
            small: baseTheme.fontSizes.sm,
            extraSmall: baseTheme.fontSizes.xs,
        },
        components: {
            Text, FormLabel, Table, Modal, Steps, Tooltip
        }
    },
)