
import React, { useEffect, useRef, useState } from 'react'

import { mutate } from 'swr'
import { AddIcon, CopyIcon, InfoIcon } from '@chakra-ui/icons'
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Box, Button, Divider, Flex, FormControl, FormLabel, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Tag, TagLabel, Text, Tooltip, VStack } from '@chakra-ui/react'
import _, { map } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FaBed } from 'react-icons/fa'
import { CopyModal } from './CopyModal'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { AddUnitTypeModal } from './AddUnitTypeModal'
import { useGetProperties } from '../../services/api/useGetProperties'
import { useMatchMutate } from "../../utils/useMatchMutate";
import { floorName } from '../../utils/Helper'
import { SHARINGS } from '../../Constants'
import { EmptyPage } from '../../ui/EmptyPage'
import { FormLabelX } from './BasicDetails'

export const AddUnits = ({ selectedFloors, currentProperty, closeModal, mutatePropertiesList, firstProperty }) => {
    const navigate = useNavigate()
    const { mutate: mutateProperties } = useGetProperties(true)
    const { matchMutate } = useMatchMutate()

    const [allFloors, setFloors] = useState([])
    const [roomModal, openRoomModal] = useState()
    const [copyModal, openCopyModal] = useState()
    const [dataAlert, showAlert] = useState()

    const { data: unitRoomTypes, loading: loadingUnitRoomTypes } = useUnitRoomTypes(currentProperty?.id, { propertyId: currentProperty?.id }, true)

    const { request: addUnitApi, loading: loadingAddUnit } = useApiRequest(URIS.ADD_ROOMS, {
        onCompleted: (d) => {
            if (firstProperty) {
                navigate('/properties')
                mutateProperties()
            } else {
                matchMutate('manage-properties-Page', { revalidate: true })
                mutate(URIS.GET_PROPERTIES + true, { revalidate: true })
            }

            closeModal()
        },
        showAlert: true,
        showLoader: true
    })

    useEffect(() => {
        if (selectedFloors?.length) {
            setFloors(selectedFloors.sort().map(floor => ({ floorNo: floor })))
        }
    }, [selectedFloors])

    const handleRoomModal = (floor) => {
        openRoomModal(d => d ? null : floor)
    }

    const handleSetFloorRooms = (selectedData) => {
        const floorData = [...allFloors]
        const floorIndx = _.findIndex(floorData, d => d.floorNo === roomModal.floorNo)
        floorData[floorIndx].rooms = selectedData
        setFloors(floorData)
        openRoomModal()
    }

    const handleCopy = (floor) => {
        openCopyModal(d => d ? null : floor)
    }

    const handleSubmitCopy = (floor) => {
        const data = JSON.parse(JSON.stringify(allFloors))
        const currentIndx = _.findIndex(data, d => d.floorNo === copyModal.floorNo)
        const targetIndx = _.findIndex(data, d => d.floorNo == floor)
        data[targetIndx] = JSON.parse(JSON.stringify({ ...data[currentIndx], floorNo: data[targetIndx].floorNo }))
        setFloors(data)
        openCopyModal(false)
    }

    const handleSave = () => {
        let data = []
        allFloors.forEach(f => {
            if (f.rooms?.length) {
                const d = _.flatMap(f.rooms, room => {
                    return _.flatMap(room, (value, key) => {
                        return typeof value === 'number' && {
                            unitRoomTypeId: room.typeId, floorNo: f.floorNo, propertyId: currentProperty?.id, count: value,
                            sharing: key === 'single' ? 1 : key === 'two' ? 2 : key === 'three' ? 3 : key === 'four' ? 4 : 0,
                        }
                    })
                })

                data = [...data, ...d]
            }
            else return []
        })

        data = data.length ? _.filter(data, d => d.count) : []



        if (data?.length)
            addUnitApi({ method: 'POST', data })
        else
            handleAlert()
    }

    const handleAlert = () => {
        showAlert(d => !d)
    }

    const cancelRef = useRef()
    return (
        <>
            <LoadingContainer height={1} loading={loadingUnitRoomTypes}>
                <VStack align={'stretch'} spacing={8}>
                    {allFloors.map(floor =>
                        <Box key={floor.floorNo} p={4} border={'2px solid'} borderColor='gray.200' borderRadius={5}>
                            <Flex mb={4} flexDir={['column', 'row', 'row']} align={['start', 'center', 'center']} flexWrap={'wrap'}
                                justifyContent={'space-between'}
                            >
                                <Text color={'gray.600'} fontWeight='bold' fontSize='xl'>{floorName(floor.floorNo)}</Text>
                                <HStack pt={1}>
                                    <Button leftIcon={<AddIcon />} size={'xs'} onClick={() => handleRoomModal(floor)} colorScheme='gray'>Add Room</Button>
                                    {
                                        floor.rooms ?
                                            <Tooltip label='Copy Floor' placement='top'>
                                                <IconButton icon={<CopyIcon />} size={'xs'} onClick={() => handleCopy(floor)} colorScheme='gray' />
                                            </Tooltip> : null
                                    }
                                </HStack>
                            </Flex>
                            <VStack align={'stretch'}>
                                {
                                    map(floor.rooms, (roomType, i) => {
                                        const type = _.find(unitRoomTypes, t => t.id === roomType.typeId)
                                        return (
                                            <Box mb={3}>
                                                <Box mb={2} pr={2}>
                                                    <Text fontSize='md' color='gray.500' fontWeight={'bold'}>{type?.typeName || 'Ground Floor'}:</Text>
                                                </Box>
                                                <HStack spacing={{ base: 3, lg: 4 }} key={i} wrap='wrap'>
                                                    {
                                                        _.map(SHARINGS, room =>
                                                            roomType[room.type] ?
                                                                <Flex pt={{ base: 2, lg: 0 }} wrap='wrap'>
                                                                    <Tag size='lg' colorScheme='pink' borderRadius='full'>
                                                                        <Avatar
                                                                            name={"" + roomType[room.type]}
                                                                            bg='pink.700'
                                                                            size='xs'
                                                                            ml={-2}
                                                                            mr={2}
                                                                        />
                                                                        <TagLabel>{room.title}</TagLabel>
                                                                    </Tag>
                                                                </Flex>
                                                            :
                                                            null
                                                    )}
                                                </HStack>
                                            </Box>
                                        )
                                    }
                                    )
                                }
                                     
                            </VStack>
                        </Box>
                    )}
                </VStack>
                <br /><br />
                <HStack justifyContent={['center', 'center', 'right']}>
                    <Button isLoading={loadingAddUnit} onClick={handleSave}>
                        Save
                    </Button>
                </HStack>
                <AlertDialog
                    isOpen={dataAlert}
                    leastDestructiveRef={cancelRef}
                    onClose={handleAlert}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Please add room to proceed.
                            </AlertDialogHeader>

                            <AlertDialogFooter>
                                <Button colorScheme={'gray'} ref={cancelRef} onClick={handleAlert}>
                                    Cancel
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </LoadingContainer>
            {copyModal &&
                <CopyModal
                    submitCopy={handleSubmitCopy}
                    allFloors={allFloors}
                    visible={copyModal}
                    closeModal={handleCopy}
                    currentFloor={copyModal}
                />
            }
            {roomModal &&
                <AddRoomModal
                    property={currentProperty}
                    defaultData={roomModal.rooms}
                    setData={handleSetFloorRooms}
                    currentFloor={roomModal}
                    visible={roomModal}
                    closeModal={handleRoomModal}
                />
            }
        </>
    )
}

const AddRoomModal = ({ visible, closeModal, currentFloor, setData, defaultData, property }) => {
    const [roomTypes, changeRoomType] = useState([])
    const [unitTypeModal, openUnitTypeModal] = useState()

    const { data: unitRoomTypes, mutate: mutateUnitRoomTypes } = useUnitRoomTypes(property?.id, { propertyId: property?.id })
    
    useEffect(() => {
        if (defaultData?.length) {
            changeRoomType(defaultData)
        }
    }, [defaultData])

    const handleSelectType = (e) => {
        const data = { typeId: e, single: 0, two: 0, three: 0, four: 0 }
        changeRoomType(d => _.xorBy(d, [data], 'typeId'))
    }

    const handleChangeCount = (typeIndx, sharingtype, count) => {
        const data = [...roomTypes]
        data[typeIndx][sharingtype] = parseInt(count) || 0
        changeRoomType(data)
    }

    const handleSubmit = () => {
        setData(roomTypes)
    }

    const isSelected = (id) => {
        return _.findIndex(roomTypes, d => d.typeId === id) === -1 ? { colorScheme: 'gray' } : {}
    }

    const handleAddNewUnitType = () => {
        openUnitTypeModal(d => !d)
    }

    return (
        <Modal isOpen={visible} size='4xl' onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Room to {floorName(currentFloor.floorNo)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <LoadingContainer>
                        <FormControl>
                            <FormLabelX label='Select room types' desc='select diffrent types of room avilable on this floor for example room with AC and room without AC'/>
                            <Flex mt={3} wrap={'wrap'}>
                                {unitRoomTypes?.length ?
                                    unitRoomTypes.map(roomType =>
                                        <Box key={roomType.id} pr={2} pb={2}>
                                            <Button onClick={() => handleSelectType(roomType.id)} {...isSelected(roomType.id)}>{roomType.typeName}</Button>
                                        </Box>
                                    )
                                    :
                                    null
                                }
                                <Box pr={2} pb={2}>
                                    <Button onClick={handleAddNewUnitType} colorScheme={'gray'} variant='outline' leftIcon={<AddIcon />}>Add New Room Type</Button>
                                </Box>
                            </Flex>
                        </FormControl>
                        <br />
                        {roomTypes.length ?
                            <FormControl>
                                <VStack borderLeft={'2px solid'} borderColor='gray.300'>
                                    {roomTypes.map((type, typeindx) =>
                                    <Box w='100%' key={type.type}>
                                    
                                        <Box px={2} pt={2} w={150}>
                                            <Text fontWeight={'bold'} color={'gray.700'}>{_.find(unitRoomTypes, t => t.id === type.typeId)?.typeName}</Text>
                                        </Box>
                                        <Flex align={'center'} wrap={'wrap'}>
                                            <Flex wrap={'wrap'}>
                                                {map(SHARINGS, sharingtype =>
                                                    <Box key={sharingtype.type} p={2}>
                                                        <Box p={2} bg='gray.50' border='1px solid' borderColor={'gray.300'} borderRadius={5}>
                                                            <NumberInput
                                                                maxW={150}
                                                                value={type[sharingtype.type]}
                                                                onChange={(e) => handleChangeCount(typeindx, sharingtype.type, e)}
                                                                min={0}
                                                                max={100}
                                                                defaultValue={0}
                                                                bg='white'
                                                            >
                                                                <NumberInputField />
                                                                <NumberInputStepper>
                                                                    <NumberIncrementStepper />
                                                                    <NumberDecrementStepper />
                                                                </NumberInputStepper>
                                                            </NumberInput>
                                                            <Text pl={1} mt={1}>
                                                                <Tooltip label={sharingtype.detail}>
                                                                    <InfoIcon mr={2} color='blue.600'/>
                                                                </Tooltip>
                                                                {sharingtype.title}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Box>
                                    )}
                                </VStack>
                            </FormControl>
                            :
                            <EmptyPage message="create or select any room type"/>
                        }
                        <br />
                    </LoadingContainer>
                    {unitTypeModal && <AddUnitTypeModal unitRoomTypes={unitRoomTypes} mutate={mutateUnitRoomTypes} property={property} visible={unitTypeModal} closeModal={handleAddNewUnitType} />}
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={handleSubmit}>Done</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}