
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Avatar, Box, Button, Flex, FormControl, FormLabel, HStack, Input, Radio, RadioGroup, Stack, VStack, AlertDialog, AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, 
} from "@chakra-ui/react"
import _, { omit } from "lodash"
import { useUserData } from "../../services/api/useUserData"
import { ActionHeader } from "../../ui/ActionHeader"
import { CustomContainer } from "../../ui/CustomContainer"
import { FormReducer } from "../../utils/FormReducer"
import { useApiRequest } from "../../services/api/useApiRequest"
import { URIS } from "../../services/api"
import { UpdateAdminDp } from "./UpdateAdminDp"

export const AdminProfile = () => {
    const {user, mutate} = useUserData(true)

    const [userData, changeUserData] = useReducer(FormReducer, {})
    const [passwordAlert, showPasswordAlert] = useState()
    const [dpModal, openDpModal] = useState()
    const cancelRef = useRef()

    const {request:updateUserAction} = useApiRequest(URIS.UPDATE_USER, {
        onCompleted:d => {
            mutate()
        },
        showLoader:true
    })

    useEffect(() => {
        if(user){
            // const data = JSON.parse(JSON.stringify(user))
            const {name, contact, email, username, gender} = user
            changeUserData({type:'reset', value:{name, contact, email, username, gender, password: ''}})
        }
    }, [user])

    const handleUpdate = (e) => {
        e.preventDefault()
        const passmatch = ((userData.password?.length ?? 0) > 0 || (userData.confirmPassword?.length ?? 0) > 0) ? userData.password === userData.confirmPassword : true
        if(passmatch){
            const data = _.omit({...userData, id:user.id}, ['confirmPassword', ...((userData.password?.length ?? 0) === 0 ? ['password'] : [])])
            updateUserAction({method:'PATCH', data})
        }else{
            showPasswordAlert(true)
        }

    }

    const handleChangeValue = (type, value) => {
        changeUserData({type, value})
    }

    const handleUpdateDp = () => {
        openDpModal(d => !d)
    }

    const handleCloseAlert = () => {
        showPasswordAlert(false)
    }

    return(
        <Box>
            <ActionHeader title="Your Profile"/>
            <br/>
            <CustomContainer>
                <br/>
                <Flex>
                    <Box width={['100%', '100%', '100%', '15%']} wrap={'wrap'} flexDir='column' px={2} borderRight='1px solid #EBEDEF'>
                        <VStack pb={6}> 
                            <Avatar className='Image' objectFit={'cover'} flex={1} 
                                src={user.avatar} alt='Loading Image' 
                                size={'2xl'}
                            />
                            <Button colorScheme={'gray'} onClick={handleUpdateDp}>Update</Button>
                        </VStack>
                    </Box>
                    <Box flex={1} w={['100%', '100%', '100%', '80%']} px={4}>
                        <VStack>
                            <form onSubmit={handleUpdate}>
                                <Flex w='100%' flexWrap='wrap' direction={{ base: 'column', md: 'row', lg: 'row' }}>
                                    <FromContainer>
                                        <FormControl isRequired>
                                            <FormLabel>Name</FormLabel>
                                            <Input name='name' value={userData.name} onChange={(e) => handleChangeValue('name', e.target.value)} placeholder='name' />
                                        </FormControl>
                                    </FromContainer>
                                    <FromContainer>
                                        <FormControl isRequired>
                                            <FormLabel>Contact</FormLabel>
                                            <Input name='contact' value={userData.contact} pattern="[6789][0-9]{9}" maxLength={10} placeholder='contact' onChange={(e) => handleChangeValue('contact', e.target.value)} />
                                        </FormControl>
                                    </FromContainer>
                                    <FromContainer>
                                        <FormControl>
                                            <FormLabel>Gender</FormLabel>
                                            <RadioGroup id="gender" value={userData.gender} onChange={(e) => handleChangeValue('gender', e)}>
                                                <Stack direction='row'>
                                                    <Radio value={'MALE'}>Male</Radio>
                                                    <Radio value={'FEMALE'}>Female </Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </FromContainer>
                                    <FromContainer>
                                        <FormControl isRequired>
                                            <FormLabel>Email</FormLabel>
                                            <Input type={'email'} value={userData.email} placeholder='email@gmail.com' onChange={(e) => handleChangeValue('email', e.target.value)} />
                                        </FormControl>
                                    </FromContainer>
                                    <FromContainer width={'100%'}>
                                        <FormControl isRequired>
                                            <FormLabel>Username</FormLabel>
                                            <Input name='username' value={userData.username} placeholder='UserName' onChange={(e) => handleChangeValue('username', e.target.value)} />
                                        </FormControl>
                                    </FromContainer>
                                    <HStack  width={'100%'}>
                                        <FromContainer>
                                            <FormControl>
                                                <FormLabel>New Password</FormLabel>
                                                <Input name='pass' autoComplete="off" type={'password'} value={userData.password} placeholder='new password' onChange={(e) => handleChangeValue('password', e.target.value)} />
                                            </FormControl>
                                        </FromContainer>
                                        <FromContainer>
                                            <FormControl isRequired={userData.password}>
                                                <FormLabel>Confirm Password</FormLabel>
                                                <Input name='confirm-pass' type={'password'} value={userData.confirmPassword} placeholder='confirm password' onChange={(e) => handleChangeValue('confirmPassword', e.target.value)} />
                                            </FormControl>
                                        </FromContainer>
                                    </HStack>
                                </Flex>
                                <Box>
                                    <Button type='submit'>Update</Button>
                                </Box>
                            </form>
                        </VStack>
                    </Box>
                </Flex>
            </CustomContainer>
            <AlertDialog
                isOpen={passwordAlert}
                leastDestructiveRef={cancelRef}
                onClose={handleCloseAlert}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Customer
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Password dosen't match. Please try again.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={handleCloseAlert}>
                        Cancel
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {dpModal && <UpdateAdminDp mutate={mutate} user={user} visible={dpModal} closeModal={handleUpdateDp} />}
        </Box>
    )
}

const FromContainer = ({children, width}) => {
    return(
        <Box width={['100%', '100%', '100%', width || '50%']} pr={8} pb={4}>
            {children}
        </Box>
    )
}