import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useOnlinePayment(q, params, revalidate) {

    const { data, error, loading, mutate } = useGetData(URIS.GET_ONLINE_PAYMENT + q, () => apis.getOneOnlinePaymentApi(params), q, revalidate)

    return {
        data,
        error, loading, mutate
    }
}