import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


//for gci
export function useDueCategoriesForGci(q, params) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_ALL_DUE_CATEGORIES + params?.propertyId, () => apis.getAllDueCategoriesApi({ ...params }), q)
    return {
        data,
        error, loading, mutate
    }
}