import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  useToast,
  Box,
  chakra,
  Center,
  HStack
} from '@chakra-ui/react';
import { FormReducer } from "../../utils/FormReducer";
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import { ROUTES } from "../routes";

import { useAppContext, useIsAuthenticated } from "../../app/Context";
export const LoginRoute = () => {
  let navigate = useNavigate();
  let location = useLocation();
  console.log({location: window.location})
  const hostName = window?.location?.hostname
  
  let auth = useIsAuthenticated();

  let from = location.state?.from?.pathname || "/";

  const { loginSuccess, setCurUserLoginDetail, user } = useAppContext()

  const [loginInput, changeLoginInput] = useReducer(FormReducer, { username: "", password: "" })

  const _changeInput = (e) => {
    changeLoginInput({ type: e.target.name, value: e.target.value })
  }

  const toast = useToast()

  useEffect(()=>{
    if(hostName){
      if(hostName === "hostel.gurukripa.ac.in" || hostName === "mis.test"){
        navigate("/tenant/payment", {
          replace:true
        })
      }
    }
  },[hostName, navigate])

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, toast, user])


  const _onCompleted = useCallback((data) => {
    if (data.isOtp) {
      setCurUserLoginDetail({ email: loginInput.username, pass: loginInput.password, token: data.token })
      navigate(ROUTES.REGISTER);
    } else {
      loginSuccess(data)
    }
  }, [loginSuccess, loginInput, navigate, setCurUserLoginDetail])


  const _onError = useCallback((data, response) => {
    toast({
      status: "error",
      title: "Login Failed",
      description: response?.message,
      isClosable: true,
      position: "top",
      duration: 1000,
    })

  }, [toast])


  const { request, loading } = useApiRequest(URIS.LOGIN, {
    onCompleted: _onCompleted,
    onError: _onError,
    showLoader: false,
  })


  function handleSubmit(event) {
    event.preventDefault();
    request({ data: loginInput })
  }

  return (
    <Center minH={'100vh'} px={{ base: 0, xl: 6 }} flexGrow={1} py={{ lg: 10 }} bg={{ lg: "linear-gradient(239deg, #3182CE 50%, white -40%)" }}>
      <Stack w='100%' bg={'white'} minH={'75vh'} maxh='650px' maxW='1250px' boxShadow={{ lg: '0px 0px 40px -15px #0000007a' }} mx={{ base: 0, lg: 10 }} alignItems='stretch' direction={{ base: 'column', lg: 'row' }}>
        <HStack align={'center'} justify='center' w={'60%'}>
          <Image alt="" src="/images/hostel-img.jpg" />
        </HStack>
        {/* <Center display={{ base: 'none', lg: 'block' }} p={2} pos='relative' flex={1.4} minH="200px">
          <Box pos='absolute' display={{ base: 'none', lg: 'block' }} top='50%' right='-22px' w="45px" h="45px" borderRadius="50%" bg="white">
          </Box>
        </Center> */}
        <Box m="0px!important" bg='#F7F9F9' borderTopRightRadius={{ lg: 10 }} borderBottomRightRadius={{ lg: 10 }} flex={1}>
          <Stack alignItems={{ base: 'center', lg: 'flex-start' }} h="100%" pt={{ base: 16, lg: 10 }} pb={{ base: 16, lg: 20 }} px={{ base: 8, md: 10, lg: 6, xl: 20 }} mx={4} justifyContent='space-between' >
            <Box fontSize={18} fontWeight='800' color="gray.700" >
              Hostel Management
            </Box>
            <Flex>
              <Stack spacing={4} w={'full'} maxW={'md'}>
                <Heading fontSize={{ base: 'lg', lg: "2xl", xl: '3xl' }} color='gray.700' mt={{ base: 20, lg: 14 }} pb={{ base: 0, xl: 4 }} mb={{ base: 4, lg: 6 }}>Login</Heading>
                <Heading fontSize={{ base: 12, md: 14, lg: 15, xl: 20 }} color='gray.700'>Login to your account</Heading>
                <chakra.h3 fontSize={{ base: 10, md: 12, lg: 13 }} color="gray.400" pb={4} borderBottom="1px solid" borderColor='gray.200'>Thank you for get back to hostel management.
                </chakra.h3>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <FormControl id="username" isRequired>
                      <FormLabel color="gray.700" fontWeight='500' fontSize={{ base: 10, md: 12, lg: 13 }}>Username</FormLabel>
                      <Input bg={'white'} borderRadius={5} name="username" onChange={_changeInput} value={loginInput.username} />
                    </FormControl>

                    <FormControl mt={4} id="password" isRequired>
                      <FormLabel color="gray.700" fontWeight='500' fontSize={{ base: 10, md: 12, lg: 13 }}>Password</FormLabel>
                      <Input bg={'white'} borderRadius={5} type='password' name="password" onChange={_changeInput} value={loginInput.password} />
                    </FormControl>
                  </Box>
                  <Stack spacing={6}>
                    <Stack
                      direction={{ sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}>
                    </Stack>
                    <Button isLoading={loading} type='submit' bg="#0070ff" color='white' variant={'solid'} _hover={{ bg: '#42638d' }}>
                      Sign in
                    </Button>
                    <Box color="gray.500" fontWeight='700' fontSize={{ base: 14, lg: 14 }} textAlign='center'>
                      <span>Don't have an account yet?</span>&nbsp;
                      <Link onClick={() => navigate('/register')} color="blue.400">Register as Owner</Link>
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </Flex>

          </Stack>
        </Box>
      </Stack>
    </Center>
  );
}
