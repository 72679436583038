import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
    Box, Button, Collapse, Flex, FormControl, FormLabel, HStack, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, PinInput, PinInputField, Tag, Text, Textarea, VStack
} from '@chakra-ui/react'
import _, { find, map, upperFirst } from 'lodash'
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from '@chakra-ui/icons'
import { AiOutlinePrinter } from "react-icons/ai";
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import { allPaymentModes, PAYMENT_METHODS, staffPermissions } from '../../Constants'
import { useDueCategories } from '../../services/api/useDueCategories'
import { usePaymentDetails } from '../../services/api/usePaymentDetails'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { displayDateFormat, webView } from '../../utils/Helper'
import { Receipt } from './Receipt'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { useRefundHistory } from '../../services/api/useRefundHistory'
import { useCallback } from 'react';
import { useAppContext } from '../../app/Context';
import { LoadingContainer } from '../../ui/LoadingContainer';

export const TenantPaymentHistory = ({ tenantData, isTenant }) => {

    const [selectedPayment, setPaymentId] = useState()
    const [paymentHistory, setPaymentHistory] = useState([])

    const { data: paymentDetails } = usePaymentDetails(tenantData?.id, { tenantId: tenantData?.tenant.id }, true)
    const { data: tenantDues } = useTenantSlips(tenantData?.id)
    const { data: orgDueCategories } = useDueCategories(true)

    useEffect(() => {
        const newPaymentHistory = _.orderBy(paymentDetails, 'createdAt', 'desc').filter(p => p.payment_type !== 'credit');
        setPaymentHistory(newPaymentHistory);
    }, [paymentDetails])

    return (
        <Box>
            <Text fontWeight={'bold'} fontSize='default' color='secondary'>Payment History</Text>
            <VStack align={'stretch'} mt={2} spacing={4}>
                {paymentHistory?.length ?
                    map(paymentHistory, payment => {
                        const paymentCategories = payment.paymentReceipts?.map(ins => {
                            const tenantDue = find(tenantDues, d => d.id === ins.tenantDuesId)
                            const dueCategory = find(orgDueCategories, d => d.id === tenantDue?.propertyDueCategory?.dueCategoryId) || ins.paymentDetails_json
                            return { ...dueCategory, fromDate: tenantDue?.from_date, installment: ins }
                        })
                        const more = selectedPayment === payment.id
                        return (
                            <DueCard tenantData={tenantData} setPaymentId={setPaymentId} payment={payment} more={more} paymentCategories={paymentCategories} />
                        )
                    })
                    :
                    <Text color={'secondary'}>No payment history available</Text>
                }
            </VStack>
        </Box>
    )
}

export const TenantRefundHistory = ({ tenantData }) => {
    const [selectedPayment, setPaymentId] = useState()
    const [refundHistory, setRefundHistory] = useState([])
    const { data: refundHistoryData } = useRefundHistory(tenantData?.id, { tenantId: tenantData?.tenant.id }, true)
    const { data: allCategories, loading } = useDueCategories(true)
    const { data: orgDueCategories } = useDueCategories(true)

    useEffect(() => {
        const newPaymentHistory = _.orderBy(refundHistoryData, 'createdAt', 'desc');
        setRefundHistory(newPaymentHistory);
    }, [refundHistoryData])
    return (
        <Box>
            <Text fontWeight={'bold'} fontSize='default' color='secondary'>Refund History</Text>
            <LoadingContainer loading={loading}>
                <VStack align={'stretch'} mt={2} spacing={4}>
                    {refundHistory?.length ?
                        map(refundHistory, refund => {
                            const paymentDetails = refund.paymentDetails;
                            const tenantDue = find(allCategories, d => d.id === refund.propertyDueCategory.dueCategoryId)
                            const paymentCategories = [{
                                ...tenantDue,
                                name: 'Refunded(' + tenantDue?.name + ")",
                                installment: { amount: paymentDetails?.amount }
                            }]
                            const more = selectedPayment === paymentDetails?.id
                            return (
                                <DueCard refundId={refund.id} tenantData={tenantData} setPaymentId={setPaymentId} payment={paymentDetails} more={more} paymentCategories={paymentCategories} />
                            )
                        })
                        :
                        <Text color={'secondary'}>No refund history available</Text>
                    }
                </VStack>
            </LoadingContainer>
        </Box>
    )
}

export const DueCard = ({ refundId, payment, paymentCategories, more, setPaymentId, tenantData, isTenant }) => {

    const { hasAccess } = useAppContext()

    const [printCopySide, setPrintCopySide] = useState(null);
    const handleMore = (paymentId) => {
        setPaymentId(d => paymentId === d ? null : paymentId)
    }
    const [cancelPayment, setCancelPayment] = useState();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setPrintCopySide(null)
    });

    const { mutate } = usePaymentDetails((!isTenant && tenantData?.id) ? tenantData.id : null);
    const { mutate: mutateTenantDues } = useTenantSlips((!isTenant && tenantData?.id) ? tenantData.id : null)

    const onSuccessCancel = useCallback(() => {
        mutateTenantDues();
        mutate();
    }, [mutateTenantDues, mutate])

    const handlePrintCopy = (c) => {
        setPrintCopySide(c);
    }

    useEffect(() => {
        if (printCopySide !== null) {
            handlePrint();
        }
    }, [printCopySide])

    const isPaymentCancellable = useMemo(() => {
        return hasAccess(staffPermissions.CANCEL_PAYMENT) || (moment(payment.createdAt).diff(moment(), 'day') === 0);
    }, [hasAccess, payment])

    return payment && (
        <Box bg={'white'} key={payment.id} py={2} px={4} border='1px solid' borderRadius={'6'}
            boxShadow='rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' borderColor={'gray.100'}
        >
            <HStack width={'100%'} mb={2} justifyContent='space-between'>
                <Text color={'gray.600'} fontWeight='bold'>{_.join(paymentCategories.map(c => c.name), ', ')}</Text>
                <Box display={'none'}>
                    <Box ref={componentRef} >
                        <Receipt key={payment.id} tenantData={tenantData} paymentCategories={paymentCategories} payment={payment} />
                        {
                            printCopySide === 2 && <Receipt key={payment.id} tenantData={tenantData} paymentCategories={paymentCategories} payment={payment} />
                        }
                    </Box>
                </Box>
            </HStack>
            <Flex wrap={'wrap'} spacing={4} color='secondary'>
                <HStack>
                    <Text>Total Amount: </Text>
                    <Text color={'defaultColor.500'}>₹ {payment.amount}</Text>
                </HStack>
                <Box px={2} {...webView}>
                    <Text>|</Text>
                </Box>
                <Text>Date of Payment: <b>{displayDateFormat(payment.paymentDate, 'LL')}</b></Text>
                {/* <Box px={2} {...webView}>
                    <Text>|</Text>
                </Box>
                <HStack>
                    <Text>Payment Type: </Text>
                    <Text color={payment.payment_type === 'credit' ? 'green' : 'red'}>{_.capitalize(payment.payment_type)}</Text>
                </HStack> */}
            </Flex>
            {payment.status === 'cancelled' && <Tag mt={4} colorScheme='orange'>Cancelled</Tag>}
            <Flex wrap={'wrap'} justify={'space-between'} mt={6}>
                <Flex wrap={'wrap'}>
                    {payment.payment_mode === PAYMENT_METHODS.NEFT || hasAccess(staffPermissions.REPRINT_SLIP) || moment().diff(moment(payment.createdAt), 'h') < 24 ?
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                <Button variant={'ghost'} colorScheme={'gray'} leftIcon={<AiOutlinePrinter />}>Print</Button>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => handlePrintCopy(1)}>Print (1 copy)</MenuItem>
                                <MenuItem onClick={() => handlePrintCopy(2)}>Print (2 copy)</MenuItem>
                            </MenuList>
                        </Menu>
                        :
                        null
                    }
                    <Button rightIcon={more ? <ChevronUpIcon fontSize={20} /> : <ChevronDownIcon fontSize={20} />} variant={'ghost'}
                        onClick={() => handleMore(payment.id)}
                    >
                        More Details
                    </Button>
                </Flex>
                {!isTenant && <Box>
                    {payment.status !== 'cancelled' && isPaymentCancellable &&
                        <>
                            <Button variant={'ghost'} onClick={() => setCancelPayment('cancel')} colorScheme={'orange'}>Cancel</Button>
                            {
                                hasAccess(staffPermissions.CHEQUE_STATUS) && payment.payment_mode === PAYMENT_METHODS.CHEQUE && payment.paymentDetails_json.chequeHistory.length === 1 && !refundId &&
                                <>
                                    <Button ml={2} variant={'outline'} onClick={() => setCancelPayment('bounce')}>Bounce Cheque</Button>
                                    <Button ml={2} variant={'outline'} onClick={() => setCancelPayment('clear')}>Clear Cheque</Button>
                                </>
                            }
                        </>
                    }
                    {/* <Button ml={2} variant={'solid'} size='sm' onClick={() => setCancelPayment('delete')} >Delete</Button> */}
                </Box>}
            </Flex>
            <Collapse in={more} animateOpacity>
                <Box mt={5} borderTop='1px solid' borderColor={'gray.200'} py={6}>
                    <Text fontWeight={'bold'} color='gray.600'>Payment Details</Text>
                    <VStack spacing={2} align={'start'} mt={3}>
                        <InputBox type={'Slip Number'} value={payment.paymentDetails_json?.slipNo} />
                        <InputBox type={'Payment Mode'} value={_.upperCase(allPaymentModes[payment.payment_mode]?.name || '')} />
                        {payment.payment_mode === PAYMENT_METHODS.CHEQUE ?
                            <>
                                <InputBox type={'Cheque Number'} value={payment.paymentDetails_json?.cheque_no} />
                                <InputBox type={'Bank Date'} value={displayDateFormat(payment.paymentDetails_json?.bank_date, 'LL')} />
                                <InputBox type={'Bank Name'} value={payment.paymentDetails_json?.bank_name} />
                            </>
                            :
                            <InputBox type={'Payment Details'} value={payment.paymentDetails_json?.payment_details} />
                        }
                        <InputBox type={'Remark'} value={payment.remark} />
                        <HStack align={'center'} pt={2}>
                            <Box width={140}>
                                <Text color={'secondary'}>Installments :</Text>
                            </Box>
                            {paymentCategories?.length ?
                                <HStack>
                                    {paymentCategories.map(ins => {
                                        return (
                                            <VStack align={'start'} spacing={0} key={ins.id} py={2} px={3} pr={5} border='1px solid' borderRadius={6} borderColor='gray.200'>
                                                <Text>{ins.name}
                                                    {
                                                        ins?.fromDate && <>&nbsp;({moment(ins.fromDate).format("MMM")})</>
                                                    }</Text>
                                                <HStack>
                                                    <Text color={'secondary'}>Amount: </Text>
                                                    <Text color={'gray.600'}>₹{ins.installment.amount}</Text>
                                                </HStack>
                                            </VStack>
                                        )
                                    })}
                                </HStack>
                                :
                                null
                            }
                        </HStack>
                    </VStack>
                </Box>
            </Collapse>
            {
                (cancelPayment === 'cancel' || cancelPayment === 'bounce' || cancelPayment === 'clear') &&
                <CancelPaymentModal onSuccess={onSuccessCancel} type={cancelPayment} closeModal={() => setCancelPayment(null)} refundId={refundId} payment={payment} />
            }
            {
                cancelPayment === 'delete' &&
                <DeletePaymentModal onSuccess={onSuccessCancel} closeModal={() => setCancelPayment(null)} refundId={refundId} payment={payment} />
            }

        </Box>
    )
}

export const CancelPaymentModal = ({ onSuccess, refundId, payment, closeModal, type }) => {
    const [remark, setRemark] = useState('');
    const paymentId = payment.id;
    const { request: cancelPayment, loading: cancelingPayment } = useApiRequest(URIS.CANCEL_PAYMENT, {
        onCompleted: () => {
            onSuccess();
            closeModal()
        },
        showAlert: true
    })

    const { request: updatePayment, loading: updatingPayment } = useApiRequest(URIS.ADD_PAYMENT, {
        onCompleted: () => {
            onSuccess();
            closeModal()
        },
        showAlert: true
    })

    const { request: cancelRefund, loading: cancellingRefund } = useApiRequest(URIS.REFUND_PAYMENT, {
        onCompleted: () => {
            onSuccess();
            closeModal()
        },
        showAlert: true
    })

    const handleCancelPayment = (e) => {
        e.preventDefault()
        let params = { id: refundId ? refundId : paymentId, companyId: payment.companyId };
        params['remark'] = remark;
        if (type === 'clear' && !refundId) {
            params['status'] = 'success';
            updatePayment({ method: 'PATCH', data: params });
        } else {
            params['status'] = type;
            params['chequeStatus'] = type;
            params['cancelled'] = true;
            if (refundId)
                cancelRefund({ method: 'DELETE', params })
            else cancelPayment({ method: 'DELETE', params })
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{upperFirst(type)} {refundId ? "Refund" : 'Payment'}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleCancelPayment}>
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>Remark</FormLabel>
                            <Textarea placeholder='remark...' value={remark} onChange={e => setRemark(e.target.value)} type='text' />
                        </FormControl>
                        {
                            !refundId && (type === 'cancel' || type === 'bounce') &&
                            <Box mt={2}>
                                <InfoIcon mr={1} color='blue.400' /> payment amount will be added to previous receipts.
                            </Box>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={closeModal}>
                            Close
                        </Button>
                        <Button isLoading={updatingPayment || cancelingPayment || cancellingRefund} type='submit' colorScheme={'orange'}>{upperFirst(type)} {payment.payment_type === 'credit' ? "Refund" : "payment"}</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}

export const DeletePaymentModal = ({ onSuccess, refundId, payment, closeModal }) => {
    const [showVarifyOtp, setShowVarifyOtp] = useState(false);
    const [varifyOtpData, setVarifyOtpData] = useState(null);
    const [otpCode, setOtpCode] = useState(null);
    const paymentId = payment.id;
    const { request: cancelPayment, loading: cancelingPayment } = useApiRequest(URIS.CANCEL_PAYMENT, {
        onCompleted: () => {
            onSuccess();
            closeModal()
        },
        showAlert: true
    })
    const { request: cancelRefund, loading: cancellingRefund } = useApiRequest(URIS.REFUND_PAYMENT, {
        onCompleted: () => {
            onSuccess();
            closeModal()
        },
        showAlert: true
    })


    const { request: sendVariyOtp, loading: sendVariyOtpLoading } = useApiRequest(URIS.SEND_OTP_DELETE_PAYMENT, {
        onCompleted: (data) => {
            setVarifyOtpData(data);
            setShowVarifyOtp(true);
        },
        showAlert: true
    })

    const { request: variyOtpCode, loading: variyOtpCodeLodaing } = useApiRequest(URIS.VARIFY_OTP_DELETE_PAYMENT, {
        onCompleted: (data) => {
            if (data.success)
                handleDeletePayment();
        },
        showAlert: true
    })


    const sendOtpHandler = useCallback(() => {
        sendVariyOtp({
            method: 'post', data: { companyId: payment.companyId }
        });
    }, [sendVariyOtp, payment])

    const handleDeletePayment = () => {
        let params = { id: refundId ? refundId : paymentId };
        if (refundId)
            cancelRefund({ method: 'DELETE', params })
        else cancelPayment({ method: 'DELETE', params })
    }

    const handleVarifyOtp = useCallback(() => {
        if (varifyOtpData)
            variyOtpCode({ method: 'POST', data: { otp: otpCode, token: varifyOtpData.token } });
    }, [otpCode, varifyOtpData, variyOtpCode])

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {
                        !showVarifyOtp ?
                            <>Delete {refundId ? "Refund" : 'Payment'}</>
                            : <>&nbsp;</>
                    }
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        showVarifyOtp ?
                            <Box>
                                <HStack justifyContent='center'>
                                    <PinInput onChange={setOtpCode} size='md'>
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                                <Box my={8} textAlign='center'>
                                    We have send an OTP to your number kindly verify it.
                                </Box>
                            </Box>
                            :
                            <Box>

                                <Box>
                                    <Box my={4} fontSize={18} fontWeight='bold'>
                                        Are you sure you want to delete {refundId ? "refund" : 'payment'}!
                                    </Box>
                                </Box>
                                {
                                    !refundId &&
                                    <Box mt={2}>
                                        <InfoIcon mr={1} color='blue.400' /> payment amount will be added to previous receipts.
                                    </Box>
                                }
                            </Box>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={closeModal}>
                        Close
                    </Button>
                    {
                        !showVarifyOtp ?
                            <Button isLoading={cancellingRefund} onClick={sendOtpHandler} colorScheme={'red'}>Delete {payment.payment_type === 'credit' ? "Refund" : "payment"}</Button>
                            :
                            <Button disabled={sendVariyOtpLoading} isLoading={variyOtpCodeLodaing || cancelingPayment || cancellingRefund || sendVariyOtpLoading} onClick={handleVarifyOtp} colorScheme={'blue'}>Varify</Button>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}



const InputBox = ({ type, value }) => {
    return (
        <HStack>
            <Box width={140}>
                <Text color={'secondary'}>{type} :</Text>
            </Box>
            <Text>{value || '-'}</Text>
        </HStack>
    )
}