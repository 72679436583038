import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, PinInput, PinInputField, Stack, VStack, } from "@chakra-ui/react"
import { useForm } from "react-hook-form"


export const TenantLogin = ({ request, loading, tenantAuth }) => {

    const { handleSubmit, register, setValue } = useForm()

    const _submit = (e) => {
        if (tenantAuth?.isOtp && tenantAuth?.token) {
            request({ data: { otp: e.otp, token: tenantAuth.token } })
        } else {
            request({ data: { contact: e.contact } })
        }
    }

    const hostUrl = window.location.host;
    const isAasthaSchool = hostUrl?.includes('aasthaschool')

    return <Box>
        <Center minH={'100vh'} px={{ base: 0, xl: 6 }} flexGrow={1} py={{ lg: 10 }} bg={{ lg: "linear-gradient(239deg, #3182CE 50%, white -40%)" }}>
            <Stack w='100%' bg={'white'} minH={'75vh'} maxh='650px' maxW='1250px' boxShadow={{ lg: '0px 0px 40px -15px #0000007a' }} mx={{ base: 0, lg: 10 }} alignItems='stretch' direction={{ base: 'column', lg: 'row' }}>

                <HStack align={'center'} justify='center' w={'60%'}>
                    <Image alt="" src="/images/hostel-img.jpg" />
                </HStack>

                <Box m="0px!important" bg='#F7F9F9' borderTopRightRadius={{ lg: 10 }} borderBottomRightRadius={{ lg: 10 }} flex={1}>

                    <Stack pt={{ base: 16, lg: 10 }} pb={{ base: 16, lg: 20 }} px={{ base: 8, md: 10, lg: 6, xl: 20 }} justifyContent='space-between' >

                        <Box fontSize={18} fontWeight='800' color="gray.700" >
                            Hostel User
                        </Box>

                        {isAasthaSchool && <Box>
                            <br />
                            <br />
                            <Center>
                                <Box p={1} px={5} bg={'blue.500'} color={'white'} fontWeight={'bold'} >Astha Academy Sr Sec School</Box>
                            </Center>
                        </Box>}


                        <Center h={{ base: '40vh', md: '30vh', lg: '40vh', xl: '60vh' }} mt={{ base: 10, md: 10 }} >

                            <Stack w={'100%'}>
                                <Heading fontSize={{ base: 12, md: 14, lg: 15, xl: 20 }} color='gray.700'>Login to your account</Heading>

                                <form onSubmit={handleSubmit(_submit)}>
                                    <Box mt={5} >
                                        {(tenantAuth?.isOtp && tenantAuth?.token) ?
                                            <Box>
                                                <Stack
                                                    maxW={'sm'}
                                                    rounded={'xl'}
                                                    boxShadow={'lg'}
                                                    p={6}
                                                >
                                                    <Center>
                                                        <Heading lineHeight={2} fontSize={{ base: '2xl', md: '3xl' }}>
                                                            Verify your Contact
                                                        </Heading>
                                                    </Center>
                                                    <Center
                                                        fontSize={{ base: 'sm', sm: 'md' }}
                                                    >
                                                        We have sent code to your Contact no
                                                    </Center>
                                                    <FormControl >
                                                        <Center>
                                                            <HStack mt={5} >
                                                                <PinInput size="lg" onChange={(e) => setValue('otp', e)}>
                                                                    <PinInputField />
                                                                    <PinInputField />
                                                                    <PinInputField />
                                                                    <PinInputField />
                                                                </PinInput>
                                                            </HStack>
                                                        </Center>
                                                    </FormControl>
                                                    <Stack >
                                                        <Button
                                                            mt={5}
                                                            bg={'blue.400'}
                                                            color={'white'}
                                                            type="submit"
                                                            _hover={{
                                                                bg: 'blue.500',
                                                            }}
                                                            size="lg"
                                                        >
                                                            Verify
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            :
                                            <Box>
                                                <Box>
                                                    <FormControl id="username" isRequired >
                                                        <FormLabel color="gray.700" fontWeight='500'>Contact</FormLabel>
                                                        <Input  {...register('contact')} maxLength={10} type="number" size="lg" bg={'white'} borderRadius={5} placeholder="Enter Contact" />
                                                    </FormControl>
                                                </Box>
                                                <Stack spacing={6}>
                                                    <Stack
                                                        direction={{ sm: 'row' }}
                                                        align={'start'}
                                                        justify={'space-between'}>
                                                    </Stack>
                                                    <Button size={'lg'} isLoading={loading} type='submit' bg="#0070ff" color='white' variant={'solid'} _hover={{ bg: '#42638d' }}>
                                                        Sign in
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        }
                                    </Box>
                                </form>
                            </Stack>
                        </Center>
                        <Box>
                            <VStack>
                                <ServiceButton name={'Terms & Conditions'} url={'https://gurukripa.ac.in/Terms-of-Services'} />
                                <Box mt={5} ><ServiceButton name={'Refund Rules'} url={'https://gurukripa.ac.in/refund-rules'} /></Box>
                            </VStack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Center>
    </Box>
}

const ServiceButton = ({ name, url }) => {
    return <Box
        cursor={'pointer'}
        color={'blue.500'}
        _hover={{ textDecor: 'underline' }}
        onClick={() => window.open(url)}
    >{name}</Box>
}