import React, { forwardRef, useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import _, { } from 'lodash'
import { useSingleProperty } from '../../services/api/useSingleProperty'
import './Receipt.css'
import { isCompanyGstOn } from '../../utils/Helper'
import { BasicSlipHeader, GstSlipHeader } from './SlipHeaders'
import { BasicSlipTable, GstSlipTable } from './SlipTable'
import moment from 'moment'

export const Receipt = forwardRef((props, ref) => {
    return (
        <Box className='reciept'>
            <Box key={props.key} border='0px solid' borderColor={'gray.500'} mx={10} p={4} pb={2} my={2} ref={ref}>
                <SlipHeader payment={props.payment} tenantData={props.tenantData} />
                <SlipTable payment={props.payment} paymentCategories={props.paymentCategories} />
            </Box >
        </Box>
    )
})

const SlipTable = ({ paymentCategories, payment }) => {
    const propertyId = payment.propertyId;
    const { data: property } = useSingleProperty(propertyId, { id: propertyId })
    const isGstOn = useMemo(() => isCompanyGstOn(property?.company), [property?.company]);
    const isGstOnAsDate = moment(payment.createdAt).isBefore((moment('2024-07-15')), 'date')
    return (
        (isGstOn && isGstOnAsDate) ?
            <GstSlipTable payment={payment} paymentCategories={paymentCategories} />
            : <BasicSlipTable paymentCategories={paymentCategories} />
    )
}

export const SlipHeader = ({ tenantData, payment }) => {
    const propertyId = payment.propertyId;
    const { data: property } = useSingleProperty(propertyId, { id: propertyId })
    const isGstOn = useMemo(() => isCompanyGstOn(property?.company), [property?.company]);
    const isGstOnAsDate = moment(payment.createdAt).isBefore((moment('2024-07-15')), 'date')
    return (
        (isGstOn && isGstOnAsDate) ?
            <GstSlipHeader tenantData={tenantData} payment={payment} />
            :
            <BasicSlipHeader tenantData={tenantData} payment={payment} />
    )
}
