import { Box, VStack, HStack, Button, Text, Flex, Tooltip, Icon, Spacer, Tag, useDisclosure, IconButton } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useGetProperties } from "../../services/api/useGetProperties";
import { BiLayer } from "react-icons/bi";
import { BsDoorOpen } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { RiHotelBedFill, RiHotelBedLine } from "react-icons/ri";
import _, { compact, find, join, property } from "lodash";
import { useGetData } from "../../utils/useGetData";
import { useUnitsData } from "../../services/api/useUnitsData";
import { URIS } from "../../services/api";
import { apis } from "../../services/api/apis";
import { useMessPropertyData } from "../../services/api/useMessData1";
import { useUserData } from "../../services/api/useUserData";
import { LoadingContainer } from "../../ui/LoadingContainer";
import { useApiRequest } from "../../services/api/useApiRequest";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { AssignPropertyModal } from "./PropertyModal";
import { EmptyPage } from "../../ui/EmptyPage";

export const ManageProperty = ({ messData, allMessPropertyData, mutate }) => {
    const { data: allProperties } = useGetProperties(true);
    const { organisation } = useUserData(true)

    const resetProperty = () => {
        mutate()
    }

    //assign property to mess modal 
    const { isOpen: assignMessPropertyModalIsOpen, onOpen: assignMessPropertyModalOnOpen, onClose: assignMessPropertyModalOnClose } = useDisclosure()

    const assignMessPropertyModalHandler = () => {
        assignMessPropertyModalOnOpen()
    }

    return <Box  >
        <Flex >
            {/* <LoadingContainer loading={loading}> */}
            <VStack display={{ base: 'none', lg: 'block' }} width={'100%'} align={'stretch'}>
                {allMessPropertyData?.filter(property => property?.messId === messData?.id)?.length > 0 ?
                    allMessPropertyData?.filter(property => property?.messId === messData?.id)?.map((property, i) => {
                        let propertyData = allProperties?.find(data => data?.id === property?.propertyId)
                        return (
                            <Flex key={property.id} w={"100%"} alignItems={"center"}>
                                <PropertyCard
                                    property={propertyData} selected={property} resetProperty={resetProperty} id={property?.id}
                                />
                                {/* <Button colorScheme="red" ml={5} leftIcon={<DeleteIcon />} >Remove from mess</Button> */}
                            </Flex>
                        )
                    }) : <EmptyPage message="No Property Assigned" />
                }
            </VStack>
            {/* </LoadingContainer> */}
            <Spacer />
            <Flex justifyContent={"end"}>
                <Button leftIcon={<AddIcon />} onClick={assignMessPropertyModalHandler}>Assign New Property</Button>
            </Flex>
        </Flex>
        <AssignPropertyModal isOpen={assignMessPropertyModalIsOpen} onClose={assignMessPropertyModalOnClose} messData={messData} allProperties={allProperties} allMessPropertyData={allMessPropertyData} resetProperty={resetProperty} />
    </Box>
}

const PropertyCard = ({ property, selected, onClick, resetProperty, id }) => {
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allUnits } = useUnitsData(property.id, { propertyId: property.id }, true)

    const state = allStates?.length ? find(allStates, state => state.id === property.state) : null
    const city = state?.cities?.length ? find(state.cities, city => city.id === property.district) : null

    const allRooms = useMemo(() => {
        if (allUnits?.length)
            return _.chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if (allUnits?.length) {
            return _.chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    const { request: createMessMutate, assignPropertyloading } = useApiRequest(URIS.MESS_PROPERTY, {
        onCompleted: d => {
            resetProperty()
        },
        showAlert: true,
        // showLoader: true,
    })

    const removeFormMessHandler = () => {
        if (property?.id) {
            createMessMutate({
                method: 'DELETE', params: { id }
            })
        }
    }

    return (
        <HStack w={"50%"} align={'stretch'} my={2} px={1} alignItems={"center"}>
            <Box flex={1} onClick={onClick} p={4} border='1px solid' borderColor={'gray.100'} borderRadius={6}
                boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                {...selected ? {
                    borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                } : { borderLeft: '6px solid', borderLeftColor: 'white' }}
                cursor={'pointer'}
                // _hover={{
                //     background: 'gray.100', borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                //     boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                // }}
                borderRightColor={'#fff'}
                transition='all .2s'
            >
                <Flex alignItems={"center"}>
                    <VStack align={'left'}>
                        <Text fontSize={'xs'} color={'gray.500'} noOfLines={2} wordBreak={'break-all'}>{join(compact([state?.name, city?.name, property.town, property.streetAddress, property.pincode]), ', ')}</Text>
                        <Text color={selected && 'defaultColor.500'} fontSize={{ base: 'sm', xl: 'large' }}>{property.name}</Text>
                        <Flex wrap={'wrap'}>
                            <FacilityCount selected={selected} count={property.noOfFloors} label={'Total Floors'} icon={BiLayer} />
                            <FacilityCount selected={selected} count={allUnits?.length || 0} label={'Total Rooms'} icon={BsDoorOpen} />
                            <FacilityCount selected={selected} count={allRooms} label={'Total Beds'} icon={RiHotelBedLine} />
                            <FacilityCount selected={selected} count={occupiedBeds} label={'Occupied Beds'} icon={RiHotelBedFill} />
                        </Flex>
                    </VStack>
                    <Spacer />
                    <Tooltip label="Unassign property from mess" >
                        <IconButton mr={3}
                            colorScheme="gray"
                            aria-label='unassign'
                            icon={<TiDeleteOutline size={20} />}
                            onClick={removeFormMessHandler}
                        />
                    </Tooltip>
                </Flex>
            </Box>

        </HStack>
    )
}

const FacilityCount = ({ count, label, icon, selected }) => {
    return (
        <Box pr={5} pb={2}>
            <Tooltip label={label}>
                <HStack spacing={1}>
                    <Icon as={icon} color={selected ? 'defaultColor.500' : 'gray'} />
                    <Text color={selected ? 'defaultColor.500' : 'gray.600'}>{count}</Text>
                </HStack>
            </Tooltip>
        </Box>
    )
}