import { Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from "@chakra-ui/react"
import { MdDone } from 'react-icons/md';


export const PaymentStatusModal = ({ isOpen, onClose, data }) => {
    const onlinePayment = data?.onlinePayment ?? null;
    return <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalCloseButton />

            <ModalBody>
                <Box py={10} px={5} >
                    <Box>
                        <VStack>
                            <Flex
                                border={'3px solid'} borderColor={'green'} borderRadius={'full'}
                                p={3} justifyContent={'center'} alignItems={'center'} color={'green'}
                            >
                                <Box><MdDone size={60} /></Box>
                            </Flex>
                            <Heading mt={6} size="lg" >SUCCESS</Heading>
                        </VStack>
                        <br />
                        {onlinePayment && <VStack fontWeight={'semibold'} color={'gray.500'} >
                            <Box>The payment of ₹{onlinePayment.meta.amount} has been successfully processed, and your order number is {onlinePayment.id}</Box>
                        </VStack>}
                    </Box>
                </Box>
            </ModalBody>

            <ModalFooter>
            </ModalFooter>
        </ModalContent>
    </Modal>
}