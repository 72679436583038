import { Avatar, Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import { TenantDueAmounts } from "./TenantDueAmounts"
import { useTenantSlips } from "../../../services/api/useTenantSlips";
import { useMemo } from "react";
import { PAYMENT_STATUS } from "../../../Constants";
import _, { find, size } from "lodash";
import { useGetProperties } from "../../../services/api/useGetProperties";
import { usePropertyDueCategories } from "../../../services/api/usePropertyDueCategories";
import { TenantSidebar } from "./TenantSidebar";
import { TenantPaymentHistory } from "../../TenantPayment/TenantPaymentHistory";
import { PaymentHistory } from "./PaymentHistory";
import { usePaymentDetails } from "../../../services/api/usePaymentDetails";
import { OnlinePayments } from "./OnlinePayments";
import { useOnlinePayments } from "../../../services/api/useOnlinPayments";
import { LoadingContainer } from "../../../ui/LoadingContainer";
import { useDueCategoriesForGci } from "../../../services/api/useDueCategories1";



export const TenantPaymentProfile = ({ tenant, onOpen, allTenants, logout }) => {

    const profileData = tenant.tenant;
    const tenantId = tenant.tenantId;

    const organisationId = 'R65qzGLO5gDYvrnN';

    const { data: tenantDues, loading, mutate: refetch } = useTenantSlips(tenantId ? tenantId : null, { tenantId }, true)
    const { data: paymentDetails, mutate: refetch1, loading: loading1 } = usePaymentDetails(tenantId ? tenantId : null, { tenantId }, true)
    const { data: onlinePayments, mutate: refetch2, loading: loading2 } = useOnlinePayments(tenantId ? tenantId : null, { tenantId }, true)

    const mutate = () => {
        refetch()
        refetch1()
        refetch2()
    }

    const generatedDueCategories = useMemo(() => {
        if (tenantDues?.length) {
            return _.filter(tenantDues, d => (d.status === PAYMENT_STATUS.GENERATED || d.status === PAYMENT_STATUS.PARTIAL_PAID))
        } return []
    }, [tenantDues])

    const unitTenant = tenant?.unitTenants?.[0];
    const { data: allProperties } = useGetProperties(true)
    const { data: orgDueCategories } = useDueCategoriesForGci(true, { organisationId: tenant.organisationId })
    const tenantPropertyId = unitTenant?.unit.propertyId;

    const { data: propertyDueCategories } = usePropertyDueCategories(profileData?.id, { propertyId: tenantPropertyId })

    const tenantProperty = useMemo(() => {
        if (unitTenant && allProperties?.length) {
            return find(allProperties, p => p.id === unitTenant.unit.propertyId)
        } else return null
    }, [unitTenant, allProperties])

    return <Box h={'100vh'} bg={'gray.100'} >
        <Flex mx={100} bg={'white'}>

            <Box w={'20%'} p={5} borderRight={'1px solid'} borderColor={'gray.300'} >
                <TenantSidebar
                    tenant={tenant}
                    allProperties={allProperties}
                    onOpen={onOpen}
                    allTenants={allTenants}
                    logout={logout}
                />
            </Box>

            <Box w={'80%'} p={5}>
                <Box h={'30px'} ></Box>
                <Box>
                    <Tabs>
                        <TabList>
                            <Tab>Due Amounts</Tab>
                            <Tab>Payment History</Tab>
                            <Tab>Online Payments</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <TenantDueAmounts
                                    loading={loading}
                                    generatedDueCategories={generatedDueCategories}
                                    tenantProperty={tenantProperty}
                                    orgDueCategories={orgDueCategories}
                                    propertyDueCategories={propertyDueCategories}
                                    profileData={profileData}
                                    unitTenant={unitTenant}
                                    tenant={tenant}
                                    mutate={mutate}
                                    allProperties={allProperties}
                                />
                            </TabPanel>
                            <TabPanel>
                                {size(paymentDetails) > 0 ?
                                    <PaymentHistory
                                        paymentDetails={paymentDetails}
                                        tenantDues={tenantDues}
                                        orgDueCategories={orgDueCategories}
                                        tenantData={tenant}
                                    />
                                    : <Box>
                                        <Flex h={'50vh'} alignItems={'center'} justifyContent={'center'} >No Data Found</Flex>
                                    </Box>
                                }

                            </TabPanel>
                            <TabPanel>
                                <LoadingContainer loading={loading2} >
                                    {size(onlinePayments) > 0 ?
                                        <OnlinePayments
                                            onlinePayments={onlinePayments}
                                            tenantDues={tenantDues}
                                            orgDueCategories={orgDueCategories}
                                            refetch2={refetch2}
                                        />
                                        : <Box>
                                            <Flex h={'50vh'} alignItems={'center'} justifyContent={'center'} >No Data Found</Flex>
                                        </Box>
                                    }
                                </LoadingContainer>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </Flex>
    </Box>
}


