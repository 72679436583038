import { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { DatePicker } from "react-widgets/cjs"
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Center, Flex, FormControl, FormLabel, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, Stat, StatHelpText, StatLabel, StatNumber, Text, Tooltip, VStack } from "@chakra-ui/react"
import _, { filter } from "lodash"
import moment from "moment"
import { BaseURL, URIS } from "../../services/api"
import { apis } from "../../services/api/apis"
import { useApiRequest } from "../../services/api/useApiRequest"
import { useGetProperties } from "../../services/api/useGetProperties"
import { useUnitRoomTypes } from "../../services/api/useUnitRoomTypes"
import { useUserData } from "../../services/api/useUserData"
import { ActionHeader } from "../../ui/ActionHeader"
import { EmptyPage } from "../../ui/EmptyPage"
import { LoadingContainer } from "../../ui/LoadingContainer"
import { READABLE_DATE_FORMAT } from "../../utils/datehelper"
import { floorName, getfloors } from "../../utils/Helper"
import { useGetData } from "../../utils/useGetData"
import { BedBox } from "../ManageTenant/AssignRoomModal"
import { useAppContext } from "../../app/Context"
import { ALL_USER_ROLES, staffPermissions } from "../../Constants"
import { useDashboardData } from "../../services/api/useDashboard"

export const Dashboard = () => {
    const { hasAccess, hasPermission } = useAppContext()

    return (
        <Box>
            {hasAccess(staffPermissions.DASHBOARD) || hasPermission(ALL_USER_ROLES.OWNER) ?
                <DashboardData />
                :
                <HStack align={'center'} p={20} minH={'80vh'} justify='center'>
                    <Flex>
                        <Image src="/favicon.ico" alt='favicon' width={20} />
                        <Text ml={1} fontSize={50} fontFamily="monospace" fontWeight="bold">
                            Hostellog
                        </Text>
                    </Flex>
                </HStack>
            }
        </Box>
    )
}

export const DashboardData = () => {
    const navigate = useNavigate();
    const [todayCollection, setTodayCollection] = useState();
    const [openDateModal, setOpenDateModal] = useState(false);
    const [filters, setFilters] = useState({ startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), dashboardDate: 'Today' })

    const { organisation } = useUserData(true)
    const { data: dashboardData, loading: loadingDashboard } = useDashboardData(organisation?.id, { organisationId: organisation?.id })
    const { data: allProperties, loading: getPropertiesLoading } = useGetProperties(true)
    const { data: allUnits, loading: loadingGetUnits } = useGetData(URIS.GET_ALL_UNITS + organisation?.id, () => apis.getAllUnitsApi({ organisationId: organisation?.id }), true, true)
    const { data: unitRoomTypes } = useUnitRoomTypes(organisation?.id, { organisationId: organisation?.id })
    const { request: getTodayCollectionCount, loading: lodingCollectionCount } = useApiRequest(URIS.GET_PAYMENT_HISTORY, {
        onCompleted: (d) => {
            setTodayCollection(d);
        },
        onError: () => { }
    })

    const stats = [
        { id: 1, label: 'Total Properties', value: dashboardData?.property || 0 },
        { id: 2, label: 'Total Rooms', value: dashboardData?.totalUnit || 0 },
        { id: 4, label: 'Total Beds', value: dashboardData?.totalBed || 0 },
        { id: 3, label: 'Total Occupied', value: dashboardData?.totalUnitTenant || 0 },
        { id: 5, label: 'Total Vacants', value: (dashboardData?.totalBed - dashboardData?.totalUnitTenant) || 0 },
    ]

    const handleExportExcel = (type) => {
        let url = '';
        const startDate = filters.startDate;
        const endDate = filters.endDate;
        const paymentType = type === 'refund' ? "credit" : 'debit';
        const fileName = type === 'refund' ? 'Refund report' : "Collection report";
        url = BaseURL + URIS.GET_PAYMENT_HISTORY + `?pagination=0&fileName=${fileName}&isDashboard=true&organisationId=${organisation?.id}&excel=true&startDate=${startDate}&endDate=${endDate}&cancelled=0&type=${paymentType}`;
        window.open(url);
    }

    useEffect(() => {
        const startDate = filters.startDate;
        const endDate = filters.endDate;
        if (!organisation?.id) return;
        getTodayCollectionCount({
            method: 'GET',
            params: { type: 'debit', startDate, endDate, organisationId: organisation.id, isDashboard: true, cancelled: 0, }
        })
    }, [organisation?.id, filters])

    const handleChangeFilter = (newVal) => {
        setFilters({ ...filters, ...newVal });
    }

    const changeDateType = (type) => {
        if (type === 'Custom') return setOpenDateModal(true)
        let newFilters = { dashboardDate: type };
        const todayDate = moment();
        if (type === 'Today') {
            newFilters['startDate'] = todayDate.format("YYYY-MM-DD");
            newFilters['endDate'] = todayDate.format("YYYY-MM-DD");
        } else if (type === 'Yesterday') {
            newFilters['startDate'] = todayDate.subtract(1, "day").format("YYYY-MM-DD");
            newFilters['endDate'] = newFilters['startDate']
        }
        handleChangeFilter(newFilters);
    }

    const handleExportTenants = () => {
        let url = BaseURL + URIS.GET_TENANT + `?organisationId=${organisation?.id}&excel=true&noPaginate=true&occupied=1`;
        window.open(url);
    }

    return (
        <Box>
            <ActionHeader title={'Dashboard'}>
                <Menu>
                    <MenuButton as={Button} variant='outline' rightIcon={<ChevronDownIcon />}>
                        {filters.dashboardDate === 'Custom' ? <>{moment(filters.startDate).format(READABLE_DATE_FORMAT)} - {moment(filters.endDate).format(READABLE_DATE_FORMAT)}</> : filters.dashboardDate}
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => changeDateType('Today')}>Today</MenuItem>
                        <MenuItem onClick={() => changeDateType('Yesterday')}>Yesterday</MenuItem>
                        <MenuItem onClick={() => changeDateType('Custom')}>Custom date</MenuItem>
                    </MenuList>
                </Menu>
            </ActionHeader>
            <br />
            <Box>
                <LoadingContainer loading={getPropertiesLoading || loadingGetUnits || lodingCollectionCount || loadingDashboard}>
                    <Box>
                        <Flex wrap={'wrap'}>
                            {stats.map((stat, i) =>
                                <Box width={['100%', '50%', '50%', '20%']} key={stat.id} px={{ base: 0, md: i === 0 || i === 5 ? 0 : 3 }} pb={6}>
                                    <Box p={4} background={'white'}>
                                        <Stat>
                                            <Flex justify={'space-between'}>
                                                <StatLabel color={'secondary'} fontSize={16}>{stat.label}</StatLabel>
                                                {stat.id === 3 &&
                                                    <Tooltip label='Download Tenants Excel'>
                                                        <IconButton size={'xs'} icon={<DownloadIcon fontSize={16} />} onClick={() => handleExportTenants()} variant='ghost' />
                                                    </Tooltip>
                                                }
                                            </Flex>
                                            <StatNumber fontSize={30}>{stat.value}</StatNumber>
                                        </Stat>
                                    </Box>
                                </Box>
                            )}
                            <Box width={['100%', '50%', '50%', '20%']} px={{ base: 0, md: 0 }} pb={6}>
                                <Box p={4} background={'white'}>
                                    <Stat>
                                        <Flex align='center' justify='space-between'>
                                            <StatLabel color={'secondary'} fontSize={16}>Collection</StatLabel>
                                            <Tooltip label='Download collection excel'>
                                                <Center onClick={() => handleExportExcel('collection')} ml={2} cursor='pointer' color='defaultColor.500' p={1} w='25px' h='25px' borderRadius='50%'>
                                                    <DownloadIcon />
                                                </Center>
                                            </Tooltip>
                                        </Flex>
                                        <StatNumber fontSize={30}>₹ {todayCollection?.totalAmount}</StatNumber>
                                    </Stat>
                                </Box>
                            </Box>
                            <Box width={['100%', '50%', '50%', '20%']} px={{ base: 0, md: 3 }} pb={6}>
                                <Box p={4} background={'white'}>
                                    <Stat>
                                        <Flex justify='space-between'>
                                            <StatLabel color={'secondary'} fontSize={16}>Refund </StatLabel>
                                            <Tooltip label='Download refund excel'>
                                                <Center onClick={() => handleExportExcel('refund')} ml={2} cursor='pointer' color='defaultColor.500' p={1} w='25px' h='25px' borderRadius='50%'>
                                                    <DownloadIcon />
                                                </Center>
                                            </Tooltip>
                                        </Flex>
                                        <StatNumber fontSize={30}>₹ {todayCollection?.refundAmount}</StatNumber>
                                    </Stat>
                                </Box>
                            </Box>
                        </Flex>
                        <Box p={4} background={'white'}>
                            <Text fontSize={'default'} color={'secondary'}>All Properties</Text>
                            <br />
                            {allProperties?.length ?
                                <Accordion allowToggle>
                                    {allProperties.map((item, i) => {
                                        const units = filter(allUnits, unit => unit.propertyId === item.id)
                                        const allFloors = getfloors(units)
                                        const tenants = _.chain(units).flatMap(unit => unit.unitTenants?.length ? unit.unitTenants : []).flatMap(tenant => tenant).value()
                                        const propertyRoomTypes = _.filter(unitRoomTypes, type => type.propertyId === item.id)
                                        return (
                                            <AccordionItem key={item.id}>
                                                <AccordionButton alignItems={'start'} _expanded={{ bg: 'defaultColor.400', color: 'white', fontWeight: 'bold' }}>
                                                    <HStack flex={1} wrap='wrap'>
                                                        <HStack width={['100%', '50%', '50%', '5%']} fontSize={14} textAlign='left'>
                                                            <Text>{++i}.</Text>
                                                        </HStack>
                                                        <HStack width={['100%', '50%', '50%', '30%']} fontSize={14} textAlign='left'>
                                                            <Text>{item.name}</Text>
                                                        </HStack>
                                                        <HStack width={['100%', '50%', '50%', '12%']} fontSize={14} textAlign='left'>
                                                            <Text>Rooms:</Text>
                                                            <Text>{units?.length}</Text>
                                                        </HStack>
                                                        <HStack width={['100%', '50%', '50%', '12%']} fontSize={14} textAlign='left'>
                                                            <Text>Beds:</Text>
                                                            <Text>{units?.length ? _.sumBy(units, u => u.sharing) : 0}</Text>
                                                        </HStack>
                                                        <HStack width={['100%', '50%', '50%', '12%']} fontSize={14} textAlign='left'>
                                                            <Text>Tenants:</Text>
                                                            <Text color={'green.500'}>{tenants?.length}</Text>
                                                        </HStack>
                                                        <HStack width={['100%', '50%', '50%', '12%']} fontSize={14} textAlign='left'>
                                                            <Text>Vacant:</Text>
                                                            <Text color={'red.500'}>{(units?.length ? _.sumBy(units, u => u.sharing) : 0) - tenants?.length}</Text>
                                                        </HStack>
                                                    </HStack>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    {allFloors.length ? allFloors.sort().map((floor, i) => {
                                                        const floorRoomTypes = propertyRoomTypes?.length && propertyRoomTypes.map(d => ({ ...d, units: filter(allUnits, u => u.floorNo === floor && u.unitRoomTypeId === d.id) }))
                                                        return (
                                                            <Box border={'1px solid #E5E8E8'} px={[4, 4, 4, 8]} py={4} key={i}>
                                                                <Box mb={3}>
                                                                    <Text fontWeight={'bold'} color='defaultColor.500'>{floorName(floor)}</Text>
                                                                </Box>
                                                                {floorRoomTypes?.length ?
                                                                    floorRoomTypes.map(unitType =>
                                                                        unitType.units?.length ?
                                                                            <Box key={unitType.id}>
                                                                                <Text>{unitType.typeName}</Text>
                                                                                <Flex mt={4} wrap={'wrap'}>
                                                                                    {
                                                                                        unitType.units.map(unit => {
                                                                                            const tenantCount = unit.unitTenants?.length
                                                                                            return (
                                                                                                <Popover>
                                                                                                    <PopoverTrigger>
                                                                                                        <Box pr={10} key={unit.id} pb={4}>
                                                                                                            <VStack spacing={1}>
                                                                                                                <Flex border={'1px solid #AEB6BF'} p={0} borderRadius={4}>
                                                                                                                    {Array.from({ length: unit.sharing }, (a, i) => {
                                                                                                                        const occupied = i + 1 <= tenantCount
                                                                                                                        return (
                                                                                                                            <BedBox
                                                                                                                                indx={i}
                                                                                                                                unit={unit}
                                                                                                                                background={occupied ? 'secondary' : ''}
                                                                                                                                color={occupied && 'white'}
                                                                                                                                name={String.fromCharCode(65 + i)}
                                                                                                                            />
                                                                                                                        )
                                                                                                                    }
                                                                                                                    )}
                                                                                                                </Flex>
                                                                                                                <Text color={'secondary'} fontFamily={'xs'}>Room {unit.name}</Text>
                                                                                                            </VStack>
                                                                                                        </Box>
                                                                                                    </PopoverTrigger>
                                                                                                    <PopoverContent>
                                                                                                        <PopoverArrow />
                                                                                                        <PopoverHeader>Tenants</PopoverHeader>
                                                                                                        <PopoverBody>
                                                                                                            <VStack align='stretch'>
                                                                                                                {unit.unitTenants?.length ?
                                                                                                                    unit.unitTenants.map(ten =>
                                                                                                                        <HStack key={ten.id} justify='space-between'>
                                                                                                                            <Avatar src={ten.tenant.avatar} />
                                                                                                                            <Text>{ten.tenant.name}</Text>
                                                                                                                            <Button variant={'ghost'}
                                                                                                                                onClick={() => navigate('/tenant/profile/' + ten.tenant?.id + '/0')}
                                                                                                                            >
                                                                                                                                View
                                                                                                                            </Button>
                                                                                                                        </HStack>
                                                                                                                    )
                                                                                                                    :
                                                                                                                    <Text>No Tenant Available</Text>
                                                                                                                }
                                                                                                            </VStack>
                                                                                                        </PopoverBody>
                                                                                                    </PopoverContent>
                                                                                                </Popover>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Flex>
                                                                                <hr />
                                                                                <br />
                                                                            </Box>
                                                                            :
                                                                            null
                                                                    )
                                                                    :
                                                                    null
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                                        :
                                                        <EmptyPage message={'No Rooms'} />
                                                    }
                                                </AccordionPanel>
                                            </AccordionItem>
                                        )
                                    })}
                                </Accordion>
                                :
                                null
                            }
                            <br />
                        </Box>
                    </Box>
                </LoadingContainer>
            </Box>
            {
                openDateModal &&
                <CustomDateModal filters={filters} onClose={() => setOpenDateModal(false)} handleChangeFilter={handleChangeFilter} />
            }
        </Box>
    )
}

const CustomDateModal = ({ onClose, filters, handleChangeFilter }) => {
    const [dates, setDates] = useState({ startDate: new Date(), endDate: new Date() })
    const changeDate = (type, date) => {
        setDates({ ...dates, [type]: date })
    }
    const handleSubmit = () => {
        const newDates = { dashboardDate: 'Custom', startDate: moment(dates.startDate).format("YYYY-MM-DD"), endDate: moment(dates.endDate).format("YYYY-MM-DD") }
        handleChangeFilter(newDates);
        onClose();
    }

    useEffect(() => {
        setDates({ startDate: new Date(filters.startDate), endDate: new Date(filters.endDate) })
    }, [filters])
    return (
        <Modal size='sm' isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Dates</ModalHeader>
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Start date</FormLabel>
                        <DatePicker value={dates.startDate} onChange={e => changeDate('startDate', e ? e : null)} />
                    </FormControl>
                    <FormControl mt={4} isRequired>
                        <FormLabel>End date</FormLabel>
                        <DatePicker value={dates.endDate} onChange={e => changeDate('endDate', e ? e : null)} />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button onClick={handleSubmit} colorScheme='blue'>Select</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
