import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useStaffData(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_STAFF+q, () => apis.getStaffDetailsApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

