import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { AiOutlineFileDone } from "react-icons/ai";
import { Box, Button, FormControl, FormLabel, HStack, Input, Spinner, VStack, ButtonGroup, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Tooltip, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, Flex } from '@chakra-ui/react'
import { isEmpty, map } from 'lodash'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useTenantDocuments } from '../../services/api/useTenantDocuments'
import { LoadingContainer } from '../../ui/LoadingContainer'

export const TenantDocuments = ({ tenantData }) => {
    const [files, setFiles] = useState({})

    const tenantId = tenantData?.tenant?.id
    const { data: allDocuments, loading: loadingDocuments, mutate } = useTenantDocuments(tenantId, { tenantId: tenantId }, true)

    const { request, loading } = useApiRequest(URIS.TENANT_DOCUMENTS, {
        onCompleted: d => {
            mutate()
        },
        showLoader: true,
        showAlert: true,
    })

    useEffect(() => {
        if (allDocuments?.length) {
            let data = {}
            allDocuments.forEach(f => data = { ...data, [f.document_type]: { id: f.id, type: f.fileData?.type, url: f.fileData?.url } })
            setFiles(data)
        }
    }, [allDocuments])

    const handleSetFile = (type, value) => {
        let data = { ...files }
        data[type] = value
        setFiles(data)
    }

    const handleSubmit = () => {
        const data = map(files, (f, key) => ({ document_type: key, fileData: { type: f.type, url: f.url }, tenantId: tenantData.tenant.id }))
        request({ method: 'POST', data: data[0] })
    }

    const documentsType = [
        { id: 1, title: 'Police Verification', document_type: 'PV' },
        { id: 2, title: 'Address Verification', document_type: 'AV' },
        { id: 3, title: 'Affidavit / Agreement', document_type: 'AA' },
    ]

    return (
        <LoadingContainer loading={loadingDocuments}>
            <VStack spacing={4} align='start'>
                {documentsType.map(doc => {
                    return <FileInput documentType={doc} key={doc.id} file={files[doc.document_type]} title={doc.title} value={files[doc.document_type]} getValue={(e) => handleSetFile(doc.document_type, e)} />
                })}
                <Button disabled={isEmpty(files)} isLoading={loading} onClick={handleSubmit}>Save</Button>
            </VStack>
        </LoadingContainer>
    )
}

const FileInput = ({ documentType, file, getValue }) => {
    const params = useParams()

    const [alertStatus, openAlert] = useState()
    const [addModal, openAddModal] = useState()
    const { mutate } = useTenantDocuments(params.id, { tenantId: params.id }, true)

    const { request, loading } = useApiRequest(URIS.TENANT_DOCUMENTS, {
        onCompleted: d => {
            mutate()
        },
        showLoader: true,
        showAlert: true,
    })

    const handleAdd = () => {
        openAddModal(d => !d)
    }

    const handleOpen = () => {
        window.open(file.url, '_blank')
    }

    const handleAlert = () => {
        openAlert(d => !d)
    }

    const handleDelete = () => {
        const data = { id: file?.id, fileData: null }
        request({ method: 'PATCH', data })
    }

    const cancelRef = useRef()
    return (
        <>
            <Flex wrap='wrap' align={'center'}>
                <Box pr={2} pb={2}>
                    <Text>{documentType.title}</Text>
                </Box>
                <Box pr={2} pb={2}>
                    <Tooltip label='Add File' placement='top'>
                        <IconButton onClick={handleAdd} variant={'outline'} colorScheme='gray' size={'sm'} aria-label='Add' icon={<AddIcon size={'sm'} />} />
                    </Tooltip>
                </Box>
                {file?.url &&
                    <ButtonGroup pb={2} size='sm' colorScheme={'gray'} isAttached variant='outline'>
                        <Button leftIcon={<AiOutlineFileDone />} onClick={handleOpen}>Preview File</Button>
                        <Tooltip label='Delete File' placement='top'>
                            <IconButton onClick={handleAlert} isLoading={loading} aria-label='Add to friends' icon={<DeleteIcon color={'red.500'} />} />
                        </Tooltip>
                    </ButtonGroup>
                }
            </Flex>
            <AlertDialog
                isOpen={alertStatus}
                leastDestructiveRef={cancelRef}
                onClose={handleAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Are you sure?
                        </AlertDialogHeader>

                        <AlertDialogFooter>
                            <HStack>
                                <Button colorScheme={'gray'} ref={cancelRef} onClick={handleAlert}>
                                    Cancel
                                </Button>
                                <Button onClick={handleDelete}>
                                    Confirm
                                </Button>
                            </HStack>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {addModal && <AddModal documentType={documentType} file={file} visible={addModal} closeModal={handleAdd} />}
        </>
    )
}

const AddModal = ({ closeModal, visible, file, documentType }) => {
    const params = useParams()
    const [selectedfile, setfile] = useState()
    const { mutate } = useTenantDocuments(params.id, { tenantId: params.id })

    const { request, loading } = useApiRequest(URIS.TENANT_DOCUMENTS, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showLoader: true,
        showAlert: true,
    })

    const handleChange = (e) => {
        let formData = new FormData()
        formData.append('file', e.target.files?.[0])
        uploadImage({ method: 'POST', data: formData })
    }

    const { request: uploadImage, loading: uploadLoading } = useApiRequest(URIS.UPLOAD_FILE, {
        onCompleted: (d) => {
            setfile(d)
        },
        onError: () => { },
    })

    const handleSubmit = () => {
        const data = { document_type: documentType.document_type, fileData: { type: selectedfile.type, url: selectedfile.url }, tenantId: params.id }

        if (file)
            request({ method: 'PATCH', data: { ...data, id: file.id } })
        else
            request({ method: 'POST', data })
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload File</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <FormControl>
                            <FormLabel>Select File</FormLabel>
                            <Input type='file' onChange={handleChange} />
                            {uploadLoading &&
                                <HStack>
                                    <Spinner />
                                    <Text>Uploading...</Text>
                                </HStack>
                            }
                        </FormControl>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button _loading={loading} onClick={handleSubmit}>Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}