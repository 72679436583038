import { Box, HStack, Modal, ModalOverlay, ModalCloseButton, ModalBody, ModalFooter, Button, Text, ModalContent, ModalHeader, FormControl, Tooltip, FormLabel, Select, Stack, } from "@chakra-ui/react";
import React, { useState } from "react";
import { useUserData } from "../../services/api/useUserData";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai"
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import * as _ from "lodash"


export const AssignPropertyModal = ({ isOpen, onClose, deviceData, allProperties, mutate, allDeviceProperties }) => {
    const { organisation } = useUserData(true)
    const [deleteModal, openDeleteModal] = useState()

    const allPropertiesData = useMemo(() => {
        return allProperties.filter(p => {
            if (!(allDeviceProperties?.find(d => d.propertyId === p.id))) { return p }
        })
    }, [allProperties, allDeviceProperties])

    //react-hook-form
    const { handleSubmit, register, reset } = useForm()

    const { request: createMessMutate, } = useApiRequest(URIS.POST_DEVICE_PROPERTY, {
        onCompleted: d => {
            mutate()
        },
        showAlert: true,
        showLoader: true,
    })

    const _submit = (data) => {
        if (deviceData && deviceData?.id) {
            createMessMutate({
                method: 'POST', data: {
                    organisationId: organisation.id,
                    biometric_deviceId: deviceData.id,
                    propertyId: data?.propertyId,
                    isEnabled: true,
                }
            })
            modalCloseHandler()
        }
    }

    const modalCloseHandler = () => {
        reset()
        onClose()
    }

    const handleDeleteModal = (isDelete) => {
        openDeleteModal(isDelete)
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={modalCloseHandler}>
                <ModalOverlay />
                <form onSubmit={handleSubmit(_submit)}>
                    <ModalContent>
                        <ModalHeader>Assign New Property</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>

                            <Text fontWeight='semibold' borderBottom={'1px solid #E5E8E8'} p={2} >Assigned Properties:</Text>
                            {allDeviceProperties?.length ?
                                <Box overflowX="scroll" className='scrollbar-1'>
                                    <Stack spacing={2} direction={['row']} p={3}  >
                                        {_.map(allDeviceProperties, d =>
                                            <HStack key={d?.id} spacing={4} border={'1px solid #E5E8E8'} p={1}>
                                                <Box value={d?.property?.id}  >
                                                    {d?.property?.name}
                                                </Box>
                                                <Tooltip placement='top' label='Delete'>
                                                    <span><AiOutlineDelete onClick={() => handleDeleteModal(d?.id)} /></span>
                                                </Tooltip>
                                            </HStack>
                                        )}
                                    </Stack>
                                </Box>
                                :
                                <Box m={4}>No Data Assigned</Box>
                            }

                            <FormControl my={5} isRequired>
                                <FormLabel>Select Property</FormLabel>
                                <Select {...register("propertyId")} >
                                    <option value={""}>-select-</option>
                                    {allPropertiesData?.map(property => {
                                        return <option key={property?.id} value={property?.id}>{property?.name}</option>
                                    })}
                                </Select>
                                {allPropertiesData?.length === 0 ? <Text mt={1} color={"red.700"} size='sm'>All Properties has assigned !</Text> : null}
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalCloseHandler} colorScheme='gray' mr={3}>Close</Button>
                            <Button type='submit'>Assign</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
            {deleteModal && <ConfirmationModal visible={deleteModal} mutate={mutate} deleteModal={deleteModal} closeModal={handleDeleteModal} />}
        </>
    )
}

const ConfirmationModal = ({ visible, closeModal, deleteModal, mutate }) => {

    const { request: deleteStaffacessApi, } = useApiRequest(URIS.DELETE_DEVICE_PROPERTY, {
        onCompleted: d => {
            closeModal(null);
            mutate();
        },
        showAlert: true,
        showLoader: true,
    })
    const confirmDelete = (id) => {
        deleteStaffacessApi({ method: 'DELETE', params: { id: id } })
    }
    const modalclose = () => {
        closeModal()
    }
    return (
        <Modal isOpen={visible} onClose={modalclose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmation!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to unAssign this property?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={modalclose}>
                        Close
                    </Button>
                    <Button variant='ghost' onClick={() => confirmDelete(deleteModal)}>Delete</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}