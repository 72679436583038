import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { DatePicker } from 'react-widgets/cjs'
import { Alert, Box, Button, Center, Divider, Flex, HStack, IconButton, Input, InputGroup, InputLeftAddon, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Tag, Table, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr, useToast, VStack, useDisclosure } from '@chakra-ui/react'
import _, { find, forEach, isEmpty, map, sum, sumBy } from 'lodash'
import { allPaymentModes, PAYMENT_STATUS, staffPermissions } from '../../Constants'
import { BaseURL, URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useDueCategories } from '../../services/api/useDueCategories'
import { useNextSlipNo } from '../../services/api/useNextSlipNo'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { useUserData } from '../../services/api/useUserData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { FormReducer } from '../../utils/FormReducer'
import { dateFormat, displayDateFormat, getPaybleAmount, mobileView, webView } from '../../utils/Helper'
import { Receipt } from './Receipt'
import { EditIcon } from '@chakra-ui/icons'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useAppContext } from '../../app/Context'
import moment from 'moment'
import { usePaymentVerify } from '../../services/usePaymentVerify'
import { PaymentStatusModal } from './PaymentStausModal'
import { useDueCategoriesForGci } from '../../services/api/useDueCategories1'


export const PaymentModal = ({ visible, closeModal, categories, tenantData, unitTenant, securityMoney, tenantProperty, isTenant }) => {
    const navigate = useNavigate()
    const { organisation, user } = useUserData(isTenant ? null : true)
    const [allDues, changeDues] = useState({})
    const toast = useToast()
    const [printReciept, setPrintReciept] = useState(null);

    const [paymentStartRes, setPaymentStartRes] = useState(null)
    const [paymentRes, setPaymentRes] = useState(null);
    const { isOpen: psIsOpen, onClose: psOnClose, onOpen: psOnOpen } = useDisclosure()

    const { data: orgDueCategories, loading: loadingDueCategories } = useDueCategoriesForGci(true, { organisationId: isTenant ? tenantData.organisationId : organisation.id })
    const { data: tenantDueCategoriesData, loading } = useTenantSlips(isTenant ? tenantData.tenantId : tenantData?.id)
    const { data: nextSlipNo, loading: loadingNextSlipNo } = useNextSlipNo(tenantProperty, { propertyId: tenantProperty?.id, prefix: tenantProperty?.company?.invoice_prefix }, true)
    const tenantDueCategories = securityMoney ?
        tenantDueCategoriesData?.filter(d => d.status && d.status !== PAYMENT_STATUS.PAID && d.status !== PAYMENT_STATUS.CANCELLED && d.meta_data_json.refundable)
        :
        tenantDueCategoriesData?.filter(d => d.status && d.status !== PAYMENT_STATUS.PAID && d.status !== PAYMENT_STATUS.CANCELLED)

    // const { loading: loadingPaymentInstallments, mutate } = usePaymentInstallmentDetails(tenantData.tenant.id, { tenantId: tenantData.tenant.id })

    const [paymentData, changePaymentData] = useReducer(FormReducer, { amount: '', mode: 'offline', date: new Date() })

    const formatBillNo = (sequence) => {
        const no = parseInt(sequence) || 0
        return String(no).padStart(4, '0')
    }
    useEffect(() => {
        if (nextSlipNo)
            changePaymentData({ type: 'slipSequence', value: formatBillNo(nextSlipNo?.sequenceCounter) })
    }, [nextSlipNo])

    const { _checkout } = usePaymentVerify({
        onSuccess: (d) => {
            setPaymentRes(d)
            // setPrintReciept(d.paymentData);
            psOnOpen()
        },
        emitra: (e) => {
            console.log("emitra", e)
        }
    })

    const checkoutPayment = (d) => {
        console.log({ d })
        setPaymentStartRes(d.response)
        // _checkout(d, tenantData.tenant);
    }

    const { request: addPaymentAction, loading: loadingAddPayment } = useApiRequest(URIS.ADD_PAYMENT, {
        onCompleted: d => {
            setPrintReciept(d);
        },
        onError: d => { },
        showAlert: true
    })

    const { request: addOnlinePaymentAction, loading: loadingAddOnlinePayment } = useApiRequest(URIS.ADD_PAYMENT_ONLINE, {
        onCompleted: d => {
            if (d.meta) {
                checkoutPayment(d)
            }
        },
        onError: d => { },
    })

    const handleAddDue = (category, amountToPay) => {
        if (allDues[category.id]) {
            changeDues(d => _.omit(d, [category.id]))
        } else {
            const obj = { id: category.id, amount: 0, due_date: category.due_date, propertyDueCategoryId: category.propertyDueCategory?.dueCategoryId, category: { ...category, amountToPay } }
            changeDues(d => ({ ...d, [category.id]: obj }))
        }
    }

    const handleChangeAmount = (id, value) => {
        let data = { ...allDues }

        if (value > data[id].category.amountToPay)
            return
        else {
            data[id].amount = value
            changeDues(data)
        }
    }

    const handleChangeData = (type, value) => {
        changePaymentData({ type, value })
    }

    const handleChangePaidAmount = (e) => {
        let newAmount = e.target.value || 0
        let duesData = { ...allDues }
        forEach(duesData, d => {
            duesData[d.id].amount = newAmount ? d.category.amountToPay > newAmount ? newAmount : d.category.amountToPay : 0
            if (newAmount > d.category.amountToPay)
                newAmount = newAmount - d.category.amountToPay
            else
                newAmount = 0
        })

        const totalAmountRequired = sum(map(duesData, d => parseInt(d.amount)))
        const amount = parseInt(e.target.value)
        if (amount > totalAmountRequired)
            return null
        else {
            changeDues(duesData)
            changePaymentData({ type: 'amount', value: amount || '' })
        }

    }

    const handleSubmit = () => {
        const { cheque_no, bank_date, bank_name, mode, payment_details, remark, date, slipSequence } = paymentData
        const receipts = map(_.filter(allDues, d => d.amount), due =>
        ({
            amount: due.amount ? parseInt(due.amount) : 0,
            tenantDuesId: due.category.id,
            paymentDate: moment(date).format('YYYY-MM-DD HH:mm'),
            payment_type: 'debit',
            paymentDetails_json: {
                name: !due.propertyDueCategoryId && due.category.meta_data_json?.name,
                ...(!due.propertyDueCategoryId ? { cgst: 6, sgst: 6, hsn: 996311 } : {})
            }
        })
        )

        const data = {
            receipts, paymentDate: moment(date).format('YYYY-MM-DD HH:mm'), remark, payment_type: 'debit',
            payment_mode: isTenant ? 'online' : mode,
            paymentDetails_json: {
                prefix: tenantProperty?.company.invoice_prefix,
                slipNo: tenantProperty?.company.invoice_prefix + formatBillNo(slipSequence),
                cheque_no, payment_details,
                bank_date: dateFormat(bank_date), bank_name,
            },
            propertyId: unitTenant?.unit.propertyId, organisationId: isTenant ? tenantData.organisationId : organisation.id, tenantId: tenantData.tenantId,
            amount: receipts?.length ? sum(receipts.map(r => r.amount)) : 0, receiptCounter: parseInt(slipSequence),
            createdById: isTenant ? tenantData.tenant?.id : user?.id,
            companyId: tenantProperty?.company.id,
            forceSlipNo: slipSequence !== formatBillNo(nextSlipNo?.sequenceCounter) ? 1 : 0,
            ...(isTenant ? { baseurl: window.location.origin } : {}),
        }

        if (!slipSequence) {
            return toast({
                status: "warning",
                title: "slip number required",
            })
        } else {

            if (isTenant) {
                addOnlinePaymentAction({ method: 'POST', data: { meta: data } })
            }
            else {
                addPaymentAction({ method: 'POST', data })
            }
        }
    }

    const disablePay = useMemo(() => {
        return !paymentData.amount || paymentData.amount < 0 || isEmpty(allDues) || (!isEmpty(allDues) && (sum(map(allDues, d => parseInt(d.amount))) !== paymentData.amount)) || !paymentData.date || !paymentData.mode
    }, [allDues, paymentData])

    const handleCloseRecieptModal = () => {
        setPrintReciept(null);
        closeModal();
    }

    const { hasAccess } = useAppContext()
    const slipNoInputRef = useRef()
    const [editSlipNo, setEditSlipNo] = useState()
    const _editSlipNo = () => {
        setEditSlipNo(d => !d)

        if (editSlipNo) {
            changePaymentData({ type: 'slipSequence', value: formatBillNo(nextSlipNo?.sequenceCounter) })
        } else {
            slipNoInputRef.current.focus()
        }
    }

    const _psOnClose = () => {
        if (paymentRes) {
            setPrintReciept(paymentRes.paymentData)
        }
        psOnClose()
    }

    useEffect(() => {
        if (paymentStartRes && paymentStartRes.id) {
            document.getElementById("_submit").submit()
        }
    }, [paymentStartRes])

    return (
        <Box>
            <Modal size={'6xl'} isOpen={visible && !printReciept} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bill Payment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <LoadingContainer loading={loadingNextSlipNo || loading || loadingDueCategories}>
                            {tenantProperty?.company ?
                                <>
                                    <Flex wrap={'wrap'} align={'start'}>
                                        <VStack pr={2} align={'right'}>
                                            <Text fontWeight={'bold'} fontSize='default' color='secondary'>Due Amounts</Text>
                                            <VStack align={'stretch'} minWidth={200}>
                                                {tenantDueCategories?.length ?
                                                    tenantDueCategories.map(category => {
                                                        const dueCategory = find(orgDueCategories, d => d.id === category.propertyDueCategory?.dueCategoryId)
                                                        const selected = allDues[category.id]
                                                        const categoryDueAmount = category.amount - (category.paid_amount || 0) - (sumBy(category.concession, 'concession'))
                                                        return (
                                                            <VStack _hover={{ borderLeft: '4px', borderLeftColor: 'defaultColor.500' }} transition='all .3s' cursor={'pointer'} border='1px solid' boxShadow={'md'} borderColor={'gray.200'} p={2} px={4} key={category.id}
                                                                onClick={() => handleAddDue(category, categoryDueAmount)}
                                                                background={selected && 'gray.100'}
                                                                align='start'
                                                                borderLeft={'4px'} borderLeftColor='white'
                                                                {...selected ? { background: 'gray.100', borderLeft: '4px', borderLeftColor: 'defaultColor.500' } : {}}
                                                            >
                                                                <Text fontWeight={'bold'}>{dueCategory ? dueCategory.name : category.meta_data_json.name}</Text>
                                                                <HStack>
                                                                    <Text>Due on: </Text>
                                                                    <Text>{displayDateFormat(category.due_date, 'LL')}</Text>
                                                                </HStack>
                                                                <HStack>
                                                                    <Text>Amount ₹: </Text>
                                                                    <Text fontWeight={'bold'} color={'defaultColor.500'}> {category.paid_amount || 0} / {getPaybleAmount(category)}</Text>
                                                                </HStack>
                                                            </VStack>
                                                        )
                                                    })
                                                    :
                                                    null
                                                }
                                            </VStack>
                                        </VStack>
                                        <Box flex={1} p={{ md: 8 }} py={{ base: 8 }}>
                                            <VStack spacing={4} align={'stretch'} mb={6}>
                                                <InputData required label={'Date'}
                                                    input={
                                                        <DatePicker disabled value={paymentData.date} style={{ fontSize: 14 }} onChange={e => handleChangeData('date', e)} placeholder='date' />
                                                    }
                                                />
                                                {!isTenant && <InputData
                                                    required
                                                    label={'Slip Number'}
                                                    input={
                                                        <HStack align={'start'}>
                                                            <Box>
                                                                <InputGroup>
                                                                    <InputLeftAddon children={tenantProperty?.company?.invoice_prefix} />
                                                                    <Input
                                                                        ref={slipNoInputRef}
                                                                        readOnly={!editSlipNo}
                                                                        value={paymentData.slipSequence}
                                                                        type='number'
                                                                        placeholder='slip no'
                                                                        onChange={e => handleChangeData('slipSequence', e.target.value)}
                                                                    />
                                                                </InputGroup>
                                                                <HStack justify={'right'}>
                                                                    <Text color={'gray.400'} fontSize='xs'>*autogenerated</Text>
                                                                </HStack>
                                                            </Box>
                                                            {hasAccess(staffPermissions.EDIT_SLIP_NUMBER) ?
                                                                editSlipNo ?
                                                                    <Tooltip label='Cancel'>
                                                                        <IconButton onClick={_editSlipNo} colorScheme={'red'} variant={'ghost'} icon={<AiOutlineCloseCircle fontSize={20} />} />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip label='Edit'>
                                                                        <IconButton onClick={_editSlipNo} colorScheme={'blackAlpha'} variant={'ghost'} icon={<EditIcon />} />
                                                                    </Tooltip>
                                                                :
                                                                null
                                                            }

                                                        </HStack>
                                                    }
                                                />
                                                }
                                                {isTenant ?
                                                    <Box>
                                                        <InputData label={'Payment Mode'}
                                                            input={
                                                                <Tag>Online</Tag>
                                                            }
                                                        />
                                                    </Box>
                                                    :
                                                    <InputData label={'Payment Mode'} required
                                                        input={
                                                            <RadioGroup value={paymentData.mode} onChange={e => handleChangeData('mode', e)}>
                                                                <Flex wrap={'wrap'}>
                                                                    {_.map(allPaymentModes, mode =>
                                                                        <Box pr={2}>
                                                                            <Radio key={mode.id} value={mode.type}>{mode.name}</Radio>
                                                                        </Box>
                                                                    )}
                                                                </Flex>
                                                            </RadioGroup>
                                                        }
                                                    />}
                                                {paymentData.mode === 'dd' || paymentData.mode === 'neft' || paymentData.mode === 'rtgs' || paymentData.mode === 'online' || paymentData.mode === 'other' ?
                                                    <>
                                                        <InputData label={'Payment Method Details'}
                                                            input={
                                                                <Textarea value={paymentData.payment_details} onChange={e => handleChangeData('payment_details', e.target.value)} type='number' placeholder='payment method details' />
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    null
                                                }
                                                {paymentData.mode === 'cheque' ?
                                                    <>
                                                        <InputData label={'Cheque Number'}
                                                            input={
                                                                <Input value={paymentData.cheque_no} onChange={e => handleChangeData('cheque_no', e.target.value)} type='number' placeholder='6 digit cheque bumber' />
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    null
                                                }
                                                {paymentData.mode === 'cheque' || paymentData.mode === 'dd' ?
                                                    <>
                                                        <InputData label={'Bank Date'}
                                                            input={
                                                                <DatePicker value={paymentData.bank_date} style={{ fontSize: 14 }} onChange={e => handleChangeData('bank_date', e)} placeholder='bank date' />
                                                            }
                                                        />
                                                        <InputData label={'Bank Name'}
                                                            input={
                                                                <Input value={paymentData.bank_name} onChange={e => handleChangeData('bank_name', e.target.value)} placeholder='bank name' />
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    null
                                                }
                                                <InputData label={'Remark'}
                                                    input={
                                                        <Textarea value={paymentData.remark} onChange={e => handleChangeData('remark', e.target.value)} type='number' placeholder='remark' />
                                                    }
                                                />
                                                <InputData label={'Total Amount Paid'}
                                                    input={
                                                        <>
                                                            <InputGroup mb={2}>
                                                                <InputLeftElement
                                                                    pointerEvents='none'
                                                                    children={'₹'}
                                                                />
                                                                <Input min={0} value={paymentData.amount}
                                                                    onChange={handleChangePaidAmount} type='number'
                                                                    placeholder={'max amount ' + sum(_.map(allDues, d => d.category?.amountToPay ? parseInt(d.category.amountToPay) : 0))}
                                                                    disabled={isEmpty(allDues)}
                                                                />
                                                            </InputGroup>
                                                            {isEmpty(allDues) && <Text fontSize='xs' color={'secondary'}>*select amount category to enter amount</Text>}
                                                        </>
                                                    }
                                                />
                                            </VStack>
                                            {!isEmpty(allDues) ?
                                                <Table variant={'simple'}>
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Category</Th>
                                                            <Th>Due</Th>
                                                            <Th>Amount (₹)</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {map(allDues, category => {
                                                            const dueCategory = find(orgDueCategories, d => d.id === category.propertyDueCategoryId)
                                                            return (
                                                                <Tr key={category.id}>
                                                                    <Td>{dueCategory?.name || category.category.meta_data_json?.name}</Td>
                                                                    <Td>{displayDateFormat(category.due_date, 'LL')}</Td>
                                                                    <Td>
                                                                        <HStack>
                                                                            <Box>
                                                                                <Input min={0} background={'gray.50'} onChange={(e) => handleChangeAmount(category.id, e.target.value)} value={category.amount} type='number' placeholder='Amount' />
                                                                            </Box>
                                                                            <Text>/</Text>
                                                                            <Text> {category.category.amountToPay}</Text>
                                                                        </HStack>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })
                                                        }
                                                        <Tr>
                                                            <Td colSpan={2} fontWeight={'bold'}>Total</Td>
                                                            <Td fontWeight={'bold'}>{sum(_.map(allDues, d => d.amount ? parseInt(d.amount) : 0))}</Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                                :
                                                null
                                            }
                                        </Box>
                                    </Flex>
                                </>
                                :
                                <Alert>
                                    <HStack>
                                        <Text>Select Company for payment.</Text>
                                        <Button onClick={() => navigate(`/properties/${tenantProperty.id}/4`)}>Select Company</Button>
                                    </HStack>
                                </Alert>
                            }

                        </LoadingContainer>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                            <Button isLoading={loadingAddPayment || loadingAddOnlinePayment} onClick={handleSubmit} disabled={disablePay || loadingAddPayment || loadingAddOnlinePayment}>Pay</Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {
                printReciept &&
                <PrintRecieptModal tenantData={tenantData} reciept={printReciept} onClose={handleCloseRecieptModal} />
            }
            {psIsOpen && <PaymentStatusModal isOpen={psIsOpen} onClose={_psOnClose} data={paymentRes} />}
            {paymentStartRes ? console.log("checkout/embedded api request payload", {
                key_id: paymentStartRes.keyId,
                amount: paymentStartRes.amount,
                order_id: paymentStartRes.id,
                name: paymentStartRes.notes.name,
                prefill: {
                    name: tenantData.tenant?.name,
                    contact: tenantData.tenant?.contact,
                    email: tenantData.tenant?.email,
                },
                image: "https://admin.next.gcisikar.com/assets/logo.png",
                callback_url: `${BaseURL}/online-payment/verify`,
                cancel_url: `${window.location.origin}/tenant/payment?id=${tenantData.tenantId}`,
            }) : null}
            {paymentStartRes ?
                <form id={"_submit"} method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
                    <input type="hidden" name="key_id" value={paymentStartRes.keyId} />
                    <input type="hidden" name="amount" value={paymentStartRes.amount} />
                    <input type="hidden" name="order_id" value={paymentStartRes.id} />
                    <input type="hidden" name="name" value={paymentStartRes.notes.name} />
                    <input type="hidden" name="image" value="https://admin.next.gcisikar.com/assets/logo.png" />
                    <input type="hidden" name="prefill[name]" value={tenantData.tenant?.name} />
                    <input type="hidden" name="prefill[contact]" value={tenantData.tenant?.contact} />
                    <input type="hidden" name="prefill[email]" value={tenantData.tenant?.email} />
                    <input type="hidden" name="callback_url" value={`${BaseURL}/online-payment/verify`} />
                    <input type="hidden" name="cancel_url" value={`${window.location.origin}/tenant/payment?id=${tenantData.tenantId}`} />
                    <button type='hidden' >Submit</button>
                </form>
                : null
            }
        </Box>
    )
}

const InputData = ({ label, input, required }) => {
    return (
        <Flex wrap={'wrap'} align='start'>
            <HStack pr={2} width={200}>
                <Text _after={required && { content: '" *"', color: 'red' }}>{label}</Text>
                <Text {...mobileView}>:</Text>
            </HStack>
            <Flex align={'start'}>
                <Box>
                    <Text {...webView}>: &nbsp;&nbsp;</Text>
                </Box>
                <Box>
                    {input}
                </Box>
            </Flex>
        </Flex>
    )
}

const PrintRecieptModal = ({ tenantData, reciept, onClose }) => {
    const [printCopySide, setPrintCopySide] = useState(null);
    const paymentCategories = reciept.paymentDetails.paymentReceipts?.map(ins => {
        return { ...(ins.tenantDues.propertyDueCategory?.dueCategory ?? ins.paymentDetails_json), installment: ins }
    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setPrintCopySide(null)
    });

    const handlePrintCopy = (c) => {
        setPrintCopySide(c);
    }

    useEffect(() => {
        if (printCopySide !== null) {
            handlePrint();
        }
    }, [printCopySide])
    return (
        <Modal isOpen={reciept} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box pb={4} textAlign='center' fontSize={18} fontWeight='bold'>
                        Print payment Reciept
                    </Box>
                    <Box display={'none'}>
                        <Box ref={componentRef}>
                            <Receipt tenantData={tenantData} paymentCategories={paymentCategories} payment={reciept.paymentDetails} />
                            {
                                printCopySide === 2 && <Receipt tenantData={tenantData} paymentCategories={paymentCategories} payment={reciept.paymentDetails} />
                            }
                        </Box>
                    </Box>
                    <Divider />
                    <Center mt={6} mb={16}>
                        <HStack>
                            <Button colorScheme='blue' onClick={() => handlePrintCopy(1)}>Print Reciept ( 1 copy )</Button>
                            <Button colorScheme='blue' onClick={() => handlePrintCopy(2)}>Print Reciept ( 2 copy )</Button>
                        </HStack>
                    </Center>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}