import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'
import { useUserData } from './useUserData'


export function useDueCategories(q, params) {
    const {organisation} = useUserData(true)
    const {data, error, loading, mutate} = useGetData(URIS.GET_ALL_DUE_CATEGORIES+params?.propertyId, () => apis.getAllDueCategoriesApi({...params, organisationId:organisation?.id}), q)
    return {
        data,
        error, loading, mutate
    }
}

