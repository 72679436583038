import React, { useEffect, useMemo, useState } from 'react'
import { mutate } from 'swr'
import { Box, Button, Flex, HStack, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import _, { filter, find } from 'lodash'
import { BsBuilding } from 'react-icons/bs'
import { URIS } from '../../services/api'
import { EmptyPage } from '../../ui/EmptyPage'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { floorName, getfloors, mobileView, webView } from '../../utils/Helper'
import { useApiRequest } from '../../services/api/useApiRequest'
import { RoomModal } from './RoomModal'
import { LeaveRoomModal } from './LeaveRoomModal'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { useGetProperties } from '../../services/api/useGetProperties'
import { useUnitsData } from '../../services/api/useUnitsData'

export const AssignRoomModal = ({ visible, closeModal, currentTenant, mutateParentData, openRentModal }) => {
    const [selectedProperty, setProperty] = useState()
    const [allFloors, setFloors] = useState([])
    const [selectedUnit, setUnit] = useState()
    const [roomModal, openRoomModal] = useState()
    const [removeModal, openRemoveModal] = useState()
    const [assignedUnit, setAssignedUnit] = useState()

    const { data: allProperties, loading: getPropertiesLoading } = useGetProperties(true)
    const { data: unitsData, loading: loadingGetUnits } = useUnitsData(selectedProperty?.id + currentTenant.id + assignedUnit?.id, { propertyId: selectedProperty?.id }, true)
    const { data: unitRoomTypes } = useUnitRoomTypes(selectedProperty?.id, { propertyId: selectedProperty?.id })

    const allUnits = useMemo(() => {
        if (unitsData?.length && selectedProperty) {
            return _.filter(unitsData, d => d.propertyId === selectedProperty.id)
        } return []
    }, [unitsData, selectedProperty])

    const { request: assingRoomAction, loading: loadingAssign } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            mutate(URIS.GET_ALL_UNITS + selectedProperty?.id)
            mutateParentData()
            openRoomModal()
            openRemoveModal()
            openRentModal(d.tenant)
            closeModal()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })

    useEffect(() => {
        if (allProperties?.length && currentTenant) {
            const tenantUnit = currentTenant.units?.length && _.find(currentTenant.units, u => !u.leavingData)
            if (tenantUnit) {
                let property = find(allProperties, p => p.id === tenantUnit.propertyId)
                setProperty(property)
            }
            setProperty(allProperties[0])
        }
    }, [allProperties, currentTenant])

    useEffect(() => {
        if (currentTenant) {
            const unitTenant = currentTenant.unitTenants
            const unit = unitTenant?.[0]?.unit
            setUnit(unit)
            setAssignedUnit(unit)
        }
    }, [currentTenant])

    const handleSave = (joiningDate) => {
        const data = { unitId: roomModal.id, tenantId: currentTenant.tenant?.id, joiningDate }
        assingRoomAction({ method: "POST", data: _.omitBy(data, d => !d) })
    }

    useEffect(() => {
        const data = getfloors(allUnits || [])
        setFloors(data)
    }, [allUnits])

    const handleSelectProperty = (obj) => {
        setProperty(obj)
    }

    const handleSelectRoom = (obj, bed) => {
        const data = { ...obj, bed }
        openRoomModal(d => d ? null : data)
    }

    const handleRemoveModal = () => {
        openRemoveModal(d => !d)
    }

    const assignedRoom = useMemo(() => {
        if (selectedUnit) {
            const property = _.find(allProperties, p => p.id === selectedUnit.propertyId)
            return `${property?.name} - ${floorName(selectedUnit.floorNo)} - (Room ${selectedUnit.name})`
        }
    }, [selectedUnit, allProperties])

    return (
        <Modal size={'6xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <LoadingContainer loading={getPropertiesLoading}>
                        <Flex wrap={'wrap'}>
                            <Box width={['100%', '100%', '25%', '25%']}>
                                <Box pb={4}>
                                    <Text fontWeight={'bold'} fontSize={'default'}>Properties</Text>
                                </Box>
                                <Flex {...mobileView} mb={10} wrap={'wrap'}
                                    visibility={['visible', 'visible', 'visible', 'hidden']}
                                    display={['block', 'block', 'block', 'none']}
                                >
                                    {allProperties?.length ?
                                        allProperties.map(prop => {
                                            const selected = selectedProperty?.id === prop.id
                                            return (
                                                <Box
                                                    mb={2}
                                                    onClick={() => handleSelectProperty(prop)} key={prop.id} p={4} py={2}
                                                    _hover={{ color: 'defaultColor.500', borderLeft: '3px solid #DD6B20' }}
                                                    transition='all .2s'
                                                    color={selected && 'defaultColor.500'}
                                                    borderLeft={selected && '3px solid #DD6B20'}
                                                    cursor={'pointer'}
                                                    background='#F4F6F6'
                                                    fontSize={'sm'}
                                                >
                                                    <HStack>
                                                        <BsBuilding />
                                                        <Text>{prop.name}</Text>
                                                    </HStack>
                                                </Box>
                                            )
                                        }
                                        )
                                        :
                                        null
                                    }
                                </Flex>
                                <List dir='row' spacing={2} {...webView}>
                                    {allProperties?.length ?
                                        allProperties.map(prop => {
                                            const selected = selectedProperty?.id === prop.id
                                            return (
                                                <ListItem onClick={() => handleSelectProperty(prop)} key={prop.id} p={4} py={2}
                                                    _hover={{ color: 'defaultColor.500', borderLeft: '3px solid #DD6B20' }}
                                                    transition='all .2s'
                                                    color={selected && 'defaultColor.500'}
                                                    borderLeft={selected && '3px solid #DD6B20'}
                                                    cursor={'pointer'}
                                                    background='#F4F6F6'
                                                    fontSize={'sm'}
                                                >
                                                    <ListIcon as={BsBuilding} />
                                                    {prop.name}
                                                </ListItem>
                                            )
                                        }
                                        )
                                        :
                                        null
                                    }
                                </List>
                            </Box>
                            <Box flex={1} pl={[0, 5, 5, 5]}>
                                {selectedUnit ?
                                    <Flex align={'center'} wrap={'wrap'}>
                                        <Flex mr={4} mb={2} wrap='wrap'>
                                            <Text fontWeight={'bold'}>Current Room: &nbsp;</Text>
                                            <Text color={'defaultColor.500'}>
                                                {assignedRoom}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    :
                                    <HStack>
                                        <Text fontWeight={'bold'}>Selected Room:</Text>
                                        <HStack spacing={1}>
                                            <Text color={'defaultColor.500'}>-</Text>
                                        </HStack>
                                    </HStack>
                                }
                                {selectedProperty ?
                                    <Box>
                                        <LoadingContainer loading={loadingGetUnits}>
                                            {allFloors.length ?
                                                <Box>
                                                    <VStack spacing={4} align={'stretch'}>
                                                        <Box>
                                                            <br />
                                                            <Flex wrap={'wrap'}>
                                                                <Box mr={4} mb={4}>
                                                                    <HStack>
                                                                        <BedBox border background={'defaultColor.500'} color='white' />
                                                                        <Text>Occupied Room</Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Box mr={4} mb={4}>
                                                                    <HStack>
                                                                        <BedBox border />
                                                                        <Text>Vacant Room</Text>
                                                                    </HStack>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        {allFloors.sort().map((floor, i) => {
                                                            const floorRoomTypes = unitRoomTypes?.length && unitRoomTypes.map(d => ({ ...d, units: filter(allUnits, u => u.floorNo === floor && u.unitRoomTypeId === d.id) }))
                                                            return (
                                                                <Box border={'1px solid #E5E8E8'} px={[4, 4, 4, 8]} py={4} key={i}>
                                                                    <Box mb={3}>
                                                                        <Text fontWeight={'bold'} color='defaultColor.500'>{floorName(floor)}</Text>
                                                                    </Box>
                                                                    {floorRoomTypes?.length ?
                                                                        floorRoomTypes.map(unitType =>
                                                                            unitType.units?.length ?
                                                                                <Box key={unitType.id}>
                                                                                    <Text>{unitType.typeName}</Text>
                                                                                    <Flex mt={4} wrap={'wrap'}>
                                                                                        {
                                                                                            unitType.units.map(unit => {
                                                                                                const tenantCount = unit.unitTenants?.length
                                                                                                return (
                                                                                                    <Box pr={10} key={unit.id} pb={4}>
                                                                                                        <VStack spacing={1}>
                                                                                                            <Flex border={'1px solid #AEB6BF'} p={0} borderRadius={4}>
                                                                                                                {Array.from({ length: unit.sharing }, (a, i) => {
                                                                                                                    const myRoom = ''
                                                                                                                    const occupied = i + 1 <= tenantCount
                                                                                                                    return (
                                                                                                                        <BedBox
                                                                                                                            key={i}
                                                                                                                            indx={i}
                                                                                                                            unit={unit}
                                                                                                                            background={myRoom ? '#85929E' : occupied ? 'defaultColor.500' : ''}
                                                                                                                            color={(myRoom || occupied) && 'white'}
                                                                                                                            onClick={() => !occupied && handleSelectRoom(unit, i + 1)}
                                                                                                                            name={String.fromCharCode(65 + i)}
                                                                                                                        />
                                                                                                                    )
                                                                                                                }
                                                                                                                )}
                                                                                                            </Flex>
                                                                                                            <Text color={'secondary'} fontFamily={'xs'}>Room {unit.name}</Text>
                                                                                                        </VStack>
                                                                                                    </Box>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Flex>
                                                                                    <hr />
                                                                                    <br />
                                                                                </Box>
                                                                                :
                                                                                null
                                                                        )
                                                                        :
                                                                        null
                                                                    }
                                                                </Box>
                                                            )
                                                        }
                                                        )}
                                                    </VStack>
                                                </Box>
                                                :
                                                <EmptyPage message={'No Rooms'} />

                                            }
                                        </LoadingContainer>
                                    </Box>
                                    :
                                    null
                                }
                            </Box>
                        </Flex>
                        {removeModal && <LeaveRoomModal action={assingRoomAction} visible={removeModal}
                            assignedUnit={assignedUnit}
                            closeModal={handleRemoveModal} currentTenant={currentTenant}
                            currentUnit={selectedUnit}
                            loading={loadingAssign}
                        />}
                        {roomModal ?
                            <RoomModal
                                loading={loadingAssign}
                                currentTenant={currentTenant}
                                assignedUnitTenant={currentTenant.unitTenants?.[0]}
                                handleAssign={handleSave}
                                defaultData={roomModal} visible={roomModal}
                                closeModal={() => handleSelectRoom(null, false)}
                                property={selectedProperty}
                                assignedProperty={find(allProperties, p => p.id === assignedUnit?.propertyId)}
                            />
                            : null
                        }
                    </LoadingContainer>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const BedBox = ({ background = '', color = '', name = 'A', onClick, unit, border, indx }) => {
    return (
        <Box px={2}
            onClick={onClick}
            borderRight={unit?.sharing !== indx + 1 && '1px solid #AEB6BF'}
            border={border && '1px solid #AEB6BF'}
            py={1}
            fontSize={10}
            size={'xs'}
            borderRadius={border && 4}
            variant='outline'
            cursor='pointer'
            _hover={{ background: 'secondary', color: 'white' }}
            transition='all .2s'
            background={background}
            color={color}
        >
            {name}
        </Box>
    )
}