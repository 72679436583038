import React, { useReducer, useState } from 'react'
import { Box, Button, Flex, FormControl, HStack, Select, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import moment from "moment"
import Pagination from "rc-pagination"
import { DatePicker } from "react-widgets/cjs"
import _, { filter, map, orderBy } from 'lodash'
import { AiOutlineReload } from "react-icons/ai"

import { LoadingContainer } from "../../ui/LoadingContainer"
import { useTenantsInOut } from "../../services/api/useInOutTenants"
import { FormReducer } from "../../utils/FormReducer"
import { dateFormat } from "../../utils/Helper";
import { EmptyPage } from "../../ui/EmptyPage"
import { dateRange } from "../../Constants"
import { useUserData } from "../../services/api/useUserData"
import { useGetMessAttendance } from "../../services/api/useMessData"

export const TenantFoodLogs = ({ tenantData, propertyId }) => {

    const defaultFilter = { limit: 10, page: 1, startDate: moment(new Date()).format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD') }

    const { organisation } = useUserData(true)
    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [formKey, changeFormKey] = useState(1)

    const { data: tenantsInOut, loading } = useTenantsInOut(tenantData?.id + filterIndex, { ...filters, organisationId: organisation?.id, unitTenantId: tenantData?.unitTenants?.[0]?.id }, true, true)
    const { data: attendance, laoding: loadingAttendance } = useGetMessAttendance((tenantData?.tenantId + filterIndex), {
        propertyId: propertyId,
        startDateTime: filters.startDate + " 00:00:00",
        endDateTime: filters.endDate + " 23:59:59",
        tenantId: tenantData?.tenantId
    })
    const handlePageChange = (page) => {
        changeFilters({ type: 'page', value: page })
        changeFilterIndex(d => ++d)
    }

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        changeFilters({ type: 'page', value: 1 })
    }

    const handleChangeValue = (type, value) => {
        changeFilters({ type: 'merge', value: { [type]: value, page: 1 } })
    }

    const handleRangeChange = (id) => {
        const range = _.find(dateRange, d => d.id == id)

        const data = { startDate: dateFormat(range.startDate), endDate: dateFormat(range.endDate), startTime: range.startTime, endTime: range.endTime }
        changeFilters({ type: 'merge', value: data })

    }

    const handleReset = () => {
        changeFilterIndex(d => ++d)
        changeFilters({ type: 'reset', value: defaultFilter })
        changeFormKey(i => ++i)
    }

    return (
        <LoadingContainer loading={loadingAttendance}>
            <form key={formKey} onSubmit={handleFilter}>
                <Flex align={'center'} wrap={'wrap'} mb={2}>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <DatePicker placeholder='Start Date' style={{ fontSize: 14 }} value={filters.startDate ? new Date(filters.startDate) : null}
                                onChange={e => handleChangeValue('startDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                            />
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <DatePicker placeholder='End Date' style={{ fontSize: 14 }} value={filters.endDate ? new Date(filters.endDate) : null}
                                onChange={e => handleChangeValue('endDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                            />
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <Select value={filters.in_out}
                                placeholder="Select Date Range"
                                onChange={e => handleRangeChange(e.target.value)}
                            >
                                {map(filter(dateRange, r => r.id !== 1), range =>
                                    <option value={range.id} key={range.id}>{range.title}</option>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <HStack>
                            <Button type="submit" variant={'outline'}>Apply</Button>
                            <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                        </HStack>
                    </Box>
                </Flex>
            </form>
            {attendance?.results?.length ?
                <>
                    <Table className="responsiveTable" variant={'simple'}>
                        <Thead>
                            <Tr>
                                <Th>Sr. No</Th>
                                <Th>Name</Th>
                                <Th>Meal</Th>
                                <Th>Time</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                orderBy(attendance.results, "loggedAt", "desc").map((entry, i) => {
                                    const meal = entry.foodMenu.meal_type
                                    const tenant = entry.tenant
                                    return (
                                        <Tr key={entry.id}>
                                            <Td>{(defaultFilter.limit * (filters.page - 1)) + i + 1}</Td>
                                            <Td>{tenant.name}</Td>
                                            <Td>{meal === "B" ? "Breakfast" : meal === "L" ? "Lunch" : meal === "S" ? "Snacks" : meal === "D" ? "Dinner" : '-'}</Td>
                                            <Td>
                                                {moment(entry.loggedAt).format('LLL')}
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                    <br />
                    <HStack justifyContent={'center'}>
                        <Pagination
                            total={attendance?.total || 10}
                            pageSize={attendance?.limit || 10}
                            onChange={handlePageChange}
                            current={parseInt(filters.page)}
                        />
                    </HStack>
                </>
                :
                <EmptyPage />
            }

        </LoadingContainer>
    )
}