import { Box, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { MainApp } from "../routes";
import { customTheme } from "../utils/theme";
import { AppProvider } from "./AppProvider";

export const App = (props) => {
  return (
    <ChakraProvider theme={customTheme}>
      <AppProvider>
        <MainApp />
      </AppProvider>
    </ChakraProvider>
  );
};
