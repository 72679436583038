import React, { useEffect, useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { Avatar, Button, HStack, Image, Input, Spinner, VStack } from '@chakra-ui/react'
import { URIS } from '../services/api'
import { useApiRequest } from '../services/api/useApiRequest'

export const UploadImage = ({ getImage, defaultImage, cover, defaultUrl, image }) => {
    const [avatar, changeAvatar] = useState()

    useEffect(() => {
        if (defaultImage) {
            changeAvatar(defaultImage)
        }

        if (defaultUrl) {
            changeAvatar({ url: defaultUrl })
        }
        if(image)
        changeAvatar({url: image})
    }, [defaultImage, defaultUrl, image])

    const { request: uploadImage, loading: uploadLoading } = useApiRequest(URIS.UPLOAD_FILE, {
        onCompleted: (d) => {
            changeAvatar(d)
            getImage(d)
        },
        onError: () => { },
    })

    const handleSelectImg = (e) => {
        let formData = new FormData()
        formData.append('file', e.target.files?.[0])
        uploadImage({ method: 'POST', data: formData })
    }

    const handleRemoveAvatar = () => {
        changeAvatar()
        getImage()

    }


    return (
        <>
            {uploadLoading ? <Spinner size={'sm'} /> :
                <Input type={'file'} onChange={handleSelectImg} accept="image/png, image/gif, image/jpeg, image/jpg" />
            }
            {avatar &&
                <HStack justify={'start'}>
                    <VStack>
                        {cover ?
                            <Avatar border={'1px solid #D6DBDF'} mt={4} mb={2} size={'xl'} src={avatar.url} />
                            :
                            <Image src={avatar.url} width={150} mt={4} />
                        }
                        <Button colorScheme={'gray'} leftIcon={<DeleteIcon />} onClick={handleRemoveAvatar} size={'xs'}>Remove</Button>
                    </VStack>
                </HStack>
            }
        </>
    )
}