import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { UploadImage } from '../../ui/UploadImage'

export const UpdateAdminDp = ({ visible, closeModal, user, mutate }) => {
    const [newAvatar, changeAvatar] = useState()

    const { request: updateUserAction } = useApiRequest(URIS.UPDATE_USER, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showLoader: true
    })

    useEffect(() => {
        if (user) {
            changeAvatar(user.avatar)
        }
    }, [user])

    const handleChange = (e) => {
        changeAvatar(e?.url)
    }

    const handleSubmit = () => {
        updateUserAction({ method: 'PATCH', data: { id: user.id, avatar: newAvatar || null } })
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Choose Image</FormLabel>
                        <UploadImage defaultUrl={newAvatar} getImage={handleChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={handleSubmit}>Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}