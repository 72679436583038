import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'

export const Truncate = ({children, length = 200, fontSize}) => {
    const [show, changeShow] = useState(false)
    const showPara = () => {
        changeShow(!show)
    }

    return <Box>
            {!children ?
                <Text>-</Text>
                :
                children.length > length && !show ?
                    <Box>
                        <Text display='inline'>{children.substring(1, length)}...</Text>&nbsp;
                        <Text display='inline' onClick={showPara} color='blue.500' transition={'all .1s'} cursor='pointer' _hover={{color:'#85C1E9'}} fontSize='sm'>view more</Text>
                    </Box>
                    :
                    <Box>
                        <Text>{children}</Text>&nbsp;
                        {children.length > length ? 
                            <Text color='blue.500' transition={'all .1s'} display='inline' onClick={showPara} cursor='pointer' _hover={{color:'#85C1E9'}} fontSize='sm'>view less</Text> 
                            : null 
                        }
                    </Box>
            }
        </Box>
}