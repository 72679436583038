import { Box, Button, Flex, FormControl, FormLabel, HStack, Icon, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Select, Stat, StatLabel, StatNumber, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import { chain, compact, find, intersectionBy, join, map, omit, orderBy } from 'lodash'
import moment from 'moment'
import Pagination from 'rc-pagination'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useAppContext, useCurrentProperty } from '../../app/Context'
import { useCompanyData } from '../../services/api/useCompanyData'
import { usePaymentHistory } from '../../services/api/usePaymentHistory'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { displayDateFormat } from '../../utils/Helper'
import { DropdownList, Multiselect } from 'react-widgets'
import { useUserData } from '../../services/api/useUserData'
import { CancelPaymentModal } from '../TenantPayment/TenantPaymentHistory'
import { ALL_USER_ROLES, PAYMENT_METHODS, PAYMENT_STATUS, staffPermissions } from '../../Constants'
import { BaseURL, URIS } from '../../services/api'
import { DownloadIcon } from '@chakra-ui/icons'
import { useStaffData } from '../../services/api/useStaffData'

export const ChequePaymentCollections = () => {
    const navigate = useNavigate()
    const limit = 10
    const { properties, hasPermission, hasAccess } = useAppContext()

    const { user } = useUserData(true)
    const { currentProperty } = useCurrentProperty()
    const { data: propertyDueCategories } = usePropertyDueCategories(currentProperty?.id, { propertyId: currentProperty?.id }, true)
    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)
    const { data: allStaff, loading: loadingStaff } = useStaffData(true)

    const [currentPage, changePage] = useState(1)
    const [cancelPayment, setCancelPayment] = useState();

    const isAllPayments = hasAccess(staffPermissions.ALL_PAYMENTS)

    const defaultValues = useMemo(() => {
        return {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            propertyIds: [currentProperty?.id],
            chequeStatus: '',
            createdById: isAllPayments ? 'all' : user?.id,
            companyId: allCompanies?.[0]?.id
        }
    }, [allCompanies, currentProperty?.id, user?.id, isAllPayments])

    const { handleSubmit, register, watch, setValue, getValues, reset } = useForm({
        values: defaultValues
    })

    const watchProperty = watch('propertyIds')
    const watchFilterType = watch('filterType')
    const watchCompanyId = watch('companyId')
    const watchStartDate = watch('startDate')
    const watchEndDate = watch('endDate')
    const watchChequeStatus = watch('chequeStatus')
    const watchCreatedById = watch('createdById')

    const staffProperties = useMemo(() => {
        if (hasPermission(ALL_USER_ROLES.OWNER) && properties?.length)
            return properties.map(d => d.id)
        else
            return user?.staffProfile?.staffAccessDetails?.map(d => d.propertyId)
    }, [user?.staffProfile?.staffAccessDetails, hasPermission, properties])


    const filterData = {
        ...getValues(),
        payment_mode: 'cheque',
        page: currentPage,
        limit,
        propertyIds: watchProperty?.[0] === 'all' ? join(staffProperties, ',') : join(watchProperty, ',') || [],
        companyId: watchCompanyId,
        startDate: watchStartDate,
        endDate: watchEndDate,
        chequeStatus: watchChequeStatus
    }

    const randomId = useMemo(() => {
        return currentPage + Date.now()
    }, [currentPage])

    const { data: duePaymentList, loading: loadingDuePaymentReport, mutate: mutateList } = usePaymentHistory(
        currentProperty?.id && getValues().companyId && getValues().createdById ?
            currentProperty.id + randomId
            :
            false
        ,
        filterData.createdById === 'all' ? omit(filterData, 'createdById') : filterData
    )

    const handlePageChange = (e) => {
        changePage(e)
    }

    const resetData = () => {
        reset(defaultValues)
        mutateList()
    }

    const _submit = () => {
        if (currentPage !== 1) {
            changePage(1)
        } else
            mutateList()
    }

    const _selectProperty = (data) => {
        const all = find(data, d => d?.id === 'all')
        let finalData = []
        if (all)
            finalData = ['all']
        else
            finalData = compact(data.map(d => d?.id))

        setValue('propertyIds', finalData.length ? finalData : [currentProperty?.id])
    }

    const handleCancelPayment = useCallback((type, payment) => {
        setCancelPayment({ type, payment });
    }, [setCancelPayment])

    const handleSuccessClear = useCallback(() => {
        mutateList();
    }, [mutateList])

    const handleExportAllPayment = () => {
        let data = omit(filterData, ['limit', 'page'])
        data = data.createdById === 'all' ? omit(data, 'createdById') : data
        data = { ...data, excel: true, pagination: 0 }
        let params = chain(data).map((fl, key) => `${key}=${fl}`).join('&').value()
        let url = BaseURL + URIS.GET_PAYMENT_HISTORY + '?' + params;

        window.open(url);
    }

    const staffPropertiesList = useMemo(() => {
        if (properties?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length) {
                return user.staffProfile.staffAccessDetails.map(d => d.property)
            } else {
                return properties
            }
        }
    }, [properties, user])

    const staffCompanies = useMemo(() => {
        if (allCompanies?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length)
                return intersectionBy(allCompanies, user.staffProfile.staffAccessDetails.map(d => ({ id: d.property.companyId })), 'id')
            else
                return allCompanies
        }
    }, [allCompanies, user])

    return (
        <Box py={4}>
            {
                (cancelPayment?.type === 'bounce' || cancelPayment?.type === 'clear') &&
                <CancelPaymentModal onSuccess={handleSuccessClear} type={cancelPayment.type} closeModal={() => setCancelPayment(null)} payment={cancelPayment.payment} />
            }
            <Text color={'secondary'} fontWeight='bold' fontSize='default'>Cheque Payment History</Text>
            <br />
            <LoadingContainer loading={loadingCompanies || loadingStaff}>
                <Flex gap={4}>
                    <StatsCard label={'Total payment'} count={(duePaymentList?.totalAmount || 0)}
                    />
                    <StatsCard label={'Total cheque'} number count={(duePaymentList?.countChequeTotal || 0)}
                    />
                    <StatsCard label={'Pending cheque'} number count={(duePaymentList?.countChequePending || 0)}
                    />
                    <StatsCard label={'Cleared cheque'} number count={(duePaymentList?.countChequeSuccess || 0)}
                    />
                    <StatsCard label={'Bounced cheque'} number count={(duePaymentList?.countChequeBounce || 0)}
                    />
                </Flex>
                <br />
                <form onSubmit={handleSubmit(_submit)}>
                    <Flex align={'end'} gap={4} mb={2}>
                        <FormControl>
                            <FormLabel>Company</FormLabel>
                            <Select size='sm' {...register('companyId')}>
                                {staffCompanies?.length ?
                                    staffCompanies.map(company => {
                                        return <option value={company.id} key={company.id}>{company.name}</option>
                                    })
                                    :
                                    null
                                }
                            </Select>
                        </FormControl>
                        {isAllPayments &&
                            <FormControl>
                                <FormLabel>Accountant</FormLabel>
                                <DropdownList
                                    onChange={e => setValue('createdById', e.id)}
                                    placeholder={'All'}
                                    data={allStaff?.length ? [{ name: 'All Accountants', id: 'all' }, ...allStaff.map(d => d.staff)] : []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watchCreatedById}
                                />
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input type={'date'} {...register('startDate')} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>End Date</FormLabel>
                            <Input type={'date'} {...register('endDate')} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Type</FormLabel>
                            <Select {...register('chequeStatus')} placeholder='All'>
                                <option value={'success'}>Cleared</option>
                                <option value={'bounce'}>Bounced</option>
                                <option value={'pending'}>Pending</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    <Flex gap={4} py={2} align='end' mb={4}>
                        <Box>
                            <FormControl>
                                <FormLabel>Select Property</FormLabel>
                                <Multiselect
                                    onChange={e => _selectProperty(e)}
                                    placeholder={'select hostel'}
                                    data={[{ name: 'All', id: 'all' }, ...staffPropertiesList] || []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watchProperty}
                                />
                            </FormControl>
                        </Box>
                        <HStack>
                            <Button type='submit' size={'sm'}>Apply</Button>
                            {duePaymentList?.results?.length ?
                                <Button onClick={handleExportAllPayment} variant='ghost' w='full' leftIcon={<DownloadIcon />}>Export Excel</Button>
                                :
                                null
                            }

                            {/* <Button variant={'outline'} onClick={resetData} size={'sm'}>Reset</Button> */}
                        </HStack>
                    </Flex>
                </form>
                <LoadingContainer loading={loadingDuePaymentReport}>
                    {duePaymentList?.results?.length ?
                        <Box>
                            <Table className='responsiveTable' variant={'simple'}>
                                <Thead>
                                    <Tr>
                                        <Th>Sr No.</Th>
                                        <Th>Tenant</Th>
                                        <Th>Invoice</Th>
                                        <Th>Installments</Th>
                                        <Th>Total Payment</Th>
                                        <Th>Property/Hostel</Th>
                                        <Th>Accountant</Th>
                                        <Th>Date</Th>
                                        <Th>Status</Th>
                                        {hasAccess(staffPermissions.CHEQUE_STATUS) &&
                                            <Th>Action</Th>
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        duePaymentList.results.map((payment, i) => {
                                            const paymentCategories = payment.paymentReceipts
                                            const chequeHistory = orderBy(payment.paymentDetails_json?.chequeHistory, 'date', ['asc']);
                                            const isBounced = chequeHistory.at(-1)?.status === 'bounce';
                                            const isCleared = chequeHistory.at(-1)?.status === 'success';
                                            const isCancelled = payment.paymentDetails_json.chequeStatus === 'cancel';
                                            const tenant = payment.tenant;
                                            const hostel = find(properties, p => p.id === payment.propertyId)
                                            const createdBy = find(allStaff, st => st.staff.id === payment.createdById)
                                            const css = isCancelled ? { as: 'del' } : {}
                                            return (
                                                <Tr bg={isCancelled && 'red.50'} key={payment.id}>
                                                    <Td><Text {...css}>{((currentPage - 1) * limit) + (i + 1)}</Text></Td>
                                                    <Td>
                                                        <Button {...css} onClick={() => navigate(`/tenant/profile/${tenant?.id}/6`)} variant={'link'}>
                                                            {tenant?.name}
                                                        </Button>
                                                    </Td>
                                                    <Td>
                                                        <Text {...css}>{payment.paymentDetails_json.slipNo}</Text>
                                                    </Td>
                                                    <Td>
                                                        {paymentCategories?.length ?
                                                            <Table mb={4} variant={'simple'}>
                                                                <Tbody>
                                                                    {paymentCategories.map(receipt =>
                                                                        <Tr key={receipt.id}>
                                                                            <Td>
                                                                                <Text {...css}>{receipt.tenantDues?.propertyDueCategory?.dueCategory?.name}</Text>
                                                                            </Td>
                                                                            <Td width={100}>
                                                                                <Text {...css}>₹{receipt.amount}</Text>
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </Tbody>
                                                            </Table>
                                                            :
                                                            null
                                                        }
                                                    </Td>
                                                    <Td><Text {...css}>₹ {isCancelled ? 0 : payment.amount}</Text></Td>
                                                    <Td><Text {...css}>{hostel?.name}</Text></Td>
                                                    <Td><Text {...css}>{createdBy?.staff?.name}</Text></Td>
                                                    <Td><Text {...css}>{moment(payment.paymentDate).format('LL HH:mm A')}</Text></Td>

                                                    <Td>
                                                        <HStack>
                                                            <Text color={isCancelled ? 'red.500' : isBounced ? 'orange.400' : isCleared ? 'green.400' : 'blue.400'} >
                                                                {isCancelled ? 'Cancelled' : isBounced ? 'Bounced' : isCleared ? 'Cleared' : 'Pending'}
                                                            </Text>
                                                            <Box>
                                                                <Popover trigger='hover'>
                                                                    <PopoverTrigger>
                                                                        <Box color='gray.400' as='span'><Icon fontSize={16} as={AiOutlineInfoCircle} /></Box>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent>
                                                                        <PopoverArrow />
                                                                        <PopoverBody>
                                                                            <VStack align={'start'}>
                                                                                <HStack>
                                                                                    <Text>Cheque No:</Text>
                                                                                    <Text>{payment.paymentDetails_json?.cheque_no || '-'}</Text>
                                                                                </HStack>
                                                                                <HStack>
                                                                                    <Text>Bank:</Text>
                                                                                    <Text>{payment.paymentDetails_json?.bank_name || '-'}</Text>
                                                                                </HStack>
                                                                                <HStack>
                                                                                    <Text>Bank Date:</Text>
                                                                                    <Text>{displayDateFormat(payment.paymentDetails_json?.bank_date, 'll') || '-'}</Text>
                                                                                </HStack>
                                                                                <HStack>
                                                                                    <Text>Payment Details:</Text>
                                                                                    <Text>{payment.paymentDetails_json?.payment_details || '-'}</Text>
                                                                                </HStack>
                                                                            </VStack>
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </Box>
                                                        </HStack>
                                                    </Td>
                                                    {hasAccess(staffPermissions.CHEQUE_STATUS) &&
                                                        <Td>
                                                            {!isCancelled &&
                                                                <>
                                                                    {
                                                                        payment.paymentDetails_json.chequeHistory.length === 1 &&
                                                                        <Flex gap={2} wrap='wrap'>
                                                                            <Button size='xs' ml={2} variant={'outline'} onClick={() => handleCancelPayment('bounce', payment)}>Bounce Cheque</Button>
                                                                            <Button size='xs' ml={2} variant={'outline'} onClick={() => handleCancelPayment('clear', payment)}>Clear Cheque</Button>
                                                                        </Flex>
                                                                    }
                                                                </>
                                                            }
                                                        </Td>
                                                    }
                                                </Tr>
                                            )
                                        }
                                        )
                                    }
                                </Tbody>
                            </Table>
                            <br />
                            <HStack justifyContent={'center'}>
                                <Pagination
                                    total={duePaymentList?.total || 10}
                                    pageSize={duePaymentList?.limit || 10}
                                    onChange={handlePageChange}
                                    current={parseInt(currentPage)}
                                />
                            </HStack>
                        </Box>
                        :
                        <Text color={'secondary'} >No history available</Text>
                    }
                </LoadingContainer>
            </LoadingContainer>
        </Box>
    )
}

export const StatsCard = ({ label, count, extra, number }) => {
    return (
        <Box height='100%'>
            <Stat border={'1px solid'} boxShadow='rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em' borderColor='gray.200' px={4} py={2} borderRadius={10}>
                <HStack justifyContent={'space-between'}>
                    <Box minW={130}>
                        <StatLabel color={'secondary'} fontSize={'small'}>{label}</StatLabel>
                        <StatNumber color={'gray.600'} fontSize={'large'}>{!number && '₹'} {Number(parseFloat(count).toFixed(2)).toLocaleString('en')}</StatNumber>
                    </Box>
                </HStack>
                {extra}
            </Stat>
        </Box>
    )
}