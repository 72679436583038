import React, { useState } from 'react'
import { Box, Button, Center, Flex, FormControl, Image, Input, Spinner, VStack } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { HiOutlineCamera } from 'react-icons/hi'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useRef } from 'react'

export default function UploadImages({ UploadURL, img, id, propertyId }) {
    const [ImageURL, setImageURL] = useState();
    const inputRef = useRef()
    const { request: uploadImage, loading: uploadLoading } = useApiRequest(URIS.UPLOAD_FILE, {
        onCompleted: (d) => {
            setImageURL(d)
            UploadURL(d)
        },
        onError: () => { },
    })

    const imageHandler = (e) => {
        if(e.target?.files?.length === 0) return;
        let formData = new FormData()
        formData.append('file', e.target.files?.[0])
        uploadImage({ method: 'POST', data: formData })
    }
    const removeImage = () => {
        setImageURL(null)
        UploadURL(null)
    }

    const handleSelectInput = () => {
        inputRef.current.click();
    }

    return (
        <VStack align={'stretch'} spacing={4}>
            <FormControl>
                <Flex flexWrap={'wrap'}>
                    <Flex onClick={ !uploadLoading && handleSelectInput } cursor='pointer' justifyContent={'center'} alignItems={'center'} boxShadow='lg' _hover={{ bg:'gray.50' }} mt={4} w={["100%", "75%", "45%", "18%"]} h="180px">
                        {uploadLoading ?
                            <Spinner size={'sm'} />
                            :
                            <Center>
                                <span>
                                    <HiOutlineCamera />
                                </span>
                                <Box ml={2} fontSize={'sm'} color='gray.500'>
                                Select image</Box>
                            </Center>
                        }
                    </Flex>
                    <Box display={'none'}>
                    <Input ref={inputRef} type="file" accept="image/*" onChange={imageHandler} />
                    </Box>
                    {ImageURL ?
                        <Flex flexWrap={"nowrap"} justifyContent="flex-end" h="195px" >
                            <Image boxShadow='lg' mt={4} ml={4} src={ImageURL?.url} alt="images" />
                            <Button mt={4} position="absolute" h={6} w={3} onClick={() => removeImage()}><CloseIcon /></Button>
                        </Flex>
                        : img ?
                            <Flex flexWrap={"nowrap"} justifyContent="flex-end" h="195px" >
                                <Image borderRadius={5} boxShadow='lg' border='1px solid' borderColor={'gray.200'} mt={4} ml={4} src={img} alt="images" />
                                <Button colorScheme={'orange'} borderRadius={'50%'} p={'16px'} mr={-3} w={6} h={6} position="absolute" onClick={() => removeImage()}><CloseIcon /></Button>
                            </Flex>
                            :
                            null
                    }
                </Flex>
            </FormControl>
        </VStack>
    )
}