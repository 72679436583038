import React, { useEffect, useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Center, Flex, FormControl, FormLabel, Image, Input, Spinner, VStack } from '@chakra-ui/react'

import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useGetProperties } from '../../services/api/useGetProperties'
import { HiOutlineCamera } from 'react-icons/hi'
import { useRef } from 'react'

export const AddImages = ({ id, propertyId, img, afterSubmit }) => {
    const { mutate } = useGetProperties('organisation_properties')
    const [ImageURL, setImageURL] = useState([])
    const inputRef = useRef()
    
    useEffect(() => {
        if (img)
            setImageURL(img)
        else
            setImageURL([])
    }, [propertyId, img])
    const { request: updateProperty, loading: propertyLoading } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: () => {
            afterSubmit();
        },
        onError: () => { },
        showAlert: true
    })
    const { request: uploadImage, loading: uploadLoading } = useApiRequest(URIS.UPLOAD_FILE, {
        onCompleted: (d) => {
            setImageURL(ele => [...ele, d])
        },
        onError: () => { },
        showLoader:true
    })
    const imageHandler = (e) => {
        let formData = new FormData()
        formData.append('file', e.target.files?.[0])
        uploadImage({ method: 'POST', data: formData })
    }
    const removeImage = (fileName) => {
        setImageURL(ImageURL.filter(d => d.fileName !== fileName))
    }
    const _uploadImage = (e) => {
        e.preventDefault()
        updateProperty({ method: 'PATCH', data: { id: id, propertyId: propertyId, photos: ImageURL } })
    }

    const handleSelectInput = () => {
        inputRef.current.click();
    }

    return (
        <form onSubmit={_uploadImage}>
            <VStack align={'stretch'} spacing={4}>
                <FormControl>
                    <FormLabel>Add Photos</FormLabel>
                    <Flex flexWrap={'wrap'} align="center">
                        <Flex onClick={ !uploadLoading && handleSelectInput } cursor='pointer' justifyContent={'center'} alignItems={'center'} boxShadow='lg' _hover={{ bg:'gray.50' }} mt={4} ml={4} w={["100%", "75%", "45%", "18%"]} h="180px">
                            {uploadLoading ?
                                <Spinner size={'sm'} />
                                :
                                <Center>
                                    <span>
                                        <HiOutlineCamera />
                                    </span>
                                    <Box ml={2} fontSize={'sm'} color='gray.500'>
                                    Select image</Box>
                                </Center>}
                        </Flex>
                        {ImageURL &&
                            ImageURL.map((d, index) => (
                                <Flex key={index} boxShadow='lg' mt={4} ml={4} justifyContent={"flex-end"} flexWrap={"nowrap"} h="180px" >
                                    <Image borderRadius={5} boxShadow='lg' border='1px solid' borderColor={'gray.200'} src={d?.url} alt="images" />
                                    <Button colorScheme={'orange'} borderRadius={'50%'} p={'16px'} mt={'-10px'} mr={-3} w={6} h={6} position="absolute" fontSize={'11px'} onClick={() => removeImage(d.fileName)}><CloseIcon /></Button>
                                </Flex>
                            ))
                        }
                    </Flex>
                    <Box display={'none'}>
                    <Input ref={inputRef} type="file" accept="image/*" onChange={imageHandler} />
                    </Box>
                </FormControl>
                {!ImageURL?.length ?
                    <Button w="100px" mt={3} type="submit" isDisabled>Save</Button>
                    :
                    <Button w="100px" mt={3} type="submit">Save</Button>
                }
            </VStack>
        </form>
    )
}