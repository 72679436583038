export const ROUTES = {
    LOGIN: '/login',
    REGISTER: '/register',
    PROPERTIES: '/properties',
    SINGLE_PROPERTY: '/properties/:id',
    ADD_PROPERTIES: '/add-property/:type',
    TENANT: '/tenants/:page',
    TENANTPROFILE: '/tenant/profile/:id',
    DASHBOARD: '/dashboard',
    WEBSITE: '/website',
    WEBSITEHOMEPAGE: '/webhomepage',
    STUDENT_PAYMENT: '/tenant/:tenantId/payment1',
    TENANT_PAYMENT: '/tenant/payment',
    PAYMENTS_STATUS: '/tenant/payment/status',
    VERIFY_OTP: '/tenant/:tenantId/payment/verify-tenant-otp',
    STUDENT_PAYMENT_HOMEPAGE: '/tenant/:tenantId/payment/verify-tenant-otp/tenant-payment'
}