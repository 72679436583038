import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Switch, Tag, Text, VStack } from '@chakra-ui/react'
import _, { indexOf, xor } from 'lodash';
import { URIS } from '../../services/api';
import { useApiRequest } from '../../services/api/useApiRequest';
import { useStaffData } from '../../services/api/useStaffData';
import { staffPermissions } from '../../Constants';

export const PropertyPermissionModal = ({ closeModal, singlestaffId, resetstaff }) => {
    const { data: singlestaff, mutate } = useStaffData(URIS.GET_STAFF, { staffId: singlestaffId, staffAccessDetails: 1 }, true)
    const [properties, changeProperties] = useState([])

    const { request: updateAssignProperty, loading: addLoading } = useApiRequest(URIS.ADD_ASSIGN_PROPERTY, {
        onCompleted: (d) => {
            mutate()
        },
        onError: () => { },
        showAlert: true
    })

    useEffect(() => {
        if (singlestaff?.[0].staffAccessDetails?.[0]?.accesses_json)
            changeProperties(singlestaff?.[0].staffAccessDetails?.[0]?.accesses_json)
    }, [singlestaff])

    const handleToggleStatus = (key) => {
        let permissions = [...properties]
        permissions = xor(permissions, [key])
        changeProperties(permissions)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        updateAssignProperty({ method: 'PATCH', data: singlestaff?.[0].staffAccessDetails.map(d => ({ id: d.id, accesses_json: properties })) })
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
                <ModalContent>
                    <ModalHeader>Property Permission</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {singlestaff?.[0].staffAccessDetails?.length ?
                            <>
                                <Flex gap={2} wrap='wrap'>
                                    {_.map(singlestaff?.[0].staffAccessDetails, (d) => <Tag key={d.id}>{d.property.name}</Tag>)}
                                </Flex>
                                <Box mt={4} border='1px solid #E5E8E8' maxH="60vh" overflowY="scroll" className='scrollbar-1' >
                                    <Box position={"sticky"} bgColor="white" zIndex={1} top={"0px"} p={2}>
                                        <Text fontWeight='semibold' borderBottom={'1px solid #E5E8E8'}   >Permission</Text>
                                    </Box>
                                    <Box p={2} >
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Dashboard</FormLabel>
                                            <Switch
                                                id={staffPermissions.DASHBOARD}
                                                isChecked={indexOf(properties, staffPermissions.DASHBOARD) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.DASHBOARD)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Tenant</FormLabel>
                                            <Switch
                                                id='tenant'
                                                isChecked={indexOf(properties, 'tenant') !== -1}
                                                onChange={e => handleToggleStatus('tenant')}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Add Tenant</FormLabel>
                                            <Switch
                                                id={staffPermissions.ADD_TENANT}
                                                isChecked={indexOf(properties, staffPermissions.ADD_TENANT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.ADD_TENANT)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Collect Payment</FormLabel>
                                            <Switch
                                                id={staffPermissions.COLLECT_PAYMENT}
                                                isChecked={indexOf(properties, staffPermissions.COLLECT_PAYMENT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.COLLECT_PAYMENT)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Property</FormLabel>
                                            <Switch
                                                id='property'
                                                isChecked={indexOf(properties, 'property') !== -1}
                                                onChange={e => handleToggleStatus('property')}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Attendance</FormLabel>
                                            <Switch
                                                id='attendance'
                                                isChecked={indexOf(properties, 'attendance') !== -1}
                                                onChange={e => handleToggleStatus('attendance')}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>In-out tenant</FormLabel>
                                            <Switch
                                                id='in_out'
                                                isChecked={indexOf(properties, 'in_out') !== -1}
                                                onChange={e => handleToggleStatus('in_out')}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Company Management</FormLabel>
                                            <Switch
                                                id={staffPermissions.COMPANY}
                                                isChecked={indexOf(properties, staffPermissions.COMPANY) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.COMPANY)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>All Payments Report</FormLabel>
                                            <Switch
                                                id={staffPermissions.ALL_PAYMENTS}
                                                isChecked={indexOf(properties, staffPermissions.ALL_PAYMENTS) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.ALL_PAYMENTS)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Self Payments Report</FormLabel>
                                            <Switch
                                                id={staffPermissions.SELF_PAYMENTS}
                                                isChecked={indexOf(properties, staffPermissions.SELF_PAYMENTS) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.SELF_PAYMENTS)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Cheque Status</FormLabel>
                                            <Switch
                                                id={staffPermissions.CHEQUE_STATUS}
                                                isChecked={indexOf(properties, staffPermissions.CHEQUE_STATUS) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.CHEQUE_STATUS)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Reprint Slip</FormLabel>
                                            <Switch
                                                id={staffPermissions.REPRINT_SLIP}
                                                isChecked={indexOf(properties, staffPermissions.REPRINT_SLIP) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.REPRINT_SLIP)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Edit Slip Number</FormLabel>
                                            <Switch
                                                id={staffPermissions.EDIT_SLIP_NUMBER}
                                                isChecked={indexOf(properties, staffPermissions.EDIT_SLIP_NUMBER) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.EDIT_SLIP_NUMBER)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Edit Category Due Amount</FormLabel>
                                            <Switch
                                                id={staffPermissions.EDIT_CATEGORY_DUE_AMOUNT}
                                                isChecked={indexOf(properties, staffPermissions.EDIT_CATEGORY_DUE_AMOUNT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.EDIT_CATEGORY_DUE_AMOUNT)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Add concession</FormLabel>
                                            <Switch
                                                id={staffPermissions.CONCESSION_ADD}
                                                isChecked={indexOf(properties, staffPermissions.CONCESSION_ADD) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.CONCESSION_ADD)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Cancel Due</FormLabel>
                                            <Switch
                                                id={staffPermissions.CANCEL_DUE}
                                                isChecked={indexOf(properties, staffPermissions.CANCEL_DUE) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.CANCEL_DUE)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Cancel Payment</FormLabel>
                                            <Switch
                                                id={staffPermissions.CANCEL_PAYMENT}
                                                isChecked={indexOf(properties, staffPermissions.CANCEL_PAYMENT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.CANCEL_PAYMENT)}
                                            />
                                        </FormControl>

                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Change Next Slip Payment Amount</FormLabel>
                                            <Switch
                                                id={staffPermissions.CHANGE_NEXT_SLIP_PAYMENT_AMOUNT}
                                                isChecked={indexOf(properties, staffPermissions.CHANGE_NEXT_SLIP_PAYMENT_AMOUNT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.CHANGE_NEXT_SLIP_PAYMENT_AMOUNT)}
                                            />
                                        </FormControl>

                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Mess</FormLabel>
                                            <Switch
                                                id={staffPermissions.MESS}
                                                isChecked={indexOf(properties, staffPermissions.MESS) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.MESS)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }} isDisabled={!(properties.some(str => str === "tenant"))}>
                                            <FormLabel>Tenant Id Card</FormLabel>
                                            <Switch
                                                id={staffPermissions.ID_CARD}
                                                isChecked={indexOf(properties, staffPermissions.ID_CARD) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.ID_CARD)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>View Tenant Contact</FormLabel>
                                            <Switch
                                                id={staffPermissions.VIEW_TENANT_CONTACT}
                                                isChecked={indexOf(properties, staffPermissions.VIEW_TENANT_CONTACT) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.VIEW_TENANT_CONTACT)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Edit Tenant Name</FormLabel>
                                            <Switch
                                                id={staffPermissions.EDIT_TENANT_NAME}
                                                isChecked={indexOf(properties, staffPermissions.EDIT_TENANT_NAME) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.EDIT_TENANT_NAME)}
                                            />
                                        </FormControl>
                                        <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }}>
                                            <FormLabel>Biometric Device</FormLabel>
                                            <Switch
                                                id={staffPermissions.DEVICE}
                                                isChecked={indexOf(properties, staffPermissions.DEVICE) !== -1}
                                                onChange={e => handleToggleStatus(staffPermissions.DEVICE)}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box position={"sticky"} bgColor="white" bottom={"0px"}>
                                        <Button type='submit' isLoading={addLoading} m={3}>Save</Button>
                                    </Box>
                                </Box>
                            </>
                            :
                            <Text color={'gray.500'}>No properties assinged</Text>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}
