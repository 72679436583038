import React, { useState } from 'react'
import moment from 'moment'
import "react-widgets/styles.css";
import _ from 'lodash';
import { Button, FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Text, Textarea, VStack } from '@chakra-ui/react'
import { floorName } from '../../utils/Helper'
import { useGetProperties } from '../../services/api/useGetProperties';

export const LeaveRoomModal = ({ visible, closeModal, currentTenant, assignedUnit, action, tenantUnit, loading }) => {

    const [date, changeDate] = useState(new Date())
    const [remark, changeRemark] = useState()

    const { data: allProperties } = useGetProperties(true)

    const handleChangeDate = (d) => {
        // console.log({ d })
        changeDate(d)
    }

    const todate = moment().format('YYYY/MM/DD')
    const yesterdate = moment().subtract(1, "day").format('YYYY/MM/DD')

    const handleRemove = (e) => {
        e.preventDefault()
        const unitTenant = currentTenant.unitTenants?.length ? currentTenant.unitTenants[0] : null
        const data = { id: unitTenant.id, remark, leavingDate: date ? date === "tod" ? todate : date === "yes" ? yesterdate : null : null }
        // console.log({ data })
        action({ method: "PATCH", data })
    }

    const handleChangeRemark = (e) => {
        changeRemark(e.target.value)
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove Tenant From Room</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleRemove}>
                    <ModalBody>
                        <VStack spacing={4} align='stretch'>
                            <HStack>
                                <Text>Property: </Text>
                                <Text color={'defaultColor.500'}>{assignedUnit ? _.find(allProperties, p => p.id === assignedUnit.propertyId)?.name : '-'}</Text>
                            </HStack>
                            <HStack>
                                <Text>Floor: </Text>
                                <Text color={'defaultColor.500'}>{assignedUnit ? `${floorName(assignedUnit.floorNo)}` : '-'}</Text>
                            </HStack>
                            <HStack>
                                <Text>Room: </Text>
                                <Text color={'defaultColor.500'}>{assignedUnit ? assignedUnit.name : '-'}</Text>
                            </HStack>
                            <FormControl isRequired>
                                <FormLabel wordBreak={'keep-all'} minW={100}>Leaving Date</FormLabel>
                                <RadioGroup onChange={(e) => handleChangeDate(e)}>
                                    <VStack align={"start"}>
                                        <Radio size="md" type='text' borderRadius={'5px'} border={'1px solid rgba(0, 0, 0, 0.3)'} value="tod">Today ({todate})</Radio>
                                        <Radio size="md" type='text' borderRadius={'5px'} border={'1px solid rgba(0, 0, 0, 0.3)'} value="yes">Yesterday ({yesterdate})</Radio>
                                    </VStack>
                                </RadioGroup>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Remark</FormLabel>
                                <Textarea name='remark' onChange={handleChangeRemark} value={remark} />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={loading} disabled={!date} type='submit'>Remove</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}