import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'
import { useUserData } from './useUserData'


export function useCompanyPaymentConfigs(q, params) {
    const { organisation } = useUserData(true)

    const { data, error, loading, mutate } = useGetData(URIS.GET_COMPANY_PAYMENT_CONFIGS + q, () => apis.getCompaniesPaymentConfig({ organisationId: organisation?.id }), q)

    return {
        data, error, loading, mutate
    }
}