import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantDueReport(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_TENANT_DUE_REPORT+q, () => apis.getTenantDueReports(params), q, revalidate)

    return {
        data, error, loading, mutate
    }
}