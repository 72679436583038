import { Box, Flex, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react"
import _, { compact, find, join } from "lodash"
import moment from "moment"
import { useEffect, useMemo } from "react"
import { allPaymentModes, PAYMENT_METHODS } from "../../Constants"
import { useSingleProperty } from "../../services/api/useSingleProperty"
import { useStates } from "../../services/api/useStates"

export const BasicSlipHeader = ({ tenantData, payment }) => {
    const propertyId = payment.propertyId

    const { data: allStates } = useStates(true)
    const { data: property } = useSingleProperty(propertyId, { id: propertyId })

    const company = property?.company
    const companyAddress = company?.address
    const companyState = _.find(allStates, state => state.id === company?.address.state)
    const city = companyState?.cities?.length ? find(companyState.cities, city => city.id == companyAddress?.district) : null
    const address = join(compact([companyAddress?.streetAddress, companyAddress?.town, city?.name, _.capitalize(companyState?.name), companyAddress?.pincode]), ', ')

    let receiptdetail = useMemo(() => {
        let data = [
            { id: 1, title: 'Invoice No.', value: payment.paymentDetails_json?.slipNo },
            { id: 4, title: 'Mode', value: allPaymentModes[payment.payment_mode]?.name + (payment?.paymentDetails_json?.payment_details ? " " + payment.paymentDetails_json?.payment_details : '') },
            { id: 4, title: 'Reg. no.', value: tenantData.registrationNo },
        ]
        let more = []
        if (payment.payment_mode === PAYMENT_METHODS.CHEQUE) {
            more = [
                { title: 'Bank Name', value: payment.paymentDetails_json?.bank_name },
                { title: 'Bank Date', value: payment.paymentDetails_json.bank_date ? moment(payment.paymentDetails_json?.bank_date).format('ll') : '' },
                { title: 'Cheque No.', value: payment.paymentDetails_json?.cheque_no },
            ]
        } else if (payment.payment_mode === PAYMENT_METHODS.DD) {
            more = [
                { title: 'Bank Name', value: payment.paymentDetails_json?.bank_name },
                { title: 'Bank Date', value: payment.paymentDetails_json?.bank_date ? moment(payment.paymentDetails_json?.bank_date).format('ll') : '' },
            ]
        }

        return [...data, ...more]

    }, [payment, tenantData])

    const tenantdetail = [
        { id: 1, title: 'Date', value: moment(payment.paymentDate).format('ll') },
        { id: 2, title: 'Name', value: tenantData.tenant.name },
    ]

    return (
        <>
            <VStack mb={2} spacing={1}>
                <Text fontSize={'lg'} fontWeight='bold'>{company?.name}</Text>
                <Text>{address}</Text>
            </VStack>
            <Flex align={'end'} justifyContent={'space-between'} w='100%'>
                <Box>
                    {_.map(receiptdetail, (d, i) =>
                        <Flex key={i}>
                            <Box>{d.title} :</Box>
                            <Box pl={1} fontWeight='semibold'>{d.value}</Box>
                        </Flex>
                    )}
                </Box>
                <Box>
                    <Flex fontWeight={'semibold'} justifyContent='flex-end' fontSize={16}>Student Copy</Flex>
                    {_.map(tenantdetail, d =>
                        <Flex key={d.id} justifyContent='flex-end'>
                            <Box>{d.title} :</Box>
                            <Box pl={1} fontWeight='semibold'>{d.value}</Box>
                        </Flex>
                    )}
                </Box>
            </Flex >
        </>
    )
}


export const GstSlipHeader = ({ tenantData, payment }) => {
    const propertyId = payment.propertyId

    const { data: allStates } = useStates(true)
    const { data: property } = useSingleProperty(propertyId, { id: propertyId })

    const company = property?.company
    const companyAddress = company?.address
    const companyState = _.find(allStates, state => state.id === company?.address.state)
    const city = companyState?.cities?.length ? find(companyState.cities, city => city.id == companyAddress?.district) : null
    const address = join(compact([companyAddress?.streetAddress, companyAddress?.town, city?.name, _.capitalize(companyState?.name), companyAddress?.pincode]), ', ')
    const cancelled = payment.status === 'cancelled'
    const refund = payment.payment_type === "credit"

    // let receiptdetail = useMemo(() => {
    //     let more = []
    //     if (payment.payment_mode === PAYMENT_METHODS.CHEQUE) {
    //         more = [
    //             { title: 'Bank Name', value: payment.paymentDetails_json?.bank_name },
    //             { title: 'Bank Date', value: payment.paymentDetails_json.bank_date ? moment(payment.paymentDetails_json?.bank_date).format('ll') : '' },
    //             { title: 'Cheque No.', value: payment.paymentDetails_json?.cheque_no },
    //         ]
    //     } else if (payment.payment_mode === PAYMENT_METHODS.DD) {
    //         more = [
    //             { title: 'Bank Name', value: payment.paymentDetails_json?.bank_name },
    //             { title: 'Bank Date', value: payment.paymentDetails_json?.bank_date ? moment(payment.paymentDetails_json?.bank_date).format('ll') : '' },
    //         ]
    //     }
    //     return more;

    // }, [payment])

    return (
        <Box mb={0}>
            <Flex align='start' justifyContent='space-between'>
                <Box>
                    <Box w={140} >
                        {
                            company.logo &&
                            <Image w='100%' h='100%' alt="" src={company.logo} />
                        }
                    </Box>
                </Box>
                <VStack pl={4} spacing={0} align='start'>
                    <Heading fontSize={12}>{refund ? 'REFUND CHALLAN' : 'TAX INOVICE'}</Heading>
                    {company.gstin && <Box fontSize={12}>GST IN : {company.gstin}</Box>}
                    {company.place_of_service && <Box fontSize={12}>PLACE OF SERVICE : {company.place_of_service}</Box>}
                    {company.state_code && <Box fontSize={12}>STATE CODE : {company.state_code}</Box>}
                    {company.cin && <Box fontSize={12}>CIN : {company.cin}</Box>}
                </VStack>
                <VStack pl={6} spacing={0} align='start'>
                    {cancelled && <Heading fontSize={14}>Cancelled</Heading>}
                    <Heading fontSize={12}>{refund ? 'CHALLAN NO.' : 'INVOICE NO.'} : {payment.paymentDetails_json.slipNo}</Heading>
                    <Heading fontSize={12}>{refund ? 'DATE' : 'INVOICE DATE'} : {moment(payment.paymentDate).format('DD-MMM-YYYY')}</Heading>
                </VStack>
            </Flex>

            {address && <Box textAlign='center' fontSize={12} mt={2}>{address}</Box>}
            <HStack align='start' justify='space-between' spacing={10} mt={3}>
                <VStack spacing={1} align='start'>
                    <Heading m={0} fontSize={12}>Name : {tenantData.tenant.name}</Heading>
                    <Heading m={0} fontSize={12}>Registration No : {tenantData.registrationNo}</Heading>
                </VStack>
                <Heading fontSize={12}>Mode : {allPaymentModes[payment.payment_mode]?.name + (payment?.paymentDetails_json?.payment_details ? " " + payment.paymentDetails_json?.payment_details : '')}</Heading>

                {/* <VStack spacing={0} align='start'>
                    {_.map(receiptdetail, (d, i) =>
                            <Flex key={i}>
                                <Box>{d.title} :</Box>
                                <Box pl={1} fontWeight='semibold'>{d.value}</Box>
                            </Flex>
                        )}
                </VStack> */}
            </HStack>

        </Box>
    )
}
