import React, { useEffect, useReducer } from 'react'
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { useOrganisation } from '../../app/Context'
import { applicable_types, INSTALLMENT_TYPE, installment_types } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useDueCategories } from '../../services/api/useDueCategories'
import { FormReducer } from '../../utils/FormReducer'

export const AddCategoryModal = ({ visible, closeModal, defaultData }) => {
    const [categoryData, changeCategoryData] = useReducer(FormReducer, {})
    const { mutate } = useDueCategories(true)

    const organisation = useOrganisation()
    const { request: addCategoryAction, loading } = useApiRequest(URIS.ADD_CATEGORY, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true
    })

    useEffect(() => {
        if (defaultData) {
            const { name, amount, installmentType, billing_cycle_from, apply_from_from_day, due_from_from_day, applicableType, cgst, igst, sgst, hsn } = defaultData
            const data = { name, amount, installmentType, billing_cycle_from, apply_from_from_day, due_from_from_day, applicableType, cgst, igst, sgst, hsn }
            changeCategoryData({ type: 'reset', value: data })
        }
    }, [defaultData])

    const handleChange = (type, value) => {
        changeCategoryData({ type, value })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (defaultData)
            addCategoryAction({ method: 'PATCH', data: { ...categoryData, id: defaultData.id } })
        else
            addCategoryAction({ method: 'POST', data: { ...categoryData, organisationId: organisation.id } })
    }

    const handleInstallmentType = (e) => {
        const installmentType = e
        if (installmentType === 'OT') {
            const data = { installmentType }
            changeCategoryData({ type: 'merge', value: data })
        } else
            changeCategoryData({ type: 'installmentType', value: installmentType })
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{ defaultData?.id ? 'Edit Category' : 'Add New Category' }</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>Category Title</FormLabel>
                                <Input name='name' value={categoryData.name} placeholder='category title' onChange={(e) => handleChange('name', e.target.value)} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Installment Type</FormLabel>
                                <RadioGroup name='installmentType' value={categoryData.installmentType} onChange={handleInstallmentType}>
                                    <HStack>
                                        {_.map(installment_types, type =>
                                            <Radio value={type.value}>{type.title}</Radio>
                                        )}
                                    </HStack>
                                </RadioGroup>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Applicable On</FormLabel>
                                <RadioGroup name='applicableType' value={parseInt(categoryData.applicableType)} onChange={(e) => handleChange('applicableType', e)}>
                                    <HStack>
                                        {_.map(applicable_types, type =>
                                            <Radio value={type.value}>{type.title}</Radio>
                                        )}
                                    </HStack>
                                </RadioGroup>
                            </FormControl>

                            
                            <FormControl>
                                <FormLabel>CGST(%)</FormLabel>
                                <Input name='cgst' value={categoryData.cgst} placeholder='%' onChange={(e) => handleChange('cgst', e.target.value)} />
                            </FormControl>
                            
                            <FormControl>
                                <FormLabel>SGST(%)</FormLabel>
                                <Input name='sgst' value={categoryData.sgst} placeholder='%' onChange={(e) => handleChange('sgst', e.target.value)} />
                            </FormControl>
                            
                            <FormControl>
                                <FormLabel>IGST(%)</FormLabel>
                                <Input name='igst' value={categoryData.igst} placeholder='%' onChange={(e) => handleChange('igst', e.target.value)} />
                            </FormControl>

                            <FormControl>
                                <FormLabel>HSN/SAC</FormLabel>
                                <Input name='hsn' value={categoryData.hsn} placeholder='hsn' onChange={(e) => handleChange('hsn', e.target.value)} />
                            </FormControl>
                            {categoryData.installmentType !== INSTALLMENT_TYPE.ONE_TIME &&
                                <>
                                    <FormControl>
                                        <FormLabel>Bill Apply Day from Billing Day </FormLabel>
                                        <Input type='number' value={categoryData.apply_from_from_day} placeholder='bill apply day' onChange={(e) => handleChange('apply_from_from_day', e.target.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Due Day from Billing Day</FormLabel>
                                        <Input type='number' value={categoryData.due_from_from_day} placeholder='due day' onChange={(e) => handleChange('due_from_from_day', e.target.value)} />
                                    </FormControl>
                                </>
                            }
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={loading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}