import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Center, Flex, Heading, Image, Stack, chakra, FormControl, FormLabel, Input, Checkbox, Button, Link, FormErrorMessage, useToast, HStack, PinInput, PinInputField } from '@chakra-ui/react'
import { map } from 'lodash';
import { useApiRequest } from '../../services/api/useApiRequest';
import { URIS } from '../../services/api';
import { useAppContext } from '../../app/Context';
import { ALL_USER_ROLES } from '../../Constants';
export function RegisterRoute(props) {
    const navigate = useNavigate()
    let location = useLocation();
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState(null);
    const { loginSuccess, user, curUserLoginDetail, setCurUserLoginDetail } = useAppContext();
    const [otpRoute, openOtpRoute] = useState()
    const toast = useToast();
    const [userData, setUserData] = useState()

    let from = location.state?.from?.pathname || "/";

    const fields = [
        {
            lable: "First Name",
            type: 'text',
            name: 'name',
            required: true,
        }, {
            lable: "Last Name",
            type: 'text',
            name: 'lastName',
            required: true,
        }, {
            lable: "Phone number",
            type: 'number',
            name: 'phone',
            required: true,
        }, {
            lable: "Email",
            type: 'email',
            name: 'email',
            required: true
        }, {
            lable: "Password",
            type: 'password',
            name: 'password',
            required: true,
        }, {
            lable: "Confirm Password",
            type: 'password',
            name: 'confirmPass',
            required: true
        },
    ]

    const handleFormChange = (type, value) => {
        if (type === 'phone' && value.length > 10) return;
        setForm({ ...form, [type]: value })
        if (errors)
            isFormValid({ ...form, [type]: value })
    }

    const handeNavigationToHome = useCallback(() => {
        navigate(from, { replace: true });
    }, [navigate, from])

    const isFormValid = (formValue) => {
        const err = {};
        if (!formValue.name) {
            err['name'] = "Name is required."
        }
        if (!formValue.lastName) {
            err['lastName'] = "Last name is required."
        }
        if (!formValue.phone) {
            err['phone'] = "Phone number is required."
        } else if (formValue.phone.length !== 10) {
            err['phone'] = "Enter a correct Phone number."
        }
        if (!formValue.email) {
            err['email'] = "Email is required."
        } else if (!validateEmail(formValue.email)) {
            err['email'] = "Enter a correct Email."
        }
        if (!formValue.password) {
            err['password'] = "Password is required."
        }
        if (formValue.confirmPass !== formValue.password) {
            err['confirmPass'] = "Password not matched."
        }
        setErrors(err);
        return Object.keys(err).length === 0;
    }

    const handelFormSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid({ ...form })) return;
        const data = {
            name: form.name + " " + form.lastName,
            email: form.email,
            username: form.email,
            password: form.password,
            role: ALL_USER_ROLES.OWNER,
            contact: form.phone,
        }
        _createAccount(data);
    }
    const _onLoginCompleted = useCallback((data) => {
        loginSuccess(data)
    }, [loginSuccess])


    const { request: loginRequest, loading: loginLoading } = useApiRequest(URIS.LOGIN, {
        onCompleted: _onLoginCompleted,
        onError: (data) => onError(data),
        showLoader: false,
    })

    const _loginUser = useCallback(() => {
        loginRequest({ data: { username: form.email, password: form.password } })
    }, [loginRequest, form])

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const { request: sendOtpAction } = useApiRequest(URIS.SEND_OTP, {
        onCompleted: d => {
            if (d.success) {
                toast({
                    status: "success",
                    title: "Otp sent!",
                    position: "top",
                    duration: 1000,
                });
                openOtpRoute(d)
            }
        },
        showLoader: true
    })

    const _success = useCallback(
        (data) => {
            toast({
                status: "success",
                title: "OTP is sent to your phone number.",
                position: "top",
                duration: 1000,
            });
            setUserData(data?.userData)
            sendOtpAction({ mehod: 'POST', data: { contact: data?.userData.contact } })

        },
        [toast, sendOtpAction]
    );

    const onCompleted = useCallback(
        (data) => {
            if (data.error) {
                toast({
                    status: "warning",
                    title: "Registration Failed!",
                    position: "top",
                    duration: 2000,
                });
            } else {
                _success(data);
            }
        },
        [_success, toast]
    );

    const onError = useCallback(
        (data, response) => {
            toast({
                status: "error",
                title: "Registration Failed!",
                description: response?.message,
                isClosable: true,
                position: "top",
            });
        },
        [toast]
    );

    const { request: createAccount, loading } = useApiRequest(URIS.SIGNUP, {
        onCompleted,
        onError,
        showErrorAlert: false,
    });

    const _createAccount = (data) => {
        createAccount({ data });
    };

    useEffect(() => {
        if (user && user.contactVerified === 1) {
            handeNavigationToHome();
        }
    }, [user, handeNavigationToHome])

    const handleSendOtp = (contact) => {
        sendOtpAction({ mehod: 'POST', data: { contact: contact } })
    }

    useEffect(() => {
        if (curUserLoginDetail) {
            setForm({
                email: curUserLoginDetail.email,
                password: curUserLoginDetail.pass
            });
            toast({
                status: "success",
                title: "OTP is sent to your phone number.",
                position: "top",
                duration: 1000,
            });
            openOtpRoute(curUserLoginDetail);
            setCurUserLoginDetail(null);
        }
    }, [curUserLoginDetail])

    return (
        <div>
            <Box display={{ base: 'none', lg: 'block' }} style={{ position: 'fixed', bottom: '0px', right: '0px', opacity: '0.7', backgroundColor: '#ff4e00', backgroundImage: 'linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)', width: '55vh', height: '55vh', borderTopLeftRadius: '400px' }}>
            </Box>
            <Center minH={'100vh'} pos='relative' px={{ base: 0, lg: 10 }} >
                <Center w="100%" my={{ base: 0, lg: 6 }} zIndex="2">
                    <Stack minH={{ base: '100vh', lg: '85vh' }} maxh='650px' maxW='1250px' w='100%' boxShadow='0px 0px 30px -15px #0000007a' alignItems='stretch' direction={{ base: 'column', lg: 'row' }}>
                        <Flex flex={1.3} display={{ base: 'none', lg: 'block' }} minH="300px" bg="blue.400">

                        </Flex>
                        <Box m="0px!important" flex={2} bg="white" borderTopRightRadius={{ lg: 10 }} borderBottomRightRadius={{ lg: 10 }}>
                            <Box py={{ base: 8, lg: 14 }} px={{ base: 4, md: 8, lg: 18, xl: 20 }} ml={4}>
                                {otpRoute ?
                                    <VerifyOTP loginUser={_loginUser} sendOtp={() => handleSendOtp(userData?.contact || user?.contact)} tokenData={otpRoute} />
                                    :
                                    <Stack spacing={4} w={'full'}>
                                        <Heading textAlign={{ base: 'center', lg: 'left' }} fontWeight='700' fontSize={{ base: '2xl', lg: "2xl", xl: '3xl' }} color='gray.700' mt={{ base: 4, lg: 6 }} pb={{ base: 0, xl: 4 }} mb={{ base: 20, lg: 6 }}>Register</Heading>

                                        <Heading fontSize={{ base: 14, md: 14, lg: 15, xl: 20 }} color='gray.700'>Manage all your Hostel Efficiently</Heading>
                                        <chakra.h3 fontSize={{ base: 10, md: 12, lg: 13 }} color="gray.400" pb={6} borderBottom="1px solid" borderColor='gray.200'>Let's get you all set up so you can verify your personal account and begin setting up your profile.</chakra.h3>
                                        <Box>
                                            <form onSubmit={handelFormSubmit}>
                                                <Flex wrap='wrap'>
                                                    {
                                                        map(fields, f => <FormInputField required={f.required} key={f.name} type={f.type} name={f.name} label={f.lable} value={form[f.name]} error={errors && errors[f.name]} onChange={(value) => handleFormChange(f.name, value)} />)
                                                    }
                                                </Flex>
                                                <Flex justifyContent={{ base: 'center', lg: 'flex-start' }} mt={10}>
                                                    <Button type='submit' isLoading={loading || loginLoading} bg="#0070ff" color='white' _hover={{ bg: '#42638d' }} size='md'>Create Account</Button>
                                                </Flex>
                                            </form>
                                            <Flex justifyContent={{ base: 'center', lg: 'flex-start' }} mt={2} color="gray.500" fontWeight='700' fontSize={{ base: 14, lg: 14 }}>
                                                <Box>
                                                    <span>Already have a account?</span>&nbsp;
                                                    <Link onClick={() => navigate('/login')} color="blue.400">Log in</Link>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Stack>
                                }
                            </Box>
                        </Box>
                    </Stack>
                </Center>
            </Center >
        </div>
    )
}

const VerifyOTP = ({ tokenData, sendOtp, loginUser }) => {
    const [yourOtp, changeOtp] = useState('')
    const toast = useToast()

    const { request: sendOtpAction } = useApiRequest(URIS.VERIFY_OTP, {
        onCompleted: d => {
            if (d.success) {
                toast({
                    status: "success",
                    title: "Congratulations! your registeration is successful.",
                    position: "top",
                    duration: 1000,
                });
                loginUser()
            }
        },
        onError: d => {
        },
        showLoader: true
    })

    useEffect(() => {
        if (yourOtp.length === 4) {
            sendOtpAction({ method: 'PATCH', data: { otp: yourOtp, token: tokenData.token } })
            changeOtp('')
        }
    }, [sendOtpAction, tokenData.token, yourOtp])

    const handleSubmit = (e) => {
        e.preventDefault()
        sendOtpAction({ method: 'PATCH', data: { otp: yourOtp, token: tokenData.token } })
    }

    const handleRequestAgain = () => {
        sendOtp()
    }

    const handleChangeOtp = (e) => {
        changeOtp(e)
    }

    return (
        <Stack spacing={4} w={'full'}>
            <Heading textAlign={{ base: 'center', lg: 'left' }} fontWeight='700' fontSize={{ base: '2xl', lg: "2xl", xl: '3xl' }} color='gray.700' mt={{ base: 4, lg: 6 }} pb={{ base: 0, xl: 4 }} mb={{ base: 20, lg: 6 }}>Verify OTP</Heading>

            <Heading fontSize={{ base: 14, md: 14, lg: 15, xl: 20 }} color='gray.700'>Verify your phone number</Heading>
            <chakra.h3 fontSize={{ base: 10, md: 12, lg: 13 }} color="gray.400" pb={6} borderBottom="1px solid" borderColor='gray.200'>Enter the verification code we just sent you on your phone number.</chakra.h3>
            <Box>
                <form onSubmit={handleSubmit}>
                    <HStack>
                        <PinInput onChange={handleChangeOtp} size={'lg'}>
                            <PinInputField boxShadow={'md'} />
                            <PinInputField boxShadow={'md'} />
                            <PinInputField boxShadow={'md'} />
                            <PinInputField boxShadow={'md'} />
                        </PinInput>
                    </HStack>
                    <Flex justifyContent={{ base: 'center', lg: 'flex-start' }} mt={10}>
                        <Button type='submit' bg="#0070ff" color='white' _hover={{ bg: '#42638d' }} size='md'>Verify and Continue</Button>
                    </Flex>
                </form>
            </Box>
            <Flex justifyContent={{ base: 'center', lg: 'flex-start' }} mt={2} color="gray.500" fontWeight='700' fontSize={{ base: 14, lg: 14 }}>
                <Box>
                    <span>Didn't receive code?</span>&nbsp;
                    <Link onClick={handleRequestAgain} color="blue.400">Request again</Link>
                </Box>
            </Flex>
        </Stack>
    )
}

const FormInputField = ({ type, name, label, value, onChange, error, required }) => {
    return (
        <Box mt={{ base: 4, lg: 2 }} pr={2} pl={{ base: 0, lg: 2 }} w={{ base: "100%", lg: '50%' }}>
            <FormControl id={name} isRequired={required}>
                <FormLabel color="#26486c" fontWeight='500' fontSize={{ base: 10, md: 12, lg: 13 }}>{label}</FormLabel>
                <Input type={type} value={value} onChange={(e) => onChange(e.target.value)} borderRadius={5} name={name} />
                {
                    <Box color='red.400' fontSize={11}>{error || "  "}</Box>
                }
            </FormControl>
        </Box>
    )
}
