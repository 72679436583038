import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useSWR from 'swr'
import { URIS } from '.'
import { apis } from './apis'
import { useGetData } from '../../utils/useGetData'


export function useIdCardData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_ID_CARD_HISTORY + q, () => apis.getIdCardHistory(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}
