import React, { useEffect, useState } from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, Heading, HStack, Input, InputGroup, InputLeftAddon, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, VStack } from '@chakra-ui/react'
import { MdAir, MdBalcony, MdFoodBank, MdOutlineLocalLaundryService } from 'react-icons/md'
import { BiCctv } from 'react-icons/bi'
import { GiOfficeChair, GiCooler } from 'react-icons/gi'
import { RiFridgeLine } from 'react-icons/ri'
import _, { find, intersectionBy, isEmpty, map, orderBy, reduce, uniq, uniqBy } from 'lodash';

import UploadImages from './singleImage'
import { AddImages } from './WebsiteImages'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useStates } from '../../services/api/useStates'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { useUserData } from '../../services/api/useUserData'
import { useGetProperties } from '../../services/api/useGetProperties'
import { GuestReviewModal } from './ReviewGuestModal'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import HosteRules from './HosteRules'
import OrgDetailsModal from './OrgDetailsModal'
import { DropdownList } from 'react-widgets'
import { useMemo } from 'react'
// import Rating from 'material-ui-rating'


export default function WebsiteDesign() {
    const [ showOrgDetailModal, setShowOrgDetailModal ] = useState();
    const [ changePropertyModal, setChangePropertyModal ] = useState(false);

    const { organisation } = useUserData(true)
    //getting called twice - todo
    const { data: allPropertiesData, mutate } = useGetProperties('organisation_properties', { organisationId: organisation?.id })
    const [allProperties, setAllProperties] = useState()
    
    useEffect(() => {
        setAllProperties(allPropertiesData)
    }, [allPropertiesData])

    const [selectedProperty, setProperty] = useState()

    useEffect(() => {
        if (allProperties?.[0]?.websiteData !== null && !selectedProperty) {
            setProperty(allProperties?.[0])
        }else if(selectedProperty){
            setProperty(find(allProperties, p => p.id === selectedProperty.id))
        }
    }, [allProperties, selectedProperty])

    const { request: addWebsite } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: (d) => {
            setProperty(prev => ({ ...prev, websiteData: d }))
            mutate()
        },
        onError: () => { },
    })


    const handleSelectProperty = (d) => {
        setProperty(d)
        if (d.websiteData == null) {
            addWebsite({ method: "POST", data: { propertyId: d.id } })
        }
    }

    return (
        <Box>
            <ActionHeader title={'Website'} />
            <br />
            <CustomContainer justifyContent="center" w="100%">
                <Box px={{ base: 2, lg: 0 }}>
                    <HStack spacing={4}>
                        <Heading flex='1' fontSize='2xl' textAlign='left'>
                            {organisation?.name}
                        </Heading>
                    </HStack>
                    <Box mb={10} color='gray.600'>
                        <ExternalLinkIcon /> <a target='_blank' href={organisation.url} rel="noreferrer">{organisation.url}</a>
                        {/* <Box>{allProperties.length} properties</Box> */}
                    </Box>
                    <Box>
                        <Button onClick={ () => setShowOrgDetailModal(true) } colorScheme={'defaultColor'}>Edit details</Button>
                    </Box>
                    {/* <Box mx={[0, 6]} pb={4}>
                        <form style={{ width: "100%", }} onSubmit={organisationSubmit}>
                            <VStack align={'stretch'} spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Title</FormLabel>
                                    <Input id="name" defaultValue={organisation?.name} name="name" type="text" placeholder="Title" />
                                    <Input name="id" type="hidden" value={organisation?.id} placeholder="Id" />
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>URL</FormLabel>
                                    <InputGroup size='sm'>
                                        <InputLeftAddon children='https://' />
                                        <Input name="url" defaultValue={suburl} type="text" placeholder='mysite' />
                                        <InputRightAddon children='.hostellog.in' />
                                    </InputGroup>
                                </FormControl>
                                <Button w="100px" mt={3} type="submit">Save</Button>
                            </VStack>
                        </form>
                    </Box> */}
                    <Divider my={10}/>
                    {organisation ?
                        <Box>
                            <Flex justify={'space-between'}>
                                <Box>
                                    <Box fontSize='2xl' flex='1' mb={3} fontWeight={"bold"} textAlign='left'>
                                        {selectedProperty?.name}
                                    </Box>
                                    <Box mt={-2} color='gray.500' fontSize={'md'}>
                                        edit property website details
                                    </Box>
                                </Box>
                                <Button onClick={ () => setChangePropertyModal(true) }>Change property</Button>
                            </Flex>
                            {/* <Flex align={'center'} wrap={'wrap'}>
                                {allProperties?.length ?
                                    allProperties.map(property => {
                                        const selected = selectedProperty?.id === property.id
                                        return (
                                            <Box key={property.id} pr={2} pb={2}>
                                                <Button onClick={() => handleSelectProperty(property)} _focus={'none'}
                                                    colorScheme={!selected ? 'gray' : 'defaultColor'}
                                                >
                                                    {property.name}
                                                </Button>
                                            </Box>
                                        )
                                    })
                                    : <></>
                                }
                            </Flex> */}
                            <br />
                            {selectedProperty ?
                                <Box>
                                    <PropertyData mutate={mutate} selectedProperty={selectedProperty}/>
                                </Box>
                                :
                                null
                            }
                        </Box>
                        :
                        <></>
                    }
                    {
                        changePropertyModal &&
                        <ChangePropertyModal selecteProperty={handleSelectProperty} selectedProperty={selectedProperty} propertieData={allProperties} onClose={ () => setChangePropertyModal(false) } />
                    }
                    {
                        showOrgDetailModal &&
                        <OrgDetailsModal organisation={organisation} onClose={ () => setShowOrgDetailModal() } />
                    }
                </Box>
            </CustomContainer >
        </Box >
    )
}

const checkboxItem = [
    { id: 1, icon: <MdBalcony />, type: "balcony", title: "Balcony" },
    { id: 2, icon: <RiFridgeLine />, type: "fridge", title: "Fridge" },
    { id: 3, icon: <GiOfficeChair />, type: "chair", title: "Chair" },
    { id: 4, icon: <MdFoodBank />, type: "food", title: "Food" },
    { id: 5, icon: <MdOutlineLocalLaundryService />, type: "laundry", title: "Laundry" },
    { id: 6, icon: <BiCctv />, type: "cctv", title: "CCTV" },
    { id: 7, icon: <GiCooler />, type: "cooler", title: "Cooler" },
    { id: 7, icon: <MdAir />, type: "ac", title: "AC" },
]

export const PropertyData = ({ selectedProperty, mutate }) => {
    const id = selectedProperty?.websiteData?.id;
    const WebsiteData = useMemo( () => selectedProperty?.websiteData, [selectedProperty])
    const propertyId = selectedProperty?.id;
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ changedDetails, setChangedDetails ] = useState([]);
    const [ changedPropertyDetail, setChangedPropertyDetail ] = useState([]);
    const [propertyData, setPropertyData] = useState({showOnHostellog:false})
    const [propertyBasicData, setPropertyBasicData] = useState({ })
    
    const [logo, setLogo] = useState()
    const [header, setHeader] = useState()
    const [rating, setRating] = useState(0)
    const [imagedata, setImageData] = useState()

    const { organisation } = useUserData(true)
    // const { mutate } = useGetProperties('organisation_properties',  { organisationId: organisation?.id }, true)
    
    var facilities = []
    var contacts = []
    var socialurls = []
    var guestreviews = []
    //todo - remove
    if (WebsiteData) {
        if (typeof WebsiteData.facilities === 'string')
            facilities = JSON.parse(WebsiteData.facilities)
        else if (typeof WebsiteData.facilities === 'object')
            facilities = !isEmpty(WebsiteData.facilities) ? 
                _.chain(WebsiteData.facilities).keys().map(f => ({type:f})).value()
                :
                []
             
            facilities = intersectionBy(checkboxItem, facilities, 'type')

        if (typeof WebsiteData.contacts === 'string')
            contacts = JSON.parse(WebsiteData.contacts)
        else if (typeof WebsiteData.contacts === 'object')
            contacts = WebsiteData.contacts

        if (typeof WebsiteData.socialUrls === 'string')
            socialurls = JSON.parse(WebsiteData.socialUrls)
        else if (typeof WebsiteData.socialUrls === 'object')
            socialurls = WebsiteData.socialUrls

        if (typeof WebsiteData.guest_review_json === 'string')
            guestreviews = JSON.parse(WebsiteData.guest_review_json)
        else if (typeof WebsiteData.guest_review_json === 'object')
            guestreviews = WebsiteData.guest_review_json
    }

    const inputHandler = (e) => {
        const { name, value } = e.target
        setChangedDetails( p => uniq([...p, name ]))
        setPropertyData((preval) => ({ ...preval, [name]: value }))
    }
    const handleBasicChange = (e, newValue) => {
        let name, value;
        if(e.target){
            name = e.target.name;
            value = e.target.value;
        }else{
            name = e;
            value = newValue; 
        }
        setChangedPropertyDetail( p => uniq([...p, name ]))
        setPropertyBasicData((preval) => ({ ...preval, [name]: value }))
    }
    const contactHandler = (name, value) => {
        setChangedDetails( p => uniq([...p, 'contacts' ]))
        setPropertyData((preval) => ({ ...preval, contacts: ({ ...preval.contacts, [name]: value }) }))
    }
    const socialUrlHandler = (name, value) => {
        setChangedDetails( p => uniq([...p, 'socialUrls' ]))
        setPropertyData((preval) => ({ ...preval, socialUrls: ({ ...preval.socialUrls, [name]: value }) }))
    }

    useEffect( () => {
        if(selectedProperty){
            setPropertyBasicData({
                name: selectedProperty.name,
                gender: selectedProperty.gender,
                streetAddress: selectedProperty.streetAddress,
                state: selectedProperty.state,
                town: selectedProperty.town,
                pincode: selectedProperty.pincode,
                district: selectedProperty.district
            })
        }
    },[selectedProperty])

    useEffect(() => {
        if (WebsiteData)
            setPropertyData({
                id: id,
                showOnHostellog:WebsiteData.showOnHostellog,
                propertyId: propertyId,
                logo: logo !== undefined ? logo?.url : WebsiteData.logo || '',
                primary_color: WebsiteData.primary_color || "#000000",
                title: WebsiteData.title || '',
                short_desc: WebsiteData.short_desc || '',
                header_image: header !== undefined ? header?.url : WebsiteData.header_image || '',
                desc: WebsiteData.desc || '',
                facilities: facilities || [],
                price_min: WebsiteData.price_min,
                price_max: WebsiteData.price_max,
                locality: WebsiteData.locality,
                landmark: WebsiteData.landmark,
                latitude: WebsiteData.latitude,
                longitude: WebsiteData.longitude,
                contacts: {
                    phone: contacts?.phone || '',
                    alternatePhone: contacts?.alternatePhone || '',
                    email: contacts?.email || '',
                    alternateEmail: contacts?.alternateEmail || '',
                    worktimefrom: contacts?.worktimefrom || '',
                    worktimeto: contacts?.worktimeto || '',
                    address: contacts?.address || ''
                },
                socialUrls: {
                    facebook: socialurls?.facebook || '',
                    instagram: socialurls?.instagram || '',
                    twitter: socialurls?.twitter || '',
                    whatsApp: socialurls?.whatsApp || '',
                },
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, propertyId, WebsiteData])
 
    useEffect(() => {
        const urls = {
            logo: logo !== undefined ? logo?.url : WebsiteData?.logo,
            header_image: header !== undefined ? header?.url : WebsiteData?.header_image
        }
        setPropertyData(d => ({ ...d, ...urls }))
    }, [logo, header])


    const { request: updatePropertyWebsiteData, loading: propertyLoading } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: d => { 
            mutate();
            goToNextTab();
            setChangedDetails([]);
        },
        onError: () => { },
        showAlert: true,
        showLoader: true
    })

    const {request: updateProperty, loading:updatingProperty } = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted:() => {
            mutate();
            goToNextTab();
            setChangedPropertyDetail([]);
        },
        onError:() => {},
        showAlert:true
    })
    const propertySubmit = (e) => {
        e.preventDefault()
        
        if(changedDetails?.length > 0){
            let facilities = propertyData.facilities?.length ? propertyData.facilities.map(f => ({[f.type]:{status:1}})) : []
            facilities = reduce(facilities,(obj, value, key) => ({...obj, ...value})  , {})
            const newWebsiteData =  {...propertyData, facilities};
            let newFiletrData = { id: id, propertyId: propertyId };
            changedDetails.forEach( d => {
                newFiletrData = { ...newFiletrData, [d] : newWebsiteData[d] }
            })
            updatePropertyWebsiteData({ method: 'PATCH', data: newFiletrData })
        }
        if(changedPropertyDetail?.length > 0){
            let newPropertyData = { id: propertyId };
            changedPropertyDetail.forEach( d => newPropertyData[d] = propertyBasicData[d] );
            updateProperty({ method: 'PATCH', data: newPropertyData })
        }
        if(changedDetails.length === 0 && changedPropertyDetail.length === 0){
            goToNextTab();
        }
    }

    const handleSelect = (item) => {
        let obj = { type: item.type, title: item.title }
        let data = [...propertyData.facilities]
        data = _.xorBy(data, [obj], 'type')
        data = _.compact(data)
        setPropertyData(d => ({ ...d, facilities: data }))
        setChangedDetails( p => uniq([...p, 'facilities' ]))
    }

    const goToNextTab = () => {
        setSelectedTab( t => (t + 1) % 8 )
    }
    const handleShow = (e) => {
        setPropertyData(d => ({...d, showOnHostellog:parseInt(e)}))
        setChangedDetails( p => uniq([...p, 'showOnHostellog' ]))
    }

    const {data:allStates, loading:loadingStates} = useStates(true)
    if(!selectedProperty) return;
    return (
        <>
            <Tabs index={selectedTab} onChange={ e => setSelectedTab(e) } colorScheme={'gray'}>
                <Box overflowX={{ base: 'scroll', xl: 'hidden' }} overflowY='hidden'>
                <TabList whiteSpace={'nowrap'}>
                    <Tab>Basic Details</Tab>
                    <Tab>Header Details</Tab>
                    <Tab>Description</Tab>
                    <Tab>Facilities</Tab>
                    <Tab >Gallery Images</Tab>
                    <Tab>Contacts</Tab>
                    <Tab >Social Links</Tab>
                    <Tab >Other details</Tab>
                </TabList>
                </Box>
                <TabPanels minH={400} pt={6} pb={20}>
                        <TabPanel>
                            <form onSubmit={propertySubmit} style={{ width: "100%", }} >
                                <Box mb={4}>
                                    <VStack align={'stretch'} spacing={4}>
                                    <FormControl>
                                            <FormLabel>Property name</FormLabel>
                                            <Input id="name" name="name" type="text" placeholder="name" value={propertyBasicData.name} onChange={ handleBasicChange } />
                                        </FormControl> 
                                        <FormControl>
                                            <FormLabel>Gender</FormLabel>
                                            <RadioGroup name="gender" value={propertyBasicData.gender} onChange={ (v) => handleBasicChange('gender', v) } defaultValue='CO-ED'>
                                                <HStack spacing={4}>
                                                <Radio colorScheme='blue' value='MALE'>Male</Radio>
                                                <Radio colorScheme='blue' value='FEMALE'>Female</Radio>
                                                <Radio colorScheme='blue' value='CO-ED'>Co-ed</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        </FormControl> 
                                        <FormControl>
                                            <FormLabel>Address</FormLabel>
                                            <Textarea name='streetAddress' value={propertyBasicData.streetAddress} onChange={ handleBasicChange } />
                                        </FormControl> 
                                        <HStack>
                                            <FormControl size='sm'>
                                                <FormLabel>State</FormLabel>
                                                <DropdownList placeholder={'select state'} 
                                                    onChange={e => handleBasicChange('state', e.id)}
                                                    data={allStates ? orderBy(allStates, 'name', 'asc')  : []}
                                                    textField='name'
                                                    style={{fontSize:14}}
                                                    dataKey={'id'}
                                                    value={propertyBasicData.state}
                                                />
                                            </FormControl>
                                            <FormControl size='sm'>
                                                <FormLabel>District</FormLabel>
                                                <DropdownList placeholder={'select district'} 
                                                    onChange={e => handleBasicChange('district', e.id)}
                                                    style={{fontSize:14}}
                                                    value={propertyBasicData.district}
                                                    data={propertyBasicData.state && find(allStates,s => s.id === propertyBasicData.state)?.cities?.length ? 
                                                        orderBy(find(allStates,s => s.id === propertyBasicData.state).cities, 'name', 'asc') 
                                                        : []
                                                    }
                                                    textField='name'
                                                    dataKey={'id'}
                                                />
                                            </FormControl>
                                        </HStack>
                                        <HStack>
                                            <FormControl size='sm'>
                                                <FormLabel>Town / Village</FormLabel>
                                                <Input placeholder={'town / village'} 
                                                name='town'
                                                onChange={handleBasicChange} 
                                                value={propertyBasicData.town}
                                                />
                                            </FormControl>
                                            <FormControl size='sm'>
                                                <FormLabel>Pincode</FormLabel>
                                                <Input placeholder={'pincode'} 
                                                name='pincode'
                                                onChange={handleBasicChange} maxLength={6} 
                                                value={propertyBasicData.pincode}
                                                />
                                            </FormControl>
                                        </HStack>
                                        <HStack spacing={4}>
                                        </HStack>
                                    </VStack>
                                </Box>
                                <VStack align={'stretch'} spacing={4}>
                                    <FormControl>
                                        <FormLabel>Logo</FormLabel>
                                        <Box mt={-2} fontSize='md' color='gray.500'>favicon for website</Box>
                                        <UploadImages UploadURL={setLogo} img={propertyData.logo} id={propertyData.id} propertyId={propertyData.propertyId} />
                                        <Input name="logo" type="hidden" value={propertyData.logo} placeholder="Logo" />
                                        <Input name="id" type="hidden" value={propertyData.id} placeholder="Id" />
                                        <Input name="propertyId" type="hidden" value={propertyData.propertyId} placeholder="Property Id" />
                                    </FormControl>
                                    <FormControl pt={4}>
                                        <FormLabel>Min Price</FormLabel>
                                        <Box mt={-2} fontSize='sm' color='gray.500'>lowest price for a room</Box>
                                        <Input mt={2} id="price_min" name="price_min" type="number" placeholder="price" value={propertyData.price_min} onChange={inputHandler} />
                                    </FormControl> 
                                    <FormControl>
                                        <FormLabel>Max Price</FormLabel>
                                        <Box mt={-2} fontSize='sm' color='gray.500'>hightest price for a room</Box>
                                        <Input mt={2} id="price_max" name="price_max" type="number" placeholder="price" value={propertyData.price_max} onChange={inputHandler} />
                                    </FormControl>
                                    <HStack spacing={2} w='100%'>
                                        <Box>
                                            <FormControl>
                                                <FormLabel>Locality</FormLabel>
                                                <Input id="locality" name="locality" type="text" placeholder="locality" value={propertyData.locality} onChange={inputHandler} />
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <FormLabel>Landmark</FormLabel>
                                                <Input id="landmark" name="landmark" type="text" placeholder="landmark" value={propertyData.landmark} onChange={inputHandler} />
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <FormLabel>Latitude</FormLabel>
                                                <Input id="latitude" name="latitude" type="text" placeholder="latitude" value={propertyData.latitude} onChange={inputHandler} />
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <FormLabel>Longitude</FormLabel>
                                                <Input id="longitude" name="longitude" type="text" placeholder="longitude" value={propertyData.longitude} onChange={inputHandler} />
                                            </FormControl>
                                        </Box>
                                    </HStack>
                                    {/* <FormControl>
                                        <FormLabel>Primary Color</FormLabel>
                                        <Input type="color" name="primary_color" value={propertyData.primary_color} onChange={inputHandler} width="30px" height={'30px'} overflow="hidden" border='none' borderRadius="50%" padding='0px' />
                                    </FormControl> */}
                                    <FormControl>
                                        <FormLabel>Show on Hostellog</FormLabel>                                        
                                        <Box mt={-2} mb={2} fontSize='md' color='gray.500'>if marked yes property will be listed on hostellog main wesite</Box>
                                        <RadioGroup value={propertyData.showOnHostellog} onChange={handleShow}>
                                            <HStack>
                                                <Radio  value={1}>Yes</Radio>
                                                <Radio value={0}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    </FormControl>

                                    <Button w="100px" mt={3} type="submit">Save</Button>
                                </VStack>
                            </form>
                        </TabPanel>
                    <TabPanel>
                            <form onSubmit={propertySubmit} style={{ width: "100%", }} >
                                <VStack align={'stretch'} spacing={4}>
                                    <FormControl isRequired>
                                        <FormLabel>Title</FormLabel>
                                        <Input id="title" name="title" type="text" placeholder="Title" value={propertyData.title} onChange={inputHandler} />
                                        <Input name="id" type="hidden" value={propertyData.id} placeholder="Id" />
                                        <Input name="propertyId" type="hidden" value={propertyData.propertyId} placeholder="Property Id" />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Short Description</FormLabel>
                                        <Input id="short_desc" name="short_desc" type="text" placeholder="Short Description" value={propertyData.short_desc} onChange={inputHandler} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Header Image</FormLabel>
                                        <UploadImages UploadURL={setHeader} img={propertyData.header_image} id={propertyData.id} />
                                        <Input name="header_image" type="hidden" value={propertyData.header_image} placeholder="Header Image" />

                                    </FormControl>
                                    <Button w="100px" mt={3} type="submit" isDisabled={!propertyData.header_image}>Save</Button>
                                </VStack>
                            </form>
                    </TabPanel>
                    <TabPanel>
                        
                            <form onSubmit={propertySubmit}>
                                <VStack align={'stretch'} spacing={4}>
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea id="desc" name="desc" rows={8} type="text" placeholder="Description" value={propertyData.desc} onChange={inputHandler} />
                                        <Input name="id" type="hidden" value={propertyData.id} placeholder="Id" />
                                        <Input name="propertyId" type="hidden" value={propertyData.propertyId} placeholder="Property Id" />
                                    </FormControl>
                                    <Button w="100px" mt={3} type="submit">Save</Button>
                                </VStack>
                            </form>
                    </TabPanel>
                    <TabPanel>
                            <form onSubmit={propertySubmit}>
                                <VStack align={'stretch'} spacing={4}>
                                    <FormControl>
                                        <FormLabel>Facilities</FormLabel>
                                        <Flex name="facilities" flexWrap={"wrap"} py={8}>
                                            {checkboxItem.map(item => {
                                                const checked = propertyData.facilities?.findIndex(d => d?.type == item.type) !== -1
                                                return (
                                                    <Box key={item.id} isChecked={checked} onClick={() => handleSelect(item)} name={item.type} ml={4} size="md">
                                                        <VStack alignItems={'center'} color={checked ? "defaultColor.500" : "blackAlpha.500"} spacing={5} mx={5} cursor="pointer">
                                                            <Text fontSize={40}>{item.icon}</Text>
                                                            <Text>{item.title}</Text>
                                                        </VStack>
                                                    </Box>
                                                )
                                            })}
                                        </Flex>
                                    </FormControl>
                                    <Button w="100px" mt={3} type="submit">Save</Button>
                                </VStack>
                            </form>
                    </TabPanel>
                    <TabPanel>
                            <AddImages afterSubmit={ goToNextTab } id={id} propertyId={propertyId} name="photos" img={WebsiteData?.photos} />
                    </TabPanel>
                    <TabPanel>
                            <form onSubmit={propertySubmit}>
                                <VStack align={'stretch'} spacing={4} name="contacts">
                                    <HStack>
                                        <FormControl isRequired>
                                            <FormLabel>Phone</FormLabel>
                                            <Input type="tel" maxLength={10} pattern="[6-9]{1}[0-9]{9}" name="phone" placeholder="Phone Number" value={propertyData.contacts?.phone} onChange={(e) => contactHandler('phone', e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Alternate Phone</FormLabel>
                                            <Input type="tel" maxLength={10} pattern="[6-9]{1}[0-9]{9}" name="alternatePhone" placeholder="Alternate Phone Number" value={propertyData.contacts?.alternatePhone} onChange={(e) => contactHandler('alternatePhone', e.target.value)} />
                                        </FormControl>
                                    </HStack>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel>Email</FormLabel>
                                            <Input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" placeholder="Email" value={propertyData.contacts?.email} onChange={(e) => contactHandler('email', e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Alternate Email</FormLabel>
                                            <Input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="alternateEmail" placeholder="Alternate Email" value={propertyData.contacts?.alternateEmail} onChange={(e) => contactHandler('alternateEmail', e.target.value)} />
                                        </FormControl>
                                    </HStack>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel> Working Time From</FormLabel>
                                            <Input type="time" name="worktimefrom" placeholder="Work Time From" value={propertyData.contacts?.worktimefrom} onChange={(e) => contactHandler('worktimefrom', e.target.value)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>To</FormLabel>
                                            <Input type="time" name="worktimeto" placeholder="Work Time To" value={propertyData.contacts?.worktimeto} onChange={(e) => contactHandler('worktimeto', e.target.value)} />
                                        </FormControl>
                                    </HStack>
                                    <FormControl>
                                        <FormLabel>Address</FormLabel>
                                        <Input type="text" name="address" placeholder="Address" value={propertyData.contacts?.address} onChange={(e) => contactHandler('address', e.target.value)} />
                                    </FormControl>
                                    <Button w="100px" mt={3} type="submit">Save</Button>
                                </VStack>
                            </form>
                    </TabPanel>
                    <TabPanel>
                            <form onSubmit={propertySubmit}>
                                <VStack align={'stretch'} spacing={4}>
                                    <FormControl>
                                        <FormLabel>Facebook</FormLabel>
                                        <Input type="url" name="facebook" placeholder="Facebook URL" value={propertyData.socialUrls?.facebook} onChange={(e) => socialUrlHandler('facebook', e.target.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Instagram</FormLabel>
                                        <Input type="url" name="instagram" placeholder="Instagram URL" value={propertyData.socialUrls?.instagram} onChange={(e) => socialUrlHandler('instagram', e.target.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>twitter</FormLabel>
                                        <Input type="url" name="twitter" placeholder="Twitter URL" value={propertyData.socialUrls?.twitter} onChange={(e) => socialUrlHandler('twitter', e.target.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>WhatsApp</FormLabel>
                                        <Input type="url" name="whatsApp" placeholder="WhatsApp URL" value={propertyData.socialUrls?.whatsApp} onChange={(e) => socialUrlHandler('whatsApp', e.target.value)} />
                                    </FormControl>
                                    <Button w="100px" mt={3} type="submit">Save</Button>
                                </VStack>
                            </form>
                    </TabPanel>
                    <TabPanel>
                            <Box>
                                <GuestReviewModal id={id} propertyId={propertyId} WebsiteData={WebsiteData}/>
                            </Box>
                            <Box mt={4}>
                                <HosteRules updateDetails={mutate} id={id} propertyId={propertyId} websiteData={WebsiteData}/>
                            </Box>
                    </TabPanel>
                
        
            </TabPanels>
        </Tabs>
        </>
    )
}

const ChangePropertyModal = ({onClose, propertieData, selectedProperty, selecteProperty }) => {
    const [ checkProperty, setCheckProperty ] = useState();
    useEffect( () => {
        setCheckProperty(selectedProperty)
    },[selectedProperty])

    const handleSelect = () => {
        selecteProperty(checkProperty);
        onClose();
    }
    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Select property</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {
                    map(propertieData, p => <Box>
                        <Checkbox key={p.id} size='md' mb={2} onChange={ () => setCheckProperty(p) } isChecked={ checkProperty?.id === p.id }><Box>{p.name}</Box></Checkbox>
                    </Box>)
                }
            </ModalBody>

            <ModalFooter>
                <Button  variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button onClick={handleSelect}>Select</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

