import React, { useEffect, useMemo, useReducer } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Stat, StatLabel, StatNumber, Icon, Text, Textarea, VStack, Progress, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { find, orderBy } from 'lodash'
import { BiBuilding } from 'react-icons/bi'
import { BsDoorOpen } from 'react-icons/bs'
import { RiHotelBedFill, RiHotelBedLine } from 'react-icons/ri'
import { DropdownList } from 'react-widgets/cjs'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useGetProperties } from '../../services/api/useGetProperties'
import { useStates } from '../../services/api/useStates'
import { useUnitsData } from '../../services/api/useUnitsData'
import { useUserData } from '../../services/api/useUserData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { FormReducer } from '../../utils/FormReducer'
import _ from 'lodash'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'

export const PropertyDetails = ({selectedProperty}) => {
    const {data:allUnits, loading:loadingGetUnits} =  useUnitsData(selectedProperty?.id, {propertyId:selectedProperty.id}, true, true)
    const {data:unitRoomTypes, loading:loadingUnitRoomTypes} = useUnitRoomTypes(selectedProperty.id, {propertyId:selectedProperty.id}, true, true)

    const allRooms = useMemo(() => {
        if(allUnits?.length)
            return _.chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if(allUnits?.length){
            return _.chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    const roomTypes = useMemo(() => {
        if(unitRoomTypes?.length){
            return _.chain(unitRoomTypes).map(d => {
                const units = allUnits?.length ? _.filter(allUnits,u => u.unitRoomTypeId === d.id) : []
                const occupiedBeds = _.chain(units).flatMap(d => d.unitTenants).compact().value().length
                const beds = _.sum(units.map(u => u.sharing))
                return ({...d, units, occupiedBeds, beds })
            }
            ).filter(d => d.units?.length).value()
        } return []
    }, [unitRoomTypes, allUnits])

    return(
        <LoadingContainer loading={loadingGetUnits || loadingUnitRoomTypes}>
            <Flex mb={{ lg:4 }} align={'stretch'} wrap={'wrap'}>
                <StatsCard label={'Total Floors'} count={selectedProperty.noOfFloors || 0} 
                    icon={BiBuilding} 
                />
                <StatsCard label={'Total Rooms'} count={allUnits?.length} 
                    icon={BsDoorOpen} 
                />
                <StatsCard label={'Total Beds'} count={allRooms} 
                    icon={RiHotelBedLine} 
                />
                <StatsCard label={'Occupied Beds'} count={occupiedBeds} 
                    icon={RiHotelBedFill} 
                />
            </Flex>
            {roomTypes?.length ? 
                <Box>
                    <Text color={'secondary'} fontWeight='bold'>Room Types</Text>
                    <Flex mt={2} wrap='wrap'>
                        {roomTypes.map(roomType => 
                            <RoomTypesCard roomType={roomType} />
                        )}
                    </Flex>
                </Box>
                :
                null
            }
            <br/>
            <Box>
                <Box py={1} px={3} background='gray.50' borderLeft='3px solid' borderLeftColor={'defaultColor.500'}>
                    <Text fontWeight={'bold'} color='gray.500' fontSize={'default'}>Basic Details</Text>
                </Box>
                <Address defaultData={selectedProperty}/>
            </Box>
        </LoadingContainer>
    )
}

const RoomTypesCard = ({roomType}) => {
    const progress = _.round((roomType.occupiedBeds / roomType.beds) * 100)
    return(
        <Box pr={4} mb={4}>
            <Stat boxShadow={'sm'} border={'1px solid'} borderColor='gray.100' px={4} py={2} borderRadius={10}>
                <HStack justifyContent={'space-between'}>
                    <VStack spacing={1} align={'stretch'} width={250}>
                        <HStack justifyContent={'space-between'}>
                            <StatLabel fontWeight={'bold'} color={'gray.600'} fontSize={'small'}>{roomType.typeName}</StatLabel>
                        </HStack>
                        <HStack color={'secondary'} align={'center'}>
                            <BsDoorOpen/>
                            <Text>Rooms : </Text>
                            <Text color={'gray.600'}>{roomType.units?.length}</Text>
                        </HStack>

                        <Box pb={2}>
                            <HStack color={'secondary'}>
                                <RiHotelBedFill/>
                                <Text>Occupied Beds : </Text>
                                <Text color={'gray.600'}>{roomType.occupiedBeds} / {roomType.beds}</Text>                    
                            </HStack>
                            <Progress mt={2} colorScheme={progress === 100 ? 'green' : 'blue'} 
                                size={'xs'} borderRadius={4} 
                                value={progress} 
                            />
                        </Box>
                    </VStack>
                </HStack>
            </Stat>
        </Box>
    )
}

export const StatsCard = ({label, count, icon, extra}) => {
    return(
        <Box p={{ base: 2, lg: 3, xl: 4 }} w={{ base: '50%', md: '33%', xl: '33%' }}>
            <Stat h='100%' border={'1px solid'} boxShadow='md' borderColor='gray.200' px={4} py={2} borderRadius={10}>
                <HStack align={'start'} justifyContent={'space-between'}>
                    <Box width={130}>
                        <StatLabel color={'secondary'} fontSize={'small'}>{label}</StatLabel>
                        <StatNumber color={'gray.600'} fontSize={'large'}>{count}</StatNumber>
                    </Box>
                    <Box>
                        <Icon as={icon} fontSize={30} color='secondary'/>
                    </Box>
                </HStack>
                {extra}
            </Stat>
        </Box>
    )
}

const Address = ({defaultData}) => {
    const {organisation} = useUserData(true)
    const [propertyData, changeData] = useReducer(FormReducer, {})

    const {data:allStates, loading:loadingStates} = useStates(true)
    const {data:allProperties, mutate} = useGetProperties(true)
    
    const {request:addRequest, loading:addLoading} = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted:() => {
            mutate()
        },
        onError:() => {},
        showAlert:true
    })

    useEffect(() => {
        if(defaultData){
            const {name, streetAddress, town, state, district, pincode, noOfFloors, gender} = defaultData
            changeData({type:'reset', value:{name, streetAddress, town, state, district, pincode, noOfFloors, gender}})
        }
    }, [defaultData, allProperties])

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {...propertyData, organisationId:organisation?.id}
        
        if(defaultData)
            addRequest({method:'PATCH', data:{...data, id:defaultData.id}})
        else
            addRequest({method:'POST', data})
    }

    const handleChange = (type, value) => {
        if(type === 'state')
            changeData({type:'merge', value:{state:value, district:null}})
        else
            changeData({type, value})
    }

    return(
        <LoadingContainer loading={loadingStates}>
            <form onSubmit={handleSubmit}>
                <VStack px={2} mt={4} align={'stretch'} spacing={4}>
                    <FormControl size='sm' isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder={'name'} onChange={e => handleChange('name', e.target.value)} name='name' value={propertyData.name}/>
                    </FormControl>
                    <FormControl size='sm' >
                        <FormLabel>Gender</FormLabel>
                        <RadioGroup defaultValue='CO-ED' onChange={(value) => handleChange('gender', value)} value={propertyData.gender}>
                        <Stack spacing={5} direction='row'>
                            <Radio colorScheme='blue' value='MALE'>
                            Male
                            </Radio>
                            <Radio colorScheme='blue' value='FEMALE'>
                            Female
                            </Radio>
                            <Radio colorScheme='blue' value='CO-ED'>
                            Co-ed
                            </Radio>
                        </Stack>
                        </RadioGroup>
                    </FormControl>
                    <FormControl size='sm' isRequired>
                        <FormLabel>Address</FormLabel>
                        <Textarea name='streetAddress' onChange={e => handleChange('streetAddress', e.target.value)} placeholder={'streetAddress'} value={propertyData.streetAddress}/>
                    </FormControl>
                    <HStack>
                        <FormControl size='sm'>
                            <FormLabel>State</FormLabel>
                            <DropdownList placeholder={'select state'} 
                                onChange={e => handleChange('state', e.id)}
                                data={allStates ? orderBy(allStates, 'name', 'asc')  : []}
                                textField='name'
                                style={{fontSize:14}}
                                dataKey={'id'}
                                value={propertyData.state}
                            />
                        </FormControl>
                        <FormControl size='sm'>
                            <FormLabel>District</FormLabel>
                            <DropdownList placeholder={'select district'} 
                                onChange={e => handleChange('district', e.id)}
                                style={{fontSize:14}}
                                value={propertyData.district}
                                data={propertyData.state && find(allStates,s => s.id === propertyData.state)?.cities?.length ? 
                                    orderBy(find(allStates,s => s.id === propertyData.state).cities, 'name', 'asc') 
                                    : []
                                }
                                textField='name'
                                dataKey={'id'}
                            />
                        </FormControl>
                    </HStack>
                    <HStack>
                        <FormControl size='sm'>
                            <FormLabel>Town / Village</FormLabel>
                            <Input placeholder={'town / village'} onChange={e => handleChange('town', e.target.value)} value={propertyData.town}/>
                        </FormControl>
                        <FormControl size='sm'>
                            <FormLabel>Pincode</FormLabel>
                            <Input placeholder={'pincode'} onChange={e => handleChange('pincode', e.target.value)} maxLength={6} value={propertyData.pincode}/>
                        </FormControl>
                    </HStack>
                    <Box>
                        <Button isLoading={addLoading} type='submit'>Update</Button>
                    </Box>
                </VStack>
            </form>
        </LoadingContainer>
    )
}