import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Center, Divider, Flex, HStack, Icon, IconButton, Input, Progress, Tag, Text, Tooltip, VStack } from '@chakra-ui/react'
import _, { filter, isEmpty, uniq } from 'lodash'
import { BiBuilding } from 'react-icons/bi'
import { AddIcon, CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import { FiUsers } from "react-icons/fi";
import { RiHotelBedLine, RiHotelBedFill } from 'react-icons/ri'
import { AiOutlineUserAdd } from "react-icons/ai";
import { SHARINGS } from '../../Constants'
import { EmptyPage } from '../../ui/EmptyPage'
import { AddFacilityModal } from './AddFacilityModal'
import { AddRoomModal } from './AddRoomModal'
import { SelectTenantModal } from './SelectTenantModal'
import { UnitTenantsModal } from './UnitTenantsModal'
import { AddSingleRoomModal } from './AddSinlgeRoomModal'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { AddFloorModal } from './AddFloorModal'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { StatsCard } from '../ManageProperties/PropertyDetails'
import { useUnitsData } from '../../services/api/useUnitsData'
import AddNewTenant from '../ManageTenant/AddNewTenant'
import { useSingleProperty } from '../../services/api/useSingleProperty'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'

export const RentModalRef = React.createRef()

export const SingleProperty = ({ keyPrefix, openRentModal }) => {
    const params = useParams()

    const [roomModal, openRoomModal] = useState()
    const [facilityModal, openFacilityModal] = useState()
    const [selectedFloor, setFloor] = useState()
    const [floorsList, setFloorsList] = useState([])
    const [tenantModal, openTenantModal] = useState()
    const [unitTenantsModal, openUnitTenantsModal] = useState()
    const [addRoomModal, openAddRoomModal] = useState()
    const [addFloorModal, openAddFloorModal] = useState()
    const [addTenatModal, openAddTenantModal] = useState()

    const { data: currentPropertyData, loading: loadingProperties } = useSingleProperty(keyPrefix + params.id, { id: params.id })
    const { data: allUnits, loading: loadingGetUnits, mutate: mutateAllUnits } = useUnitsData(keyPrefix + params.id + 'singleProperty', { propertyId: params.id }, true)
    const { data: unitRoomTypes, loading: loadingUnitRoomTypes } = useUnitRoomTypes(keyPrefix + params.id, { propertyId: params.id })

    useEffect(() => {
        if (allUnits?.length) {
            const floors = uniq(allUnits.map(d => d.floorNo)).sort()
            setFloorsList(floors)
        } else {
            setFloorsList([])
        }
    }, [allUnits])

    useEffect(() => {
        setFloor()
    }, [params.id])

    useEffect(() => {
        if (!selectedFloor && selectedFloor !== 0 && selectedFloor !== -1 && floorsList?.length) {
            setFloor(floorsList[0])
        }
    }, [selectedFloor, floorsList])

    const floorName = (floor) => {
        const name = floor === -1 ? 'Basement' : floor === 0 ? 'Ground' : floor === 1 ? '1st Floor' : floor === 2 ? '2nd Floor' : floor === 3 ? '3rd Floor' : floor + 'th floor'
        return name
    }

    const [aRoom, setARoom] = useState()
    const handleRoomModal = () => {
        openRoomModal(d => !d)
    }
    const handleFacilityModal = (room) => {
        setARoom(room)
        openFacilityModal(d => !d)
    }

    const handleSelectFloor = (floor) => {
        setFloor(floor)
    }

    const allRooms = useMemo(() => {
        if (selectedFloor || selectedFloor === 0) {
            let data = filter(allUnits, unit => unit.floorNo === selectedFloor)
            data = _.groupBy(data, r => r.unitRoomTypeId)
            return data
        }

        return {}
    }, [allUnits, selectedFloor])

    const handleSelectTenantModal = (unit) => {
        openTenantModal(d => d ? null : unit)
    }

    const handleUnitTenantsModal = (unit) => {
        openUnitTenantsModal(d => d ? null : unit)
    }

    const handleAddRoom = () => {
        openAddRoomModal(d => d ? null : { floor: selectedFloor })
    }

    const handleAddFloor = () => {
        openAddFloorModal(d => !d)
    }

    const handleCloseRentModal = (tenant) => {
        openAddTenantModal()
        openRentModal(tenant)
    }

    const handleCloseSelectTenantModal = (selectedUnit) => {
        openTenantModal(false)
        openAddTenantModal(selectedUnit)
    }

    return (
        <Box>
            <LoadingContainer
                loading={loadingProperties || loadingGetUnits || loadingUnitRoomTypes}

            >
                <Box>
                    <Flex wrap='wrap' align={'center'}>
                        <Box pr={1} pb={4}>
                            <Text >Select Floor :</Text>
                        </Box>
                        {floorsList.length ? floorsList.map((floor, i) => {
                            const selected = selectedFloor === floor
                            return (
                                <Box key={i} pb={4} pr={2}>
                                    <Button onClick={() => handleSelectFloor(floor)} _focus={'none'} variant={!selected ? 'ghost' : 'solid'} >{floorName(floor)}</Button>
                                </Box>
                            )
                        }) : null}
                        <Box pr={2} pb={4}>
                            <Button onClick={handleAddFloor} leftIcon={<AddIcon />} _focus={'none'} colorScheme='gray' >Add New Floor</Button>
                        </Box>
                    </Flex>
                    <br />
                    {selectedFloor || selectedFloor === 0 ?
                        <Box p={{ lg: 3 }} borderRadius={8}>
                            <Flex wrap={'wrap'} align='start' justifyContent='space-between'>
                                <Box width={['100%', '60%']} >
                                    <Text fontSize={'md'} color={'defaultColor.500'}>{floorName(selectedFloor)}</Text>
                                </Box>
                                <HStack width={['100%', '40%']} justify={['left', 'right', 'right', 'right']}>
                                    <Button leftIcon={<AddIcon />} onClick={handleAddRoom} colorScheme={'gray'}>Add Room</Button>
                                </HStack>
                            </Flex>
                            <hr style={{ marginTop: 10 }} />
                            <br />

                            {!isEmpty(allRooms) ?
                                _.map(allRooms, (typeRooms, typeId) => {
                                    const unitRoomType = _.find(unitRoomTypes, t => t.id === typeId)
                                    const occupiedBeds = _.chain(typeRooms).flatMap(t => t.unitTenants).value().length
                                    const totalBeds = _.chain(typeRooms).map(t => t.sharing).sum().value()
                                    const progress = _.round((occupiedBeds / totalBeds) * 100)
                                    return (
                                        <Box pb={6}>
                                            <Box py={1} px={3} background='gray.100' borderLeft='3px solid' borderLeftColor={'defaultColor.500'}>
                                                <Text fontWeight={'bold'}>{unitRoomType?.typeName}</Text>
                                            </Box>
                                            <Flex my={{ base: '15px', lg: 2 }} flexWrap={'wrap'} alignItems='stretch'>
                                                <StatsCard label={'Total Rooms'} count={typeRooms.length}
                                                    icon={BiBuilding}
                                                    extra={
                                                        <Box mt={5} />
                                                    }
                                                />
                                                <StatsCard label={'Total Beds'} count={totalBeds}
                                                    icon={RiHotelBedLine}
                                                    extra={
                                                        <Box mt={5} />
                                                    }
                                                />
                                                <StatsCard label={'Occupied'}
                                                    count={occupiedBeds + ' / ' + totalBeds}
                                                    icon={RiHotelBedFill}
                                                    extra={
                                                        <Progress my={2} colorScheme={progress === 100 ? 'green' : 'blue'}
                                                            size={'xs'} borderRadius={4}
                                                            value={progress}
                                                        />
                                                    }
                                                />
                                            </Flex>
                                            <Flex wrap={'wrap'}>
                                                {typeRooms.length ?
                                                    typeRooms.map((room) => <RoomCard mutateAllUnits={mutateAllUnits} key={room.id} room={room} handleSelectTenantModal={handleSelectTenantModal} handleUnitTenantsModal={handleUnitTenantsModal} /> )
                                                    :
                                                    <EmptyPage />
                                                }
                                            </Flex>
                                        </Box>
                                    )
                                }
                                )
                                :
                                <EmptyPage />
                            }
                        </Box>
                        :
                        <EmptyPage />
                    }
                </Box>
            </LoadingContainer>
            {addTenatModal &&
                <AddNewTenant keyPrefix={keyPrefix} openRentModal={openRentModal} showJoiningDate
                    assignRoomData={{ currentUnit: addTenatModal, contact: addTenatModal.contact }}
                    visible={addTenatModal} closeModal={() => handleCloseSelectTenantModal()}
                />
            }

            {addFloorModal && <AddFloorModal defaultFloors={floorsList} currentProperty={currentPropertyData} visible={addFloorModal} closeModal={handleAddFloor} />}
            {addRoomModal && <AddSingleRoomModal keyPrefix={keyPrefix} propertyId={params.id} visible={addRoomModal} closeModal={handleAddRoom} defaultFloor={addRoomModal?.floor} />}
            {unitTenantsModal && <UnitTenantsModal currentUnit={unitTenantsModal} visible={unitTenantsModal} closeModal={handleUnitTenantsModal} />}
            {tenantModal && <SelectTenantModal openRentModal={handleCloseRentModal} mutate={mutateAllUnits} property={currentPropertyData} visible={tenantModal} closeModal={handleCloseSelectTenantModal} currentUnit={tenantModal} />}
            {roomModal && <AddRoomModal currentFloor={selectedFloor} visible={roomModal} closeModal={handleRoomModal} />}
            {facilityModal && <AddFacilityModal visible={facilityModal} aRoom={aRoom} closeModal={handleFacilityModal} />}
        </Box>
    )
}

const RoomCard = ({ room, handleSelectTenantModal, handleUnitTenantsModal, mutateAllUnits }) => {
    const [ editName, setEditName ] = useState('');
    const toggleEditBt = useCallback( (value) => {
        setEditName( value );
    },[setEditName, room]);

    const { request: updateRequest, loading: updateLoading } = useApiRequest(URIS.GET_UNIT, {
        onCompleted: () => {
            mutateAllUnits();
        },
        onError: () => { },
        showAlert: true
    })

    const handleNameChange = useCallback( (e) => {
        e.preventDefault();
        const data = { id : room.id, name: editName };
        updateRequest({ method: 'patch', data})
    },[room.id, editName, updateRequest])
    return (
        <Box pr={2} pb={2} width={['100%', '100%', '50%', '50%', '33%']}>
            <Box p={3} border='1px solid #E5E8E8' borderRadius={8}>
                <HStack justifyContent={'space-between'} mb={4}>
                        {
                            editName ?
                                <form onSubmit={handleNameChange}>
                                    <HStack align='center'>
                                        <Input value={editName} onChange={ e => setEditName(e.target.value) } required type='text'/>   
                                        <HStack>
                                            <IconButton onClick={ () => toggleEditBt(null) } size='xs' variant='ghost'><CloseIcon /></IconButton>
                                            <IconButton isLoading={updateLoading} type='submit' size='xs' variant='solid'><CheckIcon /></IconButton>
                                        </HStack>
                                    </HStack>
                                </form>
                                :   
                                <>
                                    <Flex align='center'>
                                        <Text fontWeight={'bold'}>Room: {room.name}</Text>
                                        <IconButton onClick={ () => toggleEditBt(room.name) } size='xs' variant='ghost'><EditIcon /></IconButton>
                                    </Flex>
                                    <Tag>{SHARINGS[room.sharing]?.title}</Tag>
                                </>
                        }
                </HStack>
                <HStack flexDir={{ base: 'column', lg: 'row' }} align={{ base: 'flex-start', lg: 'center' }} justifyContent={{ lg: 'space-between' }}>
                    <HStack>
                        {Array.from({ length: room.sharing }, (d, i) => {
                            const occupied = i + 1 <= room.unitTenants?.length
                            return (
                                <VStack key={i} spacing={0}>
                                    {occupied ?
                                        <Icon as={RiHotelBedFill} color='defaultColor.500' fontSize={22} />
                                        :
                                        <Icon as={RiHotelBedLine} color='secondary' fontSize={22} />
                                    }
                                    <Text color='secondary' fontSize={'extraSmall'}>Bed {String.fromCharCode(65 + i)}</Text>
                                </VStack>
                            )
                        }
                        )}

                    </HStack>
                    <Box w='100%' marginInlineStart={'0!important'} display={{ base: 'block', lg: 'none' }}>
                        <Divider mt={2} mb={2} />
                    </Box>
                    <HStack marginInlineStart={{ base: '0!important', lg: 2 }} w={{ base: '100%' }} justify={{ base: 'space-between', lg: 'flex-end' }}>
                        <Box onClick={() => handleSelectTenantModal(room)} borderRight={{ base: '1px solid', lg: '0px solid' }} borderColor='gray.200' w={{ base: '50%', lg: 'fit-content' }}>
                            <Center>
                                <Tooltip label={'Assign Tenant'}>
                                    <IconButton disabled={room.sharing === room.unitTenants?.length} variant='ghost' icon={<AiOutlineUserAdd fontSize={20} />} />
                                </Tooltip>
                            </Center>
                            <Box textAlign='center' fontSize={{ base: 12 }} color='gray.500' fontWeight='bold' display={{ base: 'block', lg: 'none' }}>
                                Assign Tenant
                            </Box>
                        </Box>
                        <Box onClick={() => handleUnitTenantsModal(room)} w={{ base: '50%', lg: 'fit-content' }}>
                            <Center>
                                <Tooltip label={'All Tenants'}>
                                    <IconButton variant='ghost' icon={<FiUsers fontSize={20} />} />
                                </Tooltip>
                            </Center>
                            <Box textAlign='center' fontSize={{ base: 12 }} color='gray.500' fontWeight='bold' display={{ base: 'block', lg: 'none' }}>
                                All Tenant
                            </Box>
                        </Box>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    )
}