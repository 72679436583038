import React, { useMemo, useState } from 'react'
import { find } from 'lodash'
import { Box, Button, HStack, Tbody, Td, Th, Thead, Tr, Table, Avatar, IconButton } from '@chakra-ui/react'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { AddCompanyModal } from './AddCompanyModal'
import { useCompanyData } from '../../services/api/useCompanyData'
import { EmptyPage } from '../../ui/EmptyPage'
import { useStates } from '../../services/api/useStates'
import { PaymentConfigModal } from './PaymentConfigModal'
import { useCompanyPaymentConfigs } from '../../services/api/useCompanyPaymentConfig'

export const CompanyManagement = () => {
    const [companyModal, openCompanyModal] = useState()
    const [paymentConfigModal, openPaymentconfigModal] = useState()

    const { data: allCompanies, mutate, loading, } = useCompanyData(true)
    const { data: allStates, loading: loadingStates } = useStates(true)
    const { data: companyPaymentConfigs, loadin: loadingConfigs } = useCompanyPaymentConfigs(true)


    const handleAddCompany = () => {
        openCompanyModal(d => !d)
    }

    const handleEdit = (company) => {
        openCompanyModal(company)
    }

    const _addPaymentConfig = (company) => {
        openPaymentconfigModal(d => d ? null : company)
    }

    const companiesData = useMemo(() => {
        if (allCompanies?.length) {

            if (companyPaymentConfigs?.length)
                return allCompanies.map(com => ({ ...com, paymentConfig: find(companyPaymentConfigs, config => config.companyId === com.id) }))
            else
                return allCompanies
        }
    }, [allCompanies, companyPaymentConfigs])

    return (
        <Box>
            {paymentConfigModal && <PaymentConfigModal company={paymentConfigModal} closeModal={_addPaymentConfig} />}
            <ActionHeader title='Company Management'>
                <HStack>
                    <Button onClick={handleAddCompany} leftIcon={<AddIcon />}>Add Company</Button>
                </HStack>
            </ActionHeader>
            <br />
            <CustomContainer loading={loading || loadingStates || loadingConfigs}>
                {companiesData?.length ?
                    <Box overflow={'auto'} minW={200}>
                        <Table size={'sm'} className='responsiveTable'>
                            <Thead>
                                <Tr>
                                    <Th>Sr No.</Th>
                                    <Th>Logo</Th>
                                    <Th>Name</Th>
                                    <Th>Address</Th>
                                    <Th>State</Th>
                                    <Th>District</Th>
                                    <Th>Pincode</Th>
                                    <Th>GSTIN</Th>
                                    <Th>State Code</Th>
                                    <Th>Slip Prefix</Th>
                                    <Th>Refund Prefix</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {companiesData.map((company, i) => {
                                    const state = company.address?.state ? find(allStates, s => s.id === company.address.state) : null
                                    const district = company.address?.district && state && state.cities?.length ?
                                        find(state.cities, s => s.id === company.address.district) : null
                                    return (
                                        <Tr key={company.id}>
                                            <Td>{++i}</Td>
                                            <Td><Avatar src={company.logo} /></Td>
                                            <Td>{company.name}</Td>
                                            <Td>{company.address?.streetAddress || '-'}</Td>
                                            <Td>{state?.name || '-'}</Td>
                                            <Td>{district?.name || '-'}</Td>
                                            <Td>{company.address?.pincode || '-'}</Td>
                                            <Td>{company.gstin || '-'}</Td>
                                            <Td>{company.state_code || '-'}</Td>
                                            <Td>{company.invoice_prefix || '-'}</Td>
                                            <Td>{company.refund_prefix || '-'}</Td>
                                            <Td>
                                                <HStack>
                                                    <IconButton icon={<EditIcon />} variant='outline' onClick={() => handleEdit(company)} />
                                                    {company.paymentConfig ?
                                                        <Button variant={'outline'} onClick={() => _addPaymentConfig(company)}>Payment Details</Button>
                                                        :
                                                        <Button onClick={() => _addPaymentConfig(company)} variant={'outline'}>Add Payment Details</Button>
                                                    }
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                    :
                    <EmptyPage message='No companies added ' />
                }
            </CustomContainer>
            {companyModal && <AddCompanyModal defaultData={companyModal?.id ? companyModal : null} mutate={mutate} visible={companyModal} closeModal={handleAddCompany} />}
        </Box>
    )
}