import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useDashboardData(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_DASHBOARD+q, () => apis.getDashboardApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

