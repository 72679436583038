import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    VStack,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Box,
} from '@chakra-ui/react'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'

export default function OrgDetailsModal({ organisation, handleOnUpdate, onClose }) {

    const { request: updateOrganisation, loading: organisationLoading } = useApiRequest(URIS.UPDATE_ORGANISATION, {
        onCompleted: () => { onClose(); },
        onError: () => { },
        showAlert: true
    })

    const organisationSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData(e.target)
        let data = {}
        for (const pair of formData.entries()) {
            data[pair[0]] = pair[1]
        }
        data['url'] = formData.get('url') + ".hostellog.in"
        updateOrganisation({ method: 'PATCH', data: data })
    }

    let suburl = organisation && organisation.url ? organisation.url.replace('.hostellog.in', '') : ''

    return (
        <>

            <Modal isOpen={true} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <form style={{ width: "100%", }} onSubmit={organisationSubmit}>

                    <ModalHeader>Update organisation details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <VStack align={'stretch'} spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Title</FormLabel>
                                    <Input id="name" defaultValue={organisation?.name} name="name" type="text" placeholder="Title" />
                                    <Input name="id" type="hidden" value={organisation?.id} placeholder="Id" />
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>URL</FormLabel>
                                    <InputGroup size='sm'>
                                        <InputLeftAddon children='https://' />
                                        <Input name="url" defaultValue={suburl} type="text" placeholder='mysite' />
                                        <InputRightAddon children='.hostellog.in' />
                                    </InputGroup>
                                </FormControl>
                            </VStack>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button type='submit' isLoading={organisationLoading}>save</Button>
                    </ModalFooter>

                </form>
                </ModalContent>
            </Modal>
        </>
    )
}
