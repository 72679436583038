import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";
import moment from "moment";
import Pagination from 'rc-pagination';
import _, { find, map } from "lodash";
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Center, Flex, FormControl, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { BsBuilding } from 'react-icons/bs'
import { AiOutlineUser } from "react-icons/ai";
import { BaseURL, URIS } from "../../services/api";
import { FormReducer } from "../../utils/FormReducer";
import { AiOutlineReload } from "react-icons/ai";
import { useGetProperties } from "../../services/api/useGetProperties";
import { useApiRequest } from "../../services/api/useApiRequest";
import { BiExit } from "react-icons/bi";
import { LeaveRoomModal } from "./LeaveRoomModal";
import { useUserData } from "../../services/api/useUserData";
import { useTenantProfiles } from "../../services/api/useTenantProfiles";
import { AddTenantRentModal } from "../TenantPayment/AddTenantRentModal";
import { useAppContext, useCurrentProperty } from "../../app/Context";
import { LoadingContainer } from "../../ui/LoadingContainer";
import { ExportExcel } from "../../ui/ExportExcel";
import { HiDotsHorizontal } from "react-icons/hi";
import { AssignRoomModal } from "./AssignRoomModal";
import "rc-pagination/assets/index.css";
import { EmptyPage } from "../../ui/EmptyPage";
import { ALL_USER_ROLES, staffPermissions } from '../../Constants'

export default function UnAssignedTenants() {
    const navigate = useNavigate()
    const params = useParams()
    const defaultFilter = { limit: 10, occupied: 0 }
    const { page: currentPage, tab: selectedTab } = params
    const [unassignExcelData, setUnassignExcelData] = useState([]);
    const [assignRoomModal, openAssignRoomModal] = useState()
    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [leaveModal, openLeaveModal] = useState()
    const [deleteAlert, openAlert] = useState()
    const [rentModal, openRentModal] = useState()
    const { currentProperty } = useCurrentProperty()

    const { properties, hasAccess, hasPermission } = useAppContext()

    const tenantsListKey = currentProperty?.id ? URIS.GET_UNASSIGNED_TENANT + currentProperty?.id + currentPage + filterIndex : null

    const { data: allProperties } = useGetProperties(true)
    const { organisation } = useUserData(true)
    const { data: allTenant, loading: loadingTenant, mutate: mutateTenantList } = useTenantProfiles(
        tenantsListKey, { ..._.omitBy(filters, (d, i) => i === 'propertyId' && !d), page: currentPage, propertyId: currentProperty?.id, organisationId: organisation?.id },
        true
    )

    const { request: assingRoomAction } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            mutateTenantList()
            openLeaveModal()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })

    const { request: deleteTenantAction, loading: loadingDelete } = useApiRequest(URIS.ADD_TENANT, {
        onCompleted: d => {
            mutateTenantList()
        },
        showAlert: true
    })
    useEffect(() => {
        if (currentPage)
            changeFilters({ type: 'page', value: currentPage })
    }, [currentPage])

    const handleAssign = (tenant) => {
        openAssignRoomModal(d => d ? null : tenant)
    }

    const handlePageChange = (page) => {
        navigate(`/tenants/${page}/${parseInt(selectedTab)}`)
    }

    const handleChangeValue = (type, value) => {
        changeFilters({ type: 'merge', value: { [type]: value, page: 1 } })
    }

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate(`/tenants/${1}/${parseInt(selectedTab)}`)
    }

    const handleReset = () => {
        changeFilterIndex(i => ++i)
        changeFilters({ type: 'reset', value: defaultFilter })
    }

    const handleDelete = (tenantId) => {
        deleteTenantAction({ method: 'DELETE', params: { id: tenantId } })
    }

    const handleLeave = (tenant) => {
        openLeaveModal(d => d ? null : tenant)
    }

    useEffect(() => {
        if (filters.propertyId) {
            changeFilters({ type: 'merge', value: { occupied: '', page: 1 } })
        }
        else if (filters.propertyId === '') {
            changeFilters({ type: 'merge', value: { occupied: null, page: 1 } })
        }
    }, [filters.propertyId])

    const handleOpenAlert = (tenant) => {
        openAlert(tenant)
    }

    const handleOpenRentModal = (tenant) => {
        openRentModal(tenant)
    }


    useEffect(() => {
        if (allTenant?.results) {
            const newUnassignExcelData = map(allTenant?.results, tenant => {
                return ({
                    NAME: tenant.tenant.name,
                    CONTACT: tenant.tenant.contact,
                    EMAIL: tenant.tenant.email || '-',
                    DOB: tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-',
                });
            })
            setUnassignExcelData(newUnassignExcelData);
        }
    }, [allTenant, allProperties])

    const handleExportAllUnassign = () => {
        const querys = _.omitBy(filters, (d, i) => i === 'propertyId' && !d);
        let url = BaseURL + URIS.GET_TENANT + `?propertyId=${currentProperty?.id}&organisationId=${organisation?.id}&excel=true&noPaginate=false`;
        _.map(querys, (v, q) => { url += '&' + q + "=" + v; });
        window.open(url);
    }


    return (
        <>
            <br />
            <HStack>
                <Text color={'secondary'} fontWeight='bold' fontSize={'default'}>Room Not Assigned</Text>
                <Badge fontSize={14}>
                    {allTenant?.total}
                </Badge>
            </HStack>
            <br />
            <Flex wrap={'wrap'} justifyContent='space-between'>
                <form onSubmit={handleFilter} key={filterIndex}>
                    <Flex wrap={'wrap'} mb={2}>
                        <Box pr={4} pb={2}>
                            <FormControl>
                                <Input onChange={e => handleChangeValue('name', e.target.value)} value={filters.name} placeholder="Name" />
                            </FormControl>
                        </Box>
                        <Box pr={4} pb={2}>
                            <FormControl>
                                <Input onChange={e => handleChangeValue('contact', e.target.value)} value={filters.contact} placeholder="Contact" type='number' />
                            </FormControl>
                        </Box>
                        <Box pr={4} pb={2}>
                            <HStack>
                                <Button type="submit" variant={'outline'}>Apply</Button>
                                <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                            </HStack>
                        </Box>
                    </Flex>
                </form>
                {
                    allTenant?.results?.length ?
                        <Box>
                            <Menu>
                                <MenuButton as={Button}>
                                    Export excel
                                </MenuButton>
                                <MenuList>
                                    <MenuItem><Button onClick={handleExportAllUnassign} variant='ghost' w='full'><DownloadIcon mr={2} />Export All pages</Button></MenuItem>
                                    <MenuItem><ExportExcel w='full' variant='ghost' colorScheme='gray' filename={'Unassign-Tenants'} data={unassignExcelData} title='Export current' /></MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                        : null
                }
            </Flex>
            <br />

            <LoadingContainer loading={loadingTenant}>
                {
                    allTenant?.results.length ?
                        <Box>
                            <Table className={'responsiveTable'}>
                                <Thead>
                                    <Tr>
                                        <Th>Sr. No</Th>
                                        <Th>Image</Th>
                                        <Th>Name</Th>
                                        <Th>Father Name</Th>
                                        {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Th>Contact</Th> : null}
                                        <Th>Email</Th>
                                        <Th>Date of Birth</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        _.sortBy(allTenant.results, ['name']).map((tenant, i) => {
                                            const unit = tenant.unitTenants?.length && find(tenant.unitTenants, u => !u.leavingDate)
                                            const fatherName = find(tenant.guardians, g => g.relation === "FATHER" )?.name || '';
                                           
                                            return (
                                                <Tr key={tenant.id}>
                                                    <Td>{(20 * (currentPage - 1)) + i + 1}</Td>
                                                    <Td>
                                                        <Avatar size={'sm'} src={tenant.tenant.avatar} />
                                                    </Td>
                                                    <Td>{tenant.tenant.name}</Td>
                                                    <Td>{fatherName}</Td>
                                                    {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Td>{tenant.tenant.contact}</Td> : null}
                                                    <Td>{tenant.tenant.email || '-'}</Td>
                                                    <Td>{tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-'}</Td>
                                                    <Td>
                                                        <Center>
                                                            <Menu>
                                                                <MenuButton
                                                                    aria-label='Options'
                                                                    as={Button}
                                                                    variant='ghost'
                                                                    size='sm' colorScheme='blue'
                                                                >
                                                                    <Box>
                                                                        <Box display={{ base: 'none', lg: 'block' }}><HiDotsHorizontal fontSize={'15px'} /></Box>
                                                                        <Center display={{ base: 'flex', lg: 'none' }}><EditIcon mr={1}/> <Box>Edit</Box></Center>
                                                                    </Box>
                                                                </MenuButton>
                                                                <MenuList>
                                                                    <MenuItem icon={<AiOutlineUser fontSize={16} />} onClick={() => navigate(`/tenant/profile/${tenant.tenant.id}/0`)}>
                                                                        View  and Update Profile
                                                                    </MenuItem>
                                                                    {unit ?
                                                                        <MenuItem icon={<BiExit fontSize={16} />} onClick={() => handleLeave(tenant)}>
                                                                            Leave Room
                                                                        </MenuItem>
                                                                        :
                                                                        null
                                                                    }
                                                                    <MenuItem icon={<BsBuilding fontSize={16} />} onClick={() => handleAssign(tenant)}>
                                                                        {unit ? 'Change Room' : 'Assign Room'}
                                                                    </MenuItem>
                                                                    {/* <MenuItem icon={<DeleteIcon />} onClick={() => handleOpenAlert(tenant)}>
                                                                        Delete
                                                                    </MenuItem> */}
                                                                </MenuList>
                                                            </Menu>
                                                        </Center>
                                                        <AlertDialog
                                                            motionPreset='slideInBottom'
                                                            isOpen={deleteAlert?.id === tenant.id}
                                                        >
                                                            <AlertDialogOverlay>
                                                                <AlertDialogContent>
                                                                    <AlertDialogHeader>{'Are you sure, you want to permanently delete ' + deleteAlert?.tenant.name + '?'}</AlertDialogHeader>
                                                                    <AlertDialogFooter>
                                                                        <Button onClick={() => handleOpenAlert()}>
                                                                            Cancel
                                                                        </Button>
                                                                        <Button isLoading={loadingDelete} colorScheme='red' onClick={() => handleDelete(deleteAlert?.id)} ml={3}>
                                                                            Delete
                                                                        </Button>
                                                                    </AlertDialogFooter>
                                                                </AlertDialogContent>
                                                            </AlertDialogOverlay>
                                                        </AlertDialog>

                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                            <br />
                            <HStack justifyContent={'center'}>
                                <Pagination
                                    total={allTenant?.total || 10}
                                    pageSize={allTenant?.limit || 10}
                                    onChange={handlePageChange}
                                    current={parseInt(currentPage)}
                                />
                            </HStack>
                        </Box>
                        :
                        <EmptyPage />
                }
            </LoadingContainer>

            {rentModal && <AddTenantRentModal tenant={rentModal} visible={rentModal} closeModal={() => openRentModal(false)} />}

            {leaveModal && <LeaveRoomModal assignedUnit={leaveModal.unitTenants?.length ? leaveModal.unitTenants[0]?.unit : null} action={assingRoomAction} currentTenant={leaveModal} visible={leaveModal} closeModal={handleLeave} />}
            {assignRoomModal && <AssignRoomModal mutateParentData={mutateTenantList} openRentModal={handleOpenRentModal} currentTenant={assignRoomModal} visible={assignRoomModal} closeModal={handleAssign} />}
        </>
    )
}