import React, { useEffect, useReducer, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, HStack, Input, Table, Tag, Tbody, Td, Text, Th, Thead, Tr, Select, List, ListItem, InputGroup, InputLeftElement, IconButton } from '@chakra-ui/react'
import moment from 'moment'
import Pagination from 'rc-pagination'
import { useNavigate, useParams } from 'react-router-dom'
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs";
import { DatePicker } from 'react-widgets/cjs'
import { AiOutlineReload } from 'react-icons/ai'
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { useGetProperties } from '../../services/api/useGetProperties'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { EmptyPage } from '../../ui/EmptyPage'
import { FormReducer } from '../../utils/FormReducer'
import { SelectInOutTenantModal } from '../SelectInOutTenantModal'
import { AddEntryModal } from './AddEntryModal'
import { dateFormat, floorName } from "../../utils/Helper";
import _, { filter } from 'lodash'
import { useTenantsInOut } from '../../services/api/useInOutTenants'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useUserData } from '../../services/api/useUserData'
import useSWR, { mutate } from 'swr'
import { dateRange } from '../../Constants'
import { useAppContext } from '../../app/Context'

export const VisitorManagement = () => {
    const navigate = useNavigate()
    const params = useParams()
    const currentPage = params.page
    const defaultFilter = {limit:10}

    const [entryModal, changeEntryModal] = useState()
    const [tenantModal, openTenantModal] = useState()

    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [selectedTenants, setTenants] = useState([])
    const [formKey, changeFormKey] = useState(1)

    const { laoding:loadingGetProperties} = useGetProperties(true)

    const {data:tenantsInOut, mutate } = useTenantsInOut(currentPage+filterIndex+currentPage, {...filters, page:currentPage}, true) 

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate(`../in-out-management/${1}`)
    }

    const handleChangeValue = (type, value) => {
        changeFilters({type:'merge',value:{[type]:value, page:1}})
    }

    const handleReset = () => {
        changeFilterIndex(d => ++d)
        navigate(`../in-out-management/${1}`)
        changeFilters({type:'reset', value:defaultFilter})
        changeFormKey(i => ++i)
    }

    const handlePageChange = (page) => {
        navigate(`../in-out-management/${page}`)
    }
    
    const handleAddEntry = () => {
        changeEntryModal(d => !d)
    }

    const handleTenantModal = () => {
        openTenantModal(d => !d)
    }

    const handleSetTenants = (tenants) => {
        setTenants(tenants)
    }

    const handleRangeChange = (id) => {
        const range = _.find(dateRange,d => d.id == id)
        const data = {startDate:dateFormat(range.startDate), endDate:dateFormat(range.endDate), startTime:range.startTime, endTime:range.endTime}
        changeFilters({type:'merge', value:data})
    }

    return(
        <Box>
            <ActionHeader title='Tenant In-Out Management' switchProperty={true} />
            <br/>
            <CustomContainer loading={loadingGetProperties}>
                        <br/>

                        <Box mb={10}>
                            <Text fontSize={'default'} fontWeight='bold'>Mark Entry</Text>
                            <InOutList mutateInOutLog={mutate} />
                        </Box>

                        <Box mt={10}>
                            <Text fontSize={'default'} fontWeight='bold'>Last Entries</Text>
                            <br/>                        
                                <Box >
                                    <form key={formKey} onSubmit={handleFilter}>
                                        <Flex align={'center'} wrap={'wrap'} mb={2}>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <Select value={filters.in_out} placeholder="Select Status" onChange={e => handleChangeValue('in_out', e.target.value)}>
                                                        <option value={'in'}>In</option>
                                                        <option value={'out'}>Out</option>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <Input onChange={e => handleChangeValue('name', e.target.value)} value={filters.name} placeholder="Name"/>
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <DatePicker placeholder='Start Date' defau style={{fontSize:14}} value={filters.startDate ? new Date(filters.startDate) : null} 
                                                        onChange={e => handleChangeValue('startDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <DatePicker placeholder='End Date' defau style={{fontSize:14}} value={filters.endDate ? new Date(filters.endDate) : null} 
                                                        onChange={e => handleChangeValue('endDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <Select value={filters.in_out} 
                                                        placeholder="Select Date Range" 
                                                        onChange={e => handleRangeChange(e.target.value)}
                                                    >
                                                        {dateRange.map(range => 
                                                            <option value={range.id} key={range.id}>{range.title}</option>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <HStack>
                                                    <Button type="submit" variant={'outline'}>Apply</Button>
                                                    <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                                                </HStack>
                                            </Box>
                                        </Flex>
                                    </form>
                                    {tenantsInOut?.results?.length ?
                                        <>
                                            <Table className='responsiveTable' variant={'simple'}>
                                                <Thead>
                                                    <Tr>
                                                        <Th>Sr. No</Th>
                                                        <Th>Name</Th>
                                                        <Th>Room</Th>
                                                        <Th>Time</Th>
                                                        <Th>Status</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        tenantsInOut.results.map((entry, i) => {
                                                            const unit = entry.unitTenant
                                                            const tenant = entry.unitTenant.tenant
                                                            return (
                                                                <Tr key={entry.id}>
                                                                    <Td>{(defaultFilter.limit*(currentPage - 1)) + i + 1}</Td>
                                                                    <Td>{tenant.name}</Td>
                                                                    <Td>{unit ? 
                                                                            <Box>
                                                                                <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})` }</Text>
                                                                            </Box>
                                                                            : '-'
                                                                        }
                                                                    </Td>
                                                                    <Td>
                                                                        {moment(entry.time).format('LLL')}
                                                                    </Td>
                                                                    <Td>
                                                                        {entry.in_out === 'in' ? 
                                                                            <Tag fontWeight={'bold'} colorScheme={'green'}>In</Tag> 
                                                                            : 
                                                                            <Tag fontWeight={'bold'} colorScheme={'red'}>Out</Tag>
                                                                        }
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })
                                                    }
                                                </Tbody>
                                            </Table>
                                            <br/>
                                            <HStack justifyContent={'center'}>
                                                <Pagination 
                                                    total={tenantsInOut?.total || 10}
                                                    pageSize={tenantsInOut?.limit || 10}
                                                    onChange={handlePageChange}
                                                    current={parseInt(currentPage)}
                                                />
                                            </HStack>
                                        </>
                                        :
                                        <EmptyPage />
                                    }
                                </Box>                                                        
                        </Box>
            </CustomContainer>
            {entryModal && <AddEntryModal visible={entryModal} closeModal={handleAddEntry} />}
            {tenantModal && <SelectInOutTenantModal mutate={mutate} onSubmit={handleSetTenants} visible={tenantModal} closeModal={handleTenantModal}/>}
        </Box>
    )
}

const InOutList = ({mutateInOutLog}) => {
    const {user} = useUserData(true)
    const {currentProperty} = useAppContext()

    const [inTenants, changeInTenants] = useState([])
    const [outTenants, changeOutTenants] = useState([])

    const {data:attenadanceStatuses} = useSWR(URIS.GET_INOUT_STATUS+currentProperty?.id,() => apis.getInOutStatus())

    useEffect(() => {
        if(attenadanceStatuses?.data?.length){
            const data = attenadanceStatuses.data.map(d => ({...d, status:d.in_out[0]?.in_out || 'in'}))
            changeInTenants(filter(data,t => t.status === 'in'))
            changeOutTenants(filter(data,t => t.status === 'out'))
        }
    }, [attenadanceStatuses])

    const {request:addEntryAction} = useApiRequest(URIS.CREATE_IN_OUT_ENTRY, {
        onCompleted:d => {
            mutate(URIS.GET_INOUT_STATUS+currentProperty?.id, () => mutateInOutLog())
        },
        showAlert:true,
    })

    const handleIn = (dataArray) => {
        const data = _.map(dataArray, ten => 
            ({guardId:user.id, in_out:'in', unitTenantId:ten.id, time:moment().format('YYYY-MM-DD HH:mm:ss')})
        )
        addEntryAction({method:'POST', data})
    }

    const handleOut = (dataArray) => {
        const data = _.map(dataArray, ten => 
            ({guardId:user.id, in_out:'out', unitTenantId:ten.id, time:moment().format('YYYY-MM-DD HH:mm:ss')})
        )
        addEntryAction({method:'POST', data})
    }

    const handleMoveOut = (ten) => {
        changeOutTenants(d => [ten, ...d])
        changeInTenants(tenants => _.filter(tenants,t => t.id !== ten.id))
    }

    const handleMoveIn = (ten) => {
        changeOutTenants(tenants => _.filter(tenants,t => t.id !== ten.id))
        changeInTenants(d => [ten, ...d])
    }

    return(
        <Flex justifyContent={'space-between'} wrap='wrap'>
            <Box width={{base:'100%', md:'48%'}} pt={6} >
                <InList saveData={handleIn} allTenants={inTenants} moveTenant={handleMoveOut} />
            </Box>
            <Box width={{base:'100%', md:'48%'}} pt={6}>
                <OutList saveData={handleOut} allTenants={outTenants} moveTenant={handleMoveIn} />
            </Box>
        </Flex>
    )
}

const InList = ({onSelect, allTenants, moveTenant, saveData}) => {
    const [tenantsList, setTenantsList] = useState([])

    useEffect(() => {
        setTenantsList(allTenants || [])
    },[allTenants])


    const handleFilter = (e) => {
        e.preventDefault()
    }

    const handleSearchChange = (e) => {
        let data = [...allTenants]
        const text = e.target.value
        data = _.filter(data,d => _.includes(_.lowerCase(d.tenant.name), _.lowerCase(text)))
        setTenantsList(data)
    }

    const handleSelect = (tenant) => {
        moveTenant(tenant)
    }

    const handleSave = () => {
        const data = _.filter(tenantsList,t => t.status === 'out')
        saveData(data)
    }

    return(
        <Box border='1px solid #E5E8E8' minH={500}>
            <Box px={4} py={2} borderBottom='1px solid #E5E8E8'>
                <HStack color={'green.500'}>
                    <BsBoxArrowInLeft/>
                    <Text fontSize='default'>IN Tenants ( {tenantsList.length}  )</Text>
                </HStack>
            </Box>
            <Box p={4}>
                <form onSubmit={handleFilter}>
                    <Flex justifyContent={'space-evenly'}>
                        <Box width={'100%'} pr={2}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color='gray.300' />}
                                />
                                <Input onChange={handleSearchChange} placeholder='Search Name' />
                            </InputGroup>
                        </Box>
                        <IconButton type='submit' colorScheme={'gray'} borderRadius={0} icon={<SearchIcon/>} />
                    </Flex>
                </form>
                <Box mt={4} px={2} maxHeight={350} overflow='auto'>
                    <List mb={4}  spacing={1} height='100%'>
                        {tenantsList?.length ? 
                            tenantsList.map((tenant, i) => 
                                {
                                    const unitTenant = tenant.unit
                                    return(
                                        <ListItem key={tenant.id}>
                                            <HStack
                                                color={tenant.status === 'out' ? 'defaultColor.500' : 'black'}
                                                // cursor={'pointer'} 
                                                justify={'space-between'} 
                                                px={2} py={1}
                                                // _hover={{borderLeft:'2px solid', borderColor:'default.500', color:'defaultColor.500', background:'defaultColor.50'}}
                                                transition={'all .2s'}
                                                // onClick={() => handleSelect(tenant)}
                                            >
                                                <HStack width={'100%'} justify='space-between'>
                                                    <Box pr={2}>
                                                        <Text>{++i}</Text>
                                                    </Box>
                                                    <Box flex={1}borderRight={'1px solid #E5E8E8'}>
                                                        <Text>{tenant.tenant.name}</Text>
                                                    </Box>
                                                    <HStack flex={1}>
                                                        <Text>{`${floorName(unitTenant?.floorNo)} ( ${unitTenant?.name} )`}</Text>
                                                    </HStack>
                                                    <ChevronRightIcon fontSize={20}/>
                                                </HStack>
                                            </HStack>
                                        </ListItem>
                                    )
                                }
                            )
                            :
                            null
                        }
                    </List>
                </Box>
                {_.filter(allTenants,d => d.status === 'out').length ?
                    <Button onClick={handleSave} mt={4} colorScheme={'gray'} width={'100%'}>Save</Button>
                    :
                    null
                }
            </Box>
        </Box>
    )
}

const OutList = ({allTenants, moveTenant, saveData}) => {
    const [tenantsList, setTenantsList] = useState([])

    useEffect(() => {
        setTenantsList(allTenants || [])
    },[allTenants])

    const handleSelect = (tenant) => {
        moveTenant(tenant)
    }

    const handleSearchChange = (e) => {
        let data = [...allTenants]
        const text = e.target.value
        data = _.filter(data,d => _.includes(_.lowerCase(d.tenant.name), _.lowerCase(text)))
        setTenantsList(data)
    }

    const handleSave = () => {
        const data = _.filter(tenantsList,t => t.status === 'in')
        saveData(data)
    }

    return(
        <Box border='1px solid #E5E8E8' minH={500}>
            <Box px={4} py={2} borderBottom='1px solid #E5E8E8'>
                <HStack color={'red.500'}>
                    <BsBoxArrowInRight/>
                    <Text fontSize='default'>OUT Tenants ( {tenantsList.length}  )</Text>
                </HStack>
            </Box>
            <Box p={4}>
                <Flex >
                    <Box width={'100%'}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input placeholder='Search Name' onChange={handleSearchChange} />
                        </InputGroup>
                    </Box>
                </Flex>
                <Box mt={4} px={2} maxHeight={350} overflow='auto'>
                    <List mb={4}  spacing={1} height='100%'>
                        {tenantsList?.length ? 
                            tenantsList.map((tenant, i) => 
                                {
                                    const unitTenant = tenant.unit
                                    return(
                                        <ListItem key={tenant.id}>
                                            <HStack
                                                // cursor={'pointer'} 
                                                justify={'space-between'} 
                                                px={2} py={1}
                                                color={tenant.status === 'in' ? 'defaultColor.500' : 'black'}
                                                // _hover={{borderRight:'2px solid', borderColor:'default.500', color:'defaultColor.500', background:'defaultColor.50'}}
                                                transition={'all .2s'}
                                                // onClick={() => handleSelect(tenant)}
                                            >
                                                <HStack width={'100%'} justify='space-between'>
                                                    <ChevronLeftIcon fontSize={20}/>
                                                    <Box px={2}>
                                                        <Text>{++i}</Text>
                                                    </Box>
                                                    <Box flex={1}borderRight={'1px solid #E5E8E8'}>
                                                        <Text>{tenant.tenant.name}</Text>
                                                    </Box>
                                                    <Flex wrap={'wrap'} flex={1}>
                                                        <Text pr={2} fontWeight={'bold'}>Room:</Text>
                                                        <Text>{`${floorName(unitTenant?.floorNo)} ( ${unitTenant?.name} )`}</Text>
                                                    </Flex>
                                                </HStack>
                                            </HStack>
                                        </ListItem>
                                    )
                                }
                            )
                            :
                            null
                        }
                    </List>
                </Box>
                {_.filter(allTenants,d => d.status === 'in').length ?
                    <Button onClick={handleSave} mt={4} colorScheme={'gray'} width={'100%'}>Save</Button>
                    :
                    null
                }
            </Box>
        </Box>
    )
}