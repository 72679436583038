import useSWR from 'swr'
import { URIS } from '.'
import { ALL_USER_ROLES } from '../../Constants'
import { apis } from './apis'


export function useUserData(q) {
    const { data, error, isValidating, mutate } = useSWR(q ? URIS.ME : null, () => apis.requestUserApi(), {
        revalidateOnFocus: false,
        revalidateIfStale:false,
    })

    let userProfileData = data?.data?.name ? data?.data : null;
    if(userProfileData){
        if(userProfileData.role !== ALL_USER_ROLES.OWNER){
            const userAccessDetails = userProfileData.staffProfile?.staffAccessDetails;
            userProfileData = Object.assign(userProfileData, { organisations: userAccessDetails?.length > 0 && userAccessDetails[0]?.property?.organisation ? [ userAccessDetails[0]?.property?.organisation ] : [] })
        }
    }
    return {
        user: userProfileData,
        errorUser: error || data?.problem,
        isLoading: isValidating,
        allProperties: userProfileData?.organisations?.[0]?.properties,
        organisation: userProfileData?.organisations?.[0],
        mutate,
    }
}