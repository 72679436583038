import React, { useMemo, useState } from 'react'
import {
    Box, Button, Flex, HStack, IconButton, Input, Text, VStack,
} from '@chakra-ui/react'
import _ from 'lodash';
import moment from 'moment';
import { BsCashStack, BsCalendar2Check } from "react-icons/bs";
import { RiRefundFill } from 'react-icons/ri'
import { PAYMENT_STATUS } from '../../Constants';
import { usePaymentDetails } from '../../services/api/usePaymentDetails';
import { displayDateFormat, getPaybleAmount } from '../../utils/Helper';
import { useTenantWallet } from '../../services/api/useTenantWallet';
import { LoadingContainer } from '../../ui/LoadingContainer';
import { useApiRequest } from '../../services/api/useApiRequest';
import { URIS } from '../../services/api';
import { RefundPaymentModal } from './RefundPaymentModal';
import { MdOutlineSelectAll } from 'react-icons/md';

export const PaymentStats = ({ tenantDueCategories, tenantData }) => {
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

    const { loading: loadingTenantWallet, mutate: mutateWallet } = useTenantWallet(tenantData?.id, { userId: tenantData.tenant.id }, true, {
        onSuccess: tenantWallet => {
            if (!tenantWallet.data?.length)
                addWalletAction({ method: 'POST', data: { amount: 0, userId: tenantData.tenant.id } })
        }
    })
    const { data: paymentDetails } = usePaymentDetails(tenantData?.id, { tenantId: tenantData?.tenant.id }, true)

    const { request: addWalletAction } = useApiRequest(URIS.ADD_WALLET, {
        onCompleted: () => {
            mutateWallet()
        },
        showLoader: true
    })

    const pendingAmount = useMemo(() => {
        if (tenantDueCategories?.length) {
            const categories = _.filter(tenantDueCategories, cat => cat.status === PAYMENT_STATUS.GENERATED || cat.status === PAYMENT_STATUS.PARTIAL_PAID)
            let dueDates = _.compact(_.map(categories, cat => cat.due_date ? moment(cat.due_date) : null))
            let minDate = moment.min(dueDates)
            return {
                amount: _.chain(categories).map(d => getPaybleAmount(d) - d.paid_amount).sum().value(),
                due_date: minDate
            }
        } return 0
    }, [tenantDueCategories])

    const filterdPendingAmount = useMemo(() => {
        if (tenantDueCategories?.length) {
            const filterdTenantDueCategories = tenantDueCategories.filter(cat => (new Date(cat.due_date) <= (new Date(date))))
            const categories = _.filter(filterdTenantDueCategories, cat => cat.status === PAYMENT_STATUS.GENERATED || cat.status === PAYMENT_STATUS.PARTIAL_PAID)
            let dueDates = moment(date)
            let minDate = moment.min(dueDates)
            return {
                amount: _.chain(categories).map(d => getPaybleAmount(d) - d.paid_amount).sum().value(),
                due_date: minDate
            }
        } return 0
    }, [tenantDueCategories, date])


    const paidAmount = useMemo(() => {
        const filterPaymentDetails = _.filter(paymentDetails, cat => cat.payment_type !== 'credit' && cat.status !== 'cancelled')
        if (filterPaymentDetails?.length) {
            let dueDates = _.map(filterPaymentDetails, cat => moment(cat.paymentDate))
            let maxDate = moment.max(dueDates)
            return { amount: _.sum(_.map(filterPaymentDetails, p => p.amount)), lastPaidDate: maxDate }
        } return 0
    }, [paymentDetails])

    const refundAmount = useMemo(() => {
        const filterPaymentDetails = _.filter(paymentDetails, cat => cat.payment_type === 'credit' && cat.status !== 'cancelled')
        if (filterPaymentDetails?.length) {
            let dueDates = _.map(filterPaymentDetails, cat => moment(cat.paymentDate))
            let maxDate = moment.max(dueDates)
            return { amount: _.sum(_.map(filterPaymentDetails, p => p.amount)), lastPaidDate: maxDate }
        } return 0
    }, [paymentDetails])

    return (
        <LoadingContainer height={'auto'} loading={loadingTenantWallet}>
            <Flex pb={6} wrap='wrap' spacing={6} align='stretch'>

                <HStack align={'stretch'} pr={6} pb={4}>
                    <Box p={3} minW={180} background='blue.400' boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} borderRadius={10} border='1px solid' borderColor={'gray.200'}>
                        <VStack align={'start'} spacing={4}>
                            <Box>
                                <Box p={3} borderRadius={12} background='blue.300'>
                                    <BsCashStack color='white' />
                                </Box>
                            </Box>
                            <VStack color={'white'} spacing={1} align={'start'}>
                                <Text letterSpacing={1.2}>Pending Amount</Text>
                                {pendingAmount ?
                                    <>
                                        <Text fontSize={'extraSmall'} color='gray.200'>{displayDateFormat(pendingAmount.due_date, 'LL')}</Text>
                                        <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                            ₹{pendingAmount.amount?.toLocaleString('en-IN') || 0}
                                        </Text>
                                    </>
                                    :
                                    <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                        ₹0
                                    </Text>
                                }
                            </VStack>
                        </VStack>
                    </Box>
                </HStack>

                <HStack align={'stretch'} pr={6} pb={4}>
                    <Box p={3} minW={180} background='pink.400' boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} borderRadius={10} border='1px solid' borderColor={'gray.200'}>
                        <VStack align={'start'} spacing={4}>
                            <Flex justify={'space-between'} w='100%'>
                                <Box p={3} borderRadius={12} background='pink.300'>
                                    <BsCashStack color='white' />
                                </Box>
                                {/* <IconButton mt={2} colorScheme='pink' aria-label='abc' icon={<MdOutlineSelectAll />} /> */}
                            </Flex>
                            <VStack color={'white'} spacing={1} align={'start'}>
                                <Flex alignItems={"center"} >
                                    <Text mr={2} mt={-2} letterSpacing={1.2}>Pending Amount Till Today</Text>
                                    {/* <Input mt={-3} w="fit-content" type="date" value={date} onChange={(e) => setDate(e.target.value)} /> */}
                                </Flex>
                                {filterdPendingAmount ?
                                    <>
                                        <Text fontSize={'extraSmall'} color='gray.200'>{displayDateFormat(filterdPendingAmount.due_date, 'LL')}</Text>
                                        <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                            ₹{filterdPendingAmount.amount?.toLocaleString('en-IN') || 0}
                                        </Text>
                                    </>
                                    :
                                    <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                        ₹0
                                    </Text>
                                }
                            </VStack>
                        </VStack>
                    </Box>
                </HStack>

                <HStack align={'stretch'} pr={6} pb={4}>
                    <Box p={3} minW={180} background='green.400' boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} borderRadius={10} border='1px solid' borderColor={'gray.200'}>
                        <VStack align={'start'} spacing={4}>
                            <Box>
                                <Box p={3} borderRadius={12} background='green.300'>
                                    <BsCalendar2Check color='white' />
                                </Box>
                            </Box>
                            <VStack color={'white'} spacing={1} align={'start'}>
                                <Text letterSpacing={1.2}>Paid Amount</Text>
                                {paidAmount ?
                                    <>
                                        <Text fontSize={'extraSmall'} color='gray.200'>Last Payment : {displayDateFormat(paidAmount.lastPaidDate, 'LL')}</Text>
                                        <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                            ₹{paidAmount.amount.toLocaleString('en-IN')}
                                        </Text>
                                    </>
                                    :
                                    <>
                                        <Text fontSize={'extraSmall'} color='gray.200'>Last Payment : -</Text>
                                        <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                            ₹0
                                        </Text>
                                    </>
                                }
                            </VStack>
                        </VStack>
                    </Box>
                </HStack>

                <HStack align={'stretch'} pr={6} pb={4}>
                    <Box p={3} minW={210} background='purple.400' boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} borderRadius={10} border='1px solid' borderColor={'gray.200'}>
                        <VStack align={'start'} spacing={4}>
                            <Flex justify={'space-between'} w='100%'>
                                <Box p={3} borderRadius={12} background='purple.300'>
                                    <RiRefundFill color='white' />
                                </Box>

                                <Button onClick={() => setShowRefundModal(true)} colorScheme={'purple'} mt={2} size='sm'>
                                    Add
                                </Button>
                            </Flex>
                            <VStack color={'white'} spacing={1} align={'start'}>
                                <Text letterSpacing={1.2}>Refunded Amount</Text>
                                <Box>
                                    <Flex>
                                        <Box>
                                            <Text fontSize={'extraSmall'} color='gray.200'>Last Payment : {refundAmount.lastPaidDate ? displayDateFormat(refundAmount.lastPaidDate, 'LL') : '-'}</Text>
                                            <Text letterSpacing={1} fontWeight={'bold'} fontSize={20}>
                                                ₹{refundAmount?.amount ? refundAmount.amount.toLocaleString('en-IN') : 0}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </VStack>
                        </VStack>
                    </Box>
                </HStack>
                {
                    showRefundModal &&
                    <RefundPaymentModal visible={true} closeModal={() => setShowRefundModal(false)} tenantData={tenantData} />
                }
            </Flex>
        </LoadingContainer>
    )
}
