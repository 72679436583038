import { EditIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Table, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { filter, map, orderBy } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { BsImage } from "react-icons/bs";
import { CgRedo } from "react-icons/cg";
import { useCurrentProperty } from "../../app/Context";
import { URIS } from "../../services/api";
import { useApiRequest } from "../../services/api/useApiRequest";
import { useComplaintData } from "../../services/api/useComplaintData";
import { ActionHeader } from "../../ui/ActionHeader";
import { CustomContainer } from "../../ui/CustomContainer";

export default function ComplaintReport() {
    const { currentProperty } = useCurrentProperty()
    const [toggleRemarkDrawer, setToggleRemarkDrawer] = useState(null)
    const [toggleEditModal, setToggleEditModal] = useState(null)

    const { data: complaints, loading: complaintStatus, mutate } = useComplaintData(currentProperty?.id)

    const resetComplaint = () => {
        mutate()
    }

    const [input, setInput] = useState({ complaintFor: '', status: '' })
    const [inputFilter, setInputFilter] = useState({ complaintFor: '', status: '' })
    const [filteredData, setFilteredData] = useState()

    const inputHandler = (e) => {
        const { name, value } = e.target
        setInput((preval) => ({ ...preval, [name]: value }))
    }

    const filterData = (data) => {
        setInputFilter(data)
    }

    useEffect(() => {
        const filteredData = filter(complaints, f => {
            if (inputFilter) return (inputFilter.complaintFor ? f.complaintFor.includes(inputFilter.complaintFor) : true) && (inputFilter.status ? f.status.includes(inputFilter.status) : true)
        })
        setFilteredData(filteredData)
    }, [complaints, inputFilter])

    const resetFilter = () => {
        setFilteredData(complaints)
        setInput({ complaintFor: '', status: '' })
        setInputFilter({ complaintFor: '', status: '' })

    }

    const pendingComplaints = filter(filteredData, c => c.status === "ACTIVE")
    const processingComplaints = filter(filteredData, c => c.status === "PROCESSING")
    const resolvedComplaints = filter(filteredData, c => c.status === "COMPLETED")
    return (
        <Box>
            <ActionHeader title='Complaint Reports' switchProperty={true} />
            <br />
            <CustomContainer loading={complaintStatus}>
                <Box minWidth={200} minH={"75vh"} overflow='auto' bg="white">
                    <Flex justify={"space-between"}>
                        <Box w="22%" p={4} borderRadius={10} bg="red.50" >
                            <Text color={'secondary'} fontSize={16}>Total Complaints</Text>
                            <Text fontSize={30}>{filteredData?.length}</Text>
                        </Box>
                        <Box w="22%" p={4} borderRadius={10} bg="orange.50" >
                            <Text color={'secondary'} fontSize={16}>Pending Complaints</Text>
                            <Text fontSize={30}>{pendingComplaints?.length}</Text>
                        </Box>
                        <Box w="22%" p={4} borderRadius={10} bg="yellow.50" >
                            <Text color={'secondary'} fontSize={16}>Processing Complaints</Text>
                            <Text fontSize={30}>{processingComplaints?.length}</Text>
                        </Box>
                        <Box w="22%" p={4} borderRadius={10} bg="green.50" >
                            <Text color={'secondary'} fontSize={16}>Resolved Complaints</Text>
                            <Text fontSize={30}>{resolvedComplaints?.length}</Text>
                        </Box>
                    </Flex>
                    <HStack mt={7} align={"end"}>
                        <FormControl w="25%">
                            <FormLabel>Type</FormLabel>
                            <Select name={'complaintFor'} placeholder="Select Type" value={input?.complaintFor} onChange={inputHandler}>
                                <option value={"FOOD"}>FOOD</option>
                                <option value={"ROOM"}>ROOM</option>
                                <option value={"SECURITY"}>SECURITY</option>
                                <option value={"OTHER"}>OTHER</option>
                            </Select>
                        </FormControl>
                        <FormControl w="25%">
                            <FormLabel>Status</FormLabel>
                            <Select name={'status'} placeholder="Select Status" value={input?.status} onChange={inputHandler}>
                                <option value={"ACTIVE"}>ACTIVE</option>
                                <option value={"PROCESSING"}>PROCESSING</option>
                                <option value={"COMPLETED"}>COMPLETED</option>
                            </Select>
                        </FormControl>
                        <Button type="primary" onClick={() => filterData(input)}>Filter</Button>
                        <Button onClick={resetFilter}><CgRedo fontSize={20} />Reset</Button>
                    </HStack>
                    {filteredData?.length ?
                        <Table mt={10} className='responsiveTable' variant={'simple'} >
                            <Thead>
                                <Tr>
                                    <Th>Sr.NO.</Th>
                                    <Th>Tenant</Th>
                                    <Th>Type</Th>
                                    <Th w={"200px"}>Description</Th>
                                    <Th>Images</Th>
                                    <Th>created At</Th>
                                    <Th>Status</Th>
                                    <Th w={"200px"}>Remark</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {map(orderBy(filteredData, 'createdAt', 'desc'), (complaint, i) => (
                                    <Tr key={complaint.id}>
                                        <Td>{++i}</Td>
                                        <Td>{complaint.tenant?.name}</Td>
                                        <Td>{complaint.complaintFor}</Td>
                                        <Td>{complaint.description}</Td>
                                        <Td>
                                            <HStack>
                                                {map(complaint.meta_data_json?.documents, img => (
                                                    <BsImage cursor={"pointer"} onClick={() => window.open(img.url)} />
                                                ))}
                                            </HStack>
                                        </Td>
                                        <Td>{complaint.createdAt ? dayjs(complaint.createdAt).format("DD-MM-YYYY") : ''}</Td>
                                        <Td><Badge variant='outline' colorScheme={complaint.status === "ACTIVE" ? 'red' : complaint.status === "PROCESSING" ? "yellow" : 'green'}>{complaint.status}</Badge></Td>
                                        <Td>{complaint.remark}</Td>
                                        <Td>
                                            <Flex>
                                                <Tooltip label="Edit Status" placement="top">
                                                    <IconButton icon={<EditIcon />} _focus="none" variant="ghost" colorScheme="blue" onClick={() => setToggleEditModal(complaint)} />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        :
                        <Box align="center" fontSize={16} fontWeight="semibold" mt={10}>No Complaints Found</Box>
                    }
                    {/* {toggleRemarkDrawer && <ViewRemark visible={toggleRemarkDrawer} closeDrawer={() => setToggleRemarkDrawer(null)} />} */}
                    {toggleEditModal && <EditComplaint data={toggleEditModal} closeModal={() => setToggleEditModal(null)} resetComplaint={resetComplaint} />}
                </Box>
            </CustomContainer>
        </Box>
    )
}

const ViewRemark = ({ visible, closeDrawer }) => {
    return (
        <Drawer isOpen={visible} placement='right' onClose={closeDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Remarks</DrawerHeader>

                <DrawerBody>
                    {visible?.remark?.length ?
                        <></>
                        :
                        <Box align="center" fontSize={16} fontWeight="semibold">No Remark Added</Box>}
                </DrawerBody>

                <DrawerFooter>
                    <Flex w="100%">
                        <Textarea placeholder="Write your remark here ..." />
                        <Button size={"md"} ml={5} colorScheme='blue'>Add</Button>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

const EditComplaint = ({ closeModal, data, resetComplaint }) => {
    const [input, setInput] = useState({
        status: data.status,
        remark: data.remark,
    })
    const inputHandler = (e) => {
        const { name, value } = e.target
        setInput((preval) => ({ ...preval, [name]: value }))
    }

    const { request: updateComplaint, } = useApiRequest(URIS.UPDATE_COMPLAINT, {
        onCompleted: d => {
            resetComplaint()
        },
        showAlert: true,
        showLoader: true,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        updateComplaint({
            method: 'PATCH', data: {
                id: data.id,
                status: input?.status,
                remark: input?.remark
            }
        });
        closeModal()
    }
    return (
        <Modal isOpen={data} onClose={closeModal}>
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
                <ModalContent>
                    <ModalHeader>Update Complaint Status</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <FormControl isRequired>
                                <FormLabel>Status</FormLabel>
                                <Select name="status" value={input?.status} placeholder="Select Status" onChange={inputHandler}>
                                    <option value={'ACTIVE'}>ACTIVE</option>
                                    <option value={'PROCESSING'}>PROCESSING</option>
                                    <option value={'COMPLETED'}>COMPLETED</option>
                                </Select>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Remark</FormLabel>
                                <Textarea name="remark" value={input?.remark} placeholder="Write your remark here ..." onChange={inputHandler} />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' colorScheme='blue' mr={3} onClick={closeModal}>
                            Close
                        </Button>
                        <Button type="submit">Update</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}