import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useSWR from 'swr'
import { URIS } from '.'
import { apis } from './apis'


export function useGetPropertyMess(key = false, params = {}, revalidate) {
    const { data, error, mutate, isValidating } = useSWR(key ? URIS.GET_PROPERTY_MESS + key : null, () => apis.getPropertyMessApi({ ...params }), {
        revalidateOnFocus: false,
        revalidateIfStale: revalidate || false,
    })

    const toast = useToast()
    useEffect(() => {
        if (error) {
            toast({
                status: "error",
                title: "Properties mess not found",
            })
        }
    }, [data, error, toast])

    return {
        data: data?.data, error, loading: isValidating, mutate
    }
}

export const useGetMessAttendance = (key = false, params = {}, revalidate) => {
    const { data, error, mutate, isValidating } = useSWR(key ? URIS.GET_MESS_ATTENDANCE + key : null, () => apis.getMessAttendance({ ...params }), {
        revalidateOnFocus: false,
        revalidateIfStale: revalidate || false,
    })

    const toast = useToast()
    useEffect(() => {
        if (error) {
            toast({
                status: "error",
                title: "Properties mess not found",
            })
        }
    }, [data, error, toast])

    return {
        data: data?.data, error, loading: isValidating, mutate
    }
}