import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { Box, Button, FormControl, FormLabel, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Tooltip, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { AddIcon } from '@chakra-ui/icons'
import { URIS } from "../../services/api";
import { useApiRequest } from "../../services/api/useApiRequest";
import { useUnitRoomTypes } from "../../services/api/useUnitRoomTypes";
import { floorName } from "../../utils/Helper";
import { AddUnitTypeModal } from "../AddNewProperty/AddUnitTypeModal";
import { useMatchMutate } from "../../utils/useMatchMutate";


export const AddSingleRoomModal = ({ visible, closeModal, defaultFloor, propertyId, keyPrefix }) => {
    const params = useParams()
    const { matchMutate } = useMatchMutate()

    const [roomName, changeName] = useState()
    const [sharingType, changeSharingType] = useState()
    const [roomType, changeRoomType] = useState()
    const [unitTypeModal, openUnitTypeModal] = useState()

    const { data: unitRoomTypes, mutate: mutateUnitRoomTypes } = useUnitRoomTypes(propertyId, { propertyId })

    const { request: addRoomAction } = useApiRequest(URIS.ADD_ROOM, {
        onCompleted: d => {

            matchMutate(keyPrefix, { revalidate: true })
            mutate(URIS.GET_PROPERTIES + true, { revalidate: true })
            closeModal()
        },
        onError: d => { },
        showAlert: true
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        addRoomAction({ method: 'POST', data: { propertyId: params.id, floorNo: defaultFloor, unitRoomTypeId: roomType, name: roomName, sharing: sharingType } })
    }

    const handleChange = (e) => {
        changeName(e.target.value ? e.target.value : '')
    }

    const handleSharingType = (e) => {
        changeSharingType(e.target.value)
    }

    const handleRoomType = (e) => {
        changeRoomType(e.target.value)
    }

    const handleUnitTypeModal = () => {
        openUnitTypeModal(d => !d)
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Room to {floorName(defaultFloor)}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <VStack spacing={4} align={'stretch'}>
                            <HStack>
                                <Text>Floor: </Text>
                                <Text color={'defaultColor.500'}>{floorName(defaultFloor)}</Text>
                            </HStack>
                            <FormControl isRequired>
                                <FormLabel>Room Name</FormLabel>
                                <Input name="name" placeholder="Room Name" onChange={handleChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Room Type</FormLabel>
                                <HStack>
                                    <Select name='type' placeholder="select room type" onChange={handleRoomType}>
                                        {_.map(unitRoomTypes, (d) =>
                                            <option key={d.id} value={d.id}>{d.typeName}</option>
                                        )}
                                    </Select>
                                    <Tooltip label='Add New Room Type'>
                                        <IconButton onClick={handleUnitTypeModal} variant={'outline'} colorScheme={'gray'} icon={<AddIcon />} />
                                    </Tooltip>
                                </HStack>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Sharing Type</FormLabel>
                                <Select name='sharing' placeholder="select sharing type" onChange={handleSharingType}>
                                    {Array.from({ length: 4 }, (d, i) =>
                                        <option key={i} value={i + 1}>{i + 1}</option>
                                    )}
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
            {unitTypeModal && <AddUnitTypeModal mutate={mutateUnitRoomTypes} propertyId={propertyId} visible={unitTypeModal} closeModal={handleUnitTypeModal} />}
        </Modal>
    )
}