import { useToast } from "@chakra-ui/react";
import { apis } from "./api/apis";
import { ErrorAlert } from "../Constants/Helper";





export const usePaymentVerify = ({ onSuccess, emitra }) => {
    const toast = useToast();

    const _verify = async (resp, orderId, payment) => {

        const api = apis.verifyOnlinePaymemnt;

        const response = await api({ response: resp, orderId })

        const { ok, data, status } = response;

        if (ok && data) {
            if (data.onlinePayment?.status === "success") {
                onSuccess && onSuccess(data);
            } else {
                ErrorAlert('Somthing went wrong, try again later')
            }
        } else {
            toast({
                status: "error",
                title: "Payment error",
            });
        }
    };

    const _checkout = async (payload, userData) => {

        const payment = payload.meta;
        const razorpay = JSON.parse(JSON.stringify(payload.response ?? {}));

        const orderId = payload.id;

        const options = {
            key: razorpay.keyId,
            amount: Number(payment.amount) * 100,
            currency: payment.currency || 'INR',
            name: "GURUKRIPA CAREER EDUCATION HOSTEL",
            description: "Submit Hostel Fees",
            image: "https://admin.next.gcisikar.com/assets/logo.png",
            order_id: razorpay.id,
            handler: function (response) {
                _verify(response, orderId, payment);
            },
            prefill: userData,
            // notes: { address: "GCI Sikar" },
            theme: { color: "#303E88" },
            retry: false,
        };

        // eslint-disable-next-line no-undef
        const rzpClient = new Razorpay(options);
        rzpClient.open();

        rzpClient.on("payment.failed", function (response) {
            toast({
                status: "error",
                title: "Payment failed, Retry or cancel",
                description: response.error.reason,
                duration: 3000,
                position: "bottom-right",
            });
            _verify(response, orderId, payment);
        });
    };

    return {
        _checkout,
    };
};
