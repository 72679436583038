import React from "react";
import { Box, Flex, Button, HStack, FormControl, FormLabel, Input, Select, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, Text, VStack, Icon } from "@chakra-ui/react";
import { RiHotelBedLine } from "react-icons/ri"
import { BiRupee, BiCctv } from "react-icons/bi"
import { MdBalcony, MdOutlineLocalLaundryService } from "react-icons/md"
import { SHARINGS } from "../../Constants";

export const AddFacilityModal = ({ visible, closeModal, aRoom, currentFloor }) => {
    const handleSubmit = (e) => {
        e.preventDefault()
    }
    const inputHandler = () => {

    }
    const facilities = [
        { id: 5, icon: MdBalcony, title: 'Balcony' },
        { id: 12, icon: MdOutlineLocalLaundryService, title: 'Laundry' },
        { id: 13, icon: BiCctv, title: 'CCTV' },
    ]
    return (
        <Modal isOpen={visible} size='lg' onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Facility</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        {aRoom ?
                            <Box px={8} pb={8} width={['100%']}>
                                <Box p={3} border='1px solid #E5E8E8' borderRadius={8}>
                                    <HStack justifyContent={'space-between'} mb={4}>
                                        <Text fontWeight={'bold'}>Room: {aRoom.name}</Text>
                                        <Tag>{SHARINGS[aRoom.sharing]?.title}</Tag>
                                    </HStack>
                                    <HStack justifyContent={'space-between'}>
                                        <HStack>
                                            {Array.from({ length: aRoom.sharing }, (d, i) =>
                                                <VStack spacing={0}>
                                                    <RiHotelBedLine color='#718096' fontSize={22} />
                                                    <Text color={'secondary'} fontSize={'extraSmall'}>Bed {String.fromCharCode(65 + i)}</Text>
                                                </VStack>
                                            )}
                                        </HStack>
                                        <Text color="blue" fontWeight={"semibold"} cursor={'pointer'}>Rent: <BiRupee fontSize={10} />5000</Text>
                                    </HStack>
                                </Box>
                                <VStack>
                                    <Text>Room Renting Details</Text>
                                    <form>
                                        <Flex w={['100%']} justifyContent="space-between" flexWrap={'wrap'}>
                                            <FormControl w="45%">
                                                <FormLabel>Room Name</FormLabel>
                                                <Input type="number" id="roomName" placeholder="Room Name" value={aRoom.name} onChange={inputHandler} />
                                            </FormControl>
                                            <FormControl w="45%">
                                                <FormLabel>Sharing Type</FormLabel>
                                                <Select type="number" id="roomName" placeholder="Room Type" value={aRoom.name} onChange={inputHandler} >
                                                    <option>Single Room</option>
                                                    <option>Double Sharing</option>
                                                    <option>Triple Sharing</option>
                                                    <option>4 Sharing</option>
                                                    <option>5 Sharing</option>
                                                    <option>6 Sharing</option>
                                                    <option>7 Sharing</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl w="45%">
                                                <FormLabel>Room Rent</FormLabel>
                                                <Input type="number" id="roomRent" placeholder="Room Rent" value={aRoom.rent} onChange={inputHandler} />
                                            </FormControl>
                                        </Flex>
                                    </form>
                                </VStack>
                                <VStack>
                                    <Text>Room Facilities</Text>
                                    <HStack>
                                        {facilities.map((key, val) => (
                                            <VStack key={val.id}>
                                                <Icon src={val.icon} />
                                                <BiCctv />
                                                <Text>{val.title}</Text>
                                            </VStack>
                                        ))}
                                    </HStack>
                                </VStack>
                            </Box>
                            :
                            <></>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}