import React, { useEffect, useMemo, useState } from 'react'
import { ChevronDownIcon, DownloadIcon } from '@chakra-ui/icons'
import { chain, compact, filter, find, join, map, omit, omitBy, orderBy } from 'lodash'
import moment from 'moment'
import Pagination from 'rc-pagination'
import { Link as ChakraLink, Box, Button, HStack, Input, Stat, StatLabel, StatNumber, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, Icon, Flex, MenuButton, MenuList, MenuItem, Menu, FormControl, FormLabel, Spinner, Select } from '@chakra-ui/react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { DropdownList, Multiselect } from 'react-widgets'
import { useAppContext, useCurrentProperty } from '../../app/Context'
import { BaseURL, URIS } from '../../services/api'
import { useDueCategories } from '../../services/api/useDueCategories'
import { useTenantDueReport } from '../../services/api/useTenantDueReport'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { useUserData } from '../../services/api/useUserData'
import { useForm } from 'react-hook-form'
import { ALL_USER_ROLES } from '../../Constants'
import { StatsCard } from './ChequePaymentCollection'
import DuePaymentStudentWise from './DuePaymentStudentWise'

export const DuePayments = () => {
    const navigate = useNavigate()
    const limit = 20
    const { properties, hasPermission } = useAppContext()

    const [currentPage, changePage] = useState(1);
    const [studentWise, setStudentWise] = useState(false)
    const { currentProperty } = useCurrentProperty()
    const { user } = useUserData(true)

    const { register, handleSubmit, watch, setValue, getValues } = useForm({
        values: {
            propertyIds: [currentProperty?.id],
            endDate: moment().format('YYYY-MM-DD'),
            filterBy: "dueDate"
        }
    })

    const staffProperties = useMemo(() => {
        if (hasPermission(ALL_USER_ROLES.OWNER) && properties?.length)
            return properties.map(d => d.id)
        else
            return user?.staffProfile?.staffAccessDetails?.map(d => d.propertyId)
    }, [user?.staffProfile?.staffAccessDetails, hasPermission, properties])

    const watchEndDate = watch('endDate')
    const watchFilterBy = watch('filterBy')
    const watchCategoryId = watch('categoryId')
    let watchProperty = watch('propertyIds')
    watchProperty = watchProperty?.[0] === 'all' ? join(staffProperties, ',') : watchProperty.length ? join(watchProperty, ',') : ''

    const filterData = {
        page: currentPage,
        limit,
        cancelled: 0,
        propertyIds: watchProperty,
        ...(watchCategoryId !== 'Previous Due' ? { categoryId: watchCategoryId } : { previous: true })
    }
    if (watchFilterBy === "dueDate") {
        filterData.endDate = watchEndDate;
    } else if (watchFilterBy === "applyDate") {
        filterData.tillApplyDate = watchEndDate;
        filterData.ignoreDueDate = 1;
    }

    const randomId = useMemo(() => {
        return currentPage + Date.now()
    }, [currentPage])

    const { data: duePaymentList, loading: loadingDuePaymentReport, mutate: mutateList } = useTenantDueReport(
        currentProperty?.id && getValues().propertyIds ? currentProperty.id + randomId : false,
        filterData
    )

    const { data: dueStudentwiseList, loading: loadingDueStudentwise, mutate: mutateStudentWise } = useTenantDueReport(
        currentProperty?.id && getValues().propertyIds ? currentProperty.id : false,
        { ...omit(filterData, "page", "limit"), pagination: 0, cancelled: 0, getDue: 1, groupDue: 1 }
    )

    const { data: orgDueCategories } = useDueCategories(true)

    const handlePageChange = (e) => {
        changePage(e)
    }

    const handleApply = () => {
        if (currentPage === 1) {
            mutateList()
            mutateStudentWise()
        }
        else
            changePage(1)
    }

    const handleExportAllDue = () => {
        let data = omit(filterData, ['limit', 'page'])
        data = omitBy(data, d => !d)
        data = { ...data, excel: true, pagination: 0 }
        let params = chain(data).map((fl, key) => `${key}=${fl}`).join('&').value()
        params += '&cancelled=0&getDue=1';

        window.open(BaseURL + `${URIS.GET_TENANT_DUE_REPORT}?${params}`);
    }

    const handleExportStudentWise = () => {
        let data = omit(filterData, ['limit', 'page'])
        data = omitBy(data, d => !d)
        data = { ...data, excel: true, pagination: 0 }
        let params = chain(data).map((fl, key) => `${key}=${fl}`).join('&').value()
        params += '&cancelled=0&getDue=1&groupDue=1';

        window.open(BaseURL + `${URIS.GET_TENANT_DUE_REPORT}?${params}`);
    }

    const staffPropertiesList = useMemo(() => {
        if (properties?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length) {
                return user.staffProfile.staffAccessDetails.map(d => d.property)
            } else {
                return properties
            }
        }
    }, [properties, user])

    const filterProperties = useMemo(() => {
        return filter(staffPropertiesList, p => {
            if (user.role === ALL_USER_ROLES.OWNER) return true;
            return find(user?.staffProfile?.staffAccessDetails, propery => propery.propertyId === p.id) ? true : false;
        })
    }, [staffPropertiesList, user])

    const _selectProperty = (data) => {
        const all = find(data, d => d?.id === 'all')
        let finalData = []
        if (all)
            finalData = ['all']
        else
            finalData = compact(data.map(d => d?.id))

        setValue('propertyIds', finalData.length ? finalData : [currentProperty?.id])
    }

    return (
        <Box pt={4}>
            <Text color={'secondary'} fontWeight='bold' fontSize='default'>Due Payments</Text>
            <br />
            <Flex>
                <StatsCard label={'Total Pending Due'} count={duePaymentList?.pendingDues || 0} />
            </Flex>
            <br />
            <Flex justify="space-between" align="end">
                <form onSubmit={handleSubmit(handleApply)}>
                    <Flex align={'end'} gap={4}>
                        <Box>
                            <FormControl>
                                <FormLabel>Filter by:</FormLabel>
                                <Select {...register("filterBy")} size="md">
                                    <option value="dueDate">Due Date</option>
                                    <option value="applyDate">Apply Date</option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel>Till Date</FormLabel>
                                <Input
                                    {...register('endDate')}
                                    size={'md'}
                                    placeholder='select date'
                                    type={'date'}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel>Category</FormLabel>
                                <DropdownList
                                    placeholder={'select category'}
                                    onChange={e => setValue('categoryId', e.id)}
                                    data={orgDueCategories ? [{ name: 'All', id: undefined }, ...orderBy(orgDueCategories, 'name', 'asc'), { name: 'Previous due', id: 'Previous Due' }] : []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watchCategoryId}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel>Select Property</FormLabel>
                                <Multiselect
                                    onChange={e => _selectProperty(e)}
                                    placeholder={'select Property'}
                                    data={[{ name: 'All', id: 'all' }, ...orderBy(filterProperties, 'name', 'asc')] || []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watch('propertyIds')}
                                />
                            </FormControl>
                        </Box>
                        <Flex justifyContent='space-between'>
                            <Box>
                                <Button onClick={handleApply}>Apply</Button>&nbsp;&nbsp;
                            </Box>
                            {
                                duePaymentList?.results?.length ?
                                    <>
                                        <Menu>
                                            <MenuButton variant='outline' as={Button} rightIcon={<ChevronDownIcon />}>
                                                <DownloadIcon mr={2} /> Export Excel
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={handleExportAllDue}>Export Excel</MenuItem>
                                                <MenuItem onClick={handleExportStudentWise}>Export Excel ( Studentwise ) </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </>
                                    : null
                            }

                        </Flex>
                    </Flex>
                </form>
                <Flex align="center">
                    Studentwise Report
                    <Switch ml={2} size="md" isChecked={studentWise} onChange={(e) => setStudentWise(e.target.checked)} />
                </Flex>
            </Flex>
            <br />
            {studentWise ?
                dueStudentwiseList?.length ?
                    <DuePaymentStudentWise dueStudentwiseList={dueStudentwiseList} loadingDueStudentwise={loadingDueStudentwise} />
                    :
                    <Text color={'secondary'} >No dues available</Text>
                :
                duePaymentList?.results?.length ?
                    <Box>
                        <Table className='responsiveTable'>
                            <Thead>
                                <Tr>
                                    <Th width={100}>Sr No.</Th>
                                    <Th>Tenant</Th>
                                    <Th>Category</Th>
                                    <Th>Amount</Th>
                                    <Th>Due Date</Th>
                                    <Th>Property</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingDuePaymentReport ?
                                    <Tr>
                                        <Td colSpan={6} >
                                            <HStack minH={200} align={'center'} justify='center'>
                                                <Spinner fontSize={30} />
                                            </HStack>
                                        </Td>
                                    </Tr>
                                    :
                                    duePaymentList.results.map((due, i) => {
                                        const dueCategory = find(orgDueCategories, cat => cat.id === due.propertyDueCategory?.dueCategoryId)
                                        return (
                                            <Tr key={due.id}>
                                                <Td>{((currentPage - 1) * limit) + (i + 1)}</Td>
                                                <Td>
                                                    <Button variant='ghost' size='sm' as={ChakraLink}>
                                                        <Link to={`/tenant/profile/${due.unitTenant.tenant?.id}/6`}>
                                                            {due.unitTenant.tenant.name}
                                                        </Link>
                                                    </Button>
                                                </Td>
                                                <Td>{dueCategory?.name || due.meta_data_json?.name}</Td>
                                                <Td>₹ {(due.amount - due.paid_amount) || 0}</Td>
                                                <Td>{due.due_date && moment(due.due_date).format('ll')}</Td>
                                                <Td>{find(properties, pr => pr.id === due.propertyId)?.name}</Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                        <br />
                        <HStack justifyContent={'center'}>
                            <Pagination
                                total={duePaymentList?.total || limit}
                                pageSize={duePaymentList?.limit || limit}
                                onChange={handlePageChange}
                                current={parseInt(currentPage)}
                            />
                        </HStack>
                    </Box>
                    :
                    <Text color={'secondary'} >No dues available</Text>
            }
        </Box>
    )
}