import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useAttendanceSlots(q, params, revalidate) {

    const {data, error, loading, mutate} = useGetData(URIS.GET_ALL_ATTENDANCE+q, () => apis.getAllAttendanceApi(params), q, revalidate)

    return {
        data, error, loading, mutate
    }
}