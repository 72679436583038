import { FormControl, FormLabel, Select, Modal, ModalOverlay, ModalContent, ModalFooter, ModalHeader, ModalCloseButton, ModalBody, Button, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMessFoodMenu } from "../../services/api/useMessData1";
import { URIS } from "../../services/api";
import { useUserData } from "../../services/api/useUserData";
import { useApiRequest } from "../../services/api/useApiRequest";



export const CopyFoodMenuModal = ({ isOpen, onClose, allMessData, messData, mutate, allMessFoodMenuData }) => {

    const { organisation } = useUserData(true)
    const { handleSubmit, register, watch, reset } = useForm()

    const modalCloseHandler = () => {
        reset()
        onClose()
    }

    const newMessId = watch("newMessId")

    const { data: newMessFoodMenuData, loading: newMessFoodMenuLoading } = useMessFoodMenu(newMessId ? newMessId : null, { organisationId: organisation.id, messId: newMessId }, true)

    const { request: foodMenuRequest, loading: setupMessLoading } = useApiRequest(URIS.UPDATE_FOOD_MENU, {
        onCompleted: useCallback(() => {
            mutate()
        }, [mutate]),
        showAlert: true,
        showLoader: true
    })

    const _submit = (data) => {
        allMessFoodMenuData?.forEach((foodMenu) => {
            let newFoodMenu = newMessFoodMenuData?.find(data => data?.meal_type === foodMenu?.meal_type)
            foodMenuRequest({
                method: "PATCH",
                data: {
                    id: foodMenu?.id,
                    isEnabled: newFoodMenu?.isEnabled || 0,
                    startTime: newFoodMenu?.startTime,
                    endTime: newFoodMenu?.endTime,
                    meal_content_json: newFoodMenu?.meal_content_json
                }
            })
        })
        modalCloseHandler()
    }

    return <Modal isOpen={isOpen} onClose={modalCloseHandler}>
        <ModalOverlay />
        <ModalContent>
            <form onSubmit={handleSubmit(_submit)}>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Select Mess</FormLabel>
                        <Select {...register("newMessId")} placeholder="-select a mess-">
                            {allMessData?.map(data => {
                                if (data?.id === messData?.id) { return null }
                                return <option key={data?.id} value={data?.id}>{data?.name}</option>
                            })}
                        </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={modalCloseHandler}>
                        Close
                    </Button>
                    <Button
                        isDisabled={newMessFoodMenuLoading}
                        isLoading={setupMessLoading}
                        type="submit" >Copy</Button>
                </ModalFooter>
            </form>
        </ModalContent>
    </Modal>
}