import { EditIcon } from "@chakra-ui/icons";
import { Box, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { AddDeviceModal } from "./AddDevice";
import { Link, useNavigate } from "react-router-dom";


export const DeviceDataList = ({ deviceData, deviceMutate }) => {
    const [deviceDataState, setDeviceDataState] = useState(null)

    const { isOpen: addBiometricDeviceModalIsOpen, onOpen: addBiometricDeviceModalOnOpen, onClose: addBiometricDeviceModalOnClose } = useDisclosure()

    const editBiometricDeviceHandler = (data) => {
        setDeviceDataState(data)
        addBiometricDeviceModalOnOpen()
    }

    const biometricDeviceModalCloseHandler = () => {
        setDeviceDataState(null)
        addBiometricDeviceModalOnClose()
    }

    return <Box>
        <Table >
            <Thead bgColor={"gray.200"}>
                <Tr>
                    <Th>sr. no.</Th>
                    <Th>device name</Th>
                    <Th>STg Id</Th>
                    <Th>Properties</Th>
                    <Th>Action</Th>
                </Tr>
            </Thead>
            <Tbody>
                {deviceData?.map((data, i) => {
                    return <Tr key={data?.id}>
                        <Td>{i + 1}</Td>
                        <Td>
                            <Link to={`/device/${data?.id}`}>
                                <Text color="blue.500" cursor="pointer" _hover={{ textDecoration: "underline" }} >{data?.name}</Text>
                            </Link>
                        </Td>
                        <Td>{data?.boxIdNo}</Td>
                        <Td></Td>
                        <Td>
                            <IconButton
                                onClick={() => editBiometricDeviceHandler(data)}
                                aria-label="edit"
                                icon={<EditIcon />}
                            />
                        </Td>
                    </Tr>
                })}
            </Tbody>
        </Table>
        <AddDeviceModal isOpen={addBiometricDeviceModalIsOpen} onClose={biometricDeviceModalCloseHandler} mutate={deviceMutate} deviceData={deviceDataState} />
    </Box>
}