import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantProfiles(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData( q ? URIS.GET_TENANT+q : null, () => apis.getTenantApi(params), q, revalidate)

    return {
        data,
        error, loading, mutate
    }
}