import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { URIS } from '../../services/api'
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { mutate } from 'swr'
import _ from 'lodash'
import moment from 'moment'
import { FormReducer } from '../../utils/FormReducer'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useUserData } from '../../services/api/useUserData'
import { floorName } from '../../utils/Helper'
import { SHARINGS } from '../../Constants'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { useCurrentProperty } from '../../app/Context'
import { useMatchMutate } from '../../utils/useMatchMutate'

export default function AddNewTenant({ closeModal, visible, defaultData, Tenant, openRoomAssignModal, assignRoomData, showJoiningDate, openRentModal, mutateTenantList, keyPrefix }) {
    const {matchMutate} = useMatchMutate()
    const [ addTenantByRegisteraionNo, setAddTenantByRegisteraionNo ] = useState(false);
    const [tenantData, changeData] = useReducer(FormReducer, {})
    const [joiningDate, changeJoinigDate] = useState(new Date())
    
    const {currentProperty} = useCurrentProperty()
    const {organisation} = useUserData(true)
    const currentUnit =assignRoomData?.currentUnit

    const {data:unitRoomTypes} = useUnitRoomTypes(currentUnit?.propertyId, {propertyId:currentUnit?.propertyId})

    const {request:assingRoomAction} = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted:d => {
            matchMutate(keyPrefix, {revalidate:true})
            mutate(URIS.GET_PROPERTIES+true, {revalidate:true})
            openRentModal(d)
        },
        onError:d => {},
        showLoader:true,
        showAlert:true,
    })

    const { request: addRequest, loading: addLoading } = useApiRequest( addTenantByRegisteraionNo ? URIS.ADD_TENANT_BY_REGISTRATION_NUMBER : URIS.ADD_TENANT, {
        onCompleted: (d) => {
            if(assignRoomData?.currentUnit){
                assingRoomAction({method:'POST', data:{unitId:assignRoomData.currentUnit.id, tenantId:d.tenantId, joiningDate:joiningDate ? moment(joiningDate).format('YYYY-MM-DD') : null}})
            }else{
                openRoomAssignModal(d)
                mutateTenantList()
            }
        },
        onError: () => { },
        showAlert: true
    })
    const { request: updateRequest, loading: updateLoading } = useApiRequest(URIS.UPDATE_TENANT, {
        onCompleted: () => {
            closeModal()
        },
        onError: () => { },
        showAlert: true
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!tenantData?.id) {
            addRequest({ method: 'POST', data: { registrationNo: tenantData.registrationNo || '', userData: !addTenantByRegisteraionNo ?  _.omitBy(_.omit(tenantData,'registrationNo'),d => !d) : undefined, organisationId:organisation?.id, propertyId:currentProperty?.id }})
        }
        else {
            updateRequest({ method: 'PATCH', data: { id: Tenant?.id, propertyId:currentProperty?.id, organisationId:organisation.id, userData: _.omitBy(tenantData,d => !d) } })
        }
    }

    useEffect(() => {
        if (Tenant) {
            changeData({ type: 'reset', value: { id: Tenant.tenant.id, name: Tenant.tenant.name, contact: Tenant.tenant.contact } })
        }else if(assignRoomData?.contact){
            changeData({ type: 'reset', value: { contact: assignRoomData.contact } })
        }
    }, [Tenant, assignRoomData])

    const handleChange = (type, value) => {
        changeData({ type, value })
    }

    const handleJoiningDate = (date) => {
        changeJoinigDate(date)
    }

    const unitRoomType = useMemo(() => {
        if(unitRoomTypes?.length){
            return _.find(unitRoomTypes,t => t.id === currentUnit.unitRoomTypeId)
        } return null
    }, [unitRoomTypes, currentUnit])

    const changeAddMode = useCallback( () => {
        setAddTenantByRegisteraionNo( p => !p );
    },[setAddTenantByRegisteraionNo])

    return (
        <Modal onClose={closeModal} isOpen={visible}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{defaultData ? 'Update' : 'Add'} Tenant and Assign</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit} >
                    <ModalBody>
                        {currentUnit &&
                            <VStack align={'stretch'} mb={8}>
                                <HStack>
                                    <Box width={100}>
                                        <Text>Property : </Text>
                                    </Box>
                                    <Text color={'defaultColor.500'}>{`${currentUnit.property?.name}`}</Text>
                                </HStack>
                                <Flex wrap={'wrap'}>
                                    <HStack flex={1}>
                                        <Box width={100}>
                                            <Text>Floor : </Text>
                                        </Box>
                                        <Text color={'defaultColor.500'}>{`${floorName (currentUnit.floorNo)}`}</Text>
                                    </HStack>
                                    <HStack flex={1}>
                                        <Box width={100}>
                                            <Text>Room : </Text>
                                        </Box>
                                        <Text color={'defaultColor.500'}>{`${currentUnit.name}`}</Text>
                                    </HStack>
                                </Flex>
                                <Flex wrap={'wrap'}>
                                    <HStack flex={1}>
                                        <Box width={100}>
                                            <Text>Room Type : </Text>
                                        </Box>
                                        <Text color={'defaultColor.500'}>{unitRoomType?.typeName}</Text>
                                    </HStack>
                                    <HStack flex={1}>
                                        <Box width={100}>
                                            <Text>Sharing Type : </Text>
                                        </Box>
                                        <Text color={'defaultColor.500'}>{SHARINGS[currentUnit.sharing].title}</Text>
                                    </HStack>
                                </Flex>
                            </VStack>
                        }
                        
                        <VStack spacing={4}>
                            {
                                !currentUnit && addTenantByRegisteraionNo ?
                                    <>
                                        <FormControl size='sm' isRequired>
                                            <FormLabel>Registration Number</FormLabel>
                                            <Input placeholder={'Registration Number'} onChange={e => handleChange('registrationNo', e.target.value)} name='name' value={tenantData.registrationNo} />
                                        </FormControl>
                                    </>
                                :
                                <>
                                    <FormControl size='sm' isRequired>
                                        <FormLabel>Tenant Name</FormLabel>
                                        <Input placeholder={'name'} onChange={e => handleChange('name', e.target.value)} name='name' value={tenantData.name} />
                                    </FormControl>
                                    <FormControl size='sm' isRequired>
                                        <FormLabel>Contact</FormLabel>
                                        <Input placeholder={'contact'} type="tel" maxLength={10} pattern="[6-9]{1}[0-9]{9}" onChange={e => handleChange('contact', e.target.value)} name='contact' value={tenantData.contact} />
                                    </FormControl>
                                    <FormControl size='sm' isRequired>
                                        <FormLabel>Registration No</FormLabel>
                                        <Input placeholder={'Registration Number'} type="text" onChange={e => handleChange('registrationNo', e.target.value)} name='registrationNo' value={tenantData.registrationNo } />
                                    </FormControl>
                                    {showJoiningDate &&
                                        <FormControl size='sm'>
                                            <FormLabel>Date Of Joining</FormLabel>
                                            <DatePicker style={{fontSize:14}} placeholder="mm/dd/yy" name="joiningDate" type="date" onChange={handleJoiningDate} value={joiningDate} />
                                        </FormControl>
                                    }
                                </>
                            }
                        </VStack>
                        <Box mt={4} fontSize='sm'>
                            <Link onClick={ changeAddMode }>{ addTenantByRegisteraionNo ? 'Create New Tenant' : 'Import tenant by registration number' }</Link>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={addLoading || updateLoading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>)
}