import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useSWR from 'swr'
import { URIS } from '.'
import { apis } from './apis'
export function useStates(q) {
    const { data, error, isValidating:loading, mutate } = useSWR(q ? URIS.STATES+q : null, () => apis.getStatesApi(), {
        revalidateOnFocus: false,
        revalidateIfStale:false,
    })
    const toast = useToast()

    useEffect(() => {
        if (error) {
            toast({
                status: "error",
                title: "States not found",
            })
        }
        if (data && !data.ok) {
            toast({
                status: "error",
                title: "States not found",
                description: data?.data?.message
            })
        }

    }, [data, error, toast])


    return {
        data: data?.data || null,
        error, loading, mutate
    }
}