import React, { useState } from "react";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useUserData } from "../../services/api/useUserData";
import { ActionHeader } from "../../ui/ActionHeader";
import { CustomContainer } from "../../ui/CustomContainer";

export default function WebHomePage() {
    const { allProperties, organisation } = useUserData(true)
    const [selectedProperty, setProperty] = useState()
    const handleSelectProperty = (d) => {
        setProperty(d)
    }
    return (
        <Box>
            <ActionHeader title={"HomePage"} />
            <br />
            <CustomContainer w="100%" justifyContent="center">
                <Heading textAlign={'center'} size='lg'>{organisation.name}</Heading>
                <Flex w="fit-content" mt={10} justifyContent="center" align={'center'} wrap={'wrap'}>
                    {allProperties?.length ?
                        allProperties.map(property => {
                            const selected = selectedProperty?.id === property.id
                            return (
                                <Box key={property.id} pr={3} pb={3}>
                                    <Button w="200px" onClick={() => handleSelectProperty(property)} _focus={'none'} colorScheme={!selected ? 'gray' : 'defaultColor'}>
                                        {property.name}
                                    </Button>
                                </Box>
                            )
                        })
                        : <></>
                    }
                </Flex>
            </CustomContainer>
        </Box>
    )
}