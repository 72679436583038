import React, { useState } from 'react'
import { Box, Button, Checkbox, HStack, List, ListItem } from '@chakra-ui/react'
import _, { findIndex } from 'lodash'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { floorName } from '../../utils/Helper'

export const SelectedFloors = ({ nextStep, setFloors, defaultFloors }) => {
    const [selectedFloors, setSelectedFloors] = useState([])
    const allFloors = Array.from({ length: 11 }, (d, i) => --i).map((d, i) => ({ floorNo: d, id: ++i }))
    const handleChange = (floor) => {
        let floors = JSON.parse(JSON.stringify(selectedFloors))
        floors = _.xorBy(floors, [floor], 'floorNo')
        setSelectedFloors(floors)
    }

    const handleNext = () => {
        setFloors(selectedFloors.map(floor => floor.floorNo))
        nextStep(1)
    }

    const addedFloors = defaultFloors || []
    return (
        <Box>
            <List w='fit-content'>
                {allFloors.map(floor => {
                    const defaultFloor = _.findIndex(addedFloors, d => d === floor.floorNo) !== -1
                    const selected = findIndex(selectedFloors, f => f.floorNo === floor.floorNo) !== -1
                    return (
                        <ListItem textAlign={'left'} key={floor.id} fontSize={20}>
                            <Checkbox
                                disabled={defaultFloor}
                                onChange={() => handleChange(floor)}
                                isChecked={selected || defaultFloor}
                                size={'md'}
                            >
                                {floorName(floor.floorNo)}
                            </Checkbox>
                        </ListItem>
                    )
                }
                )}
            </List>
            <br /><br />
            <HStack justifyContent={['center', 'center', 'right']}>
                <Button disabled={!selectedFloors.length} onClick={handleNext} rightIcon={<AiOutlineDoubleRight />}>
                    Next
                </Button>
            </HStack>
        </Box>
    )
}