import React, { useEffect, useState } from 'react'
import { Box, Center, Divider, Flex, Heading, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip } from '@chakra-ui/react'
import { Step, Steps } from 'chakra-ui-steps';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';
import { BasicDetails } from './BasicDetails';
import { AddFloor } from './AddFloor';
import { AddUnits } from './AddUnits';
import { URIS } from '../../services/api';
import { LoadingContainer } from '../../ui/LoadingContainer';
import { SelectFloors } from '../SingleProperty/SelectFloors';
import { ROUTES } from '../routes';
import { ActionHeader } from '../../ui/ActionHeader';
import { useUnitsData } from '../../services/api/useUnitsData';
import { useAppContext } from '../../app/Context';
import { useSingleProperty } from '../../services/api/useSingleProperty';
import { map } from 'lodash';
import { BiDetail, BiSelectMultiple } from 'react-icons/bi';
import { RiBuilding4Fill } from 'react-icons/ri';
import { MdAddBusiness } from 'react-icons/md';

export const AddNewPropertyModal = ({ visible, closeModal, defaultData }) => {
    return (
        <Modal size={'6xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent pt={16} pb={20}>
                <ModalBody px={0}>
                    <AddNewProperty onComplete={closeModal} defaultData={defaultData} />
                </ModalBody>
            </ModalContent>
        </Modal>);
}

export const AddNewProperty = ({ onComplete, defaultData, firstProperty }) => {
    const navigate = useNavigate();
    const closeModal = firstProperty ? () => navigate(ROUTES.PROPERTIES) : onComplete;

    const { logout } = useAppContext()

    const [activeStep, setActiveStep] = useState(0)
    const [property, setCurrentProperty] = useState()
    const [selectedFloors, setFloors] = useState()
    const [floorsCount, setFloorsCount] = useState()

    const propertyId = property?.id || defaultData?.id
    const { data: currentProperty, loading: loadingProperties } = useSingleProperty(URIS.GET_SINGLE_PROPERTY + propertyId, { id: propertyId }, propertyId)
    const { data: allUnits, loading: loadingGetUnits } = useUnitsData(URIS.GET_ALL_UNITS + currentProperty?.id, { propertyId: currentProperty?.id }, currentProperty?.id)

    useEffect(() => {
        if (propertyId)
            handleNext(1)
    }, [propertyId])

    const handleNext = (step) => {
        setActiveStep(step)
    }

    const handleCurrentProperty = (d) => {
        setCurrentProperty(d.Property || d)
    }

    const handleSetFloors = (floors) => {
        setFloors(floors)
    }

    const handleSelectFloors = (count) => {
        setFloorsCount(count)
        handleNext(2)
    }

    const steps = [
        { id: 1, label: "Step 1", icon: <BiDetail />, description: "Property Details ", content: <BasicDetails firstProperty={firstProperty} setData={handleCurrentProperty} currentProperty={currentProperty} nextStep={handleNext} /> },
        { id: 2, label: "Step 2", icon: <RiBuilding4Fill />, description: "Add Floors", content: <AddFloor selectFloorsCount={handleSelectFloors} currentProperty={currentProperty} nextStep={handleNext} /> },
        { id: 3, label: "Step 3", icon: <BiSelectMultiple />, description: "Select Floors", content: <SelectFloors floorsCount={floorsCount} currentProperty={currentProperty} nextStep={handleNext} setFloors={handleSetFloors} /> },
        { id: 4, label: "Step 4", icon: <MdAddBusiness />, description: "Add Units", content: <AddUnits firstProperty={firstProperty} selectedFloors={selectedFloors} closeModal={closeModal} nextStep={handleNext} currentProperty={currentProperty} /> },
    ]

    const handleBack = () => {
        setActiveStep(d => --d)
    }

    return (
        <Box>
            <Box px={{ base: firstProperty ? 5 : 1, md: 10 }} py={firstProperty ? { base: 10, md: 8 } : {}} bg='white'>
                
                <Flex 
                 align='center' justify={'space-between'} borderBottom='0px solid' borderColor={'white'}>
                    <Center
                    borderLeft='4px solid' borderColor={'defaultColor.400'} py={2} pl={2}
                    >
                        <Box>
                            {
                                <Heading fontSize='2xl' color='gray.700'>{ steps[activeStep].description }</Heading>
                            }
                        </Box>
                    </Center>
                    
                    <Flex align={'end'}>
                        {
                            map(steps, (s, index) => {
                                const isActive = activeStep === s.id - 1;
                                return (
                                    <Tooltip hasArrow label={s.description}>
                                        <Box _hover={{ zIndex: 9 }} zIndex={ isActive ? 5 : activeStep >= steps.length / 2 ? index : steps.length - index } ml={ index !== 0 ? -6 : 0 }
                                        >
                                            <Center  
                                            border='1px solid'
                                            borderColor={'white'}
                                            borderRadius={'50%'} 
                                            w={'45px'}
                                            h={'45px'}
                                            transition='all 0.3s'
                                            _hover={{ transform: 'translateY(-5px)' }}
                                            p={3} bg={ isActive ? 'defaultColor.600' : 'gray.700' } color={ isActive ? 'white' : 'white' } fontSize={'3xl'}
                                            >
                                                { s.icon }
                                            </Center>
                                        </Box>
                                    </Tooltip>
                                );
                            })
                        }
                    </Flex>
                </Flex>
                <Divider mt={3} borderBottomWidth={2}/>
                <Flex justify='start' mt={6} mb={8}>
                    {
                        map(steps, (s, index) => {
                            const isActive = activeStep === s.id - 1
                            return (
                                <Box 
                                transition='box-shadow 0.3s'
                                boxShadow={ isActive && '0px 0px 0px 2px white, 0px 0px 0px 3px #b8327e' }
                                mr={2} key={index} borderRadius={'50%'} w={3} h={3} bg={ isActive ? 'defaultColor.500' : activeStep > index ? 'gray.600' : 'gray.300' }>

                                </Box>
                            )
                        })
                    }
                </Flex>
                <Box overflow={'hidden'}>
                    <Flex w='100%' pos='relative' left={ activeStep * -100 + "%" } transition='all 0.5s'>
                        {
                            map(steps, s => <Box minW='100%'>{s.content}</Box>)
                        }
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}