import React from 'react'
import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { find } from 'lodash'
import moment from 'moment'

import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { useGetData } from '../../utils/useGetData'
import { floorName } from '../../utils/Helper'
import { useUserData } from '../../services/api/useUserData'
import { Truncate } from '../../ui/Truncate'
import { useGetProperties } from '../../services/api/useGetProperties'

export const TenantHistory = ({ tenantData }) => {
    const { organisation } = useUserData(true)
    const historyKey = tenantData ? URIS.GET_UNIT_TENANTS + tenantData.tenantId + tenantData.unitTenants?.[0]?.id + organisation?.id : URIS.GET_UNIT_TENANTS
    const { data: allProperties } = useGetProperties(true);
    const { data: unitHistory, loading: loadingUnitHistory } = useGetData(historyKey, () => apis.getUnitTenants({ tenantId: tenantData?.tenantId, organisationId: organisation?.id }), true, true)

    return (
        <LoadingContainer loading={loadingUnitHistory}>
            {unitHistory?.length ?
                <Table className='responsiveTable'>
                    <Thead>
                        <Tr>
                            <Th>Sr. No</Th>
                            <Th>Room</Th>
                            <Th>Joining Date</Th>
                            <Th>Leaving Date</Th>
                            <Th>Remark</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {unitHistory.map((unit, i) => {
                            const property = unit.unit && find(allProperties, p => p.id === unit.unit.propertyId)
                            return (
                                <Tr key={unit.id}>
                                    <Td>{i + 1}</Td>
                                    <Td>
                                        <Text>{property?.name}.</Text>
                                        <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})`}</Text>
                                    </Td>
                                    <Td>{unit.joiningDate ? moment(unit.joiningDate).format('DD/MM/YYYY') : '-'}</Td>
                                    <Td>{unit.leavingDate ? moment(unit.leavingDate).format('DD/MM/YYYY') : '-'}</Td>
                                    <Td>{<Truncate length={100}>{unit.remark}</Truncate> || '-'}</Td>
                                </Tr>
                            )
                        }
                        )}
                    </Tbody>
                </Table>
                :
                null
            }
        </LoadingContainer>
    )
}