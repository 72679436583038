import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import { useUserData } from "../../services/api/useUserData";

export default function ({ closeModal, visible, resetReason, defaultData }) {
  const { organisation, user } = useUserData(true);
  const { request: addReasonAction } = useApiRequest(
    URIS.ADD_TENANT_OUT_REASON,
    {
      onCompleted: (d) => {
        resetReason();
      },
      showAlert: true,
      showLoader: true,
    }
  );
  const { request: updateReasonReaction } = useApiRequest(
    URIS.UPDATE_TENANT_OUT_REASON,
    {
      onCompleted: (d) => {
        resetReason();
      },
      showAlert: true,
      showLoader: true,
    }
  );

  const { handleSubmit, register } = useForm({
    defaultValues: {
      reason: defaultData ? defaultData.reason : "",
    },
  });

  const _submit = (data) => {
    if (defaultData) {
      updateReasonReaction({
        method: "PATCH",
        data: {
          id: defaultData.id,
          reason: data.reason,
        },
      });
      closeModal();
    } else {
      addReasonAction({
        method: "POST",
        data: {
          reason: data.reason,
          organisationId: organisation.id,
          createdById: user?.id,
        },
      });
      closeModal();
    }
  };
  return (
    <Modal onClose={closeModal} isOpen={visible}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {defaultData ? "Edit Reason" : "Add New Reason"}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(_submit)}>
          <ModalBody>
            <VStack w="100%" spacing={4}>
              <FormControl w="100%" mb={2} isRequired>
                <FormLabel htmlFor="reason">Reason</FormLabel>
                <Input
                  style={{ width: "100%" }}
                  id="name"
                  type="text"
                  {...register("reason")}
                  placeholder="Reason"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={closeModal} colorScheme={"gray"}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
