import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Box, Button, Center, Flex, HStack, Input, InputGroup, InputRightAddon, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AiOutlineUser } from 'react-icons/ai'
import { BiExit } from 'react-icons/bi'
import { BsBuilding } from 'react-icons/bs'
import Pagination from 'rc-pagination'
import { HiDotsHorizontal } from 'react-icons/hi'
import _, { find, map } from 'lodash'
import moment from 'moment'
import { AddTenantRentModal } from '../TenantPayment/AddTenantRentModal'
import { LeaveRoomModal } from './LeaveRoomModal'
import { AssignRoomModal } from './AssignRoomModal'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { floorName } from '../../utils/Helper'
import { useTenantProfiles } from '../../services/api/useTenantProfiles'
import { useAppContext, useCurrentProperty } from '../../app/Context'
import { ALL_USER_ROLES, staffPermissions } from '../../Constants'
import { useUserData } from '../../services/api/useUserData'
import { useGetProperties } from '../../services/api/useGetProperties'
import { LoadingContainer } from '../../ui/LoadingContainer'

export default function AllTenatnts(props) {
    const [leaveModal, openLeaveModal] = useState()
    const [rentModal, openRentModal] = useState()
    const [assignRoomModal, openAssignRoomModal] = useState()
    const [deleteAlert, openAlert] = useState()
    const params = useParams()
    const [searchParams] = useSearchParams();
    const { page: currentPage, tab: selectedTab } = params;
    const searchQuery = searchParams.get('search');
    const searchQueryType = searchParams.get('type') || 'name';
    const [searchInput, setSearchInput] = useState(searchQuery);
    const [curSearchkey, setCurSearchkey] = useState(searchQuery);

    const navigate = useNavigate()
    const { request: assingRoomAction } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            openLeaveModal()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })

    const { currentProperty } = useCurrentProperty()

    const { data: allProperties } = useGetProperties(true)

    const { organisation } = useUserData(true)
    
    const { properties, hasAccess, hasPermission } = useAppContext()
    const { user } = useUserData(true)

    const staffPropertiesList = useMemo(() => {
        if(hasPermission(ALL_USER_ROLES.OWNER)) return undefined;
        if (properties?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length) {
                return user.staffProfile.staffAccessDetails.map(d => d.property.id)
            } else {
                return map(properties,'id')
            }
        }
    }, [properties, user])

    const tenantsListKey = curSearchkey ? URIS.GET_TENANT + currentProperty?.id + currentPage + searchQueryType + curSearchkey : null;

    const { data: allTenant, loading: loadingTenant, mutate: mutateTenantList } = useTenantProfiles(
        tenantsListKey, { ...(searchQueryType === 'contact' ? ({ contact: searchQuery }) : searchQueryType === 'registration' ? ({ registrationNo: searchQuery }) : ({ name: searchQuery })), page: currentPage, organisationId: organisation?.id, propertyIds: staffPropertiesList?.join(",") },
        true
    )

    const { request: deleteTenantAction, loading: loadingDelete } = useApiRequest(URIS.ADD_TENANT, {
        onCompleted: d => {
            mutateTenantList()
        },
        showAlert: true
    })

    const handlePageChange = (page) => {
        navigate(`/tenants/${page}/${parseInt(selectedTab)}?search=${searchQuery ? searchQuery : ''}&type=${searchQueryType ? searchQueryType : ''}`)
    }

    const handleDelete = (tenantId) => {
        deleteTenantAction({ method: 'DELETE', params: { id: tenantId } })
    }

    const handleOpenRentModal = (tenant) => {
        openRentModal(tenant)
    }
    const handleAssign = (tenant) => {
        openAssignRoomModal(d => d ? null : tenant)
    }
    const handleLeave = (tenant) => {
        openLeaveModal(d => d ? null : tenant)
    }

    const handleOpenAlert = (tenant) => {
        openAlert(tenant)
    }

    const handleSearch = useCallback((e) => {
        e.preventDefault();
        updateParmas('search', searchInput)
    }, [searchInput])

    useEffect(() => {
        setSearchInput(searchQuery);
        setCurSearchkey(searchQuery);
    }, [searchQuery])

    const updateParmas = (type, value) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(type, value);
        navigate(`/tenants/1/2?${currentUrlParams.toString()}`)
    }
    return (
        <Box>
            <br />
            <HStack>
                <form style={{ width: '100%' }} onSubmit={handleSearch}>
                    <InputGroup size={'sm'}>
                        <Input variant={'outline'} value={searchInput} onChange={(e) => { setSearchInput(e.target.value); }} placeholder='Search tenant' />
                        <InputRightAddon border='none' children={<Button variant='flushed' onClick={handleSearch}>search</Button>} />
                    </InputGroup>
                </form>
            </HStack>
            <Flex mt={4}>
                <Box><Button size='xs' variant={searchQueryType === 'name' ? "solid" : 'ghost'} onClick={() => updateParmas('type', 'name')}>name</Button></Box>
                <Box ml={2}><Button variant={searchQueryType === 'contact' ? "solid" : 'ghost'} size='xs' onClick={() => updateParmas('type', 'contact')}>contact</Button></Box>
                <Box ml={2}><Button variant={searchQueryType === 'registration' ? "solid" : 'ghost'} size='xs' onClick={() => updateParmas('type', 'registration')}>Registration No</Button></Box>
            </Flex>
            {
                !curSearchkey || !searchQueryType ?
                    null
                    :
                    <LoadingContainer loading={loadingTenant}>
                        <Box mt={4}>
                            <Table className={'responsiveTable'}>
                                <Thead>
                                    <Tr>
                                        <Th>Sr. No</Th>
                                        <Th>Image</Th>
                                        <Th>Name</Th>
                                        <Th>Father Name</Th>
                                        {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Th>Contact</Th> : null}
                                        <Th>Email</Th>
                                        <Th>Date of Birth</Th>
                                        <Th>Room</Th>
                                        <Th>Property</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        _.sortBy(allTenant?.results, ['name']).map((tenant, i) => {
                                            const unit = tenant.unitTenants?.length && _.find(tenant.unitTenants, u => !u.leavingDate)
                                            const property = unit && _.find(allProperties, p => p.id === unit.unit?.propertyId)
                                            const fatherName = find(tenant.guardians, g => g.relation === "FATHER" )?.name;
                                            return (
                                                <Tr key={tenant.id}>
                                                    <Td>{(20 * (currentPage - 1)) + i + 1}</Td>
                                                    <Td>
                                                        <Avatar size={'sm'} src={tenant.tenant.avatar} />
                                                    </Td>
                                                    <Td>{tenant.tenant.name}</Td>
                                                    <Td>{fatherName || ''}</Td>
                                                    {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Td>{tenant.tenant.contact}</Td> : null}
                                                    <Td>{tenant.tenant.email || '-'}</Td>
                                                    <Td>{tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-'}</Td>
                                                    <Td>{unit ?
                                                        <Box>
                                                            <Text>{property?.name}</Text>
                                                            <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})`}</Text>
                                                        </Box>
                                                        : '-'
                                                    }
                                                    </Td>
                                                    <Td>{
                                                        property?.name
                                                    }</Td>
                                                    <Td>
                                                        <Center>
                                                            <Menu>
                                                                <MenuButton
                                                                    aria-label='Options'
                                                                    as={Button}
                                                                    variant='ghost'
                                                                    size='sm' colorScheme='blue'
                                                                >
                                                                    <Box>
                                                                        <Box display={{ base: 'none', lg: 'block' }}><HiDotsHorizontal fontSize={'15px'} /></Box>
                                                                        <Center display={{ base: 'flex', lg: 'none' }}><EditIcon mr={1} /> <Box>Edit</Box></Center>
                                                                    </Box>
                                                                </MenuButton>
                                                                <MenuList>
                                                                    <MenuItem icon={<AiOutlineUser fontSize={16} />} onClick={() => navigate(`/tenant/profile/${tenant.tenant.id}/0`)}>
                                                                        View  and Update Profile
                                                                    </MenuItem>
                                                                    {unit ?
                                                                        <MenuItem icon={<BiExit fontSize={16} />} onClick={() => handleLeave(tenant)}>
                                                                            Leave Room
                                                                        </MenuItem>
                                                                        :
                                                                        null
                                                                    }
                                                                    <MenuItem icon={<BsBuilding fontSize={16} />} onClick={() => handleAssign(tenant)}>
                                                                        {unit ? 'Change Room' : 'Assign Room'}
                                                                    </MenuItem>
                                                                    {/* <MenuItem icon={<DeleteIcon />} onClick={() => handleOpenAlert(tenant)}>
                                                                        Delete
                                                                    </MenuItem> */}
                                                                </MenuList>
                                                            </Menu>
                                                        </Center>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                            <HStack mt={4} justifyContent={'center'}>
                                <Pagination
                                    total={allTenant?.total || 10}
                                    pageSize={allTenant?.limit || 10}
                                    onChange={handlePageChange}
                                    current={parseInt(currentPage)}
                                />
                            </HStack>
                        </Box>
                    </LoadingContainer>

            }
            <AlertDialog
                motionPreset='slideInBottom'
                isOpen={deleteAlert?.id}
            >

                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>{deleteAlert?.unitTenants.length ? "UnAssigned The Room Before Delete" : 'Are you sure, you want to permanently delete ' + deleteAlert?.tenant.name + '?'}</AlertDialogHeader>
                        <AlertDialogFooter>
                            {deleteAlert?.unitTenants.length ?
                                <Button onClick={() => handleOpenAlert()}>
                                    Cancel
                                </Button> : <>
                                    <Button onClick={() => handleOpenAlert()}>
                                        Cancel
                                    </Button>
                                    <Button isLoading={loadingDelete} colorScheme='red' onClick={() => handleDelete(deleteAlert?.id)} ml={3}>
                                        Delete
                                    </Button>
                                </>
                            }
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {rentModal && <AddTenantRentModal tenant={rentModal} visible={rentModal} closeModal={() => openRentModal(false)} />}
            {leaveModal && <LeaveRoomModal assignedUnit={leaveModal.unitTenants?.length ? leaveModal.unitTenants[0]?.unit : null} action={assingRoomAction} currentTenant={leaveModal} visible={leaveModal} closeModal={handleLeave} />}
            {assignRoomModal && <AssignRoomModal mutateParentData={mutateTenantList} openRentModal={handleOpenRentModal} currentTenant={assignRoomModal} visible={assignRoomModal} closeModal={handleAssign} />}
        </Box>
    )
}
