import { Box, VStack, Center, Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { map, find } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Receipt } from '../TenantPayment/Receipt';
import { useReactToPrint } from 'react-to-print'
import { useDueCategories } from '../../services/api/useDueCategories';
import { ChevronDownIcon } from '@chakra-ui/icons';

const PRINT_MODES = { a4: 'a4', a5 : 'a5' }

export default function RecieptModeHistory(props) {
    const [ printMode, setPrintMode ] = useState(null);
    const { payments } = props;

    useEffect( () => {
        if(printMode){
            handlePrint();
        }
    },[printMode])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setPrintMode(null)
    });

    return (
        <Box p={2} border='2px solid' borderColor='gray.300'>
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Print All
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={ () => setPrintMode(PRINT_MODES.a4) }>Print A4</MenuItem>
                    <MenuItem onClick={ () => setPrintMode(PRINT_MODES.a5) }>Print A5</MenuItem>
                </MenuList>
            </Menu>
            <Center>
                <VStack spacing={0} maxW='210mm' ref={componentRef}>
                    {
                        map(payments, (payment, index) =>  payment.payment_type === "debit" ? 
                            <PaymentDetails printMode={printMode} payment={payment} index={index} key={payment.id}/> 
                        :   
                            <RefundDetails printMode={printMode} refund={payment} index={index} key={payment.id}/> 
                        )
                    }
                </VStack>
            </Center>
        </Box>
    )
}

const PaymentDetails = ({ payment, index, printMode }) => {
    const breakPage = index % 2 === 0;
    const { data: orgDueCategories } = useDueCategories(true)
    const paymentCategories = payment.paymentReceipts?.map(ins => {
        // const tenantDues = ins.tenantDues;
        const tenantDue = ins.tenantDues;
        const dueCategory = find(orgDueCategories, d => d.id === tenantDue?.propertyDueCategory?.dueCategoryId) || ins.paymentDetails_json
        return { ...dueCategory, fromDate: tenantDue.from_date, installment: ins }
    });
    const tenantData = { tenant: payment?.tenant, registrationNo: payment?.tenant?.tenantProfile?.registrationNo };
    return <Box css={{ '@media print': {
        minHeight: getMinHeight(printMode),
      }}} style={{ pageBreakBefore: breakPage && 'always' }}><Receipt key={payment.id} tenantData={tenantData} paymentCategories={paymentCategories} payment={payment}/></Box>
}


const RefundDetails = ({ refund, index, printMode }) => {
    const { data: allCategories } = useDueCategories(true)
    const breakPage = index % 2 === 0;
    refund = refund?.paymentRefunds?.[0];
    const tenantDue = useMemo( () => find(allCategories, d => refund && d.id === refund?.propertyDueCategory?.dueCategoryId),[allCategories, refund])
    const paymentCategories = [{
        ...tenantDue,
        name: 'Refunded(' + tenantDue?.name + ")",
        installment: { amount: refund?.amount }
    }]
    const tenantData =  { tenant: refund?.tenant, registrationNo: refund?.tenant?.tenantProfile?.registrationNo };
    return <Box css={{ '@media print': {
        minHeight: getMinHeight(printMode),
      }}} style={{ pageBreakBefore: breakPage && 'always' }}><Receipt tenantData={tenantData} paymentCategories={paymentCategories} payment={refund}/></Box>
}

const getMinHeight = (mode) => {
    return mode === PRINT_MODES.a4 ? '50vh' : '100vh'
}