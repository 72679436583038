import React, { useMemo, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, VStack } from '@chakra-ui/react'
import { differenceBy, filter, find, map, orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { INSTALLMENT_TYPE, PAYMENT_STATUS, installment_types, staffPermissions } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { dateFormat } from '../../utils/Helper'
import { useAppContext, useCurrentProperty } from '../../app/Context'
import { useGetProperties } from '../../services/api/useGetProperties'

export const AddSingleDue = ({ visible, closeModal, tenantData, unitTenant }) => {

    const [selectedCategory, setCategory] = useState()
    const [formKey, changeFormKey] = useState(0)

    const propertyId = useMemo(() => unitTenant.unit.propertyId, [unitTenant]);
    const { data: tenantDueCategories, loading, mutate } = useTenantSlips(tenantData?.id, { tenantData })

    const tenantUnit = tenantData?.unitTenants?.[0] || tenantData.unit
    const dueCategoryKey = tenantUnit ? tenantData.id + tenantUnit.id : false
    const { data: propertyDueCategories, loading: loadingPropertyCategories } = usePropertyDueCategories(dueCategoryKey,
        tenantUnit?.unit &&
        {
            propertyId: tenantUnit.unit.propertyId,
            sharing_type: tenantUnit.unit.sharing,
            unitRoomTypeId: tenantUnit.unit.unitRoomTypeId
        },
        true
    )

    const { request: addTenantDueAction, loading: isLoading } = useApiRequest(URIS.ADD_TENANT_DUE, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true,
        showLoader: true,
    })

    const remainingCategories = useMemo(() => {
        if (propertyDueCategories?.length) {
            return filter(propertyDueCategories, p => p.isEnabled && p.dueCategory.installmentType === installment_types.OT.value);
        } return []
    }, [propertyDueCategories])

    const selectCategory = (cat) => {
        setCategory({ id: cat.id, name: cat.dueCategory.name, amount: cat.amount, dueCategory: cat.dueCategory })
        changeFormKey(d => ++d)
    }

    // const handleAddNewDue = () => {
    //     setCategory({ name: '', amount: 0, due_date: null, remark: null })
    //     changeFormKey(d => ++d)
    // }

    const handleSubmit = (e) => {
        e.preventDefault()
        let category = { ...selectedCategory }

        if (category.id || category.name) {
            let finalData = {
                next_slip: false,
                unit_tenantId: unitTenant?.id,
                property_due_categoryId: category.id,
                amount: category.amount,
                due_date: category.due_date ? dateFormat(category.due_date) : null,
                apply_date: moment().format('YYYY-MM-DD'),
                remark: category.remark,
                status: PAYMENT_STATUS.GENERATED,
                propertyId: unitTenant.unit.propertyId,
                meta_data_json: {
                    installmentType: INSTALLMENT_TYPE.ONE_TIME,
                    name: category?.id ? null : category.name,
                    refundable: category.dueCategory?.refundable
                }
            }

            addTenantDueAction({ method: 'POST', data: [finalData] })
        }
    }

    const handleChange = (type, value) => {
        setCategory(d => d ? { ...d, [type]: value } : { [type]: value })
    }

    const { hasAccess } = useAppContext()
    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Due</ModalHeader>
                <ModalCloseButton />
                <form key={formKey} onSubmit={handleSubmit}>
                    <ModalBody>
                        <LoadingContainer loading={loading}>
                            <VStack align={'start'}>
                                <Flex wrap={'wrap'} align='center'>
                                    {remainingCategories?.length ?
                                        <>
                                            <Box pr={4} pb={3}>
                                                <Text>Select Category : </Text>
                                            </Box>
                                            {remainingCategories.map(cat => {
                                                const selected = cat.id === selectedCategory?.id
                                                return (
                                                    <Box pr={4} pb={3}>
                                                        <Button
                                                            onClick={() => selectCategory(cat)} _focus={'none'}
                                                            colorScheme={selected ? 'defaultColor' : 'gray'}
                                                        >
                                                            {cat.dueCategory.name}
                                                        </Button>
                                                    </Box>
                                                )
                                            })}
                                        </>
                                        :
                                        <Box pr={4} pb={3}>
                                            <Text color='secondary'>No categories available</Text>
                                        </Box>
                                    }
                                    {/* <Box pr={4} pb={3}>
                                        <Button leftIcon={<AddIcon />} onClick={handleAddNewDue} _focus={'none'} variant='ghost' >
                                            Add New Due
                                        </Button>
                                    </Box> */}
                                </Flex>
                                <br />
                                {selectedCategory ?
                                    <>
                                        <FormControl isRequired>
                                            <FormLabel>Category</FormLabel>
                                            <Input readOnly={selectedCategory.id} name='category' onChange={(e) => handleChange('name', e.target.value)} value={selectedCategory.name} placeholder='category' />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Amount</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={'₹'}
                                                />
                                                <Input
                                                    disabled={!hasAccess(staffPermissions.EDIT_CATEGORY_DUE_AMOUNT)}
                                                    name='amount'
                                                    onChange={(e) => handleChange('amount', e.target.value)}
                                                    value={selectedCategory.amount}
                                                    placeholder='amount'
                                                    type={'number'}
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        {!selectedCategory.dueCategory?.refundable &&
                                            <FormControl>
                                                <FormLabel>Due Date</FormLabel>
                                                <Input value={selectedCategory.due_date || null} type='date' placeholder='select due date'
                                                    onChange={(e) => handleChange('due_date', e.target.value)}
                                                />
                                            </FormControl>
                                        }
                                        <FormControl>
                                            <FormLabel>Remark</FormLabel>
                                            <Textarea onChange={(e) => handleChange('remark', e.target.value)} value={selectedCategory.remark} placeholder='remark' />
                                        </FormControl>
                                    </>
                                    :
                                    null
                                }
                            </VStack>
                        </LoadingContainer>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={'gray'} onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button type='submit' isLoading={isLoading} isDisabled={isLoading} >
                                Save
                            </Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}