import React from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

export const AddEntryModal = ({ closeModal, visible }) => {

    const handleSubmit = () => {

    }
    return (
        <Modal isOpen={visible} size={'4xl'} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Entry</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>

                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button onClick={closeModal} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}