
import { Button } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons'
import React from 'react'
import XLSX from 'xlsx';

export const ExportExcel = ({ filename, data, title, button, size = 'sm', type, ...props }) => {
    return (
        <>
            {button || <Button colorScheme={'teal'} type={type || null} size={size} onClick={() => exportFile(data, filename)} {...props} >{<div><DownloadIcon mr={2} />{title || 'Export Excel'}</div>}</Button>}
        </>
    )
}

export const exportFile = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "report");
    XLSX.writeFile(wb, filename ? `${filename}.xlsx` : 'Report.xlsx')
};