import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useDeviceData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_PROPERTY_MESS + q, () => apis.getDeviceDataApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

export function useSingleDeviceData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.ADD_DEVICE_DATA + q, () => apis.getSingleDeviceDataApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}


export function useDeviceProperties(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_DEVICE_PROPERTY + q, () => apis.getDevicePropertyApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

export function useDeviceTenants(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_DEVICE_TENANTS + q, () => apis.getDeviceTenantsApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}