import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantWallet(q, params, revalidate, extra) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_TENANT_WALLET+q, () => apis.getTenantWalletApi(params), q, revalidate, extra)

    return {
        data,
        error, loading, mutate
    }
}