import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import React, { } from "react";
import _, { } from "lodash";
import { URIS } from "../../services/api";
import { useUserData } from "../../services/api/useUserData";
import { AddDeviceModal } from "./AddDevice";
import { useDeviceData } from "../../services/api/useDeviceData";
import { DeviceDataList } from "./DeviceList";
import { ActionHeader } from "../../ui/ActionHeader";
import { CustomContainer } from "../../ui/CustomContainer";


export const Devices = () => {

    const { organisation } = useUserData(true)

    const { data: deviceData, mutate: deviceMutate } = useDeviceData(URIS.GET_DEVICE_DATA, { organisationId: organisation.id }, true)

    const { isOpen: addBiometricDeviceModalIsOpen, onOpen: addBiometricDeviceModalOnOpen, onClose: addBiometricDeviceModalOnClose } = useDisclosure()

    const addBiometricDeviceHandler = () => {
        addBiometricDeviceModalOnOpen()
    }
    const biometricDeviceModalCloseHandler = () => {
        addBiometricDeviceModalOnClose()
    }

    return <Box mt={5}>

        <ActionHeader title='Manage Biometric Device'>
        </ActionHeader>
        <br />
        <CustomContainer>
            <Flex justifyContent={"end"}>
                <Button leftIcon={<AddIcon />} onClick={addBiometricDeviceHandler}>Add Biometric Device</Button>
            </Flex>
            <Box mt={7}>
                <DeviceDataList deviceData={deviceData} deviceMutate={deviceMutate} />
            </Box>
            {addBiometricDeviceModalIsOpen &&
                <AddDeviceModal isOpen={addBiometricDeviceModalIsOpen} onClose={biometricDeviceModalCloseHandler} deviceData={null} mutate={deviceMutate} />}
        </CustomContainer>

    </Box>
}

