
import React, { useRef } from 'react'
import { AddIcon, CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Button, Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    InputGroup,
    Input,
    InputRightAddon,
    HStack,
    VStack,
    Center,
    IconButton,
  } from '@chakra-ui/react'
import { useState } from 'react'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { filter, map } from 'lodash'

export default function HosteRules({ id, websiteData, propertyId, updateDetails }) {
    const [ newRule, setNewRule ] = useState(false);
    const addNewInputRef = useRef();
    const { request: updateProperty, loading: propertyLoading } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: d => { 
            updateDetails();
            setNewRule(false);
         },
        onError: () => { },
        showAlert: true,
        showLoader: true
    })

    const createNewRule = (e) => {
        e.preventDefault();
        const newRulesData = websiteData?.hostel_rules_json ? [...websiteData.hostel_rules_json, newRule] : [newRule]
        updateProperty({ method: 'PATCH', data: { id: id, propertyId: propertyId, hostel_rules_json: newRulesData } })
    }
    const deleteRule = (index) => {
        const newRulesData = filter(websiteData?.hostel_rules_json, (r, i) => i !== index);
        updateProperty({ method: 'PATCH', data: { id: id, propertyId: propertyId, hostel_rules_json: newRulesData } })
    }

    return (
        <Box>
            <Flex justify={'space-between'}>
                <Box color='gray.700' fontWeight={'bold'}>
                    Hostel rules
                </Box>
                {/* <Box>
                    <Button leftIcon={<AddIcon />}>Add hostel rules</Button>
                </Box> */}
            </Flex>
            <VStack justify={'start'} spacing={1} mt={6}>
                {
                    map(websiteData?.hostel_rules_json, (rule, i) => 
                        <Flex w='100%' align='center'>
                            <CheckCircleIcon mt={1} color='pink.400' mr={2} fontSize={16}/>
                            <Box flexGrow={1}>
                               {rule}
                            </Box>
                            <Box ml={2}>
                                <IconButton onClick={ () => deleteRule(i) } colorScheme='orange' icon={<DeleteIcon />} />
                            </Box>
                        </Flex> )
                }
            </VStack>
            {
                newRule === false ?
                    <Center mt={10}>
                        <Button onClick={ () => { setNewRule(''); addNewInputRef.current.focus();} } colorScheme={'gray'} size='sm'><AddIcon mr={2}/> Add new rule</Button>
                    </Center>
                : null
            }
            {
                <Box opacity={ newRule !== false ? '1' : '0' }>
                    <Flex mt={4}>
                        <CheckCircleIcon mt={2} color='pink.400' mr={2} fontSize={16}/>
                        <Box flexGrow={1}>
                            <form onSubmit={createNewRule}>
                                <InputGroup>
                                    <Input ref={addNewInputRef} value={newRule} onChange={ (e) => setNewRule(e.target.value) } type='text' placeholder='rule' variant={'flushed'}></Input>
                                    <InputRightAddon bg='transparent' border={'none'}>
                                        <Box>
                                            <Button colorScheme={'gray'} onClick={ () => setNewRule(false) } mr={2}>cancel</Button>
                                            <Button size='xs'>Save</Button>
                                        </Box>
                                    </InputRightAddon>
                                </InputGroup>
                            </form>
                        </Box>
                    </Flex>
                </Box>
            }
            {/* <EditRuleModal /> */}
        </Box>
    )
}

const EditRuleModal = ({onClose}) => {
    return (
        <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add rule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            some body text
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}
