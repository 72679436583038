import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantOutReasonsData(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_TENANT_OUT_REASONS+q, () => apis.getTenantOutReasons(params), q, revalidate)

    return {
        data, error, loading, mutate
    }
}