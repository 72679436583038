import React, { useMemo } from 'react'
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, VStack } from '@chakra-ui/react'
import { URIS } from '../../services/api';
import { useApiRequest } from '../../services/api/useApiRequest';
import { useUserData } from '../../services/api/useUserData';
import { PUBLIC_USER_ROLES } from '../../Constants'
import { map } from 'lodash'
import { useForm } from 'react-hook-form';

export default function AddStaffModal({ closeModal, visible, defaultData, resetstaff }) {
    const { organisation } = useUserData(true)
    const { request: updateStaffAction, } = useApiRequest(URIS.UPDATE_STAFF, {
        onCompleted: d => {
            resetstaff()
        },
        showAlert: true,
        showLoader: true,
    })

    const _submit = (data) => {
        if (defaultData) {
            updateStaffAction({
                method: 'PATCH', data: {
                    id: defaultData.id,
                    userData: {
                        id: defaultData.staff.id,
                        name: data.name,
                        contact: data.contact,
                        role: data.role,
                    },
                    code: data.code,
                    isActive: data.isActive === 'true' ? true : false,
                }
            });
            closeModal()
        } else {
            updateStaffAction({
                method: 'POST', data: {
                    userData: {
                        name: data.name,
                        contact: data.contact,
                        role: data.role,
                    },
                    code: data.code,
                    organisationId: organisation.id,
                    isActive: data.isActive === 'true' ? true : false
                }
            });
            closeModal()
        }
    }

    const defaultValues = useMemo(() => {
        return defaultData ?
            {
                name: defaultData.staff.name || '',
                contact: defaultData.staff.contact || '',
                code: defaultData.code || '',
                role: defaultData.staff.role || '',
                isActive: defaultData.isActive ? 'true' : 'false'
            }
            :
            {}
    }, [defaultData])

    const { handleSubmit, register } = useForm({
        defaultValues
    })

    return (
        <Modal onClose={closeModal} isOpen={visible}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{defaultData?.id ? 'Update Staff' : 'Add New Staff'}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit(_submit)}>
                    <ModalBody>
                        <VStack w="100%" spacing={4}>
                            <FormControl w="100%" mb={2} isRequired>
                                <FormLabel htmlFor="firstName">Name</FormLabel>
                                <Input
                                    style={{ width: "100%" }}
                                    id="name" type="text"
                                    {...register('name')}
                                    placeholder="Name"

                                />
                            </FormControl>
                            <FormControl w="100%" mb={2} isRequired>
                                <FormLabel htmlFor="contact">Contact</FormLabel>
                                <Input style={{ width: "100%" }} id="contact" type="tel" {...register('contact')} placeholder="Contact" pattern='[6-9]{1}[0-9]{9}' />
                            </FormControl>
                            <FormControl w="100%" mb={2} >
                                <FormLabel htmlFor="Code">Code</FormLabel>
                                <Input style={{ width: "100%" }} id="code" type="code" {...register('code')} placeholder="Code" />
                            </FormControl>
                            <FormControl w="100%" mb={2} isRequired>
                                <FormLabel htmlFor="role">Role</FormLabel>
                                <Select style={{ width: "100%" }} w="100%" id="role" type="select" {...register('role')} placeholder="Role" >
                                    {map(PUBLIC_USER_ROLES, (role, key) => {
                                        return <option key={key} value={role}>{key}</option>;
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl w="100%" mb={2} >
                                <FormLabel htmlFor="status">Status</FormLabel>
                                <Select style={{ width: "100%" }} w="100%" id="isActive" type="select" {...register('isActive')} placeholder="Status" >
                                    <option value={'true'} >Active</option>
                                    <option value={'false'}>In-Active</option>
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal >
    )
}