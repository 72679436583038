import { CloseIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, FormControl, FormLabel, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Spinner, Textarea, Tooltip, VStack } from '@chakra-ui/react'
import { omitBy, pick } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineFileImage } from 'react-icons/ai'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useCompanyPaymentConfigs } from '../../services/api/useCompanyPaymentConfig'

export const PaymentConfigModal = ({ closeModal, company }) => {
    const { handleSubmit, register, setValue, watch } = useForm({
        defaultValues: company.paymentConfig ?
            pick(company.paymentConfig, ['onlineGatewayConfig_json', 'ifsc', 'accountNumber', 'bankAndBranch', 'upi', 'footer', 'upiQrCode'])
            :
            {}
    })

    const { mutate: mutatePaymentConfigs } = useCompanyPaymentConfigs(true)


    const watchQrCode = watch('upiQrCode')
    const { request: uploadImage, loading: uploadLoading } = useApiRequest(URIS.UPLOAD_FILE, {
        onCompleted: (d) => {
            setValue('upiQrCode', d?.url)
        },
    })

    const { request: addPaymentConfigAction, loading: addConfigLoading } = useApiRequest(URIS.ADD_PAYMENT_CONFIG, {
        onCompleted: (d) => {
            mutatePaymentConfigs()
            closeModal()
        },
    })

    const imageHandler = (e) => {
        if (e.target?.files?.length === 0) return;
        let formData = new FormData()
        formData.append('file', e.target.files?.[0])
        uploadImage({ method: 'POST', data: formData })
    }

    const _submit = (d) => {
        if (company.paymentConfig)
            addPaymentConfigAction({ method: 'PATCH', data: { ...d, id: company.paymentConfig.id } })
        else
            addPaymentConfigAction({ method: 'POST', data: omitBy({ ...d, companyId: company.id }, d => !d) })
    }

    return (
        <Modal size={'2xl'} isOpen={true} onClose={closeModal}>
            <ModalOverlay />
            <form onSubmit={handleSubmit(_submit)}>
                <ModalContent>
                    <ModalHeader>{company.paymentConfig ? 'Update' : 'Add'} Payment Method Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align={'stretch'}>
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Beneficiary Name</FormLabel>
                                    <Input {...register('onlineGatewayConfig_json[accountHolderName]')} placeholder='account holder name' />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>IFSC Code</FormLabel>
                                    <Input {...register('ifsc')} placeholder='ifsc code' />
                                </FormControl>
                            </HStack>
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Account Number</FormLabel>
                                    <NumberInput>
                                        <NumberInputField {...register('accountNumber')} placeholder='account number' />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Bank & Branch</FormLabel>
                                    <Input {...register('bankAndBranch')} placeholder='bank & branch' />
                                </FormControl>
                            </HStack>
                            <HStack spacing={4} align='stretch'>
                                <FormControl>
                                    <FormLabel>UPI ID</FormLabel>
                                    <Input {...register('upi')} placeholder='upi id' />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>UPI QR</FormLabel>
                                    {uploadLoading ?
                                        <Spinner /> :
                                        <Input accept="image/png, image/jpeg, image/jpg" onChange={imageHandler} type={'file'} placeholder='upi id' />
                                    }
                                    {watchQrCode &&
                                        <ButtonGroup mt={2} size={'sm'} colorScheme='gray' isAttached variant='outline'>
                                            <Tooltip label='view file'>
                                                <Button
                                                    fontWeight={'normal'}
                                                    leftIcon={<AiOutlineFileImage />}
                                                    onClick={() => window.open(watchQrCode, '_blank')}
                                                >
                                                    QR Image
                                                </Button>
                                            </Tooltip>
                                            <Tooltip label='remove'>
                                                <IconButton onClick={() => setValue('upiQrCode', null)} color={'red.500'} icon={<CloseIcon fontSize={10} />} />
                                            </Tooltip>
                                        </ButtonGroup>
                                    }
                                </FormControl>
                            </HStack>
                            <FormControl>
                                <FormLabel>Footer Text</FormLabel>
                                <Textarea {...register('footer')} placeholder='footer text' />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                            <Button isLoading={addConfigLoading} type='submit'>{company.paymentConfig ? 'Update' : 'Add'}</Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}