import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import {
    Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Divider, Flex, Heading, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl, Input
} from '@chakra-ui/react'
import { FormLabelX } from '../AddNewProperty/BasicDetails'
import _, { find } from 'lodash'
import { AiFillMinusCircle, AiOutlineCloseCircle, AiOutlineMore } from 'react-icons/ai'

import { PAYMENT_STATUS, staffPermissions } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useDueCategories } from '../../services/api/useDueCategories'
import { useGetProperties } from '../../services/api/useGetProperties'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { useUserData } from '../../services/api/useUserData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { displayDateFormat, floorName, getPaybleAmount, mobileView, webView } from '../../utils/Helper'
import { AddPaymentCycleModal } from './AddPaymentCycleModal'
import { AddSingleDue } from './AddSingleDue'
import { AddTenantRentModal } from './AddTenantRentModal'
import { FeesSummary } from './FeesSummary'
import { PaymentModal } from './PaymentModal'
import { PaymentStats } from './PaymentStats'
import { TenantDueModal } from './TenantDueModal'
import { useAppContext } from '../../app/Context';
import { useForm } from 'react-hook-form'
import { TenantPaymentHistory, TenantRefundHistory } from './TenantPaymentHistory'
import moment from 'moment'

export const TenantSlips = ({ tenantData, setDueDates }) => {
    const unitTenant = tenantData?.unitTenants?.[0]
    const tenantPropertyId = unitTenant?.unit.propertyId
    const navigate = useNavigate()

    const [rentModal, openRentModal] = useState()
    const [tenantDueCategories, setDueCategories] = useState([])
    const [addCycleModal, openAddCycleModal] = useState()

    const tenantId = tenantData?.tenant?.id

    const { data: allProperties } = useGetProperties(true)
    const { data: orgDueCategories } = useDueCategories(true)
    const { organisation } = useUserData(true)
    const { data: tenantDues, loading } = useTenantSlips(tenantData?.id, { tenantId, organisationId: organisation?.id }, true)
    const { data: propertyDueCategories } = usePropertyDueCategories(tenantData?.id, { propertyId: tenantPropertyId }, true)

    useEffect(() => {
        if (tenantDues && orgDueCategories?.length) {
            let data = tenantDues?.length ?
                tenantDues.map(category => {
                    const dueCategory = find(orgDueCategories, d => d.id === category.propertyDueCategory?.dueCategoryId)
                    const paidAmount = category.paid_amount || 0
                    return { ...category, paidAmount, dueCategory }
                }) : []
            setDueCategories(data)
        }
    }, [orgDueCategories, tenantDues])

    const handlRentModal = () => {
        openRentModal(d => !d)
    }

    const tenantProperty = useMemo(() => {
        if (unitTenant && allProperties?.length) {
            return find(allProperties, p => p.id === unitTenant.unit.propertyId)
        } else return null
    }, [unitTenant, allProperties])

    const applyPaymentCycleDisable = useMemo(() => {
        return _.filter(tenantDues, d => d).length
    }, [tenantDues])

    const handleAadNewPaymentCycle = () => {
        openAddCycleModal(d => !d)
    }
    return (
        propertyDueCategories?.length && _.filter(propertyDueCategories, d => d.isEnabled).length ?
            <Box>
                <LoadingContainer loading={loading}>
                    <HStack justifyContent={'space-between'}>
                        <Heading>Payments</Heading>
                        {applyPaymentCycleDisable &&
                            <Tooltip label='Add New Payment Cycle' placement='top'>
                                <Button onClick={handleAadNewPaymentCycle} variant={'solid'} leftIcon={<AddIcon />} size='sm'>Add Payment Cycle</Button>
                            </Tooltip>
                        }
                    </HStack>
                    <br />
                    {applyPaymentCycleDisable ? null :
                        <>
                            <Button colorScheme={'gray'} onClick={handlRentModal}>Apply Payment Cycle</Button>
                            <br />
                        </>
                    }
                    {tenantDueCategories?.length ?
                        <>
                            <PaymentStats tenantData={tenantData} tenantDueCategories={tenantDueCategories} />
                            <Tabs isLazy mt={4} isManual variant='enclosed'>
                                <TabList whiteSpace={'nowrap'} overflowX={'auto'} overflowY='hidden'>
                                    <Tab>Due Payments</Tab>
                                    <Tab>Payment History</Tab>
                                    <Tab>Refund History</Tab>
                                    <Tab>Fees Summary</Tab>
                                </TabList>
                                <TabPanels pt={3}>
                                    <TabPanel bg={'gray.50'}>
                                        <DuePayments tenantProperty={tenantProperty} tenantData={tenantData} unitTenant={unitTenant} tenantDueCategories={tenantDueCategories} setDueDates={setDueDates} />
                                    </TabPanel>
                                    <TabPanel bg={'gray.50'}>
                                        <TenantPaymentHistory tenantData={tenantData} />
                                    </TabPanel>
                                    <TabPanel bg={'gray.50'}>
                                        <TenantRefundHistory tenantData={tenantData} />
                                    </TabPanel>
                                    <TabPanel bg={'gray.50'}>
                                        <FeesSummary tenantProperty={tenantProperty} tenantData={tenantData} />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </>
                        :
                        null
                    }
                    {addCycleModal && <AddPaymentCycleModal unitTenant={unitTenant} tenantProfile={tenantData} visible={addCycleModal} closeModal={handleAadNewPaymentCycle} />}
                    {rentModal && <AddTenantRentModal tenant={tenantData?.tenant} visible={rentModal} closeModal={handlRentModal} />}
                </LoadingContainer>
            </Box>
            :
            <Alert status='info'>
                <AlertIcon />
                <HStack>
                    <Text>Enable payment categories for <b>{tenantProperty?.name}</b></Text>
                    <Button onClick={() => navigate(`/properties/${tenantProperty?.id}/2`)}>Property Categories</Button>
                </HStack>
            </Alert>

    )
}

const DuePayments = ({ tenantData, unitTenant, tenantDueCategories, tenantProperty, setDueDates }) => {
    const [tenantFeesModal, openTenantFeesModal] = useState()
    const [paymentModal, openPaymentModal] = useState()

    const { mutate } = useTenantSlips(tenantData?.id)
    let [dueDateString, setDueDateString] = useState("")

    const handleAddFees = () => {
        openTenantFeesModal(d => !d)
        if (tenantFeesModal) {
            mutate()
        }
    }

    const handlePay = () => {
        openPaymentModal(d => !d)
        if (paymentModal) {
            mutate()
        }
    }

    const generatedDueCategories = useMemo(() => {
        if (tenantDueCategories?.length) {
            return _.filter(tenantDueCategories, d => (d.status === PAYMENT_STATUS.GENERATED || d.status === PAYMENT_STATUS.PARTIAL_PAID))
        } return []
    }, [tenantDueCategories])

    useEffect(() => {
        let dueDates = generatedDueCategories.sort((a, b) => (new Date(a.due_date)) - (new Date(b.due_date)))
        let firstDueDate = dueDates.length > 0 ? dueDates[0] : null;
        if (firstDueDate) {
            let dueDate = moment(firstDueDate.due_date).format('DD MMMM YYYY');
            setDueDateString(dueDate)
        } else {
            setDueDateString("")
        }
    }, [generatedDueCategories])

    return (
        <Box>
            <Text fontWeight={'bold'} fontSize='default' color='secondary' >Due Payments</Text>
            {generatedDueCategories?.length ?
                <VStack align={'stretch'} mt={2} spacing={4}>
                    {
                        generatedDueCategories.map(category => {
                            return (
                                <DueCategory category={category} tenantData={tenantData} tenantPropertyId={tenantProperty?.id} key={category.id} />
                            )
                        })
                    }
                </VStack>
                :
                <Box py={4}>
                    <Text color={'secondary'}>No due payments available</Text>
                </Box>
            }
            <HStack mt={2} justify={generatedDueCategories?.length && 'right'}>
                <Button onClick={handleAddFees}>Add Due</Button>
                {generatedDueCategories.length && <Button onClick={handlePay}>Pay Bill</Button>}
            </HStack>
            <NextPaymentSlip tenantData={tenantData} tenantPropertyId={tenantProperty?.id} dueDateString={dueDateString} setDueDates={setDueDates} />
            {tenantFeesModal && <AddSingleDue visible={tenantFeesModal} closeModal={handleAddFees} unitTenant={unitTenant} tenantData={tenantData} />}
            {paymentModal && <PaymentModal tenantProperty={tenantProperty} tenantData={tenantData} visible={paymentModal} closeModal={handlePay} unitTenant={unitTenant} />}
        </Box>
    )
}

const NextPaymentSlip = ({ tenantData, tenantPropertyId, dueDateString, setDueDates }) => {

    const [changeAmountModal, setChangeAmountModal] = useState();
    const { mutate } = useTenantSlips(tenantData?.id);
    const { data: tenantDues, mutate: mutateNextSlipNo } = useTenantSlips(tenantData?.id + tenantPropertyId + "next_slip", { tenantId: tenantData?.tenantId, propertyId: tenantPropertyId, next_slip: 1 })
    const [dueCategories, setDueCategories] = useState(null);

    const { hasAccess } = useAppContext()
    const { data: allProperties } = useGetProperties(true)
    const { request: getNextDueCategorySlip, loading: generatingNextSlip } = useApiRequest(URIS.ADD_DUE_NEXT_SLIP, {
        onCompleted: d => {
            mutate();
            mutateNextSlipNo()
        },
        showAlert: true
    })
    const { data: orgDueCategories } = useDueCategories(true)
    const addNextPaymentCycle = (category) => {
        getNextDueCategorySlip({ method: 'GET', params: { tenantDuesId: category?.id } })
    }

    useEffect(() => {
        if (tenantDues && orgDueCategories?.length) {
            let data = tenantDues?.length ?
                tenantDues.map(category => {
                    const dueCategory = find(orgDueCategories, d => d.id === category.propertyDueCategory?.dueCategoryId)
                    const paidAmount = category.paid_amount || 0
                    return { ...category, paidAmount, dueCategory }
                }) : []
            setDueCategories(data)
        }
    }, [orgDueCategories, tenantDues])

    useEffect(() => {
        if (!dueDateString) {
            let nextDateString = dueCategories?.length > 0 ? moment(dueCategories[0]?.next_due_date).format("DD-MMMM-YYYY") : ""
            setDueDates(nextDateString)
        } else {
            setDueDates(dueDateString)
        }
    }, [dueCategories, dueDateString])

    return (
        <Box mt={10}>
            <VStack align={'stretch'} mt={2} spacing={4}>
                {
                    dueCategories?.map(category => {

                        const tenantUnit = category.unitTenant.unit;
                        return (
                            <Box bg='white' py={3} boxShadow='sm' borderRadius={5} px={4} mb={2}>
                                <Flex wrap={'wrap'} justify={'space-between'}>
                                    <Box><Text color={'gray.600'} fontWeight='bold'>{category.dueCategory?.name || category.meta_data_json.name}</Text></Box>
                                    <Box><Text color={'gray.600'} fontWeight='bold'>Next Apply Date: {displayDateFormat(category.next_apply_date, 'LL')}</Text></Box>
                                </Flex>
                                <Flex wrap={'wrap'} align='end' mt={4} justify='space-between'>
                                    <Flex wrap={'wrap'} spacing={4}>
                                        <Text color={'defaultColor.500'}>₹ {(category.next_amount || 0)}</Text>
                                        <Box px={2}><Text>|</Text></Box>
                                        <HStack>
                                            <Text>Room: </Text>
                                            <Text color={'gray.700'}>{`${_.find(allProperties, p => p.id === tenantUnit.propertyId)?.name} - ${floorName(tenantUnit.floorNo)}  (${tenantUnit.name})`}</Text>
                                        </HStack>
                                    </Flex>
                                    <Flex>
                                        {
                                            hasAccess(staffPermissions.CHANGE_NEXT_SLIP_PAYMENT_AMOUNT) &&
                                            <Button onClick={() => setChangeAmountModal(category)} size='sm' variant='ghost' mr={2}><EditIcon /> </Button>
                                        }
                                        <Button isLoading={generatingNextSlip} variant='outline' size='sm' onClick={() => addNextPaymentCycle(category)}>Generate slip now</Button>
                                    </Flex>
                                </Flex>
                            </Box>
                        )
                    })
                }
                {
                    changeAmountModal &&
                    <UpdateNextSlipAmount defaultAmount={changeAmountModal.next_amount} dueCategoryId={changeAmountModal?.id} onSuccess={mutateNextSlipNo} onClose={() => setChangeAmountModal(null)} isOpen={changeAmountModal} />
                }
            </VStack>
        </Box>
    )
}

export const UpdateNextSlipAmount = ({ defaultAmount, dueCategoryId, onSuccess, onClose, isOpen }) => {
    const { register, control, handleSubmit, reset } = useForm({
        defaultValues: {
            amount: defaultAmount
        }
    })
    const { request: updateTenantDue, loading: loadingUpdateDue } = useApiRequest(URIS.UPDATE_TENANT_DUE, {
        onCompleted: d => {
            onSuccess()
            onClose()
        },
        showAlert: true,
    })

    const onSubmit = (d) => {
        updateTenantDue({ method: 'PATCH', data: { id: dueCategoryId, next_amount: parseFloat(d.amount) } })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader>Edit Apply Amount</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabelX label='Next Apply Amount' desc='new amount apply from next month' />
                            <Input {...register('amount')} type='number' placeholder='new amount' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button type='submit' isLoading={loadingUpdateDue} w='full'>Change Amount</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}

export const DueCategory = ({ category, tenantData, tenantPropertyId, paid, hideMenu = false, isTenant }) => {

    const cancelRef = useRef()
    const [tenantDueModal, openTenantDueModal] = useState()
    const [dueCancelAlert, openCancelAlert] = useState()
    const { mutate } = useTenantSlips((!isTenant && tenantData?.id) ? tenantData.id : null)

    const { data: allProperties } = useGetProperties(true)

    const { request: updateTenantDue, loading: loadingUpdateDue } = useApiRequest(URIS.UPDATE_TENANT_DUE, {
        onCompleted: d => {
            mutate()
            openCancelAlert()
        },
        showAlert: true,
    })

    const handleCancelDue = (category) => {
        openCancelAlert(d => d ? null : category)
    }

    const handleUpdateTenantDue = (type, category) => {
        openTenantDueModal(d => d ? null : { type, category })
    }

    const handleConfirm = () => {
        updateTenantDue({ method: 'PATCH', data: { id: category.id, status: PAYMENT_STATUS.CANCELLED } })
    }
    const concessionCount = useMemo(() => _.sumBy(category.concession, 'concession') || 0, [category]);

    const tenantUnit = category.unitTenant.unit;

    const { hasAccess } = useAppContext()
    return (
        <Box>
            <Box bg={'white'} borderRadius={'6'} border='1px solid' py={2} px={4} boxShadow='rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' borderColor={'gray.100'}>
                <Flex wrap={'wrap'} width={'100%'} justifyContent='space-between'>
                    <HStack flex={{ base: 1 }} justify={'space-between'}>
                        <Text color={'gray.600'} fontWeight='bold'>{category.dueCategory?.name || category.meta_data_json.name || category.propertyDueCategory?.dueCategory?.name}</Text>
                        {!paid && !hideMenu && !isTenant &&
                            <Menu size={'sm'}>
                                <MenuButton
                                    {...mobileView}
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<AiOutlineMore fontSize={26} />}
                                    variant='ghost'
                                />
                                <MenuList fontSize={'small'}>
                                    {
                                        <>
                                            <MenuItem onClick={() => handleUpdateTenantDue('addAmount', category)} icon={<EditIcon fontSize={14} />}>
                                                Add Amount
                                            </MenuItem>
                                            {
                                                hasAccess(staffPermissions.CONCESSION_ADD) &&
                                                <MenuItem onClick={() => handleUpdateTenantDue('concession', category)} icon={<AiFillMinusCircle fontSize={14} />}>
                                                    Add Concession
                                                </MenuItem>
                                            }
                                        </>
                                    }
                                    {
                                        category.status !== PAYMENT_STATUS.PARTIAL_PAID && hasAccess(staffPermissions.CANCEL_DUE) &&
                                        <MenuItem colorScheme={'red'} onClick={() => handleCancelDue(category)} icon={<AiOutlineCloseCircle fontSize={16} />}>
                                            Cancel Payment
                                        </MenuItem>
                                    }
                                </MenuList>
                            </Menu>
                        }
                    </HStack>
                    <Flex wrap={'wrap'}>
                        {category.apply_date &&
                            <Box>
                                <Text color='secondary'>Apply Date: <b>{displayDateFormat(category.apply_date, 'LL')}</b></Text>
                            </Box>
                        }
                        <Box>
                            <Text color='secondary'>Due Date: <b>{displayDateFormat(category.due_date, 'LL') || '-'}</b></Text>
                        </Box>
                        {!paid && !hideMenu && !isTenant &&
                            <Menu size={'sm'}>
                                <MenuButton
                                    {...webView}
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<AiOutlineMore fontSize={26} />}
                                    variant='ghost'
                                />
                                <MenuList fontSize={'small'}>
                                    {
                                        <>
                                            <MenuItem onClick={() => handleUpdateTenantDue('addAmount', category)} icon={<EditIcon fontSize={14} />}>
                                                Add Amount
                                            </MenuItem>
                                            {
                                                hasAccess(staffPermissions.CONCESSION_ADD) &&

                                                <MenuItem onClick={() => handleUpdateTenantDue('concession', category)} icon={<AiFillMinusCircle fontSize={14} />}>
                                                    Add Concession
                                                </MenuItem>
                                            }
                                        </>
                                    }
                                    {
                                        category.status !== PAYMENT_STATUS.PARTIAL_PAID && hasAccess(staffPermissions.CANCEL_DUE) &&
                                        <MenuItem colorScheme={'red'} onClick={() => handleCancelDue(category)} icon={<AiOutlineCloseCircle fontSize={16} />}>
                                            Cancel Payment
                                        </MenuItem>
                                    }
                                </MenuList>
                            </Menu>
                        }
                    </Flex>
                </Flex>
                <Flex wrap={'wrap'} spacing={4} mt={4} color='secondary'>
                    <HStack pr={2}>
                        <Text>Amount: </Text>
                        <Text color={'defaultColor.500'}>₹ {category.paidAmount || category.paid_amount || '0'} / {getPaybleAmount(category)}</Text>
                    </HStack>
                    <Text>|</Text>
                    <HStack px={{ md: 2 }} pr={{ base: 2 }}>
                        <Text>Status: </Text>
                        <Tag variant={'subtle'} borderRadius='full' px={3}
                            colorScheme={
                                category.status === PAYMENT_STATUS.CANCELLED ?
                                    'red'
                                    : category.status === PAYMENT_STATUS.PARTIAL_PAID ?
                                        'blue'
                                        : category.status === PAYMENT_STATUS.PAID ?
                                            'green'
                                            :
                                            'yellow'

                            }
                        >
                            {_.upperCase(category.status)}
                        </Tag>
                    </HStack>
                    <Text>|</Text>
                    <HStack px={{ md: 2 }}>
                        <Text>Room: </Text>
                        <Text color={'gray.700'}>{`${_.find(allProperties, p => p.id === tenantUnit.propertyId)?.name} - ${floorName(tenantUnit.floorNo)}  (${tenantUnit.name})`}</Text>
                    </HStack>
                </Flex>
                <Box>
                    {
                        category.from_date && category.to_date &&
                        <>
                            <Flex mt={3} color='secondary'>
                                <Text>From {displayDateFormat(category.from_date, 'LL')} To {displayDateFormat(category.to_date, 'LL')}</Text>
                            </Flex>
                        </>
                    }
                </Box>
                {
                    concessionCount !== 0 &&
                    <Tooltip hasArrow placement='bottom' label={<ConccessionTooltipLabel concession={category.concession} />}>
                        <Tag cursor='pointer' mt={2} size='sm'>Concession : ₹ {concessionCount}</Tag>
                    </Tooltip>
                }
            </Box>
            <AlertDialog
                isOpen={dueCancelAlert}
                leastDestructiveRef={cancelRef}
                onClose={handleUpdateTenantDue}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Cancel Payment
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You want to cancel this due payment?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={handleCancelDue}>
                                Cancel
                            </Button>
                            <Button isLoading={loadingUpdateDue} colorScheme='red' onClick={handleConfirm} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {tenantDueModal && <TenantDueModal tenantData={tenantData} tenantPropertyId={tenantPropertyId} type={tenantDueModal.type} category={tenantDueModal.category?.id ? tenantDueModal.category : null} visible={tenantDueModal} closeModal={handleUpdateTenantDue} />}
        </Box>
    )
}


const ConccessionTooltipLabel = ({ concession }) => {
    return (
        <Box>
            {
                _.map(concession, (c, i) => <Box key={c.id}>
                    <Box>₹ {c.concession}</Box>
                    <Box>{c.remark}</Box>
                    {i !== concession.length - 1 && <Divider />}
                </Box>)
            }
        </Box>
    )
}

const InputInfo = ({ type = '', value = '-' }) => {
    return (
        <HStack>
            <Box><Text>{type} :</Text></Box>
            <Box fontWeight={'bold'}><Text>{value || '-'}</Text></Box>
        </HStack>
    )
}