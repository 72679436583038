import { Avatar, Box, Button, Text, VStack } from "@chakra-ui/react"
import { useMemo } from "react"
import { ProfileUnderDetails } from "../../TenantProfile"
import { BsBuilding, BsDoorOpen } from "react-icons/bs"
import { BiLayer } from "react-icons/bi"
import { MdPlace } from "react-icons/md"
import { floorName } from "../../../utils/Helper"
import { useUnitRoomTypes } from "../../../services/api/useUnitRoomTypes"
import { find } from "lodash"


export const TenantSidebar = ({ tenant, allProperties, onOpen, allTenants, logout }) => {

    const tenantRoom = useMemo(() => { return tenant?.unitTenants?.length ? tenant.unitTenants[0] : null }, [tenant])
    const propertyid = tenantRoom?.unit?.propertyId

    const { data: unitRoomTypes } = useUnitRoomTypes(propertyid, { propertyId: propertyid })

    return <VStack pt={10}>
        <Box textAlign={'center'} >
            <Avatar src={tenant.tenant?.avatar} size="xl" />
            <Box mt={3} fontSize={'xl'} fontWeight={'bold'} >{tenant.tenant?.name}</Box>
            <Box color={'gray.500'}  >{tenant.registrationNo}</Box>
        </Box>
        <br />
        <br />
        <Box w={'full'} px={5} >
            {tenantRoom ?
                <VStack w='100%' justify={'space-between'} wrap='wrap' flexDir={{ base: 'row', lg: 'column' }} align={'center'} spacing={{ base: 2, lg: 4 }}>
                    <ProfileUnderDetails icon={<BsBuilding fontSize={16} />} title={tenantRoom.unit.name} desc={'Room'} />
                    <ProfileUnderDetails icon={<BiLayer fontSize={16} />} title={floorName(tenantRoom.unit.floorNo)} desc={'Floor'} />
                    <ProfileUnderDetails icon={<MdPlace fontSize={16} />} title={allProperties ? find(allProperties, p => p.id === tenantRoom.unit.propertyId)?.name : null} desc={'Property'} />
                    <ProfileUnderDetails icon={<BsDoorOpen fontSize={16} />} title={unitRoomTypes?.find(d => d.id === tenantRoom.unit.unitRoomTypeId)?.typeName} desc={'Room Type'} />
                </VStack>
                :
                <>
                    <Text color='secondary'>No room assigned</Text>
                </>
            }
        </Box>
        <br />
        <br />
        <br />
        <Box>
            <Button mr={3} colorScheme="red" onClick={logout} >Logout</Button>
            {allTenants?.length > 1 && <Button colorScheme="gray" onClick={onOpen} >Change User</Button>}
        </Box>
    </VStack>
}