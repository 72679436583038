import { Avatar, Box, VStack, Text } from "@chakra-ui/react"
import { map, find, orderBy } from 'lodash'
import { DueCard } from "../../TenantPayment/TenantPaymentHistory"
import { useState, useEffect } from "react"

export const PaymentHistory = ({ paymentDetails, tenantDues, orgDueCategories, tenantData }) => {

    const [selectedPayment, setPaymentId] = useState()
    const [paymentHistory, setPaymentHistory] = useState([])

    useEffect(() => {
        const newPaymentHistory = orderBy(paymentDetails, 'createdAt', 'desc').filter(p => p.payment_type !== 'credit');
        setPaymentHistory(newPaymentHistory);
    }, [paymentDetails])

    return <Box>
        <VStack align={'stretch'} mt={3} spacing={4} h={'80vh'} overflowY={'scroll'} className='scrollbar-1' >
            {paymentHistory?.length ?
                map(paymentHistory, payment => {
                    const paymentCategories = payment.paymentReceipts?.map(ins => {
                        const tenantDue = find(tenantDues, d => d.id === ins.tenantDuesId)
                        const dueCategory = find(orgDueCategories, d => d.id === tenantDue?.propertyDueCategory?.dueCategoryId) || ins.paymentDetails_json
                        return { ...dueCategory, fromDate: tenantDue?.from_date, installment: ins }
                    })
                    const more = selectedPayment === payment.id
                    return (
                        <DueCard tenantData={tenantData} setPaymentId={setPaymentId} payment={payment} more={more} paymentCategories={paymentCategories} isTenant={true} />
                    )
                })
                :
                <Text color={'secondary'}>No payment history available</Text>
            }
        </VStack>
    </Box>
}