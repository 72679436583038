import React, { useState } from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { UploadImage } from '../../ui/UploadImage'

export const UpdateDPModal = ({ visible, closeModal, currentTenant, mutate }) => {
    const [image, changeImage] = useState()
    const [guest, setGuest] = useState()


    const { request: updateRequest } = useApiRequest(URIS.UPDATE_TENANT_PROFILE, {
        onCompleted: () => {
            mutate()
            closeModal()
        },
        onError: () => { },
        showAlert: true,
        showLoader: true,
    })

    const handleImage = (img) => {
        changeImage(img)
    }

    const handleSave = () => {
        updateRequest({ method: 'PATCH', data: { userData: { avatar: image?.url || '', id: currentTenant?.tenant?.id, }, id: currentTenant?.id, } })
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Profile Picture</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <UploadImage defaultUrl={currentTenant?.tenant?.avatar} UploadURL={setGuest} getImage={handleImage} />
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}