import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash';
import moment from 'moment'
import { DatePicker } from 'react-widgets/cjs'
import { Box, Button, FormControl, FormLabel, HStack, Input, InputGroup, Flex, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import { INSTALLMENT_TYPE, PAYMENT_STATUS, staffPermissions } from '../../Constants'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { useSingleProperty } from '../../services/api/useSingleProperty'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { useAppContext } from '../../app/Context';
import { installment_types } from '../../Constants'

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export const AddPaymentCycleModal = ({ visible, closeModal, unitTenant, tenantProfile }) => {
    const { hasAccess } = useAppContext()
    const [selectedCategory, setCategories] = useState()

    const tenantUnit = tenantProfile?.unitTenants?.[0]
    const propertyId = tenantUnit?.unit.propertyId

    const { data: tenantProperty, loading: loadingProperty } = useSingleProperty(propertyId, { id: propertyId }, true)
    const { data: propertyDueCategoriesData } = usePropertyDueCategories(tenantProfile?.id,
        unitTenant?.unit ? { propertyId: unitTenant.unit.propertyId, sharing_type: unitTenant.unit.sharing, unitRoomTypeId: unitTenant.unit.unitRoomTypeId } : {},
        true
    )
    const { data: tenantDues, mutate } = useTenantSlips(tenantProfile?.id)

    const { request: addTenantDueAction } = useApiRequest(URIS.ADD_TENANT_DUE, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true,
        showLoader: true,
    })

    const remainingCategories = useMemo(() => {
        const filterTenantDues = _.filter(tenantDues, d => d.next_slip === 1 || d.propertyDueCategory?.dueCategory?.installmentType === INSTALLMENT_TYPE.ONE_TIME)
        if (propertyDueCategoriesData?.length)
            return _.differenceBy(propertyDueCategoriesData
                .map(d => ({ ...d, property_due_categoryId: d.id })), filterTenantDues, 'property_due_categoryId')
                .filter(d => d?.isEnabled && d?.dueCategory?.installmentType !== INSTALLMENT_TYPE.ONE_TIME)
    }, [tenantDues, propertyDueCategoriesData])
    const handleSelectCategory = (category) => {
        const { installmentType, apply_from_from_day, due_from_from_day, name } = category?.dueCategory
        let data = {
            next_amount: category?.amount,
            name,
            installmentType,
            billing_cycle_from: tenantUnit?.joiningDate,
            apply_from_from_day,
            due_from_from_day,
            id: category.id,
            dueCategory: category?.dueCategory
        }
        setCategories(data)
    }

    const handleChange = (type, value) => {
        setCategories(d => d ? { ...d, [type]: value } : { [type]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...selectedCategory }
        if (data?.id) {
            const billing_cycle_from = data.billing_cycle_from
            const generate = moment(moment().format('YYYY-MM-DD')).isSameOrAfter(billing_cycle_from, 'day', billing_cycle_from)
            const next_billing_cycle_from = generate ? moment(data.billing_cycle_from).add(1, 'M').format('YYYY-MM-DD') : moment(data.billing_cycle_from).format('YYYY-MM-DD')
            const next_due_date = next_billing_cycle_from ? moment(next_billing_cycle_from).add('day', parseInt(data.due_from_from_day)).format('YYYY-MM-DD') : null
            const next_apply_date = next_billing_cycle_from ? moment(next_billing_cycle_from).add('day', parseInt(data.apply_from_from_day)).format('YYYY-MM-DD') : null
            const next_amount = data.next_amount ? parseInt(data.next_amount) : 0

            const due_date = billing_cycle_from ? moment(billing_cycle_from).add('day', parseInt(data.due_from_from_day)).format('YYYY-MM-DD') : null
            const apply_date = billing_cycle_from ? moment(billing_cycle_from).add('day', parseInt(data.apply_from_from_day)).format('YYYY-MM-DD') : null
            const amount = data.next_amount ? parseInt(data.next_amount) : 0
            const to_date = moment(apply_date).add('months', 1).subtract('days', 1).format(SERVER_DATE_FORMAT)
            data = {
                due_date,
                apply_date,
                amount,
                from_date: apply_date,
                to_date,
                next_apply_date,
                next_due_date,
                next_billing_cycle_from,
                meta_data_json: { installmentType: data.installmentType },
                next_amount,
                property_due_categoryId: data.id,
                propertyId: propertyId,
                next_slip: true,
                unit_tenantId: tenantUnit.id,
                status: apply_date && PAYMENT_STATUS.GENERATED
            }
            addTenantDueAction({ method: 'POST', data: [data] })
        }
    }

    const billingCycleFrom = useCallback((joiningDate) => {
        if (tenantProperty && joiningDate) {

            const isSameOrAfter = moment(joiningDate).isSameOrAfter(moment(), 'date')
            const isMonthSame = moment(joiningDate).month() === moment().month()

            const billing_cycle_from = tenantProperty?.meta_data_json?.billingCycleStartDay === 'jd' ?
                isSameOrAfter ?
                    moment(joiningDate).format(SERVER_DATE_FORMAT)
                    :
                    isMonthSame ?
                        moment(joiningDate).set('month', moment().month()).add(1, "month").format(SERVER_DATE_FORMAT)
                        :
                        moment(joiningDate).set('month', moment().month()).format(SERVER_DATE_FORMAT)
                :
                moment().add(1, 'M').startOf('month').format(SERVER_DATE_FORMAT)
            return billing_cycle_from
        }
        return moment().add(1, 'M').startOf('month').format(SERVER_DATE_FORMAT)
    }, [tenantProperty])

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Payment Cycle</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <LoadingContainer loading={loadingProperty}>
                            <Box>

                                {remainingCategories?.length ?
                                    <Flex mb={6} wrap='wrap' align={'center'} spacing={4}>
                                        <Box pr={2} pb={2}>
                                            <Text>Select Category : </Text>
                                        </Box>
                                        {remainingCategories.map(cat => {
                                            const selected = selectedCategory?.id === cat.id
                                            return (
                                                <Box pr={2} pb={2}>
                                                    <Button
                                                        variant={selected ? 'solid' : 'ghost'}
                                                        onClick={() => handleSelectCategory(cat)} _focus={'none'}
                                                    >
                                                        {cat?.dueCategory?.name}
                                                    </Button>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                    :
                                    <Text color={'secondary'}>No payment category available</Text>
                                }
                                {selectedCategory &&
                                    <VStack align={'stretch'}>
                                        <FormControl isRequired>
                                            <FormLabel>Category</FormLabel>
                                            <Input readOnly={selectedCategory.id} name='category' value={selectedCategory.name} placeholder='category' />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Amount</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={'₹'}
                                                />
                                                <Input
                                                    name='amount'
                                                    onChange={(e) => handleChange('next_amount', e.target.value)}
                                                    value={selectedCategory.next_amount}
                                                    placeholder='amount'
                                                    type={'number'}
                                                    readOnly={!hasAccess(staffPermissions.EDIT_CATEGORY_DUE_AMOUNT)}
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Apply Date</FormLabel>
                                            <DatePicker type={'date'} name='due_date'
                                                style={{ fontSize: 14 }}
                                                value={selectedCategory.billing_cycle_from ? new Date(selectedCategory.billing_cycle_from) : null}
                                                placeholder='due day'
                                                onChange={(e) => handleChange('billing_cycle_from', e)}
                                            />
                                        </FormControl>
                                    </VStack>
                                }
                            </Box>
                        </LoadingContainer>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button disabled={!selectedCategory} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}