import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react"
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnlinePayment } from "../../../services/api/useTenantPayment";
import { LoadingContainer } from "../../../ui/LoadingContainer";
import { useMemo } from "react";



export const TenantPaymentStatus = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const order_id = location.search ? location.search.split('=')[1] : '';

    const { data: onlinePayment, loading } = useOnlinePayment(order_id ? true : false, { order_id })

    const _ok = () => {
        if (onlinePayment) {
            navigate(`/tenant/payment?id=${onlinePayment.tenantId}`)
        }
        else {
            navigate(`/tenant/payment`)
        }
    }

    const isError = useMemo(() => {
        if (onlinePayment) { return onlinePayment.status === 'failed' ? true : false }
        return false
    }, [onlinePayment])

    return <Box bg={'gray.100'} py={150} h={'100vh'} >
        <LoadingContainer loading={loading} >
            <VStack>
                <Box bg={'white'} p={10} w='fit-content' >
                    <VStack>
                        <Flex
                            border={'3px solid'} borderColor={isError ? 'red' : 'green'} borderRadius={'full'}
                            p={3} justifyContent={'center'} alignItems={'center'} color={isError ? 'red' : 'green'}
                        >
                            <Box>{isError ? <RxCross2 size={90} /> : <MdDone size={90} />}</Box>
                        </Flex>
                        <Heading mt={8} size="xl" >{isError ? 'FAILED' : 'SUCCESS'}</Heading>
                    </VStack>
                    <br />
                    {onlinePayment && <VStack fontWeight={'semibold'} color={'gray.500'} >
                        {isError ?
                            <Box>The payment of ₹{onlinePayment.meta.amount} has been failed !</Box>
                            :
                            <Box>The payment of ₹{onlinePayment.meta.amount} has been successfully processed, and your order number is {onlinePayment.id}</Box>
                        }
                    </VStack>}
                    <br />
                    <br />
                    <VStack w={'full'} >
                        <Button onClick={_ok} size="lg" w={'70%'} >Okay</Button>
                    </VStack>
                </Box>
            </VStack>
        </LoadingContainer>
    </Box>
}