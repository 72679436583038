import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Flex, FormControl, FormLabel, Heading, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Tag, Text, Textarea, useDisclosure, VStack } from '@chakra-ui/react'
import _ from 'lodash';
import { AddIcon, StarIcon } from '@chakra-ui/icons'
import { AiOutlineDelete } from 'react-icons/ai'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { apis } from '../../services/api/apis'
import { useGetData } from '../../utils/useGetData'
import { UpdateGuestDP } from './UpdateGuestDP'

export const GuestReviewModal = ({ id, propertyId, WebsiteData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [propertyData, setPropertyData] = useState()
    const [rating, setRating] = useState(0)
    const [dpModal, openDpModal] = useState()
    const [imageurl, setImageURL] = useState()
    const [guest, setGuest] = useState()


    const { data: profileData, loading: loadingProfile, mutate } = useGetData(URIS.GET_WEBSITE_LISTING + propertyId, () => apis
        .getAllWebsiteListingApi({ unitData: true, propertyId: propertyId }), true)
    const { request: updateProperty, loading: propertyLoading } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: d => { mutate() },
        onError: () => { },
        showAlert: true,
        showLoader: true
    })

    const handleUpdateDp = () => {
        openDpModal(d => !d)
    }
    const handleImageURL = (imageData) => {
        if (imageData)
            setImageURL(imageData?.url)
    }
    var oldProfileData = _.flatten(profileData?.[0]?.guest_review_json)
    const [mergedGuestReview, mergingGuestReview] = useState()

    useEffect(() => {
        mergingGuestReview(_.concat(oldProfileData, propertyData))
    }, [propertyData])

    const propertySubmit = (e) => {
        e.preventDefault()
        updateProperty({ method: 'PATCH', data: { id: id, propertyId: propertyId, guest_review_json: mergedGuestReview } })
        onClose()
    }

    const reviewHandler = (name, value) => {
        setPropertyData((preval) => ({ ...preval, [name]: value }))
    }

    useEffect(() => {
        reviewHandler('rating', rating)
        reviewHandler('image_url', imageurl)
    }, [rating, imageurl])

    var guestreviews = []

    if (WebsiteData) {

        if (typeof WebsiteData.guest_review_json === 'string')
            guestreviews = JSON.parse(WebsiteData.guest_review_json)
        else if (typeof WebsiteData.guest_review_json === 'object')
            guestreviews = WebsiteData.guest_review_json
    }

    useEffect(() => {
        setRating(0)
        setImageURL('')
    }, [isOpen])

    return (
        <>
            <HStack justifyContent={'space-between'}>
                <Box color='gray.700' fontWeight={'bold'}>
                    Guest reviews
                </Box>
                <Button onClick={onOpen} leftIcon={<AddIcon />}>Add Guest Review</Button>
            </HStack>
            <br />
            <ReviewCard id={id} propertyId={propertyId} handleUpdateDp={handleUpdateDp} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <form onSubmit={propertySubmit}>
                    <ModalContent>
                        <ModalHeader>Add Guest Review</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box border={'1px solid #E5E8E8'} borderRadius={'lg'} p={4}  >
                                <HStack mt={2} spacing={8} alignItems='center' >
                                    <VStack pb={4}>
                                        <Avatar className='Image' objectFit={'cover'} flex={1}
                                            src={imageurl} alt='Loading Image'
                                            size={'lg'}
                                        />
                                        <Button colorScheme={'gray'} onClick={handleUpdateDp}>Update</Button>
                                    </VStack>
                                    <FormControl isRequired name='name' onChange={(e) => reviewHandler('name', e.target.value)} value={propertyData?.guest_review_json?.name} >
                                        <Input placeholder='Name' />
                                    </FormControl>
                                </HStack>

                                <Flex w="70%" justifyContent="space-evenly" fontSize={20} my={4}>
                                    {[...Array(5)].map((item, index) => (
                                        <StarIcon color={index + 1 <= rating || index + 1 === rating ? "yellow.400" : "gray"} onClick={() => setRating(index + 1)} />
                                    ))}
                                </Flex>
                                <HStack mt={2}>
                                    <FormControl name='comment' onChange={(e) => reviewHandler('comment', e.target.value)} value={propertyData?.guest_review_json?.comment}>
                                        <Textarea placeholder='Comments'></Textarea>
                                    </FormControl>
                                </HStack>
                                <FormControl my={2} >
                                    <FormLabel>Active:</FormLabel>
                                    <RadioGroup name='active' onChange={(value) => reviewHandler('active', value)} defaultValue='true'>
                                        <Stack spacing={4} direction='row'>
                                            <Radio value={'true'}>True</Radio>
                                            <Radio value={'false'}>False</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} variant='ghost' onClick={onClose}>
                                Close
                            </Button>
                            <Button type='submit'>Save</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
            {dpModal && <UpdateGuestDP propertyData={propertyData} handleImageURL={handleImageURL} profileData={profileData}
                mutate={mutate} setGuest={setGuest} visible={dpModal} closeModal={handleUpdateDp} />}
        </>
    )
}
const ReviewCard = ({ id, propertyId, handleUpdateDp }) => {
    const { data: profileData, loading: loadingProfile, mutate } = useGetData(URIS.GET_WEBSITE_LISTING + propertyId, () => apis.getAllWebsiteListingApi({ unitData: true, propertyId: propertyId }), true)
    const [rating, setRating] = useState(0)
    const [deleteModal, openDeleteModal] = useState(0)

    const handleDeleteModal = (reviewindex) => {
        openDeleteModal(reviewindex)
    };

    return (
        <VStack align={'stretch'}>
            {profileData?.[0]?.guest_review_json?.length ?
                <Flex wrap={'wrap'} >
                    {_.map(profileData?.[0].guest_review_json, (d, i) =>
                        <Box key={d.id} borderRadius={'md'} border={'1px solid #E2E8F0 '} m={4} p={2}>
                            <HStack mt={2} spacing={6} alignItems='center' >
                                <VStack pb={4}>
                                    <Avatar className='Image' objectFit={'cover'} flex={1}
                                        src={d?.image_url} alt='Loading Image'
                                        size={'md'}
                                    />
                                </VStack>
                                <VStack spacing={2}>
                                    <Flex><Heading>{d.name}</Heading></Flex>
                                    <Flex w="70%" justifyContent="space-around" fontSize={16} >
                                        {[...Array(5)].map((item, index) => (
                                            <StarIcon color={index + 1 <= d.rating || index + 1 === d.rating ? "yellow.400" : "gray"} onClick={() => setRating(index + 1)} />
                                        ))}
                                    </Flex>
                                </VStack>
                                <IconButton icon={<AiOutlineDelete />} variant='ghost' onClick={() => handleDeleteModal(i + 1)} />
                            </HStack>

                            <Flex justifyContent={'flex-end'}><Tag color={d.active == true ? 'blue' : 'red'}>{d.active == true ? 'Active' : 'InActive'}</Tag></Flex>
                            <Flex >{d.comment}</Flex>
                        </Box>
                    )}
                </Flex>
                :
                <Box my={4} textAlign={'center'} color='gray.500'>No Guest Review</Box>
            }
            {deleteModal && <ConfirmationModal visible={deleteModal} deleteModal={deleteModal} id={id} propertyId={propertyId} profileData={profileData} closeModal={handleDeleteModal} />}
        </VStack >
    )
}

const ConfirmationModal = ({ id, propertyId, visible, closeModal, deleteModal, profileData }) => {
    const { request: updateProperty, loading: propertyLoading } = useApiRequest(URIS.WEBSITE_PROPERTY, {
        onCompleted: d => { },
        onError: () => { },
        showAlert: true,
        showLoader: true
    })


    let oldarray = profileData?.[0]?.guest_review_json
    const confirmDelete = (index) => {
        let newarray = oldarray.splice(index, 1)
        updateProperty({ method: 'PATCH', data: { id: id, propertyId: propertyId, guest_review_json: oldarray } })
        closeModal()
    }
    const modalclose = () => {
        closeModal()
    }

    return (
        <Modal isOpen={visible} onClose={modalclose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmation!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to delete this review?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={modalclose}>
                        Close
                    </Button>
                    <Button variant='ghost' onClick={() => confirmDelete(deleteModal - 1)}>Delete</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}