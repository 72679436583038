import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure, Text, Spacer, HStack, VStack, Icon, Tooltip, Avatar, Tag, Tab, TabList, Tabs, TabPanels, TabPanel, Table, Thead, Tr, Th, Tbody, Td, Spinner, Wrap } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { EmptyPage } from "../../ui/EmptyPage";
import { AddBiometricDeviceModal } from "./AddBiometricDevice";
import { GrPowerReset } from "react-icons/gr";
import _, { compact, find, join } from "lodash";
import { apis } from "../../services/api/apis";
import { URIS } from "../../services/api";
import { useGetData } from "../../utils/useGetData";
import { useUnitsData } from "../../services/api/useUnitsData";
import { BiLayer } from "react-icons/bi";
import { BsDoorOpen } from "react-icons/bs";
import { RiHotelBedFill, RiHotelBedLine } from "react-icons/ri";
import { useUserData } from "../../services/api/useUserData";
import { useGetProperties } from "../../services/api/useGetProperties";
import { AiFillCaretRight } from "react-icons/ai";
import { SelectPropertyModal } from "../ManageProperties/SelectPropertyModal";
import { useMessDeviceTenants } from "../../services/api/useMessData1";
import { useApiRequest } from "../../services/api/useApiRequest";
import Pagination from 'rc-pagination'


export const BiometricDevice = ({ messDeviceData, messData, resetMess, allMessPropertyData }) => {

    const { isOpen: addBiometricDeviceModalIsOpen, onOpen: addBiometricDeviceModalOnOpen, onClose: addBiometricDeviceModalOnClose } = useDisclosure()

    const addBiometricDeviceHandler = () => {
        addBiometricDeviceModalOnOpen()
    }

    const editBiometricDeviceHandler = (data) => {
        addBiometricDeviceModalOnOpen()
    }

    const biometricDeviceModalCloseHandler = () => {
        addBiometricDeviceModalOnClose()
    }

    return <Box>
        <Flex>
            {messDeviceData ?
                <Box mb={{ base: '15px', lg: 0 }} >
                    <Text fontSize={'large'} color='black.500' fontWeight='bold'>{messDeviceData?.name}</Text>
                    <Text fontSize={'md'} color='black.500' >{`STG ID: ${messDeviceData?.biometricDevice?.boxIdNo}`}</Text>

                </Box>
                : <EmptyPage message='No Biometric Device Added' />}
            <Spacer />
            <Flex justifyContent={"end"}>
                {!messDeviceData ?
                    <Button leftIcon={<AddIcon />} onClick={addBiometricDeviceHandler}>Add Biometric Device</Button> :
                    <Button leftIcon={<EditIcon />} onClick={() => editBiometricDeviceHandler(messDeviceData)}>Edit Biometric Device</Button>}
            </Flex>
        </Flex>

        <Box mt={5}>
            <ManageProperty messData={messData} allMessPropertyData={allMessPropertyData} messDeviceData={messDeviceData} />
        </Box>
        <AddBiometricDeviceModal isOpen={addBiometricDeviceModalIsOpen} onClose={biometricDeviceModalCloseHandler} messDeviceData={messDeviceData} messData={messData} resetMess={resetMess} />
    </Box>
}


export const ManageProperty = ({ messData, allMessPropertyData, mutate, messDeviceData }) => {

    const { organisation } = useUserData(true)
    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const { data: allProperties } = useGetProperties(true);
    const [selectedProperty, setProperty] = useState()
    const [showPropertyModal, setShowPropertyModal] = useState(false);

    const filteredMessProperties = useMemo(() => {

        return allMessPropertyData?.filter((property) => property?.messId === messData?.id)?.map((property) => {
            const singlePropertyData = allProperties?.find((data) => data?.id === property?.propertyId);
            return { ...property, propertyData: singlePropertyData };
        });
    }, [allMessPropertyData, messData, allProperties]);

    useEffect(() => {
        if (filteredMessProperties?.length > 0) {
            setProperty(filteredMessProperties[0])
        }
    }, [filteredMessProperties])

    // mess device all tenants
    const { data: allMessDeviceTenants, loading, mutate: allMessDeviceTenantsMutate } = useMessDeviceTenants(selectedProperty?.id ? selectedProperty.id + page : null, { organisationId: organisation.id, messPropertyId: selectedProperty?.propertyId, boxIdNo: messDeviceData?.biometricDevice?.boxIdNo, page, limit: 20, isPending: 0 }, true)

    const { data: allMessDeviceTenantsSynced, loading: allMessDeviceTenantsloadingSynced, mutate: allMessDeviceTenantsMutateSynced } = useMessDeviceTenants(selectedProperty?.id ? selectedProperty.id + page1 + "synced" : null, { organisationId: organisation.id, messPropertyId: selectedProperty?.propertyId, boxIdNo: messDeviceData?.biometricDevice?.boxIdNo, page: page1, limit: 20, isPending: 1 }, true)

    useEffect(() => {
        setPage(1)
        setPage1(1)
    }, [selectedProperty])

    const handleSelectProperty = (prop) => {
        setProperty(prop)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }
    const handlePageChange1 = (page) => {
        setPage1(page)
    }

    return <Box>
        <Box display={{ base: "block", lg: "flex" }} >
            <Box w={"30%"} align={'stretch'} display={{ base: "flex", lg: "block" }}>
                {filteredMessProperties?.length > 0 && messDeviceData ?
                    filteredMessProperties?.map((property, i) => {
                        const selected = property.id === selectedProperty?.id
                        return (
                            <Flex mt={2} marginRight={{ base: 5, lg: 0 }} key={property.id} w={"100%"} alignItems={"center"}>
                                <PropertyCard onClick={() => handleSelectProperty(property)} key={property.id}
                                    selected={selected} property={property?.propertyData} allMessDeviceTenantsMutate={allMessDeviceTenantsMutate} allMessDeviceTenantsMutateSynced={allMessDeviceTenantsMutateSynced} messDeviceData={messDeviceData}
                                />
                            </Flex>
                        )
                    }) : messDeviceData ? <EmptyPage message="No Property Assigned" /> : null
                }
            </Box>
            {
                showPropertyModal &&
                <SelectPropertyModal allProperties={allProperties} onClose={() => setShowPropertyModal(false)} selectedPropertyId={selectedProperty?.propertyId} selectProperty={(p) => { handleSelectProperty(p); setShowPropertyModal(false); }} />
            }
            {
                messDeviceData && selectedProperty ? <Box width={{ base: '100%', lg: '70%' }} py={{ lg: 2 }} px={{ lg: 4 }}>
                    <Box boxShadow={{ lg: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }} p={{ base: 2, lg: 4 }} minH={400} borderRadius={8}
                    >
                        <Flex>
                            <Tabs isFitted variant="enclosed" w={"full"}>
                                <TabList>
                                    <Tab onClick={() => setPage1(1)}>Synced Tenants</Tab>
                                    <Tab onClick={() => setPage(1)}>Not Synced Tenants</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Table variant="simple">
                                            <Thead bgColor={"gray.100"}>
                                                <Tr>
                                                    <Th>Sr. no.</Th>
                                                    <Th>Name</Th>
                                                    <Th>contact</Th>
                                                </Tr>
                                            </Thead>
                                            {allMessDeviceTenantsloadingSynced ? <Tbody><Spinner /></Tbody> :
                                                <Tbody>
                                                    {allMessDeviceTenantsSynced?.results?.length > 0 ?
                                                        allMessDeviceTenantsSynced?.results?.map((tenantData, i) => {
                                                            return <Tr key={tenantData?.id}>
                                                                <Td>{(20 * (page1 - 1)) + i + 1}</Td>
                                                                <Td>{tenantData?.tenant?.name}</Td>
                                                                <Td>{tenantData?.tenant?.contact}</Td>
                                                            </Tr>
                                                        }) : <Tr><Td>No Data Found</Td></Tr>}
                                                </Tbody>}
                                        </Table>
                                        {allMessDeviceTenantsSynced?.results?.length > 0 ? <HStack mt={4} position={"relative"} bottom={"0px"} justifyContent={'center'}>
                                            <Pagination
                                                total={allMessDeviceTenantsSynced?.total || 20}
                                                pageSize={allMessDeviceTenantsSynced?.limit || 20}
                                                onChange={handlePageChange1}
                                                current={parseInt(page1)}
                                            />
                                        </HStack> : null}
                                    </TabPanel>

                                    <TabPanel textAlign={"center"}>
                                        <Table variant="simple">
                                            <Thead bgColor={"gray.100"}>
                                                <Tr>
                                                    <Th>Sr. no.</Th>
                                                    <Th>Name</Th>
                                                    <Th>contact</Th>
                                                </Tr>
                                            </Thead>
                                            {loading ? <Tbody><Spinner /></Tbody> :
                                                <Tbody>
                                                    {allMessDeviceTenants?.results?.length > 0 ?
                                                        allMessDeviceTenants?.results?.map((tenantData, i) => {
                                                            return <Tr key={tenantData?.id}>
                                                                <Td>{(20 * (page - 1)) + i + 1}</Td>
                                                                <Td>{tenantData?.tenant?.name}</Td>
                                                                <Td>{tenantData?.tenant?.contact}</Td>
                                                            </Tr>
                                                        }) : <Tr><Td>No Data Found</Td></Tr>}
                                                </Tbody>}
                                        </Table>
                                        {allMessDeviceTenants?.results?.length > 0 ? <HStack mt={4} justifyContent={'center'}>
                                            <Pagination
                                                total={allMessDeviceTenants?.total || 20}
                                                pageSize={allMessDeviceTenants?.limit || 20}
                                                onChange={handlePageChange}
                                                current={parseInt(page)}
                                            />
                                        </HStack> : null}

                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        </Flex>
                    </Box>
                </Box> : null
            }
        </Box>
    </Box>
}



const PropertyCard = ({ property, selected, onClick, allMessDeviceTenantsMutate, allMessDeviceTenantsMutateSynced, messDeviceData }) => {

    const { organisation } = useUserData(true)
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allUnits } = useUnitsData(property.id, { propertyId: property.id }, true)

    const state = allStates?.length ? find(allStates, state => state.id === property.state) : null
    const city = state?.cities?.length ? find(state.cities, city => city.id === property.district) : null

    const allRooms = useMemo(() => {
        if (allUnits?.length)
            return _.chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if (allUnits?.length) {
            return _.chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    //sync button handler
    const { request: syncPropertyOnDevice, loading: asycPropertyOnDeviceIsLoading } = useApiRequest(URIS.MESS_PROPERTY_SYNC_ON_DEVICE, {
        onCompleted: d => {
            allMessDeviceTenantsMutate()
            allMessDeviceTenantsMutateSynced()
        },
        showAlert: true,
        showLoader: true,
    })

    const syncPropertyHandler = (property) => {
        if (property?.id && messDeviceData?.biometricDevice?.boxIdNo)
            syncPropertyOnDevice({
                method: 'POST', data: {
                    boxIdNo: messDeviceData.biometricDevice.boxIdNo,
                    organisationId: organisation.id,
                    propertyId: property.id
                }
            })
    }

    return (
        <HStack w={"100%"} align={'stretch'} alignItems={"center"}>
            <Box flex={1} onClick={onClick} p={4} border='1px solid' borderColor={'gray.100'} borderRadius={6}
                boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                {...selected ? {
                    borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                } : { borderLeft: '6px solid', borderLeftColor: 'white' }}
                cursor={'pointer'}
                borderRightColor={'#fff'}
                transition='all .2s'
            >
                <Flex alignItems={"center"}>
                    <VStack align={'left'}>
                        <Text fontSize={'xs'} color={'gray.500'} noOfLines={2} wordBreak={'break-all'}>{join(compact([state?.name, city?.name, property.town, property.streetAddress, property.pincode]), ', ')}</Text>
                        <Text color={selected && 'defaultColor.500'} fontSize={{ base: 'sm', xl: 'large' }}>{property.name}</Text>
                        <Flex wrap={'wrap'}>
                            <FacilityCount selected={selected} count={property.noOfFloors} label={'Total Floors'} icon={BiLayer} />
                            <FacilityCount selected={selected} count={allUnits?.length || 0} label={'Total Rooms'} icon={BsDoorOpen} />
                            <FacilityCount selected={selected} count={allRooms} label={'Total Beds'} icon={RiHotelBedLine} />
                            <FacilityCount selected={selected} count={occupiedBeds} label={'Occupied Beds'} icon={RiHotelBedFill} />
                        </Flex>
                    </VStack>
                    <Spacer />
                    {selected ?
                        <Tooltip label="Sync all tenant of the property">
                            <Button ml={5} leftIcon={<GrPowerReset />}
                                isLoading={asycPropertyOnDeviceIsLoading}
                                onClick={() => syncPropertyHandler(property)}
                            >Sync</Button>
                        </Tooltip> : null}

                </Flex>
            </Box>
            <HStack pos={'relative'} width={4}>
                <Box>
                    {selected ?
                        <AiFillCaretRight color='#D6DBDF' fontSize={30}
                            boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}
                        />
                        :
                        null
                    }
                </Box>
            </HStack>
        </HStack>
    )
}

const FacilityCount = ({ count, label, icon, selected }) => {
    return (
        <Box pr={5} pb={2}>
            <Tooltip label={label}>
                <HStack spacing={1}>
                    <Icon as={icon} color={selected ? 'defaultColor.500' : 'gray'} />
                    <Text color={selected ? 'defaultColor.500' : 'gray.600'}>{count}</Text>
                </HStack>
            </Tooltip>
        </Box>
    )
}