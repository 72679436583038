import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import { findIndex } from 'lodash'
import { mutate } from 'swr'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useMatchMutate } from '../../utils/useMatchMutate'
import { ac_room_types, nonac_room_types } from '../../Constants'

export const AddRoomModal = ({ visible, closeModal, currentFloor, keyPrefix }) => {
    const params = useParams()
    const { matchMutate } = useMatchMutate()

    const [acRooms, changeAcRooms] = useState(ac_room_types)
    const [nonAcRooms, changeNonAcRooms] = useState(nonac_room_types)

    const { request: addRoomApi, loading: addRoomsLoading } = useApiRequest(URIS.ADD_ROOMS, {
        onCompleted: d => {
            matchMutate(keyPrefix, { revalidate: true })
            mutate(URIS.GET_PROPERTIES + true, { revalidate: true })
            closeModal()
        },
        showAlert: true
    })

    const handleChangeAc = (type, count) => {
        const data = [...acRooms]
        const indx = findIndex(data, d => d.id === type.id)
        data[indx].count = count
        changeAcRooms(data)
    }

    const handleChangeNonAc = (type, count) => {
        const data = [...nonAcRooms]
        const indx = findIndex(data, d => d.id === type.id)
        data[indx].count = count
        changeNonAcRooms(data)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let acRoomsData = acRooms.map((d) => ({ unitType: 'room_ac', sharing: d.sharing, floorNo: currentFloor.floorNo, count: d.count || 0, propertyId: params.id }))
        let nonAcRoomsData = nonAcRooms.map((d) => ({ unitType: 'room_nonac', sharing: d.sharing, floorNo: currentFloor.floorNo, count: d.count || 0, propertyId: params.id }))
        const data = [...acRoomsData, ...nonAcRoomsData]
        addRoomApi({ method: 'POST', data })
    }

    return (
        <Modal isOpen={visible} size='3xl' onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Rooms</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <VStack spacing={8} align={'stretch'}>
                            <VStack align={'stretch'}>
                                <Text fontSize={'md'}>AC Rooms</Text>
                                <Flex spacing={4} wrap='wrap'>
                                    {acRooms.map(type =>
                                        <Box key={type.id} width={['100%', '50%', '25%']} p={'0px 12px 6px 0'}>
                                            <Text>{type.title}</Text>
                                        </Box>
                                    )}
                                </Flex>
                            </VStack>
                            <VStack spacing={4} align='stretch'>
                                <Text fontSize={'md'}>Non-AC Rooms</Text>
                                <Flex spacing={4} wrap='wrap'>
                                    {nonAcRooms.map(type =>
                                        <Box p={'0px 12px 6px 0'} width={['100%', '50%', '25%']} key={type.id}>
                                            <Text>{type.title}</Text>
                                        </Box>
                                    )}
                                </Flex>
                            </VStack>
                        </VStack>
                        <br />
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={addRoomsLoading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}