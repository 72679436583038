import React, { useEffect, useReducer, useState } from "react";
import { AddIcon, DeleteIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";
import { BsBuilding } from 'react-icons/bs'
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import moment from "moment";
import Pagination from 'rc-pagination';
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Center, Flex, FormControl, FormLabel, HStack, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Select, Tab, Table, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { BaseURL, URIS } from "../../services/api";
import { ActionHeader } from "../../ui/ActionHeader";
import { CustomContainer } from "../../ui/CustomContainer";
import { EmptyPage } from "../../ui/EmptyPage";
import AddNewTenant from "./AddNewTenant";
import { AssignRoomModal } from "./AssignRoomModal";
import { floorName } from "../../utils/Helper";
import _, { find, map } from "lodash";
import { FormReducer } from "../../utils/FormReducer";
import { AiOutlineReload } from "react-icons/ai";
import { useGetProperties } from "../../services/api/useGetProperties";
import { useApiRequest } from "../../services/api/useApiRequest";
import { BiExit } from "react-icons/bi";
import { LeaveRoomModal } from "./LeaveRoomModal";
import { useUserData } from "../../services/api/useUserData";
import { useTenantProfiles } from "../../services/api/useTenantProfiles";
import { AddTenantRentModal } from "../TenantPayment/AddTenantRentModal";
import { useAppContext, useCurrentProperty } from "../../app/Context";
import UnAssignedTenants from "./UnassignedTenants";
import { ExportExcel } from "../../ui/ExportExcel";
import AllTenatnts from "./AllTenatnts";
import "rc-pagination/assets/index.css";
import { ALL_USER_ROLES, staffPermissions } from "../../Constants";

export default function ManageTenant() {
    const navigate = useNavigate()
    const params = useParams()
    const { page: currentPage, tab: selectedTab } = params
    const defaultFilter = { limit: 10, occupied: 1 }

    const [assignExcelData, setAssignExcelData] = useState([])
    const [addModal, openAddModal] = useState()
    const [assignRoomModal, openAssignRoomModal] = useState()
    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [leaveModal, openLeaveModal] = useState()
    const [deleteAlert, openAlert] = useState()
    const [rentModal, openRentModal] = useState()

    const { currentProperty } = useCurrentProperty()
    const tenantsListKey = currentProperty?.id && selectedTab === '0' ? currentProperty?.id + currentPage + filterIndex : undefined
    const { organisation } = useUserData(true);
    const { data: allTenant, loading: loadingTenant, mutate: mutateTenantList } = useTenantProfiles(
        tenantsListKey, { ..._.omitBy(filters, (d, i) => i === 'propertyId' && !d), page: currentPage, propertyId: currentProperty?.id, organisationId: organisation?.id },
    )
    const { data: allProperties, laoding: loadingGetProperties } = useGetProperties(true)

    const { request: assingRoomAction } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            mutateTenantList()
            openLeaveModal()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })

    const { request: deleteTenantAction, loading: loadingDelete } = useApiRequest(URIS.ADD_TENANT, {
        onCompleted: d => {
            mutateTenantList()
        },
        showAlert: true
    })

    const handleAddModal = () => {
        openAddModal(d => !d)
    }

    useEffect(() => {
        if (currentPage)
            changeFilters({ type: 'page', value: currentPage })
    }, [currentPage])

    const handleAssign = (tenant) => {
        openAssignRoomModal(d => d ? null : tenant)
        openAddModal(false)
    }

    const handlePageChange = (page) => {
        navigate(`/tenants/${page}/${parseInt(selectedTab)}`)
    }

    const handleChangeValue = (type, value) => {
        changeFilters({ type: 'merge', value: { [type]: value, page: 1 } })
    }

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate(`/tenants/${1}/${parseInt(selectedTab)}`)
    }

    const handleReset = () => {
        changeFilterIndex(i => ++i)
        changeFilters({ type: 'reset', value: defaultFilter })
    }

    const handleDelete = (tenantId) => {
        deleteTenantAction({ method: 'DELETE', params: { id: tenantId } })
    }

    const handleLeave = (tenant) => {
        openLeaveModal(d => d ? null : tenant)
    }

    useEffect(() => {
        if (filters.propertyId) {
            changeFilters({ type: 'merge', value: { occupied: '', page: 1 } })
        }
        else if (filters.propertyId === '') {
            changeFilters({ type: 'merge', value: { occupied: null, page: 1 } })
        }
    }, [filters.propertyId])

    const handleOpenAlert = (tenant) => {
        openAlert(tenant)
    }

    const handleOpenRentModal = (tenant) => {
        openRentModal(tenant)
    }

    const handleExportAllAssign = () => {
        const querys = _.omitBy(filters, (d, i) => i === 'propertyId' && !d);
        let url = BaseURL + URIS.GET_TENANT + `?propertyId=${currentProperty?.id}&organisationId=${organisation?.id}&excel=true&noPaginate=false`;
        _.map(querys, (v, q) => { url += '&' + q + "=" + v; });
        window.open(url);
    }

    useEffect(() => {
        if (allTenant?.results) {
            const newAssignExcelData = map(allTenant?.results, tenant => {
                const unit = tenant.unitTenants?.length && find(tenant.unitTenants, u => !u.leavingDate)
                const property = unit && _.find(allProperties, p => p.id === unit.unit.propertyId)

                return ({
                    NAME: tenant.tenant.name,
                    CONTACT: tenant.tenant.contact,
                    EMAIL: tenant.tenant.email || '-',
                    DOB: tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-',
                    ROOM: unit ? property?.name + ' ' + `${floorName(unit.unit.floorNo)} (${unit.unit.name})` : '-'
                });
            })
            setAssignExcelData(newAssignExcelData);
        }
    }, [allTenant, allProperties])

    const handleTabChange = (e) => {
        navigate(`/tenants/1/${e}`)
    }

    const { hasAccess, hasPermission } = useAppContext()
    return (
        <Box>
            <ActionHeader title={'Tenant List'} switchProperty={true} />
            <br />
            <CustomContainer loading={loadingGetProperties}>
                <Box p={2} overflow={'auto'} minW={200}>

                    <HStack justifyContent={"flex-end"}>
                        {hasAccess(staffPermissions.ADD_TENANT) && <Button leftIcon={<AddIcon />} onClick={handleAddModal}>Add Tenant</Button>}
                    </HStack>
                    <Tabs mt={{ base: 4, lg: 0 }} isLazy={true} lazyBehavior='keepMounted' index={parseInt(selectedTab)} onChange={handleTabChange} variant={'enclosed'}>
                        <TabList whiteSpace={'nowrap'}>
                            <Tab>Room Assigned</Tab>
                            <Tab>Room Not Assigned</Tab>
                            <Tab>Search all</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <br />
                                <HStack>
                                    <Text color={'secondary'} fontWeight='bold' fontSize={'default'}>Room Assigned</Text>
                                    <Badge fontSize={14}>
                                        {allTenant?.total}
                                    </Badge>
                                </HStack>
                                <br />
                                <Flex wrap={'wrap'} justifyContent={'space-between'}>
                                    <form onSubmit={handleFilter} key={filterIndex}>
                                        <Flex wrap={'wrap'} mb={2}>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <Input onChange={e => handleChangeValue('name', e.target.value)} value={filters.name} placeholder="Name" />
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <FormControl>
                                                    <Input onChange={e => handleChangeValue('contact', e.target.value)} value={filters.contact} placeholder="Contact" type='number' />
                                                </FormControl>
                                            </Box>
                                            <Box pr={4} pb={2}>
                                                <HStack>
                                                    <Button type="submit" variant={'outline'}>Apply</Button>
                                                    <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                                                </HStack>
                                            </Box>
                                        </Flex>
                                    </form>
                                    {
                                        allTenant?.results?.length ?
                                            <Box>
                                                <Menu>
                                                    <MenuButton as={Button}>
                                                        Export excel
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem><Button onClick={handleExportAllAssign} variant='ghost' w='full'><DownloadIcon mr={2} />Export All pages</Button></MenuItem>
                                                        <MenuItem><ExportExcel w='full' variant='ghost' colorScheme='gray' filename={'Assign-Tenants'} title='Export current' data={assignExcelData} /></MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Box>
                                            : null
                                    }
                                </Flex>
                                <br />
                                <CustomContainer loading={loadingTenant}>
                                    {
                                        allTenant?.results?.length ?
                                            <Box>
                                                <Table className="responsiveTable">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Sr. No</Th>
                                                            <Th>Image</Th>
                                                            <Th>Name</Th>
                                                            <Th>Father Name</Th>
                                                            {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Th>Contact</Th> : null}
                                                            <Th>Email</Th>
                                                            <Th>Date of Birth</Th>
                                                            <Th>Room</Th>
                                                            <Th textAlign={'center'}>Actions</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            _.sortBy(allTenant.results, ['name']).map((tenant, i) => {
                                                                const unit = tenant.unitTenants?.length && find(tenant.unitTenants, u => !u.leavingDate)
                                                                const property = unit && _.find(allProperties, p => p.id === unit.unit.propertyId)
                                                                const fatherName = find(tenant.guardians, g => g.relation === "FATHER" )?.name || '';
                                           
                                                                return (
                                                                    <Tr key={tenant.id}>
                                                                        <Td>{(10 * (currentPage - 1)) + i + 1}</Td>
                                                                        <Td>
                                                                            <Avatar size={'sm'} src={tenant.tenant.avatar} />
                                                                        </Td>
                                                                        <Td>{tenant.tenant.name}</Td>
                                                                        <Td>{fatherName}</Td>
                                                                        {hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? <Td>{tenant.tenant.contact}</Td> : null}
                                                                        <Td>{tenant.tenant.email || '-'}</Td>
                                                                        <Td>{tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-'}</Td>
                                                                        <Td>{unit ?
                                                                            <Box>
                                                                                <Text>{property?.name}</Text>
                                                                                <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})`}</Text>
                                                                            </Box>
                                                                            : '-'
                                                                        }
                                                                        </Td>
                                                                        <Td>
                                                                            <Center>
                                                                                <Menu>
                                                                                    <MenuButton
                                                                                        aria-label='Options'
                                                                                        as={Button}
                                                                                        variant='ghost'
                                                                                        size='sm' colorScheme='blue'
                                                                                    >
                                                                                        <Box>
                                                                                            <Box display={{ base: 'none', lg: 'block' }}><HiDotsHorizontal fontSize={'15px'} /></Box>
                                                                                            <Center display={{ base: 'flex', lg: 'none' }}><EditIcon mr={1} /> <Box>Edit</Box></Center>
                                                                                        </Box>
                                                                                    </MenuButton>
                                                                                    <MenuList>
                                                                                        <MenuItem icon={<AiOutlineUser fontSize={16} />} onClick={() => navigate(`/tenant/profile/${tenant.tenant.id}/0`)}>
                                                                                            View  and Update Profile
                                                                                        </MenuItem>
                                                                                        {unit ?
                                                                                            <MenuItem icon={<BiExit fontSize={16} />} onClick={() => handleLeave(tenant)}>
                                                                                                Leave Room
                                                                                            </MenuItem>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        <MenuItem icon={<BsBuilding fontSize={16} />} onClick={() => handleAssign(tenant)}>
                                                                                            {unit ? 'Change Room' : 'Assign Room'}
                                                                                        </MenuItem>
                                                                                        {/* <MenuItem icon={<DeleteIcon />} onClick={() => handleOpenAlert(tenant)}>
                                                                                            Delete
                                                                                        </MenuItem> */}
                                                                                    </MenuList>
                                                                                </Menu>
                                                                            </Center>
                                                                            <AlertDialog
                                                                                motionPreset='slideInBottom'
                                                                                isOpen={deleteAlert?.id === tenant.id}
                                                                            >

                                                                                <AlertDialogOverlay>
                                                                                    <AlertDialogContent>
                                                                                        <AlertDialogHeader>{deleteAlert?.unitTenants.length ? "UnAssigned The Room Before Delete" : 'Are you sure, you want to permanently delete ' + deleteAlert?.tenant.name + '?'}</AlertDialogHeader>
                                                                                        <AlertDialogFooter>
                                                                                            {deleteAlert?.unitTenants.length ?
                                                                                                <Button onClick={() => handleOpenAlert()}>
                                                                                                    Cancel
                                                                                                </Button> : <>
                                                                                                    <Button onClick={() => handleOpenAlert()}>
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                    <Button isLoading={loadingDelete} colorScheme='red' onClick={() => handleDelete(deleteAlert?.id)} ml={3}>
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </>
                                                                                            }

                                                                                        </AlertDialogFooter>
                                                                                    </AlertDialogContent>
                                                                                </AlertDialogOverlay>
                                                                            </AlertDialog>

                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                                <br />
                                                <HStack justifyContent={'center'}>
                                                    <Pagination
                                                        total={allTenant?.total || 10}
                                                        pageSize={allTenant?.limit || 10}
                                                        onChange={handlePageChange}
                                                        current={parseInt(currentPage)}
                                                    />
                                                </HStack>
                                            </Box>
                                            :
                                            <EmptyPage />
                                    }
                                </CustomContainer>
                            </TabPanel>
                            <TabPanel>
                                <UnAssignedTenants />
                            </TabPanel>
                            <TabPanel>
                                <AllTenatnts />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box>
                <br />
            </CustomContainer>
            {rentModal && <AddTenantRentModal tenant={rentModal} visible={rentModal} closeModal={() => openRentModal(false)} />}
            {leaveModal && <LeaveRoomModal assignedUnit={leaveModal.unitTenants?.length ? leaveModal.unitTenants[0]?.unit : null} action={assingRoomAction} currentTenant={leaveModal} visible={leaveModal} closeModal={handleLeave} />}
            {assignRoomModal && <AssignRoomModal mutateParentData={mutateTenantList} openRentModal={handleOpenRentModal} currentTenant={assignRoomModal} visible={assignRoomModal} closeModal={handleAssign} />}
            {addModal && <AddNewTenant openRoomAssignModal={d => handleAssign(d)} closeModal={handleAddModal} mutateTenantList={mutateTenantList} visible={addModal} />}
        </Box>
    )
}