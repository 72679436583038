import React, { useReducer, useState } from 'react'
import { Box, Button, Flex, FormControl, HStack, Select, Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import moment from "moment"
import Pagination from "rc-pagination"
import { DatePicker } from "react-widgets/cjs"
import _ from 'lodash'
import { AiOutlineReload } from "react-icons/ai"

import { LoadingContainer } from "../../ui/LoadingContainer"
import { useTenantsInOut } from "../../services/api/useInOutTenants"
import { FormReducer } from "../../utils/FormReducer"
import { dateFormat, floorName } from "../../utils/Helper";
import { EmptyPage } from "../../ui/EmptyPage"
import { dateRange } from "../../Constants"
import { useUserData } from "../../services/api/useUserData"

export const TenantInOutLogs = ({ tenantData }) => {
    const defaultFilter = { limit: 10, page: 1 }

    const { organisation } = useUserData(true)
    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [formKey, changeFormKey] = useState(1)

    const { data: tenantsInOut, loading } = useTenantsInOut(tenantData?.id + filterIndex, { ...filters, organisationId: organisation?.id, unitTenantId: tenantData?.unitTenants?.[0]?.id }, true, true)

    const handlePageChange = (page) => {
        changeFilters({ type: 'page', value: page })
        changeFilterIndex(d => ++d)
    }

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        changeFilters({ type: 'page', value: 1 })
    }

    const handleChangeValue = (type, value) => {
        changeFilters({ type: 'merge', value: { [type]: value, page: 1 } })
    }

    const handleRangeChange = (id) => {
        const range = _.find(dateRange, d => d.id == id)

        const data = { startDate: dateFormat(range.startDate), endDate: dateFormat(range.endDate), startTime: range.startTime, endTime: range.endTime }
        changeFilters({ type: 'merge', value: data })

    }

    const handleReset = () => {
        changeFilterIndex(d => ++d)
        changeFilters({ type: 'reset', value: defaultFilter })
        changeFormKey(i => ++i)
    }

    return (
        <LoadingContainer loading={loading}>
            <form key={formKey} onSubmit={handleFilter}>
                <Flex align={'center'} wrap={'wrap'} mb={2}>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <Select value={filters.in_out} placeholder="Select Status" onChange={e => handleChangeValue('in_out', e.target.value)}>
                                <option value={'in'}>In</option>
                                <option value={'out'}>Out</option>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <DatePicker placeholder='Start Date' defau style={{ fontSize: 14 }} value={filters.startDate ? new Date(filters.startDate) : null}
                                onChange={e => handleChangeValue('startDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                            />
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <DatePicker placeholder='End Date' defau style={{ fontSize: 14 }} value={filters.endDate ? new Date(filters.endDate) : null}
                                onChange={e => handleChangeValue('endDate', e ? moment(e).format('YYYY-MM-DD') : null)}
                            />
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <FormControl>
                            <Select value={filters.in_out}
                                placeholder="Select Date Range"
                                onChange={e => handleRangeChange(e.target.value)}
                            >
                                {dateRange.map(range =>
                                    <option value={range.id} key={range.id}>{range.title}</option>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pr={4} pb={2}>
                        <HStack>
                            <Button type="submit" variant={'outline'}>Apply</Button>
                            <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                        </HStack>
                    </Box>
                </Flex>
            </form>
            {tenantsInOut?.results?.length ?
                <>
                    <Table className="responsiveTable" variant={'simple'}>
                        <Thead>
                            <Tr>
                                <Th>Sr. No</Th>
                                <Th>Name</Th>
                                <Th>Room</Th>
                                <Th>Time</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                tenantsInOut.results.map((entry, i) => {
                                    const unit = entry.unitTenant
                                    const tenant = entry.unitTenant.tenant
                                    return (
                                        <Tr key={entry.id}>
                                            <Td>{(defaultFilter.limit * (filters.page - 1)) + i + 1}</Td>
                                            <Td>{tenant.name}</Td>
                                            <Td>{unit ?
                                                <Box>
                                                    <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})`}</Text>
                                                </Box>
                                                : '-'
                                            }
                                            </Td>
                                            <Td>
                                                {moment(entry.time).format('LLL')}
                                            </Td>
                                            <Td>
                                                {entry.in_out === 'in' ?
                                                    <Tag fontWeight={'bold'} colorScheme={'green'}>In</Tag>
                                                    :
                                                    <Tag fontWeight={'bold'} colorScheme={'red'}>Out</Tag>
                                                }
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                    <br />
                    <HStack justifyContent={'center'}>
                        <Pagination
                            total={tenantsInOut?.total || 10}
                            pageSize={tenantsInOut?.limit || 10}
                            onChange={handlePageChange}
                            current={parseInt(filters.page)}
                        />
                    </HStack>
                </>
                :
                <EmptyPage />
            }

        </LoadingContainer>
    )
}