import React, { useEffect, useReducer, useState } from 'react'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, FormControl, FormLabel, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import { DropdownList } from 'react-widgets/cjs'
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { useGetData } from '../../utils/useGetData'
import "react-widgets/styles.css";
import { FormReducer } from '../../utils/FormReducer'
import _, { find, findIndex, last, orderBy} from 'lodash'
import { useApiRequest } from '../../services/api/useApiRequest'
import useSWR from 'swr'
import { ChevronRightIcon, EditIcon } from '@chakra-ui/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { useUserData } from '../../services/api/useUserData'
import { ac_room_types, nonac_room_types } from '../../Constants'

export const AddProperty = ({defaultData}) => {
    const params = useParams()
    const navigate = useNavigate()

    const [propertyData, changeData] = useReducer(FormReducer, {})
    const {organisation} = useUserData(true)
    
    const {data:allStates} = useGetData(URIS.STATES, () => apis.getStatesApi(), true)

    const {request:addRequest} = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted:(d) => {
            navigate({pathname:'/add-property/add-rooms', search:'?id='+d.id})
        },
        onError:() => {},
        showAlert:true
    })

    useEffect(() => {
        if(defaultData){
            const {name, streetAddress, town, state, district, pincode, noOfFloors} = defaultData
            changeData({type:'reset', value:{name, streetAddress, town, state, district, pincode, noOfFloors}})
        }
    }, [defaultData])
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {...propertyData, organisationId:organisation?.id}
        
        if(defaultData)
            addRequest({method:'PATCH', data:{...data, id:defaultData.id}})
        else
            addRequest({method:'POST', data})
    }

    const handleChange = (type, value) => {
        if(type === 'state')
            changeData({type:'merge', value:{state:value, district:null}})
        else
            changeData({type, value})
    }


    const addRooms = params.type === 'add-rooms'
    const breadcrumb =  addRooms ? 
    [
        {id:1, title:'Basic Details', src:'/add-property/basic-details'},
        {id:2, title:'Add Rooms', src:'#'},
    ]
    :
    [
        {id:1, title:'Basic Details'},
    ]

    return(
        <Box>
            <ActionHeader title={'Add Property'}></ActionHeader>
            <br/>
            <CustomContainer 
            >
                <Text fontSize={'large'} fontWeight='bold' color={'secondary'}>{last(breadcrumb).title}</Text>
                <Breadcrumb separator={<ChevronRightIcon/>}>
                    {breadcrumb.map(d => 
                        <BreadcrumbItem key={d.id}>
                            <BreadcrumbLink href='#'>{d.title}</BreadcrumbLink>
                        </BreadcrumbItem>
                    )}
                </Breadcrumb>
                <br/>
                {addRooms ?
                    <AddRooms changeFloorCount={e => handleChange('noOfFloors', e)} />
                    :
                    <form onSubmit={handleSubmit} >
                        <VStack align={'stretch'} spacing={4} width={['100%' , '50%', '50%']}>
                            <FormControl size='sm' isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder={'name'} onChange={e => handleChange('name', e.target.value)} name='name' value={propertyData.name}/>
                            </FormControl>
                            <FormControl size='sm' isRequired>
                                <FormLabel>Address</FormLabel>
                                <Textarea name='streetAddress' onChange={e => handleChange('streetAddress', e.target.value)} placeholder={'address'} value={propertyData.streetAddress}/>
                            </FormControl>
                            <FormControl size='sm'>
                                <FormLabel>Town / Village</FormLabel>
                                <Input placeholder={'town / village'} onChange={e => handleChange('town', e.target.value)} value={propertyData.town}/>
                            </FormControl>
                            <FormControl size='sm'>
                                <FormLabel>State</FormLabel>
                                <DropdownList placeholder={'select state'} 
                                    onChange={e => handleChange('state', e.id)}
                                    data={allStates ? orderBy(allStates, 'name', 'asc')  : []}
                                    textField='name'
                                    style={{fontSize:14}}
                                    dataKey={'id'}
                                    value={propertyData.state}
                                />
                            </FormControl>
                            <FormControl size='sm'>
                                <FormLabel>District</FormLabel>
                                <DropdownList placeholder={'select district'} 
                                    onChange={e => handleChange('district', e.id)}
                                    style={{fontSize:14}}
                                    value={propertyData.district}
                                    data={propertyData.state && find(allStates,s => s.id === propertyData.state)?.cities?.length ? 
                                        orderBy(find(allStates,s => s.id === propertyData.state).cities, 'name', 'asc') 
                                        : []
                                    }
                                    textField='name'
                                    dataKey={'id'}
                                />
                            </FormControl>
                            <FormControl size='sm'>
                                <FormLabel>Pincode</FormLabel>
                                <Input placeholder={'pincode'} onChange={e => handleChange('pincode', e.target.value)} maxLength={6} value={propertyData.pincode}/>
                            </FormControl>
                            <FormControl size='sm' isRequired>
                                <FormLabel>Number of Floors</FormLabel>
                                <Input placeholder={'number of floors'} name='noOfFloors' onChange={e => handleChange('noOfFloors', e.target.value)} type='number' value={propertyData.noOfFloors}/>
                            </FormControl>
                        </VStack>
                        <br/>
                        <HStack>
                            <Button type='submit'>Save</Button>
                        </HStack>
                    </form>
                }
            </CustomContainer>
        </Box>
    )
}
export const AddRooms = () => {
    const params = useParams()
    const location = useLocation()
    const urlSearchParams = new URLSearchParams(location.search)
    const propertyId = urlSearchParams.get('id')

    const {data:currentProperty} = useGetData(URIS.GET_SINGLE_PROPERTY+propertyId, () => apis.getSinglePropertiesApi({id:propertyId}), true)
    const {mutate} = useSWR(URIS.GET_PROPERTIES)

    const [floorsList, setFloors] = useState([])

    const {request:addRoomApi} = useApiRequest(URIS.ADD_ROOMS, {
        onCompleted:() => {
            mutate()
        },
        showAlert:true,
        showLoader:true
    })

    useEffect(() => {
        if(currentProperty){
            const data = Array.from({length:currentProperty.noOfFloors},(e, i) => ({floorNo:i, id:i, ac_room_types:[...ac_room_types], nonac_room_types:[...nonac_room_types]}))
            setFloors(data)
        }
    }, [currentProperty])


    const floorName = (f) => {
        const floor = parseInt(f)
        const name = floor === -1 ? 'Basement' :floor === 0 ? 'Ground' :floor === 1 ? '1st Floor' :floor === 2 ? '2nd Floor' :floor === 3 ? '3rd Floor' : floor+'th floor'   
        return name
    }

    const [floorModal, openFloorModal] = useState()

    const handleFloorModal = (floor) => {
        openFloorModal(d => d ? null : floor)
    }

    const handleUpdateFloor = (floorNo) => {
        const data = [...floorsList]
        const floorIndx = findIndex(data,d => d.id === floorModal.id)
        data[floorIndx] = Object.assign({}, data[floorIndx], {floorNo})
        setFloors(data)
        openFloorModal(false)
    }

    const handleChangeRoom = (indx, type, typeIndx, value) => {
        const data = [...floorsList]
        data[indx][type][typeIndx] = Object.assign({}, data[indx][type][typeIndx], {count:value})
        setFloors(data)
    } 

    const handleSave = () => {
        const acTypes = floorsList.map(floor => 
                floor.ac_room_types.map(type => ({unitType:'room_ac', sharing:type.sharing, floorNo:parseInt(floor.floorNo), propertyId, count:parseInt(type.count) || 0}))
            )
        const nonAcTypes = floorsList.map(floor => 
                floor.nonac_room_types.map(type => ({unitType:'room_nonac', sharing:type.sharing, floorNo:parseInt(floor.floorNo), propertyId, count:parseInt(type.count) || 0}))
            )
        let data = [..._.flatMapDeep(acTypes), ..._.flattenDeep(nonAcTypes) ]
        data = _.filter(data,d => d.count)
        addRoomApi({method:'POST', data})
    }

    return(
        
        <VStack spacing={8} align={'stretch'}>
            {floorsList.length ? 
                floorsList.map((floor, indx) => 
                    <Box key={floor.id} border={'1px solid #E5E8E8'} borderRadius={8} p={4}>
                        <HStack mb={4}>
                            <Text fontSize={'default'} fontWeight='bold' color='defaultColor.500'>{floorName(floor.floorNo)}</Text>
                            <IconButton size={'xs'} onClick={() => handleFloorModal(floor)} icon={<EditIcon/>} />
                        </HStack>
                        <HStack key={floor.id} spacing={10}>
                            <VStack align={'stretch'}>
                                <Text fontSize={'md'}>AC Rooms</Text>
                                <Flex wrap='wrap'>
                                    {floor.ac_room_types.map((type, typeIndx) => 
                                        <Box key={type.id} width={['100%', '50%', '50%']} p={'0px 20px 6px 0'}>
                                            <Text color={'secondary'}>{type.title}</Text>
                                            <NumberInput min={0} value={type.count} onChange={e => handleChangeRoom(indx, 'ac_room_types', typeIndx, e)} defaultValue={0} max={100}>
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </Box>
                                    )}
                                </Flex>
                            </VStack>
                            <VStack spacing={4} align='stretch'>
                                <Text  fontSize={'md'}>Non-AC Rooms</Text>
                                <Flex spacing={4} wrap='wrap'>
                                    {floor.nonac_room_types.map((type, typeIndx) => 
                                        <Box p={'0px 20px 6px 0'} width={['100%', '50%', '50%']} key={type.id}>
                                            <Text color={'secondary'}>{type.title}</Text>
                                            <NumberInput min={0} onChange={e => handleChangeRoom(indx, 'nonac_room_types', typeIndx, e)} defaultValue={0} max={100}>
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </Box>
                                    )}
                                </Flex>
                            </VStack>
                        </HStack>
                    </Box>
                ) : null}
                <br/>
                <HStack>
                    <Button onClick={handleSave}>Save</Button>
                </HStack>
                {floorModal && <EditFloorModal visible={floorModal} defaultFloor={floorModal} handleSubmit={handleUpdateFloor} closeModal={handleFloorModal} floorsCount={currentProperty} /> }
        </VStack>
    )
}

const EditFloorModal = ({currentProperty, visible, closeModal, handleSubmit, defaultFloor}) => {

    const [floorsList, setFloors] = useState([])
    const [newFloor, setNewfloor] = useState()

    useEffect(() => {
        const data = Array.from({length:11},(d,i) => ({id:i, floorNo:i - 1}))
        setFloors(data)

    }, [currentProperty])

    const floorName = (floor) => {
        const name = floor === -1 ? 'Basement' :floor === 0 ? 'Ground' :floor === 1 ? '1st Floor' :floor === 2 ? '2nd Floor' :floor === 3 ? '3rd Floor' : floor+'th floor'   
        return name
    }

    const handleDone = () => {
        handleSubmit(newFloor)
    }

    const handleChangeFloor = (e) => {
        setNewfloor(e.target.value)
    }

    useEffect(() => {
        setNewfloor(defaultFloor.floorNo)
    }, [defaultFloor])

    return(
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Select Floor</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Select Floor</FormLabel>
                        <Select value={newFloor} placeholder='select floor' onChange={handleChangeFloor}>
                            {floorsList.map(floor => 
                                <option key={floor.id} value={floor.floorNo}>
                                    {floorName(floor.floorNo)}
                                </option>
                            )}
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={handleDone}>Done</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}