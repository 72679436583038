import { Box, Flex, Heading, Image, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import _, { sumBy } from 'lodash'
import { useMemo } from "react"
import { useSingleProperty } from "../../services/api/useSingleProperty"
import { inWords } from "../../utils/Helper"

export const BasicSlipTable = (props) => {
    const total = props.paymentCategories?.length && _.sum(props.paymentCategories.map(d => parseInt(d.installment.amount || 0)))
    return (
        <Box>
            <Table mt={2} size='md' variant={'simple'} >
                <Thead>
                    <Tr>
                        <Th w='10%'>Sr.No.</Th>
                        <Th>Particulars</Th>
                        <Th isNumeric>Paid Amount (₹)</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.paymentCategories?.length ?
                        props.paymentCategories.map((receipt, i) =>
                            <Tr key={receipt.installment.id}>
                                <Td>{++i}</Td>
                                <Td>{receipt.name}
                                    {/* {
                                    receipt?.fromDate && <>&nbsp;({moment(receipt.fromDate).format("MMM")})</>
                                } */}
                                </Td>
                                <Td isNumeric>{receipt.installment.amount}</Td>
                            </Tr>
                        )
                        :
                        null
                    }
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th colSpan={'2'}>{`AMOUNT IN WORDS ( ${inWords(total || 0)} )`}</Th>
                        <Th isNumeric fontSize={16}>{total}</Th>
                    </Tr>
                </Tfoot>
            </Table>
            <Flex justifyContent={'space-between'} mt={14} w='100%'>
                <Flex>Cleared</Flex>
                <Flex>Cashier</Flex>
            </Flex>
        </Box>
    )
}


export const GstSlipTable = (props) => {
    
    const cancelled = props.payment.status === 'cancelled'
    const total = props.paymentCategories?.length && _.sum(props.paymentCategories.map(d => parseInt(d.installment.amount || 0)))
    const propertyId = props.payment.propertyId;
    const {data:property} = useSingleProperty(propertyId, {id:propertyId})
    const company = property?.company;
    const totalAmountBeforeTax = useMemo( () => sumBy(props.paymentCategories, receipt => getAmountWithoutTax(receipt, cancelled)?.taxableAmount ), [props.paymentCategories, cancelled] )
    const totalAmount = useMemo( () => sumBy(props.paymentCategories, i => i.installment.amount), [props.paymentCategories] )
    const totalSgst = useMemo( () => sumBy(props.paymentCategories, receipt =>  getAmountWithoutTax(receipt, cancelled)?.sgstAmount), [props.paymentCategories, cancelled] )
    const totalCgst = useMemo( () => sumBy(props.paymentCategories, receipt => getAmountWithoutTax(receipt, cancelled)?.cgstAmount), [props.paymentCategories, cancelled] )
    const totalIgst = useMemo( () => sumBy(props.paymentCategories, receipt => getAmountWithoutTax(receipt, cancelled)?.igstAmount), [props.paymentCategories, cancelled] )
    // use scale need urgent requirement
    return (
        <Box transform='scale(0.92)' ml={-6} css={{ '& td,& th' : { fontSize: '14px' }, '& th' : { padding: '0px 10px', whiteSpace: 'nowrap' }, '& td' : { padding: '0px 10px' } } }>
            {/* <TableContainer> */}
                <table variant={'simple'} >
                    <thead>
                        <tr>
                            <th rowSpan={2}>Sr.No.</th>
                            <th style={{ minWidth: '135px' }} rowSpan={2}>Particulars</th>
                            <th rowSpan={2}>HSN / SAC</th>
                            <th rowSpan={2}>TAXABLE VALUE</th>
                            <th colSpan={2}>CGST</th>
                            <th colSpan={2}>SGST</th>
                            {/* <th colSpan={2}>IGST</th> */}
                            <th rowSpan={2}>TOTAL</th>
                        </tr>
                        <tr>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            {/* <th>Amount</th>
                            <th>Rate</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {props.paymentCategories?.length ?
                            props.paymentCategories.map((receipt, i) => {
                               const { cgstAmount, sgstAmount, igstAmount, taxableAmount } = getAmountWithoutTax(receipt, cancelled);
                                return (
                                    <tr key={receipt.installment.id}>
                                        <td>{++i}</td>
                                        <td>{receipt.name}</td>
                                        <td>{ receipt.hsn || '' }</td>
                                        <td isNumeric>{ parseFloat(taxableAmount).toFixed(2)}</td>
                                        <td>{ parseFloat(cgstAmount).toFixed(2) }</td>
                                        <td>{ receipt.cgst || 0 }%</td>
                                        <td>{ parseFloat(sgstAmount).toFixed(2)}</td>
                                        <td>{ receipt.sgst || 0 }%</td>
                                        {/* <td>{ parseFloat(igstAmount).toFixed(2) }</td>
                                        <td>{ receipt.igst || 0 }%</td> */}
                                        <td>{cancelled ? 0 : receipt.installment.amount}</td>
                                    </tr>);
                                }
                            )
                            :
                            null
                        }
                    </tbody>
                </table>
            {/* </TableContainer> */}
            <Flex mt={4} justifyContent='space-between'>
                <Box>
                    <Heading size='sm'>Rs {cancelled ? 'Zero' : inWords(totalAmount)}</Heading>
                </Box>
                <Box minW={310}>
                    <Flex justify='space-between'>
                        <Heading fontSize={12} whiteSpace='nowrap' w='50%'>TOTAL AMOUNT BEFORE TAX</Heading>
                        <Heading fontSize={12}>{cancelled ? 0 : parseFloat(totalAmountBeforeTax).toFixed(2)}</Heading>
                    </Flex>
                    <Flex justify='space-between'>
                        <Heading fontSize={12} whiteSpace='nowrap' w='50%'>CGST</Heading>
                        <Heading fontSize={12}>{cancelled ? 0 :parseFloat(totalCgst).toFixed(2)}</Heading>
                    </Flex>
                    <Flex justify='space-between'>
                        <Heading fontSize={12} whiteSpace='nowrap' w='50%'>SGST</Heading>
                        <Heading fontSize={12}>{cancelled ? 0 :parseFloat(totalSgst).toFixed(2)}</Heading>
                    </Flex>
                    {/* <Flex justify='space-between'>
                        <Heading whiteSpace='nowrap' w='50%'>IGST</Heading>
                        <Heading>{parseFloat(totalIgst).toFixed(2)}</Heading>
                    </Flex> */}
                    <Flex justify='space-between'>
                        <Heading fontSize={12} whiteSpace='nowrap' w='50%'>TOTAL AMOUNT AFTER TAX</Heading>
                        <Heading fontSize={12}>{cancelled ? 0 :parseFloat(totalAmount).toFixed(2)}</Heading>
                    </Flex>
                </Box>
            </Flex>
            <Box mt={0}>
                <Image w={125} mb={10} src='/images/signature.jpg' alt='signature'/>
                <Box fontSize={12}>AUTHORISED SIGNATORY</Box>
            </Box>
        </Box>
    )
}
const getAmountWithoutTax = (receipt, cancelled) => {
    const { cgst, sgst, igst } = receipt;
    const amount = receipt.installment.amount;
    const tax = (sgst || 0 ) + (cgst || 0) + ( igst || 0 );
    let amountWithoutTax = amount * 100 / (100 + tax);
    const cgstAmount = cancelled ? 0 : receipt.cgst ? amountWithoutTax * receipt.cgst / 100 : 0;
    const sgstAmount = cancelled ? 0 : receipt.sgst ? amountWithoutTax * receipt.sgst / 100 : 0;
    const igstAmount = cancelled ? 0 : receipt.igst ? amountWithoutTax * receipt.igst / 100 : 0;
    const taxableAmount = cancelled ? 0 : amountWithoutTax;
    return ({ cgstAmount, sgstAmount, igstAmount, taxableAmount });
}