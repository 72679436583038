import React, { useReducer, useRef, useState } from 'react'
import { Avatar, Box, Button, Checkbox, Flex, FormControl, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { AiOutlineReload } from 'react-icons/ai'
import _, { find } from 'lodash'
import { GiEntryDoor, GiExitDoor } from "react-icons/gi";
import Pagination from 'rc-pagination'
import moment from 'moment'
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { useGetProperties } from '../../services/api/useGetProperties'
import { EmptyPage } from '../../ui/EmptyPage'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { FormReducer } from '../../utils/FormReducer'
import { useGetData } from '../../utils/useGetData'
import { floorName } from "../../utils/Helper";
import { useApiRequest } from '../../services/api/useApiRequest'
import { useUserData } from '../../services/api/useUserData'


export const SelectInOutTenantModal = ({visible, closeModal, multiple, onSubmit, mutate}) => {
    const defaultFilter = {limit:20}

    const [filters, changeFilters] = useReducer(FormReducer, defaultFilter)
    const [filterIndex, changeFilterIndex] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedTenants, setTenants] = useState([])
    const currentId = useRef()
    const {data:allProperties, laoding:loadingGetProperties} = useGetProperties(true)
    const {user} = useUserData(true)
    const { data: allTenant, loading: loadingTenant } = useGetData(URIS.GET_TENANT+currentPage+filterIndex, 
        () => apis.getTenantApi({...filters, page:currentPage}), currentPage, 
        true
    )
    const {request:addEntryAction, loading} = useApiRequest(URIS.CREATE_IN_OUT_ENTRY, {
        onCompleted:d => {
            mutate()
        },
        showAlert:true,
    })
    const handleSubmit = () => {
        onSubmit(selectedTenants)
    }
    const handleFilter = (e) => {
        e.preventDefault()
        setCurrentPage(1)
        changeFilterIndex(d => ++d)
    }
    const handleChangeValue = (type, value) => {
        changeFilters({type:'merge',value:{[type]:value, page:1}})
    }
    const handleReset = () => {
        changeFilterIndex(i => ++i)
        changeFilters({type:'reset', value:defaultFilter})
    }
    const handlePageChange = (e) => {
        setCurrentPage(e)
    }
    const handleSelect = (tenant) => {
        if(multiple){
            return
        }else{
            setTenants(d => d[0]?.id === tenant.id ? [] : [tenant] )
        }
    }
    const handleEntry = (tenant, entery) => {
        currentId.current = tenant.id
        addEntryAction({method:'POST', data:{guardId:user.id, in_out:entery, unitTenantId:tenant.unitTenants?.[0]?.id, time:moment().format('YYYY/MM/DD HH:mm')}})
    }

    return(
        <Modal size={'6xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add In-Out Entry</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <LoadingContainer loading={loadingGetProperties || loadingTenant}>
                        <form onSubmit={handleFilter}>
                            <Flex p={3} wrap={'wrap'} mb={2}>
                                <Box pr={4} pb={2}>
                                    <FormControl>
                                        <Input onChange={e => handleChangeValue('name', e.target.value)} placeholder="name"/>
                                    </FormControl>
                                </Box>
                                <Box pr={4} pb={2}>
                                    <FormControl>
                                        <Input onChange={e => handleChangeValue('contact', e.target.value)} placeholder="contact" type='number'/>
                                    </FormControl>
                                </Box>
                                <Box pr={4} pb={2}>
                                    <HStack>
                                        <Button type="submit" variant={'outline'}>Apply</Button>
                                        <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                                    </HStack>
                                </Box>
                            </Flex>
                        </form>
                        <br/>
                        {allTenant?.results?.length ?
                            <Box>
                                <Table variant={'simple'}>
                                    <Thead>
                                        <Tr>
                                            <Th>Sr. No</Th>
                                            <Th>Image</Th>
                                            <Th>Name</Th>
                                            <Th>Contact</Th>
                                            <Th>Date of Birth</Th>
                                            <Th>Room</Th>
                                            <Th>Select</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            _.sortBy(allTenant.results,['name']).map((tenant, i) => {
                                                const unit = tenant.unitTenants?.length && find(tenant.unitTenants,u => !u.leavingDate)
                                                const property = unit && _.find(allProperties,p => p.id === unit.unit.propertyId)
                                                const selected = _.findIndex(selectedTenants,t => t.id === tenant.id) !== -1 
                                                return (
                                                    <Tr key={tenant.id}>
                                                        <Td>{(defaultFilter.limit*(currentPage - 1)) + i + 1}</Td>
                                                        <Td>
                                                            <Avatar size={'sm'} src={tenant.tenant.avatar}/>
                                                        </Td>
                                                        <Td>{tenant.tenant.name}</Td>
                                                        <Td>{tenant.tenant.contact}</Td>
                                                        <Td>{tenant.tenant.dob ? moment(tenant.tenant.dob).format('DD/MM/YYYY') : '-'}</Td>
                                                        <Td>{unit ? 
                                                                <Box>
                                                                    <Text>{property?.name}</Text>
                                                                    <Text>{`${floorName(unit.unit.floorNo)} (${unit.unit.name})` }</Text>
                                                                </Box>
                                                                : '-'
                                                            }
                                                        </Td>
                                                        <Td>
                                                            <HStack>
                                                               <Button loading={currentId.current === tenant.id && loading} onClick={() => handleEntry(tenant, 'in')} variant={'ghost'} colorScheme={'green'} leftIcon={<GiEntryDoor fontSize={20}/>}>In</Button>
                                                               <Button onClick={() => handleEntry(tenant, 'out')} variant={'ghost'} colorScheme={'red'} leftIcon={<GiExitDoor fontSize={20}/>}>Out</Button>
                                                            </HStack>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        }
                                    </Tbody>
                                </Table>
                                <br/>
                                <HStack justifyContent={'center'}>
                                    <Pagination 
                                        total={allTenant?.total || 10}
                                        pageSize={allTenant?.limit || 10}
                                        onChange={handlePageChange}
                                        current={parseInt(currentPage)}
                                    />
                                </HStack>
                            </Box>
                            :
                            <EmptyPage />
                        }
                    </LoadingContainer>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={closeModal}>Done</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}