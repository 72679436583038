import { Box, Button, Flex, FormControl, FormLabel, HStack, Icon, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Select, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack } from '@chakra-ui/react'
import { chain, compact, find, intersectionBy, join, omit, orderBy } from 'lodash'
import moment from 'moment'
import Pagination from 'rc-pagination'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillLayout, AiOutlineInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useAppContext, useCurrentProperty } from '../../app/Context'
import { allPaymentModes, ALL_USER_ROLES, PAYMENT_METHODS, staffPermissions } from '../../Constants'
import { useCompanyData } from '../../services/api/useCompanyData'
import { usePaymentHistory } from '../../services/api/usePaymentHistory'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { displayDateFormat } from '../../utils/Helper'
import { DropdownList, Multiselect } from 'react-widgets'
import { useUserData } from '../../services/api/useUserData'
import { StatsCard } from './ChequePaymentCollection'
import { BaseURL, URIS } from '../../services/api'
import { DownloadIcon } from '@chakra-ui/icons'
import { useStaffData } from '../../services/api/useStaffData'
import { useEffect } from 'react'
import RecieptModeHistory from './RecieptModeHistory'

export const PaymentCollections = () => {
    const navigate = useNavigate()
    const { properties, hasPermission, hasAccess } = useAppContext()

    const { user } = useUserData(true)
    const { currentProperty } = useCurrentProperty()
    const { data: propertyDueCategories } = usePropertyDueCategories(currentProperty?.id, { propertyId: currentProperty?.id }, true)
    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)
    const { data: allStaff, loading: loadingStaff } = useStaffData(true)
    const [ viewType, setViewType ] = useState('normal')
    const [currentPage, changePage] = useState(1)
    const [curFilterType, setCurFilterType] = useState('payment')

    const isAllPayments = hasAccess(staffPermissions.ALL_PAYMENTS)
    const isSelfPayments = hasAccess(staffPermissions.SELF_PAYMENTS)

    const defaultValues = useMemo(() => {
        return {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            propertyIds: [currentProperty?.id],
            filterType: 'payment',
            createdById: isAllPayments ? 'all' : user?.id,
            companyId: allCompanies?.[0]?.id,
            limit: 10
        }
    }, [allCompanies, currentProperty?.id, user?.id, isAllPayments])

    const { handleSubmit, register, watch, setValue, getValues, reset } = useForm({
        values: defaultValues
    })

    const watchProperty = watch('propertyIds')
    const watchFilterType = watch('filterType')
    const watchCompanyId = watch('companyId')
    const watchStartDate = watch('startDate')
    const watchEndDate = watch('endDate')
    const watchCreatedById = watch('createdById')
    const limit = watch('limit')

    const getFiltersType = useMemo(() => {
        if (watchFilterType === 'payment')
            return ({ type: 'debit' })
        else if (watchFilterType === 'refund')
            return ({ type: 'credit' })
    }, [watchFilterType])

    const staffProperties = useMemo(() => {
        if (hasPermission(ALL_USER_ROLES.OWNER) && properties?.length)
            return properties.map(d => d.id)
        else
            return user?.staffProfile?.staffAccessDetails?.map(d => d.propertyId)
    }, [user?.staffProfile?.staffAccessDetails, hasPermission, properties])

    useEffect( () => {
        changePage(1);
    },[limit, setValue])

    const filterData = {
        page: currentPage,
        limit,
        ...getFiltersType,
        ...getValues(),
        propertyIds: watchProperty?.[0] === 'all' ? join(staffProperties, ',') : watchProperty.length ? join(watchProperty, ',') : '',
        companyId: watchCompanyId,
        startDate: watchStartDate,
        endDate: watchEndDate
    }

    const randomId = useMemo(() => {
        return currentPage + Date.now()
    }, [currentPage])

    const { data: duePaymentList, loading: loadingDuePaymentReport, mutate: mutateList } = usePaymentHistory(
        currentProperty?.id && getValues().companyId && getValues().createdById ?
            currentProperty.id + randomId 
            :
            false
        ,
        filterData.createdById === 'all' ? omit(filterData, 'createdById') : filterData
    )

    const handlePageChange = (e) => {
        changePage(e)
    }

    const resetData = () => {
        reset(defaultValues)
        mutateList()
    }

    const _submit = () => {
        if (currentPage !== 1) {
            changePage(1)
        } else
            mutateList()
        setCurFilterType(watchFilterType)

    }

    const toggleView = () => {
        setViewType( p => {
            if(p === 'normal')
                return 'reciept'
            else
                return 'normal'
        } )
    }
    const _selectProperty = (data) => {
        const all = find(data, d => d?.id === 'all')
        let finalData = []
        if (all)
            finalData = ['all']
        else
            finalData = compact(data.map(d => d?.id))

        setValue('propertyIds', finalData.length ? finalData : [currentProperty?.id])
    }

    const handleExportAllPayment = () => {
        let data = omit(filterData, ['limit', 'page'])
        data = data.createdById === 'all' ? omit(data, 'createdById') : data
        data = { ...data, excel: true, pagination: 0 }
        let params = chain(data).map((fl, key) => `${key}=${fl}`).join('&').value()
        let url = BaseURL + URIS.GET_PAYMENT_HISTORY + '?' + params;
        window.open(url);
    }

    const staffPropertiesList = useMemo(() => {
        if (properties?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length) {
                return user.staffProfile.staffAccessDetails.map(d => d.property)
            } else {
                return properties
            }
        }
    }, [properties, user])

    const staffCompanies = useMemo(() => {
        if (allCompanies?.length) {
            if (user?.staffProfile?.staffAccessDetails?.length)
                return intersectionBy(allCompanies, user.staffProfile.staffAccessDetails.map(d => ({ id: d.property.companyId })), 'id')
            else
                return allCompanies
        }
    }, [allCompanies, user])

    return (
        <Box py={4}>
            <Text color={'secondary'} fontWeight='bold' fontSize='default'>Payment History</Text>
            <br />
            <LoadingContainer loading={loadingCompanies || loadingStaff}>
                <Flex wrap={'wrap'} gap={4}>
                    <StatsCard label={'Total Payment'} count={(duePaymentList?.totalAmount || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'Cash'} count={(duePaymentList?.sumTotalByPaymentMode_offline || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'Cheque'} count={(duePaymentList?.sumTotalByPaymentMode_cheque || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'NEFT'} count={(duePaymentList?.sumTotalByPaymentMode_neft || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'DD'} count={(duePaymentList?.sumTotalByPaymentMode_dd || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'RTGS'} count={(duePaymentList?.sumTotalByPaymentMode_rtgs || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'Online'} count={(duePaymentList?.sumTotalByPaymentMode_online || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                    <StatsCard label={'Other'} count={(duePaymentList?.sumTotalByPaymentMode_other || 0)}
                        string={<Text color={'gray.500'} fontSize={28}>₹</Text>}
                    />
                </Flex>
                <br />
                <form onSubmit={handleSubmit(_submit)}>
                    <Flex align={'end'} gap={4} mb={2}>
                        <FormControl>
                            <FormLabel>Company</FormLabel>
                            <Select size='sm' {...register('companyId')}>
                                {staffCompanies?.length ?
                                    staffCompanies.map(company => {
                                        return <option value={company.id} key={company.id}>{company.name}</option>
                                    })
                                    :
                                    null
                                }
                            </Select>
                        </FormControl>
                        {isAllPayments &&
                            <FormControl>
                                <FormLabel>Accountant</FormLabel>
                                <DropdownList
                                    onChange={e => setValue('createdById', e.id)}
                                    placeholder={'All'}
                                    data={allStaff?.length ? [{ name: 'All Accountants', id: 'all' }, ...allStaff.map(d => d.staff)] : []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watchCreatedById}
                                />
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input type={'date'} {...register('startDate')} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>End Date</FormLabel>
                            <Input type={'date'} {...register('endDate')} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Type</FormLabel>
                            <Select {...register('filterType')}>
                                <option value={'payment'}>Payment</option>
                                <option value={'refund'}>Refund</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Limit</FormLabel>
                            <Select {...register('limit')}>
                                <option value={'10'}>10</option>
                                <option value={'20'}>20</option>
                                <option value={'30'}>30</option>
                                <option value={'40'}>40</option>
                                <option value={'50'}>50</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    <Flex justifyContent='space-between' align='end' mb={4}>
                    <Flex gap={4} align='end'>
                        <Box>
                            <FormControl>
                                <FormLabel>Select Property</FormLabel>
                                <Multiselect
                                    onChange={e => _selectProperty(e)}
                                    placeholder={'select Property'}
                                    data={[{ name: 'All', id: 'all' }, ...staffPropertiesList] || []}
                                    textField='name'
                                    style={{ fontSize: 14, minWidth: 200 }}
                                    dataKey={'id'}
                                    value={watchProperty}
                                />
                            </FormControl>
                        </Box>
                        <HStack>
                            <Button type='submit' size={'sm'}>Apply</Button>
                            {/* <Button variant={'outline'} onClick={resetData} size={'sm'}>Reset</Button> */}
                            {
                                duePaymentList?.results?.length ?
                                    <Box>
                                        <Button onClick={handleExportAllPayment} variant='ghost' w='full' leftIcon={<DownloadIcon />}>Export Excel</Button>
                                    </Box>
                                    : null
                            }
                        </HStack>
                    </Flex>
                    <Button onClick={toggleView} leftIcon={<AiFillLayout />}> Change View</Button>
                        
                    </Flex>
                </form>
                <LoadingContainer loading={loadingDuePaymentReport}>
                    {duePaymentList?.results?.length ?
                        <Box>
                            <PaginationBox duePaymentList={duePaymentList} handlePageChange={handlePageChange} currentPage={currentPage} />
                            
                            {
                                viewType === 'normal' ?
                                <Box mt={2}>
                                    <Table className='responsiveTable' variant={'simple'}>
                                        <Thead>
                                            <Tr>
                                                <Th>Sr No.</Th>
                                                <Th>Tenant</Th>
                                                <Th>Invoice</Th>
                                                <Th>Installments</Th>
                                                <Th>Total Payment</Th>
                                                <Th>Property/Hostel</Th>
                                                <Th>Accountant</Th>
                                                <Th>Date</Th>
                                                <Th>Mode</Th>
                                                <Th>Status</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                duePaymentList.results.map((payment, i) => {
                                                    const paymentCategories = payment.paymentReceipts
                                                    const tenant = payment.tenant
                                                    const hostel = find(properties, p => p.id === payment.propertyId)
                                                    const createdBy = find(allStaff, st => st.staff.id === payment.createdById)
                                                    const isCheque = payment.payment_mode === 'cheque'
                                                    const chequeHistory = isCheque && orderBy(payment.paymentDetails_json?.chequeHistory, 'date', ['asc']);
                                                    const isBounced = isCheque && chequeHistory.at(-1)?.status === 'bounce';
                                                    const isCleared = isCheque && chequeHistory.at(-1)?.status === 'success';
                                                    const isCancelled = payment.status === 'cancelled';
                                                    const css = isCancelled ? { as: 'del' } : {}
                                                    return (
                                                        <Tr bg={isCancelled && 'red.50'} key={payment.id}>
                                                            <Td>
                                                                <Text {...css}>{((currentPage - 1) * limit) + (i + 1)}</Text>
                                                            </Td>
                                                            <Td>
                                                                <Tooltip>
                                                                    <Button {...css} onClick={() => navigate(`/tenant/profile/${tenant?.id}/6`)} variant={'link'}>
                                                                        <Text whiteSpace='normal' maxW={120} noOfLines={2}>{tenant?.name}</Text>
                                                                    </Button>
                                                                </Tooltip>
                                                            </Td>
                                                            <Td>
                                                                <Text {...css}>{payment.paymentDetails_json.slipNo}</Text>
                                                            </Td>
                                                            <Td>
                                                                {(curFilterType === 'paymentCancel' || curFilterType === 'payment') && paymentCategories?.length ?
                                                                    <Table mb={4} variant={'simple'}>
                                                                        <Tbody>
                                                                            {paymentCategories.map(category => {
                                                                                return (
                                                                                    <Tr key={category.id}>
                                                                                        <Td>
                                                                                            <Text {...css}>
                                                                                                {category.paymentDetails_json.name || category.tenantDues?.propertyDueCategory?.dueCategory?.name}
                                                                                            </Text>
                                                                                        </Td>
                                                                                        <Td width={100}>
                                                                                            <Text {...css}>₹{category.amount}</Text>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )
                                                                            }
                                                                            )}
                                                                        </Tbody>
                                                                    </Table>
                                                                    :
                                                                    null
                                                                }

                                                            </Td>
                                                            <Td><Text {...css}>₹ {isCancelled ? 0 : payment.amount}</Text></Td>
                                                            <Td><Text {...css}>{hostel?.name}</Text></Td>
                                                            <Td><Text {...css}>{createdBy?.staff?.name}</Text></Td>
                                                            <Td><Text {...css}>{moment(payment.paymentDate).format('LL hh:mm A')}</Text></Td>
                                                            <Td>
                                                                <HStack>
                                                                    <Text {...css}>{allPaymentModes[payment.payment_mode]?.name}</Text>
                                                                    <Box>
                                                                        {payment.payment_mode === PAYMENT_METHODS.CHEQUE || payment.payment_mode === PAYMENT_METHODS.DD ?
                                                                            <Popover trigger='hover'>
                                                                                <PopoverTrigger>
                                                                                    <span><Icon fontSize={16} as={AiOutlineInfoCircle} color='blue' /></span>
                                                                                </PopoverTrigger>
                                                                                <PopoverContent>
                                                                                    <PopoverArrow />
                                                                                    <PopoverBody>
                                                                                        <VStack align={'start'}>
                                                                                            {payment.payment_mode === PAYMENT_METHODS.CHEQUE &&
                                                                                                <HStack>
                                                                                                    <Text>Cheque No:</Text>
                                                                                                    <Text>{payment.paymentDetails_json?.cheque_no || '-'}</Text>
                                                                                                </HStack>
                                                                                            }
                                                                                            <HStack>
                                                                                                <Text>Bank:</Text>
                                                                                                <Text>{payment.paymentDetails_json?.bank_name || '-'}</Text>
                                                                                            </HStack>
                                                                                            <HStack>
                                                                                                <Text>Bank Date:</Text>
                                                                                                <Text>{displayDateFormat(payment.paymentDetails_json?.bank_date, 'll') || '-'}</Text>
                                                                                            </HStack>
                                                                                            <HStack>
                                                                                                <Text>Payment Details:</Text>
                                                                                                <Text>{payment.paymentDetails_json?.payment_details || '-'}</Text>
                                                                                            </HStack>
                                                                                        </VStack>
                                                                                    </PopoverBody>
                                                                                </PopoverContent>
                                                                            </Popover>
                                                                            :
                                                                            payment.paymentDetails_json?.payment_details ?
                                                                                <Popover trigger='hover'>
                                                                                    <PopoverTrigger>
                                                                                        <span><Icon fontSize={16} as={AiOutlineInfoCircle} color='blue' /></span>
                                                                                    </PopoverTrigger>
                                                                                    <PopoverContent>
                                                                                        <PopoverArrow />
                                                                                        <PopoverBody>
                                                                                            <VStack align={'start'}>
                                                                                                <HStack>
                                                                                                    <Text>Payment Details:</Text>
                                                                                                    <Text>{payment.paymentDetails_json.payment_details || '-'}</Text>
                                                                                                </HStack>
                                                                                            </VStack>
                                                                                        </PopoverBody>
                                                                                    </PopoverContent>
                                                                                </Popover>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Box>
                                                                </HStack>
                                                            </Td>
                                                            <Td>
                                                                {isCheque ?
                                                                    <HStack>
                                                                        <Text color={isCancelled ? 'red.500' : isBounced ? 'orange.500' : isCleared ? 'green.500' : 'blue.500'} >
                                                                            {isCancelled ? 'Cancelled' : isBounced ? 'Bounced' : isCleared ? 'Cleared' : 'Pending'}
                                                                        </Text>
                                                                        <Box>
                                                                            {isCancelled ? null :
                                                                                payment.payment_mode === PAYMENT_METHODS.CHEQUE || payment.payment_mode === PAYMENT_METHODS.DD ?
                                                                                    <Popover trigger='hover'>
                                                                                        <PopoverTrigger>
                                                                                            <Box color='gray.400' as='span'><Icon fontSize={16} as={AiOutlineInfoCircle} /></Box>
                                                                                        </PopoverTrigger>
                                                                                        <PopoverContent>
                                                                                            <PopoverArrow />
                                                                                            <PopoverBody>
                                                                                                <VStack align={'start'}>
                                                                                                    {payment.payment_mode === PAYMENT_METHODS.CHEQUE &&
                                                                                                        <HStack>
                                                                                                            <Text>Cheque No:</Text>
                                                                                                            <Text>{payment.paymentDetails_json?.cheque_no || '-'}</Text>
                                                                                                        </HStack>
                                                                                                    }
                                                                                                    <HStack>
                                                                                                        <Text>Bank:</Text>
                                                                                                        <Text>{payment.paymentDetails_json?.bank_name || '-'}</Text>
                                                                                                    </HStack>
                                                                                                    <HStack>
                                                                                                        <Text>Bank Date:</Text>
                                                                                                        <Text>{displayDateFormat(payment.paymentDetails_json?.bank_date, 'll') || '-'}</Text>
                                                                                                    </HStack>
                                                                                                    <HStack>
                                                                                                        <Text>Payment Details:</Text>
                                                                                                        <Text>{payment.paymentDetails_json?.payment_details || '-'}</Text>
                                                                                                    </HStack>
                                                                                                </VStack>
                                                                                            </PopoverBody>
                                                                                        </PopoverContent>
                                                                                    </Popover>
                                                                                    :
                                                                                    payment.paymentDetails_json?.payment_details ?
                                                                                        <Popover trigger='hover'>
                                                                                            <PopoverTrigger>
                                                                                                <span><Icon fontSize={16} as={AiOutlineInfoCircle} color='blue' /></span>
                                                                                            </PopoverTrigger>
                                                                                            <PopoverContent>
                                                                                                <PopoverArrow />
                                                                                                <PopoverBody>
                                                                                                    <VStack align={'start'}>
                                                                                                        <HStack>
                                                                                                            <Text>Payment Details:</Text>
                                                                                                            <Text>{payment.paymentDetails_json.payment_details || '-'}</Text>
                                                                                                        </HStack>
                                                                                                    </VStack>
                                                                                                </PopoverBody>
                                                                                            </PopoverContent>
                                                                                        </Popover>
                                                                                        :
                                                                                        null
                                                                            }
                                                                        </Box>
                                                                    </HStack>
                                                                    :
                                                                    <Text color={payment.status === 'cancelled' ? 'red.500' : 'green.500'}>
                                                                        {payment.status}
                                                                    </Text>
                                                                }
                                                            </Td>
                                                        </Tr>
                                                    )
                                                }
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    <br />
                                </Box>
                            : 
                                <Box my={2}>
                                    <RecieptModeHistory payments={duePaymentList.results}/>
                                </Box>
                            }
                            
                            <PaginationBox duePaymentList={duePaymentList} handlePageChange={handlePageChange} currentPage={currentPage} />
                        </Box>
                        :
                        <Text color={'secondary'} >No history available</Text>
                    }
                </LoadingContainer>
            </LoadingContainer>
        </Box >
    )
}

const PaginationBox = ({ duePaymentList, handlePageChange, currentPage }) => {
    return (
        <HStack justifyContent={'center'}>
            <Pagination
                total={duePaymentList?.total || 10}
                pageSize={duePaymentList?.limit || 10}
                onChange={handlePageChange}
                current={parseInt(currentPage)}
            />
        </HStack>
    )
}