import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useGuardianData(key = false, params) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_ALL_GUARDIAN+params.tenantId, () => apis.getAllGuardiansApi(params), key)

    return {
        data, error, loading, mutate
    }
}