import React, { useState } from 'react'
import { Box, Button, Checkbox, CheckboxGroup, FormControl, FormLabel, Select, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import _ from 'lodash';
import { useEffect } from 'react';
import { URIS } from '../../services/api';
import { useApiRequest } from '../../services/api/useApiRequest';
import { useGetProperties } from '../../services/api/useGetProperties';
import { AiOutlineDelete } from 'react-icons/ai';
import { useMemo } from 'react';
import { useStaffData } from '../../services/api/useStaffData';
import { useForm } from 'react-hook-form';
import { useUserData } from '../../services/api/useUserData';
import { useMessPropertyData } from '../../services/api/useMessData1';

export const AssignPropertyModal = ({ isOpen, onClose, messData, allProperties, allMessPropertyData, resetProperty }) => {

    const { organisation } = useUserData(true)

    let options = useMemo(() => {
        let result = []
        allProperties?.forEach(property => {
            let findProperty = allMessPropertyData?.find(messProperty => messProperty?.propertyId === property?.id)
            if (!findProperty) { result.push(property) }
        })
        return result
    }, [allMessPropertyData, allProperties])

    //react-hook-form
    const { handleSubmit, register, reset } = useForm()

    const { request: createMessMutate, } = useApiRequest(URIS.MESS_PROPERTY, {
        onCompleted: d => {
            resetProperty()
        },
        showAlert: true,
        showLoader: true,
    })

    const _submit = (data) => {
        createMessMutate({
            method: 'POST', data: {
                organisationId: organisation.id,
                messId: messData.id,
                propertyId: data?.propertyId,
            }
        })
        modalCloseHandler()
    }

    const modalCloseHandler = () => {
        reset()
        onClose()
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={modalCloseHandler}>
                <ModalOverlay />
                <form onSubmit={handleSubmit(_submit)}>
                    <ModalContent>
                        <ModalHeader>Assign New Property</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl my={5} isRequired>
                                <FormLabel>Select Property</FormLabel>
                                <Select {...register("propertyId")} >
                                    <option value={""}>-select-</option>
                                    {options?.map(property => {
                                        return <option key={property?.id} value={property?.id}>{property?.name}</option>
                                    })}
                                </Select>
                                {options?.length === 0 ? <Text mt={1} color={"red.700"} size='sm'>All Properties has assigned to other Messes</Text> : null}
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalCloseHandler} colorScheme='gray' mr={3}>Close</Button>
                            <Button type='submit'>Assign</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </>
    )
}

