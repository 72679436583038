import React, { useMemo, useEffect, useReducer, useRef, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { find, last } from 'lodash'
import moment from 'moment'
import { FiUserCheck, FiUserX } from "react-icons/fi";
import { ImExit } from "react-icons/im";
import Pagination from 'rc-pagination'
import { BsChatLeftText } from "react-icons/bs";
import { AiOutlineReload } from 'react-icons/ai'
import { DatePicker } from 'react-widgets/cjs'
import _ from 'lodash'
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Textarea, Th, Thead, Tr } from '@chakra-ui/react'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useTenantsData } from '../../services/api/useGuardianData copy'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { EmptyPage } from '../../ui/EmptyPage'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { floorName } from '../../utils/Helper'
import { Truncate } from '../../ui/Truncate'
import { FormReducer } from '../../utils/FormReducer'
import { useCurrentProperty } from '../../app/Context'
import { useAttendanceSlots } from '../../services/api/useAttendanceSlots'
import { useAttendanceRecords } from '../../services/api/useAttendanceRecords'
import { useUserData } from '../../services/api/useUserData'

export const MarkAttendance = () => {
    const params = useParams()
    const currentPage = params.page
    const navigate = useNavigate()
    const cancelRef = useRef()
    const {user} = useUserData(true)

    const [currentAttendance, setAttendance] = useState()
    const [remarkModal, openRemarkModal] = useState()
    const [filters, changeFilters] = useReducer(FormReducer, {})
    const [filterIndex, changeFilterIndex] = useState(0)
    const [selectedDate, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [attendanceAlert, openAttendanceAlert] = useState()

    const { currentProperty: selectedProperty } = useCurrentProperty()
    const { data: allTenants, loading } = useTenantsData(true + filterIndex, { page: currentPage, ...filters }, true)
    const { data: attendanceSlots, loading: loadingGetAttendance, mutate: mutateAttendanceSlots } = useAttendanceSlots(
        selectedDate, { startDate: selectedDate, limit: 100 }, true
    )
    const { data: attendanceRecords, mutate: mutateAttendanceRecords } = useAttendanceRecords(currentAttendance?.id, { attendanceId: currentAttendance?.id }, true)

    const { request: createAttendanceAction } = useApiRequest(URIS.CREATE_ATTENDANCE, {
        onCompleted: d => {
            mutateAttendanceSlots()
            setAttendance(last(d || []))
        },
        showAlert: true,
        showLoader: true
    })

    useEffect(() => {

    }, [])

    const { request: markAttendanceAction } = useApiRequest(URIS.CREATE_ATTENDANCE_RECORD, {
        onCompleted: d => {
            mutateAttendanceRecords()
            openRemarkModal(false)
        },
        showAlert: true,
        showLoader: true
    })

    useEffect(() => {
        if (attendanceSlots?.results?.length && selectedProperty) {
            setAttendance(last(attendanceSlots.results))
        } else {
            setAttendance(null)
        }
    }, [attendanceSlots, selectedProperty])

    const handleMark = (recordObj, tenantData, mark) => {
        const status = recordObj?.status

        if (status === mark)
            markAttendanceAction({ method: 'PATCH', data: { id: recordObj.id, status: null } })
        else if (recordObj)
            markAttendanceAction({ method: 'PATCH', data: { id: recordObj.id, status: parseInt(mark) } })
        else
            markAttendanceAction({ method: 'POST', data: { createdById: user.id, tenantId: tenantData.tenant.id, attendanceId: currentAttendance.id, status: parseInt(mark) } })
    }

    const handleRemark = (recordObj, tenantData) => {
        const data = { tenantData, recordObj }
        openRemarkModal(d => d ? null : data)
    }

    const handleCreateAttendance = () => {
        openAttendanceAlert(d => !d)
    }

    const createAttendance = () => {
        const data = { propertyId: selectedProperty.id, title: moment().format('LLLL'), date: moment().format('YYYY-MM-DD H:mm:ss') }
        createAttendanceAction({ method: 'POST', data })
        openAttendanceAlert()
    }

    const handleSelectSlot = (id) => {
        const slot = find(attendanceSlots.results, s => s.id === id)
        setAttendance(slot)
    }

    const handleAddRemark = (remark, type) => {
        const tenant = remarkModal.tenantData
        const record = remarkModal.recordObj
        if (type === 'update')
            markAttendanceAction({ method: 'PATCH', data: { id: record.id, remark } })
        else
            markAttendanceAction({ method: 'POST', data: { tenantId: tenant.tenant.id, attendanceId: currentAttendance.id, remark } })
    }

    const handlePageChange = (page) => {
        navigate('/mark-attendance/' + page)
    }

    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate(`/mark-attendance/${1}`)
    }

    const handleChangeFilter = (type, value) => {
        changeFilters({ type, value })
    }

    const handleReset = () => {
        changeFilterIndex(0)
        changeFilters({ type: 'reset', value: {} })
    }

    const markTypes = [
        { id: 1, title: 'Present', color: 'green', icon: <FiUserCheck />, value: 1 },
        { id: 2, title: 'Absent', color: 'red', icon: <FiUserX />, value: 0 },
        { id: 3, title: 'Leave', color: 'blue', icon: <ImExit />, value: 2 },
    ]

    const handleChangeDate = (date) => {
        const d = date ? moment(date).format('YYYY-MM-DD') : null
        setAttendance(null)
        setDate(d)
    }

    const canCreateSlot = useMemo(() => moment(moment().format('YYYY-MM-DD')).isSame(selectedDate), [selectedDate]);

    return (
        <Box>
            <ActionHeader title={'Mark Attendance'} switchProperty={true} />
            <br />
            <CustomContainer loading={''}>
                <Box>
                    {selectedProperty ?
                        <Box border={'1px solid #E2E8F0'} p={4}>
                            <Flex justifyContent={"space-between"} mb={6}>
                                <Text fontSize={'large'} color={'defaultColor.500'} fontWeight='bold'>{selectedProperty.name}</Text>
                                {selectedProperty ?
                                    <Flex alignItems='center' wrap={'wrap'}>
                                        <>
                                            <Flex align={'center'} wrap='wrap' mr={2}>
                                                <Box pr={2}>
                                                    <Text>Select Date : </Text>
                                                </Box>
                                                <Box pr={2}>
                                                    <DatePicker
                                                        style={{ fontSize: 14 }}
                                                        value={selectedDate ? new Date(selectedDate) : null}
                                                        placeholder='Select Date' onChange={handleChangeDate}
                                                    />
                                                </Box>
                                                {canCreateSlot ?
                                                    <Box mr={2}>
                                                        <Button variant='outline' leftIcon={<AddIcon />} colorScheme={'gray'} onClick={handleCreateAttendance}>Create New Attendance Slot</Button>
                                                    </Box>
                                                    :
                                                    null
                                                }
                                            </Flex>
                                        </>
                                    </Flex>
                                    :
                                    null
                                }
                            </Flex>
                            <>
                                <Flex align={'center'} wrap='wrap' mr={2} mb={2}>
                                    {attendanceSlots?.results.length ?
                                        _.orderBy(attendanceSlots.results, ['createdAt'], ['asc']).map(slot =>
                                            <Box pr={2} pb={2}>
                                                <Button
                                                    colorScheme={currentAttendance?.id === slot.id ? 'defaultColor' : 'gray'}
                                                    onClick={() => handleSelectSlot(slot.id)}
                                                    value={slot.id} key={slot.id}
                                                >
                                                    {slot.title}
                                                </Button>
                                            </Box>
                                        )
                                        :
                                        null
                                    }
                                </Flex>
                            </>
                            <form key={filterIndex} onSubmit={handleFilter}>
                                <Flex py={3} wrap={'wrap'} mb={2}>
                                    <Box pr={4} pb={2}>
                                        <FormControl>
                                            <Input onChange={e => handleChangeFilter('name', e.target.value)} value={filters?.name} placeholder="name" />
                                        </FormControl>
                                    </Box>
                                    <Box pr={4} pb={2}>
                                        <FormControl>
                                            <Input onChange={e => handleChangeFilter('contact', e.target.value)} value={filters?.contact} placeholder="contact" type='number' />
                                        </FormControl>
                                    </Box>
                                    <Box pr={4} pb={2}>
                                        <HStack>
                                            <Button type="submit" variant={'outline'}>Apply</Button>
                                            <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                                        </HStack>
                                    </Box>
                                </Flex>
                            </form>

                            <LoadingContainer loading={loadingGetAttendance}>
                                {currentAttendance ?
                                    allTenants?.results?.length ?
                                        <Box>
                                            <br />
                                            <LoadingContainer loading={loading}>
                                                <Box minWidth={200} overflow='auto'>
                                                    <Table className='responsiveTable' variant={'simple'}>
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Sr No.</Th>
                                                                <Th>Name</Th>
                                                                <Th>Contact</Th>
                                                                <Th>Floor</Th>
                                                                <Th>Room</Th>
                                                                <Th>Remark</Th>
                                                                <Th>Action</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {allTenants.results.map((tenant, i) => {
                                                                const unitTenant = tenant.unitTenants[0]
                                                                const attendanceObj = find(attendanceRecords, r => r.tenantId === tenant.tenantId)
                                                                const attendanceStatus = attendanceObj?.status

                                                                return (
                                                                    <Tr key={tenant.id}>
                                                                        <Td>{(20 * (currentPage - 1)) + i + 1}</Td>
                                                                        <Td>{tenant.tenant.name}</Td>
                                                                        <Td>{tenant.tenant.contact}</Td>
                                                                        <Td>{floorName(unitTenant.unit.floorNo)}</Td>
                                                                        <Td>{unitTenant.unit.name}</Td>
                                                                        <Td>
                                                                            <Truncate length={10}>
                                                                                {attendanceObj?.remark}
                                                                            </Truncate>
                                                                        </Td>
                                                                        <Td>
                                                                            <Flex wrap={'wrap'} flexDir={{ base: 'column', md: 'row' }}>
                                                                                {markTypes.map(type =>
                                                                                    <Box key={type.id} pr={4} pb={4}>
                                                                                        <Button
                                                                                            leftIcon={type.icon}
                                                                                            colorScheme={type.color}
                                                                                            onClick={() => handleMark(attendanceObj, tenant, type.value)}
                                                                                            variant={attendanceStatus === type.value ? 'solid' : 'outline'}
                                                                                        >
                                                                                            {type.title}
                                                                                        </Button>
                                                                                    </Box>
                                                                                )}
                                                                                <Box pr={2} pb={4}>
                                                                                    <Button
                                                                                        leftIcon={<BsChatLeftText />}
                                                                                        onClick={() => handleRemark(attendanceObj, tenant)}
                                                                                        colorScheme={'gray'}
                                                                                        variant='outline'
                                                                                    >
                                                                                        Remark
                                                                                    </Button>
                                                                                </Box>
                                                                            </Flex>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            }
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                </Box>
                                            </LoadingContainer>
                                            <br />
                                            <HStack justify={'center'}>
                                                <Pagination current={parseInt(allTenants.page)} total={allTenants.total} pageSize={allTenants.limit} onChange={handlePageChange} />
                                            </HStack>
                                        </Box>
                                        :
                                        <EmptyPage message='no tenants available' />
                                    :
                                    attendanceSlots?.results?.length === 0 && !canCreateSlot ?
                                        <EmptyPage message='no attendance slot available' />
                                        :
                                        <Text color={'secondary'}>Select {canCreateSlot ? 'or create a new ' : ''} attendance slot</Text>
                                }
                            </LoadingContainer>
                        </Box>
                        :
                        null
                    }
                </Box>
            </CustomContainer>
            <AlertDialog
                isOpen={attendanceAlert}
                leastDestructiveRef={cancelRef}
                onClose={handleCreateAttendance}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Are you sure?
                        </AlertDialogHeader>

                        <AlertDialogFooter>
                            <HStack>
                                <Button colorScheme={'gray'} ref={cancelRef} onClick={handleCreateAttendance}>
                                    Cancel
                                </Button>
                                <Button onClick={createAttendance}>
                                    Confirm
                                </Button>
                            </HStack>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {remarkModal && <RemarkModal defaultData={remarkModal} onSubmit={(remark, type) => handleAddRemark(remark, type)} visible={remarkModal} closeModal={handleRemark} />}
        </Box>
    )
}

const RemarkModal = ({ visible, closeModal, onSubmit, defaultData }) => {

    const [remark, changeRemark] = useState()

    useEffect(() => {
        if (defaultData.recordObj) {
            changeRemark(defaultData.recordObj.remark)
        }
    }, [defaultData])

    const handleSave = () => {
        onSubmit(remark, defaultData.recordObj ? 'update' : 'add')
    }

    const handleChange = (e) => {
        changeRemark(e.target.value)
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{defaultData.tenantData.tenant.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Remark</FormLabel>
                        <Textarea rows={6} value={remark} placeholder={'write your remark for ' + defaultData.tenantData.tenant.name + ' here'} onChange={handleChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={handleSave} >Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}