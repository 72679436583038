import React from 'react'
import { Box, HStack, Table, Tbody, Td, Th, Thead, Tr, Spinner } from '@chakra-ui/react'
import { map } from 'lodash';


export default function DuePaymentStudentWise({ dueStudentwiseList, loadingDueStudentwise }) {
    const columns = Object.keys(dueStudentwiseList?.[0])
    return (
        <Box>
            <Table className='responsiveTable'>
                <Thead>
                    <Tr>
                        <Th>Sr No.</Th>
                        {map(columns, c => (
                            <Th>{c}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {loadingDueStudentwise ?
                        <Tr>
                            <Td colSpan={6} >
                                <HStack minH={200} align={'center'} justify='center'>
                                    <Spinner fontSize={30} />
                                </HStack>
                            </Td>
                        </Tr>
                        :
                        dueStudentwiseList.map((due, i) => {
                            return (
                                <Tr key={due["Registration Number"]}>
                                    <Td>{i + 1}</Td>
                                    {map(columns, c => (
                                        <Td>{due[c]}</Td>
                                    ))}
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )
}