import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, HStack, Input, InputGroup, InputLeftElement, Select, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import _, { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { installment_types, SHARINGS } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useCompanyData } from '../../services/api/useCompanyData'
import { useDueCategories } from '../../services/api/useDueCategories'
import { useGetProperties } from '../../services/api/useGetProperties'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { usePropertyDueStatus } from '../../services/api/usePropertyDueStatus'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { useUnitsData } from '../../services/api/useUnitsData'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { EmptyPage } from '../../ui/EmptyPage'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { MobileViewPaymenCategory } from './MobileViewPaymenCategory'

export const PropertyRent = ({ keyPrefix, currentProperty }) => {
    const params = useParams()
    const propertyId = useMemo(() => params.id && params.id !== ':id' && params.id, [params.id])
    const { data, loading } = useDueCategories(propertyId)
    const { data: unitRoomTypes, loading: loadingUnitTypes } = useUnitRoomTypes(keyPrefix + propertyId, { propertyId: propertyId }, true)
    const { data: unitsData, loading: loadingGetUnits } = useUnitsData(keyPrefix + propertyId, { propertyId: params?.id }, true)
    const { data: propertyDueCategories, mutate: mutatePropertyDuecategories } = usePropertyDueCategories(propertyId, { propertyId: propertyId }, true)
    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)
    const { mutate: mutateProperties } = useGetProperties(true)

    const { data: propertyDueStatuses, loading: loadingPropertyDueSatus, mutate: mutetaDueStatus } = usePropertyDueStatus(propertyId, { propertyId: propertyId })
    // const [defaultRoomTypes, setRoomTypes] = useState({})

    const [dueCategories, setDueCategories] = useState({})
    const [selectedCompanyId, setCompany] = useState()
    const [billingCycleStartDay, setBillingCycleStartDay] = useState()
    const [sendNewSlipOnWhatsapp, setSendNewSlipOnWhatsapp] = useState(0)
    const [sendPaymentReceiptOnWhatsapp, setSendPaymentReceiptOnWhatsapp] = useState(0)

    useEffect(() => {
        if (currentProperty) {
            setCompany(currentProperty.companyId)
            if (currentProperty.meta_data_json?.billingCycleStartDay) setBillingCycleStartDay(currentProperty.meta_data_json.billingCycleStartDay)
            if (currentProperty.meta_data_json?.sendNewSlipOnWhatsapp) setSendNewSlipOnWhatsapp(currentProperty.meta_data_json.sendNewSlipOnWhatsapp)
            if (currentProperty.meta_data_json?.sendPaymentReceiptOnWhatsapp) setSendPaymentReceiptOnWhatsapp(currentProperty.meta_data_json.sendPaymentReceiptOnWhatsapp)
        }
    }, [currentProperty])

    const { request: addDueCategory } = useApiRequest(URIS.ADD_PROPERTY_DUE_CATEGORY, {
        onCompleted: d => {
            mutatePropertyDuecategories()
        },
        showAlert: true,
        showLoader: true
    })

    const { request: addSingleDueCategory } = useApiRequest(URIS.ADD_SINGLE_PROPERTY_DUE_CATEGORY, {
        onCompleted: d => {
            mutatePropertyDuecategories()
        },
        showAlert: true,
        showLoader: true
    })

    const { request: addDueCategoryStatus } = useApiRequest(URIS.ADD_PROPERTY_DUE_STATUS, {
        onCompleted: d => {
            mutatePropertyDuecategories()
        },
        showLoader: true,
        showAlert: true
    })

    const { request: updatePropertyRequest, loading: loadingUpdateProperty } = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted: () => {
            mutateProperties()
        },
        onError: () => { },
        showAlert: true
    })

    const allCategories = useMemo(() => {
        if (data?.length) {
            return data
        } else return []
    }, [data])

    // useEffect(() => {
    //     if(unitsData?.length){
    //         let obj = {}
    //         let data = _.groupBy(unitsData,d => d.unitRoomTypeId)
    //         _.forEach(data,(arr, id) =>  obj = {...obj, [id]:{amount:'', sharing_types:_.uniqBy(arr,'sharing').map(a => ({sharing_type:a.sharing, amount:''}))}} )
    // setRoomTypes(obj)

    //         let categoryObj = {}
    //         allCategories.map(d => categoryObj = {...categoryObj, [d.id]:{amount:'', unitRoomTypes:obj}})
    //         setDueCategories(categoryObj)
    //     }
    // }, [unitsData, allCategories])

    useEffect(() => {
        if (unitsData?.length) {

            let obj = {}
            let initialData = _.groupBy(unitsData, d => d.unitRoomTypeId)
            _.forEach(initialData, (arr, id) => obj = { ...obj, [id]: { amount: '', sharing_types: _.uniqBy(arr, 'sharing').map(a => ({ sharing_type: a.sharing, amount: '' })) } })
            let categoryObj = {}
            allCategories.map(d => categoryObj = { ...categoryObj, [d.id]: { amount: '', unitRoomTypes: obj } })

            if (propertyDueCategories?.length) {
                let categories = _.groupBy(propertyDueCategories, 'dueCategoryId') || {}
                let data = {}

                categories = _.chain(categoryObj).reduce((catObj, roomTypes, catId) => {
                    const propertyDueCategories = categories[catId]
                    return {
                        ...catObj, [catId]: {
                            ...roomTypes,
                            isEnabled: _.findIndex(propertyDueCategories, c => c.isEnabled) !== -1,
                            amount: propertyDueCategories ? _.map(propertyDueCategories, d => d.amount).every((val, i, arr) => val === arr[0]) ? propertyDueCategories[0].amount : '' : '',
                            unitRoomTypes: _.reduce(roomTypes.unitRoomTypes, (typeObj, typeVal, typeId) => {
                                const sharing_types = typeVal.sharing_types.map(sharing =>
                                    _.find(propertyDueCategories, s => s.unitRoomTypeId === typeId && sharing.sharing_type === s.sharing_type) || sharing
                                )
                                return {
                                    ...typeObj, [typeId]: {
                                        ...typeVal,
                                        amount: _.map(sharing_types, d => d.amount).every((val, i, arr) => val === arr[0]) ? sharing_types[0].amount : '',
                                        sharing_types
                                    }
                                }
                            }
                                , {})
                        }
                    }
                }
                    , {}).value()

                // categories = _.reduce(categories,(catObj, catValue, catId) => 
                //         ({...categoryObj, amount:0})
                //     , {})

                // categories = _.reduce(categories, (obj, roomTypes, catId) => ({...obj, [catId]:{
                //                 isEnabled:roomTypes[0].isEnabled,
                //                 amount:_.map(roomTypes, d => d.amount).every((val, i, arr) => val === arr[0]) ? roomTypes[Object.keys(roomTypes)[0]].amount : '', 
                //                 // propertyDueStatus:roomTypes[0].propertyDueStatus,
                //                 unitRoomTypes:_.reduce(roomTypes, (roomTypesObj, type) => {
                //                     const sharing_types = unionBy(_.filter(roomTypes,t => t.unitRoomTypeId === type.unitRoomTypeId), categoryObj[catId].unitRoomTypes[type.unitRoomTypeId]?.sharing_types, 'sharing_type')
                //                     return ({
                //                         ...roomTypesObj, 
                //                         [type.unitRoomTypeId]:{
                //                             amount:sharing_types.map(t => t.amount).every((val, i, arr) => val === arr[0]) ? sharing_types[0].amount : '', sharing_types
                //                         }
                //                     }) 
                //                 } 
                //                 , {})}}
                //             ), {})

                // _.forEach(categories, (cat, catId) => {
                //     let unitTypeObj = {}
                //     _.forEach(_.groupBy(cat, 'unitRoomTypeId'), (types, typeId) => {
                //         unitTypeObj = {
                //             ...unitTypeObj,
                //             [typeId]: { amount: types.map(t => t.amount).every((val, i, arr) => val === arr[0]) ? types[0].amount : '', sharing_types: types.map(t => ({ amount: t.amount, id: t.id, sharing_type: t.sharing_type, isEnabled:t.isEnabled })) }
                //         }
                //     })

                //     data = {
                //         ...data, [catId]: {
                //             amount: '', unitRoomTypes: {...categoryObj[catId]?.unitRoomTypes, ...unitTypeObj},
                //             isEnabled: cat[0].isEnabled
                //         }
                //     }
                // })

                // _.forEach(categoryObj, (cat, catId) => {
                //     let cate = data[catId] ? {
                //         ...data[catId],
                //         amount: _.map(data[catId].unitRoomTypes, d => d.amount).every((val, i, arr) => val === arr[0]) ? data[catId].unitRoomTypes[Object.keys(data[catId].unitRoomTypes)[0]].amount : ''
                //     } : cat

                //     data = { ...data, [catId]: cate }
                // })

                categoryObj = categories
            }

            // if(propertyDueStatuses?.length && !isEmpty(categoryObj)){
            //     _.forEach(categoryObj,(cat, catId) => categoryObj[catId]['dueStatus'] = _.find(propertyDueStatuses,d => d.dueCategoryId === catId) )
            // }

            setDueCategories(categoryObj)
        }
    }, [allCategories, propertyDueCategories, propertyDueStatuses, unitsData])

    // useEffect(() => {
    //     if(propertyDueStatuses?.length && dueCategories?.length){
    //         const obj = _.map(dueCategories,(cat, catId) => ({...cat, dueStatus:_.find(propertyDueStatuses,d => d.dueCategoryId === catId)}) )
    //         setDueCategories(obj)
    //     }
    // }, [propertyDueStatuses, dueCategories])

    useEffect(() => {
        if (!isEmpty(dueCategories)) {

        }
    }, [dueCategories])

    const categories = useMemo(() => {
        let obj = {}
        allCategories.forEach(d => obj = { ...obj, [d.id]: d })
        return obj
    }, [allCategories])

    const changeCategoryAmount = (id, value) => {
        let obj = JSON.parse(JSON.stringify(dueCategories))
        obj[id].amount = value

        _.forEach(obj[id].unitRoomTypes, (d, dId) => {
            obj[id].unitRoomTypes[dId].amount = value
            obj[id].unitRoomTypes[dId].sharing_types = obj[id].unitRoomTypes[dId].sharing_types.map(s => ({ ...s, amount: value }))
        }
        )

        setDueCategories(obj)
    }

    const changeUnitTypeAmount = (categoryId, unitTypeId, value) => {
        let obj = JSON.parse(JSON.stringify(dueCategories))
        obj[categoryId].amount = ''
        obj[categoryId].unitRoomTypes[unitTypeId].amount = value
        obj[categoryId].unitRoomTypes[unitTypeId].sharing_types = obj[categoryId].unitRoomTypes[unitTypeId].sharing_types.map(sh => ({ ...sh, amount: value }))
        setDueCategories(obj)
    }

    const changeSharingTypeAmount = (categoryId, unitTypeId, sharing_type, value) => {
        let obj = JSON.parse(JSON.stringify(dueCategories))
        const arr = obj[categoryId].unitRoomTypes[unitTypeId].sharing_types
        const indx = _.findIndex(arr, a => a.sharing_type === sharing_type)
        obj[categoryId].amount = ''
        obj[categoryId].unitRoomTypes[unitTypeId].amount = ''
        obj[categoryId].unitRoomTypes[unitTypeId].sharing_types[indx].amount = value
        setDueCategories(obj)
    }

    const handleEnable = (value, id, dueStatusId) => {
        // if(dueStatusId){
        //     const data = [{ id:dueStatusId, isEnabled:e.target.checked}]
        //     addDueCategoryStatus({method:'PATCH', data})
        // }else{
        //     const data = [{isEnabled:e.target.checked, propertyId:params.id, dueCategoryId:id}]
        //     addDueCategoryStatus({method:'POST', data})
        // }

        // let obj = JSON.parse(JSON.stringify(dueCategories))
        // obj[id].isEnabled = value
        // setDueCategories(obj)

        const data =
            _.flatMap(dueCategories[id].unitRoomTypes, (type, typeId) =>
                _.flatMap(type.sharing_types, sharing =>
                ({
                    sharing_type: sharing.sharing_type, dueCategoryId: id, unitRoomTypeId: typeId,
                    propertyId: params.id, amount: sharing.amount || null,
                    isEnabled: value, id: sharing.id
                })
                )
            )
        addDueCategory({ method: 'PATCH', data })
    }

    // const handleChangeCompany = (companyId, dueStatusId) => {
    //     const data = { id:dueStatusId, companyId:companyId || null}
    //     addDueCategoryStatus({method:'PATCH', data})
    // }

    const handleSubmit = () => {
        const data = _.flatMap(dueCategories, (cat, catId) =>
            _.flatMap(cat.unitRoomTypes, (type, typeId) =>
                _.flatMap(type.sharing_types, sharing =>
                ({
                    sharing_type: sharing.sharing_type, dueCategoryId: catId, unitRoomTypeId: typeId,
                    propertyId: params.id, amount: sharing.amount || null,
                    isEnabled: cat.isEnabled, id: sharing.id
                })
                )
            ))
        addDueCategory({ method: 'PATCH', data })
    }

    const updateRef = useRef()
    const handleSaveCompany = () => {
        const data = { id: params.id, companyId: selectedCompanyId }
        updateRef.current = 'company'
        updatePropertyRequest({ method: 'PATCH', data })
    }

    const handleCreatePropertyDue = (dueCategoryId, unitRoomTypeId, sharing_type, value) => {
        const data = [{ sharing_type, unitRoomTypeId, dueCategoryId, isEnabled: value, propertyId: params.id }]
        // const obj = {...dueCategories}
        // const indx = _.findIndex(obj[dueCategoryId].unitRoomTypes[unitRoomTypeId].sharing_types,s => s.sharing_type === sharing_type)
        // obj[dueCategoryId].unitRoomTypes[unitRoomTypeId].sharing_types[indx].isEnabled = value

        addDueCategory({ method: 'PATCH', data })
        // setDueCategories(obj)
    }

    const handleSaveBillingCycleStartDay = () => {
        updateRef.current = 'billingDate'
        const data = { id: params.id, billingCycleStartDay }
        updatePropertyRequest({ method: 'PATCH', data })
    }

    const handleSaveSendNewSlipOnWhatsapp = () => {
        updateRef.current = 'sendOnWhatsapp'
        const data = { id: params.id, sendNewSlipOnWhatsapp }
        updatePropertyRequest({ method: 'PATCH', data })
    }

    const handleSaveSendPaymentReceiptOnWhatsapp = () => {
        updateRef.current = 'sendPaymentReceiptOnWhatsapp'
        const data = { id: params.id, sendPaymentReceiptOnWhatsapp }
        updatePropertyRequest({ method: 'PATCH', data })
    }

    return (
        <Box>
            <LoadingContainer loading={loadingPropertyDueSatus || loadingCompanies || loading || loadingUnitTypes || loadingGetUnits}>
                {/* <Flex wrap={'wrap'}>
                    <Box w={{ base: '100%', lg: 'auto' }} mb={10} pr={6}>
                        <Flex align='start' flexDir={{ base: 'column', lg: 'row' }}>
                            <Box flexGrow={{ lg: '1' }}>
                                <Text fontWeight={'bold'}>Company :</Text>
                            </Box>
                            <Flex mt={{ base: 2, lg: 0 }} w={{ base: '100%', lg: 'auto' }} ml={{ lg: 2 }} justify={'space-between'}>
                                <Select value={selectedCompanyId} onChange={(e) => setCompany(e.target.value)} placeholder='select company'>
                                    {allCompanies?.length ? 
                                        allCompanies.map(company => 
                                            <option value={company.id} key={company.id}>{company.name}</option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                                <Button ml={2} disabled={!selectedCompanyId} isLoading={loadingUpdateProperty && updateRef.current === 'company'} onClick={handleSaveCompany}>Update</Button>
                            </Flex>
                        </Flex>
                    </Box>
                    <Box w={{ base: '100%', lg: 'auto' }} mb={10}>
                        <Flex align='start' flexDir={{ base: 'column', lg: 'row' }}>
                            <Box flexGrow={{ lg: '1' }}>
                                <Text fontWeight={'bold'}>Select Billing Start Day :</Text>
                            </Box>
                            <Flex mt={{ base: 2, lg: 0 }} w={{ base: '100%', lg: 'auto' }} ml={{ lg: 2 }} justify={'space-between'}>
                                <Select value={billingCycleStartDay} onChange={(e) => setBillingCycleStartDay(e.target.value)} placeholder='select start day'>
                                    <option value='fd'>First Day of Month</option>
                                    <option value='jd'>Joining Day of Month</option>
                                </Select>
                                <Button ml={2} disabled={!billingCycleStartDay} isLoading={loadingUpdateProperty && updateRef.current === 'billingDate'} onClick={handleSaveBillingCycleStartDay}>Update</Button>
                            </Flex>
                        </Flex>
                    </Box>
                    
                </Flex> */}
                {/* <Box mb={10}>
                        <HStack align='start' flexDir={{ base: 'column', lg: 'row' }}>
                            <Box>
                                <Text fontWeight={'bold'}>Send Due Slips On Whatsapp :</Text>
                            </Box>
                            <Box mt={{ base: 2, lg: 0 }} w={{ base: '100%', lg: 'auto' }} ml={{ lg: 2 }} justify={'space-between'}>
                                <Select value={sendNewSlipOnWhatsapp} onChange={(e) => setSendNewSlipOnWhatsapp(parseInt(e.target.value))}>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </Select>
                            </Box>
                            <Button ml={2} isLoading={loadingUpdateProperty && updateRef.current === 'sendOnWhatsapp'} onClick={handleSaveSendNewSlipOnWhatsapp}>Update</Button>
                            
                        </HStack>
                    </Box>
                    <Box mb={10}>
                        <HStack>
                            <Box>
                                <Text fontWeight={'bold'}>Send Payment Receipts On Whatsapp :</Text>
                            </Box>
                            <Box>
                                <Select value={sendPaymentReceiptOnWhatsapp} onChange={(e) => setSendPaymentReceiptOnWhatsapp(parseInt(e.target.value))}>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </Select>
                            </Box>
                            <Button isLoading={loadingUpdateProperty && updateRef.current === 'sendPaymentReceiptOnWhatsapp'} onClick={handleSaveSendPaymentReceiptOnWhatsapp}>Update</Button>
                        </HStack>
                    </Box> */}
                {!isEmpty(dueCategories) ?
                    <Box>
                        <Box display={{ base: 'none', xl: 'block' }}>
                            <Table size={'sm'} variant={'simple'}>
                                <Thead>
                                    <Tr>
                                        <Th border={'1px solid #ECF0F1'} width={30}>Enable</Th>
                                        <Th border={'1px solid #ECF0F1'} width={100}>
                                            Category
                                        </Th>
                                        {/* <Th border={'1px solid #ECF0F1'} width={150}>Company</Th> */}
                                        {/* <Th border={'1px solid #ECF0F1'} width={90}>Installment Type</Th> */}
                                        <Th border={'1px solid #ECF0F1'}>Amount</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {_.map(dueCategories, (cat, catId) => {
                                        const category = categories[catId]
                                        const categoryData = dueCategories[catId]
                                        const dueStatus = cat.dueStatus
                                        //const isEnabled = dueStatus?.isEnabled
                                        return (
                                            <Tr key={catId}>
                                                <Td border={'1px solid #ECF0F1'}>
                                                    <Switch isChecked={cat.isEnabled} onChange={e => handleEnable(e.target.checked, catId, cat.propertyDueStatus?.id)} />
                                                </Td>
                                                <Td border={'1px solid #ECF0F1'}>
                                                    {category?.name}<br />
                                                    <Text fontStyle={"italic"} fontSize="xs" color="red.400">
                                                        {installment_types[category?.installmentType]?.title}
                                                    </Text>
                                                </Td>
                                                {/* <Td>
                                                    <Select value={dueStatus?.companyId || ''} onChange={e => handleChangeCompany(e.target.value, dueStatus?.id)} disabled={!isEnabled} placeholder='Select Company'>
                                                        {allCompanies?.length ? 
                                                            allCompanies.map(cmpny => 
                                                                <option value={cmpny.id} key={cmpny.id}>{cmpny.name}</option>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </Select>
                                                </Td> */}
                                                {/* <Td border={'1px solid #ECF0F1'}>{installment_types[category?.installmentType]?.title}</Td> */}
                                                <Td border={'1px solid #ECF0F1'}>
                                                    <Accordion boxShadow={'sm'} defaultIndex={[0]} allowMultiple>
                                                        <AccordionItem border={'1px solid #D6DBDF'}>
                                                            {({ isExpanded }) => (
                                                                <>
                                                                    <Box background={isExpanded && 'gray.100'} p={2} borderBottom={'1px solid #ECF0F1'}>
                                                                        <HStack justifyContent={'space-between'} width='100%'>
                                                                            <CustomInput
                                                                                value={categoryData.amount}
                                                                                onChange={(e) => changeCategoryAmount(catId, e.target.value)}
                                                                            />
                                                                            <AccordionButton width={50}>
                                                                                {isExpanded ? <MinusIcon fontSize='12px' /> : <AddIcon fontSize='12px' />}
                                                                            </AccordionButton>
                                                                        </HStack>
                                                                    </Box>
                                                                    <AccordionPanel pb={4}>
                                                                        <Table>
                                                                            {_.map(cat.unitRoomTypes, (unitType, typeId) => {
                                                                                const unitRoomType = _.find(unitRoomTypes, t => t.id === typeId)
                                                                                const roomTypeData = dueCategories[catId].unitRoomTypes[typeId]
                                                                                return (
                                                                                    <Tr key={typeId}>
                                                                                        <Td border={'1px solid #ECF0F1'}>
                                                                                            <Text color={'defaultColor.500'}>{unitRoomType?.typeName}</Text>
                                                                                        </Td>
                                                                                        <Td border={'1px solid #ECF0F1'}>
                                                                                            <Accordion allowToggle allowMultiple defaultIndex={Array.from(Array(cat.unitRoomTypes.length).keys())}>
                                                                                                <AccordionItem border={'1px solid #ECF0F1'}>
                                                                                                    {({ isExpanded }) => (
                                                                                                        <>
                                                                                                            <HStack background={isExpanded && 'gray.100'} boxShadow={'sm'} border={'1px solid #ECF0F1'} p={2} justifyContent={'space-between'} width='100%'>
                                                                                                                <CustomInput
                                                                                                                    onChange={(e) => changeUnitTypeAmount(catId, typeId, e.target.value)}
                                                                                                                    value={roomTypeData.amount}
                                                                                                                />
                                                                                                                <AccordionButton width={50}>
                                                                                                                    {isExpanded ? <MinusIcon fontSize='12px' /> : <AddIcon fontSize='12px' />}
                                                                                                                </AccordionButton>
                                                                                                            </HStack>
                                                                                                            <AccordionPanel pb={4}>
                                                                                                                <Table>
                                                                                                                    {unitType.sharing_types.map((sharing) => {
                                                                                                                        return (
                                                                                                                            <RoomPayAmount cat={cat} sharing={sharing} changeSharingTypeAmount={changeSharingTypeAmount} catId={catId} typeId={typeId} dueCategories={dueCategories} handleCreatePropertyDue={handleCreatePropertyDue} />
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </Table>
                                                                                                            </AccordionPanel>
                                                                                                        </>
                                                                                                    )}
                                                                                                </AccordionItem>
                                                                                            </Accordion>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )
                                                                            })}
                                                                        </Table>
                                                                    </AccordionPanel>
                                                                </>
                                                            )}
                                                        </AccordionItem>
                                                    </Accordion>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box display={{ base: 'block', xl: 'none' }}>
                            <MobileViewPaymenCategory unitRoomTypes={unitRoomTypes} handleEnable={handleEnable} categories={categories} dueCategories={dueCategories} changeSharingTypeAmount={changeSharingTypeAmount} handleCreatePropertyDue={handleCreatePropertyDue} />
                        </Box>
                        <br />
                        <Button onClick={handleSubmit}>Save</Button>
                    </Box>
                    :
                    <EmptyPage message='no payment categories available' />
                }
            </LoadingContainer>
        </Box>
    )
}

export const RoomPayAmount = ({ sharing, changeSharingTypeAmount, catId, cat, typeId, dueCategories, handleCreatePropertyDue }) => {
    const sharingData = _.find(dueCategories[catId].unitRoomTypes[typeId]?.sharing_types, s => s.sharing_type === sharing.sharing_type)
    const disabled = cat.isEnabled && !sharingData.id

    return (
        <Tr key={sharing.sharing_type}>
            <Td border={'1px solid #ECF0F1'} fontSize='extraSmall' color={'defaultColor.500'}>
                {SHARINGS[sharing.sharing_type]?.title}
            </Td>
            <Td colSpan={!disabled && 2} border={'1px solid #ECF0F1'}>
                <CustomInput
                    disabled={disabled}
                    onChange={(e) => changeSharingTypeAmount(catId, typeId, sharing.sharing_type, e.target.value)}
                    value={sharingData?.amount}
                />
            </Td>
            {disabled ?
                <Td>
                    <Switch onChange={(e) => handleCreatePropertyDue(catId, typeId, sharing.sharing_type, e.target.checked)} />
                </Td>
                :
                null
            }
        </Tr>
    )
}

const CustomInput = ({ value, onChange, disabled }) => {
    return (
        <InputGroup>
            <InputLeftElement
                pointerEvents='none'
                children={'₹'}
            />
            <Input background={'white'} width={150} placeholder='Amount' type='number'
                onChange={onChange} value={value}
                disabled={disabled}
            />
        </InputGroup>
    )
}