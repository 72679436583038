import React, { useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, VStack, FormControl, FormLabel, Input, Box, Flex, Avatar, Text, Tag } from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import { useUserData } from '../../services/api/useUserData'


export const AddDeviceModal = ({ isOpen, onClose, deviceData, mutate }) => {
    console.log({ deviceData })
    const { organisation } = useUserData(true)
    //react-hook-form
    const { handleSubmit, register, reset, setValue } = useForm()

    const { request: createMessMutate, } = useApiRequest(URIS.ADD_DEVICE_DATA, {
        onCompleted: d => {
            mutate()
        },
        showAlert: true,
        showLoader: true,
    })

    useEffect(() => {
        if (deviceData) {
            setValue("deviceName", deviceData?.name)
            setValue("boxIdNo", deviceData?.boxIdNo)
        }
    }, [deviceData, setValue])

    //Add divice main submit function
    const _submit = (data) => {
        if (deviceData && deviceData?.id) {
            createMessMutate({
                method: 'PATCH', data: {
                    id: deviceData.id,
                    name: data?.deviceName,
                    boxIdNo: data?.boxIdNo,
                }
            })
        } else {
            createMessMutate({
                method: 'POST', data: {
                    name: data?.deviceName,
                    boxIdNo: data?.boxIdNo,
                    organisationId: organisation.id,
                }
            })
        }
        modalOnCloseHandler()
    }

    const modalOnCloseHandler = () => {
        if (!deviceData) {
            reset()
        }
        onClose()
    }


    return <Modal isOpen={isOpen} onClose={modalOnCloseHandler} size="xl">
        <ModalOverlay />
        <ModalContent>
            <form onSubmit={handleSubmit(_submit)}>
                <ModalHeader>{deviceData ? "Edit" : "Add"} Device</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w="100%" spacing={4}>

                        <FormControl w="100%" mb={2} isRequired>
                            <FormLabel htmlFor="deviceName">Device Name</FormLabel>
                            <Input
                                style={{ width: "100%" }}
                                id="deviceName" type="text"
                                {...register('deviceName')}
                                placeholder="Enter Device Name"

                            />
                        </FormControl>

                        <FormControl w="100%" mb={2} isRequired>
                            <FormLabel htmlFor="boxIdNo">Device STG ID</FormLabel>
                            <Input
                                style={{ width: "100%" }}
                                id="boxIdNo" type="text"
                                {...register('boxIdNo')}
                                placeholder="Enter Device STG ID"

                            />
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} colorScheme="gray" onClick={modalOnCloseHandler}>
                        Close
                    </Button>
                    <Button type="submit" >{deviceData ? "Update" : "Save"}</Button>
                </ModalFooter>
            </form>
        </ModalContent>
    </Modal >
}