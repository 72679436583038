import React, { useMemo } from 'react'
import {
    Box, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    HStack,
    VStack,
    Text,
    Flex,
    Tooltip,
    Icon
} from '@chakra-ui/react'
import { chain, compact, find, join, map } from 'lodash';
import { BiLayer } from 'react-icons/bi';
import { BsDoorOpen } from 'react-icons/bs';
import { RiHotelBedFill, RiHotelBedLine } from 'react-icons/ri';
import { URIS } from '../../services/api';
import { apis } from '../../services/api/apis';
import { useGetProperties } from '../../services/api/useGetProperties'
import { useUnitsData } from '../../services/api/useUnitsData';
import { useGetData } from '../../utils/useGetData';

export function SelectPropertyModal(props) {
    const { onClose, selectedPropertyId, selectProperty } = props;
    const { data: allProperties } = useGetProperties(true)

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Property</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        map(allProperties, (property, i) => <SingleProperty property={property} key={i}
                            onClick={() => selectProperty(property)}
                            selected={property.id === selectedPropertyId} />)
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
const SingleProperty = ({ property, selected, onClick }) => {
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allUnits } = useUnitsData(property.id)

    const state = allStates?.length ? find(allStates, state => state.id === property.state) : null
    const city = state?.cities?.length ? find(state.cities, city => city.id === property.district) : null

    const allRooms = useMemo(() => {
        if (allUnits?.length)
            return chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if (allUnits?.length) {
            return chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    return (
        <HStack align={'stretch'} mb={2} px={1}>
            <Box flex={1} onClick={onClick} p={4} border='1px solid' borderColor={'gray.100'} borderRadius={6}
                boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                {...selected ? {
                    borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                } : { borderLeft: '6px solid', borderLeftColor: 'white' }}
                cursor={'pointer'} _hover={{
                    background: 'gray.100', borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                }}
                borderRightColor={'#fff'}
                transition='all .2s'
            >
                <VStack align={'left'}>
                    <Text fontSize={'xs'} color={'gray.500'} noOfLines={2} wordBreak={'break-all'}>{join(compact([state?.name, city?.name, property.town, property.streetAddress, property.pincode]), ', ')}</Text>
                    <Text color={selected && 'defaultColor.500'} fontSize={'large'}>{property.name}</Text>
                    <Flex wrap={'wrap'} spacing={6}>
                        <FacilityCount selected={selected} count={property.noOfFloors} label={'Total Floors'} icon={BiLayer} />
                        <FacilityCount selected={selected} count={allUnits?.length || 0} label={'Total Rooms'} icon={BsDoorOpen} />
                        <FacilityCount selected={selected} count={allRooms} label={'Total Beds'} icon={RiHotelBedLine} />
                        <FacilityCount selected={selected} count={occupiedBeds} label={'Occupied Beds'} icon={RiHotelBedFill} />
                    </Flex>
                </VStack>
            </Box>
        </HStack>
    )
}
const FacilityCount = ({ count, label, icon, selected }) => {
    return (
        <Box pr={5} pb={2}>
            <Tooltip label={label}>
                <HStack spacing={1}>
                    <Icon as={icon} color={selected ? 'defaultColor.500' : 'gray'} />
                    <Text color={selected ? 'defaultColor.500' : 'gray.600'}>{count}</Text>
                </HStack>
            </Tooltip>
        </Box>
    )
}