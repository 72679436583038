import React, { useMemo } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { useAppContext, useIsAuthenticated } from "../app/Context";
import { DashboardRoutes } from "./ProtectedRoutes";
import { LoginRoute } from "./public/LoginRoute";
import { RegisterRoute } from './public/RegisterRoute'
import { ROUTES } from "./routes";
import { TenantPayment } from "./public/tenant-payment";
import { TenantPaymentStatus } from "./public/payment-status";

const Routess = () => {
  return (
    <Routes>
      {
        <>
          <Route path={ROUTES.LOGIN} element={<LoginRoute />} />
          <Route path={ROUTES.REGISTER} element={<RegisterRoute />} />
          <Route path={ROUTES.TENANT_PAYMENT} element={<TenantPayment />} />
          <Route path={ROUTES.PAYMENTS_STATUS} element={<TenantPaymentStatus />} />
        </>
      }
      <Route
        path="/*"
        element={
          <RequireAuth>
            <DashboardRoutes />
          </RequireAuth>
        }
      />
    </Routes>
  )
}
export const MainApp = (props) => {
  return (
    <Box>
      <LoaderComponent />
      <BrowserRouter>
        <Routess />
      </BrowserRouter>
    </Box>
  );
};

const LoaderComponent = () => {
  const { isLoading, loadingProperties } = useAppContext();
  const showLoader = useMemo(() => isLoading || loadingProperties, [loadingProperties, isLoading])
  return (
    <Center display={showLoader ? 'flex' : 'none'} pos='fixed' top='0px' w='100vw' h="100vh" bg='white' zIndex={99999}>
      {showLoader ? <Spinner /> : null}
    </Center>
  )
}

const RequireAuth = ({ children }) => {
  let auth = useIsAuthenticated();
  let location = useLocation();

  if (!auth) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};
