import { Box, HStack, Button, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { AddMessModal } from "./AddMessModal"
import { MessList } from "./MessList"

export const Mess = () => {
    return (
        <Box>
            <MessList/>
        </Box>
    )
}