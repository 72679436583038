import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function usePropertyDueStatus(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_PROPERTY_DUE_STATUS+q, () => apis.getPropertyDueStatusApi(params), q, revalidate)
    return {
        data, error, loading, mutate
    }
}