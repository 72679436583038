import { Box, Heading, HStack, Tabs, TabList, Tab, TabPanels, TabPanel, Input, FormControl, Text, Flex, Thead, Tbody, Table, Tr, Th, Td, FormLabel, Select, Spinner, } from "@chakra-ui/react";
import { find } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMessAttendanceReport } from "../../services/api/useMessData1";
import { useMemo } from "react";
import { useGetProperties } from "../../services/api/useGetProperties";
import { useEffect } from "react";
import Pagination from "rc-pagination";


export const AttendanceReport = ({ messData, allMessFoodMenuData, allMessPropertyData }) => {

    const [meal_type, set_meal_type] = useState("")

    useEffect(() => {
        let mealData = allMessFoodMenuData?.find(meal => meal.meal_type === "B")
        if (mealData && mealData?.id) { set_meal_type(mealData.id) }
    }, [allMessFoodMenuData])

    const [page, setPage] = useState(1)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        setPage(1)
    }, [])

    const { register, watch } = useForm({
        defaultValues: {
            date: moment().format("YYYY-MM-DD"),
        }
    })
    const date = watch("date")
    const propertyId = watch("propertyId");

    const { data: messAttendanceReport, loading: messReportIsLoading } = useMessAttendanceReport(messData?.id && date && propertyId && meal_type ? messData.id + propertyId + date + meal_type + page : null, {
        startDateTime: `${date} 00:00:00`, endDateTime: `${date} 23:59:59`,
        messId: messData?.id, propertyId, foodMenuId: meal_type, page,
    }, true)

    const { data: allProperties } = useGetProperties(true)

    const breakfastDataHandler = () => {
        setPage(1)
        let mealData = allMessFoodMenuData?.find(meal => meal.meal_type === "B")
        if (mealData && mealData?.id) { set_meal_type(mealData.id) }
    }
    const lunchDataHandler = () => {
        setPage(1)
        let mealData = allMessFoodMenuData?.find(meal => meal.meal_type === "L")
        if (mealData && mealData?.id) { set_meal_type(mealData.id) }
    }
    const snacksDataHandler = () => {
        setPage(1)
        let mealData = allMessFoodMenuData?.find(meal => meal.meal_type === "S")
        if (mealData && mealData?.id) { set_meal_type(mealData.id) }
    }
    const dinnerDataHandler = () => {
        setPage(1)
        let mealData = allMessFoodMenuData?.find(meal => meal.meal_type === "D")
        if (mealData && mealData?.id) { set_meal_type(mealData.id) }
    }

    return <Box>
        <form >
            <Flex justifyContent={"space-between"} alignItems={"center"} >
                <Heading mr={10}>Mess Report</Heading>
            </Flex>

            <Flex mt={3}>
                <FormControl mr={5} w={"fit-content"} >
                    <FormLabel>Date</FormLabel>
                    <Input type="date" {...register("date")} name="date" />
                </FormControl>
                <FormControl w={"fit-content"} >
                    <FormLabel>Property</FormLabel>
                    <Select {...register("propertyId")}>
                        {allMessPropertyData?.filter(property => property?.messId === messData?.id)?.map(property => {
                            let propertyData = allProperties?.find(data => data?.id === property?.propertyId)
                            return <option key={property.propertyId} value={property.propertyId}>{propertyData?.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Flex>

            <Tabs mt={5} isLazy onChange={(index) => setTabIndex(index)}>
                <TabList>
                    <Tab isDisabled={find(allMessFoodMenuData, meal => meal.meal_type === "B")?.isEnabled ? false : true} onClick={breakfastDataHandler} >Breakfast</Tab>
                    <Tab isDisabled={find(allMessFoodMenuData, meal => meal.meal_type === "L")?.isEnabled ? false : true} onClick={lunchDataHandler} >Lunch</Tab>
                    <Tab isDisabled={find(allMessFoodMenuData, meal => meal.meal_type === "S")?.isEnabled ? false : true} onClick={snacksDataHandler} >Snacks</Tab>
                    <Tab isDisabled={find(allMessFoodMenuData, meal => meal.meal_type === "D")?.isEnabled ? false : true} onClick={dinnerDataHandler} >Dinner</Tab>
                </TabList>
                {messReportIsLoading ?
                    <Box>
                        <Spinner />
                    </Box> :

                    <TabPanels>
                        <TabPanel><ReportTable mess={messData} foodMenu={find(allMessFoodMenuData, meal => meal.meal_type === "B")?.id} tabIndex={tabIndex} date={date} tableData={messAttendanceReport} page={page} setPage={setPage} /></TabPanel>
                        <TabPanel><ReportTable mess={messData} foodMenu={find(allMessFoodMenuData, meal => meal.meal_type === "L")?.id} tabIndex={tabIndex} date={date} tableData={messAttendanceReport} page={page} setPage={setPage} /></TabPanel>
                        <TabPanel><ReportTable mess={messData} foodMenu={find(allMessFoodMenuData, meal => meal.meal_type === "S")?.id} tabIndex={tabIndex} date={date} tableData={messAttendanceReport} page={page} setPage={setPage} /></TabPanel>
                        <TabPanel><ReportTable mess={messData} foodMenu={find(allMessFoodMenuData, meal => meal.meal_type === "D")?.id} tabIndex={tabIndex} date={date} tableData={messAttendanceReport} page={page} setPage={setPage} /></TabPanel>
                    </TabPanels>
                }
            </Tabs>
        </form>
    </Box>
}


const ReportTable = ({ mess, foodMenu, allTenants = 0, currentProperty, tabIndex, date, tableData, page, setPage }) => {

    const handlePageChange = (page) => {
        setPage(page)
    }

    return (
        <Box >
            <Flex mt={2}>
                {/* <Text fontSize={"lg"}>Total Student: {100}</Text> */}
                <Text fontSize={"lg"} >Meal Taken by: {tableData?.total ?? 0}</Text>
                {/* <Text fontSize={"lg"} ml={10}>Remaining Students: {12}</Text> */}
            </Flex>
            {/* {attendance?.results && attendance.results.length ? */}
            <Table mt={10} className="responsiveTable" variant={'simple'}>
                <Thead bgColor={"gray.200"}>
                    <Tr>
                        <Th>Sr. No</Th>
                        <Th>Name</Th>
                        <Th>Meal</Th>
                        <Th>Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData?.results?.length > 0 ?
                        tableData?.results?.map((data, i) => {
                            return <Tr key={data.id}>
                                <Td>{(20 * (page - 1)) + i + 1}</Td>
                                <Td>{data?.tenant?.name}</Td>
                                <Td>{data?.foodMenu?.meal_type === "B" ? "Breakfast" : data?.foodMenu?.meal_type === "L" ? "Lunch" : data?.foodMenu?.meal_type === "S" ? "Snacks" : data?.foodMenu?.meal_type === "D" ? "Dinner" : '-'}</Td>
                                <Td>
                                    {moment(data.loggedAt).format('LLL')}
                                </Td>
                            </Tr>
                        })
                        : <Tr><Td>No Data Found</Td></Tr>}
                </Tbody>
            </Table>
            {tableData?.results?.length > 0 ? <HStack mt={4} justifyContent={'center'}>
                <Pagination
                    total={tableData?.total || 20}
                    pageSize={tableData?.limit || 20}
                    onChange={handlePageChange}
                    current={parseInt(page)}
                />
            </HStack> : null}

        </Box>
    )
}