import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useTenantSlips(q, params, revalidate) {

    const { data, error, loading, mutate } = useGetData(URIS.GET_TENANT_SLIPS + q, () => apis.getTenantSlipsApi(params), q, revalidate)

    return {
        data,
        error, loading, mutate
    }
}