import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Flex, VStack, Heading, Text, Tabs, TabList, Tab, TabPanels, TabPanel, Stack, Button, FormControl, FormLabel, Input, Radio, RadioGroup, HStack, Avatar, Textarea, Center, IconButton, Tooltip } from '@chakra-ui/react'
import _, { find, omit, orderBy } from 'lodash'
import moment from 'moment'
import { DropdownList } from 'react-widgets/cjs'
import "react-widgets/styles.css";

import { useGetData } from '../../utils/useGetData'
import { FormReducer } from '../../utils/FormReducer'
import { useApiRequest } from '../../services/api/useApiRequest'
import { CustomContainer } from '../../ui/CustomContainer'
import DatePicker from "react-widgets/DatePicker";
import { GuardianDetails } from './GuardianDetails'
import { ActionHeader } from '../../ui/ActionHeader'
import { UpdateDPModal } from './UpdateDPModal'
import { floorName } from '../../utils/Helper'
import { TenantHistory } from './TenantHistory'
import { useUserData } from '../../services/api/useUserData'
import { TenantInOutLogs } from './TenantInOutLogs'
import { useStates } from '../../services/api/useStates'
import { TenantDocuments } from './TenantDocuments'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { TenantSlips } from '../TenantPayment/TenantSlips'
import { useTenantProfile } from '../../services/api/useTenantProfile'
import { AssignRoomModal } from '../ManageTenant/AssignRoomModal'
import { AddTenantRentModal } from '../TenantPayment/AddTenantRentModal'
import { TenantFoodLogs } from './TenantFoodLogs'
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { BsBuilding, BsDoorOpen } from 'react-icons/bs'
import { MdOutlineBedroomChild, MdPlace } from 'react-icons/md'
import { BiLayer } from 'react-icons/bi'
import { useAppContext } from '../../app/Context'
import { ALL_USER_ROLES, staffPermissions } from '../../Constants'
import { TenantIdCard } from './TenantIdCard'


export const TenantProfile = () => {
    const navigate = useNavigate()
    const { id, tab } = useParams()

    const [dueDates, setDueDates] = useState('')
    const [userData, changeUserData] = useReducer(FormReducer, {})
    const [dpModal, openDpModal] = useState()
    const [assignRoomModal, openAssignRoomModal] = useState()
    const [rentModal, openRentModal] = useState()
    const [parentKey, changeParentKey] = useState(0)

    const { organisation, } = useUserData(true)
    const { data: profileData, loading: loadingProfile, mutate } = useTenantProfile(id, { organisationId: organisation?.id, tenantId: id, unitData: true }, true)
    const { data: allProperties, loading: loadingAllProperties } = useGetData(URIS.GET_PROPERTIES, () => apis.getPropertiesApi({ organisationId: organisation?.id }), true)
    const { data: allStates } = useStates(true)

    useEffect(() => {
        if (profileData?.tenant) {
            const { name, avatar, email, dob, gender, isActive, username, contact } = profileData.tenant
            const { permanentAddress, localAddress, registrationNo } = profileData
            const pAddress = permanentAddress || {}
            const lAddress = localAddress || {}

            changeUserData({
                type: 'reset', value: {
                    name, avatar, email, dob, gender, isActive, username, contact,
                    registrationNo,
                    localAddress: lAddress.address, localState: lAddress.state, localDistrict: lAddress.district, localPincode: lAddress.pincode,
                    permanentAddress: pAddress.address, permanentState: pAddress.state, permanentDistrict: pAddress.district, permanentPincode: pAddress.pincode
                }
            })
        }
    }, [profileData])

    const { request: updateRequest } = useApiRequest(URIS.UPDATE_TENANT_PROFILE, {
        onCompleted: () => {
            mutate()
        },
        onError: () => { },
        showAlert: true,
        showLoader: true,
    })

    const handleChangeValue = (type, value) => {

        if (type === 'permanentState') {
            changeUserData({ type: 'permanentDistrict', value: null })
        } else if (type === 'localState') {
            changeUserData({ type: 'localDistrict', value: null })
        }

        changeUserData({ type, value })
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        const { localAddress, localState, localDistrict, localPincode, permanentAddress, permanentState, permanentDistrict, permanentPincode } = userData
        let data = {
            id: profileData.id,
            localAddress: { address: localAddress, state: localState, district: localDistrict, pincode: localPincode },
            permanentAddress: { address: permanentAddress, state: permanentState, district: permanentDistrict, pincode: permanentPincode },
            registrationNo: userData.registrationNo,
            userData: {
                ..._.omit(userData, ['localAddress', 'registrationNo', 'username', 'localState', 'localDistrict', 'localPincode', 'permanentAddress', 'permanentState', 'permanentDistrict', 'permanentPincode']),
                id: profileData.tenant.id,
                dob: userData.dob ? moment(userData.dob).format('YYYY/MM/DD') : null,
            }
        }

        updateRequest({ method: 'PATCH', data: data.dob ? data : omit(data, ['dob']) })
    }

    const handleUpdateDp = () => {
        openDpModal(d => !d)
    }


    const handleChangeTab = (e) => {
        navigate(`/tenant/profile/${id}/${e}`)
    }

    const handleAssignRoom = () => {
        openAssignRoomModal(d => !d)
    }

    const handleOpenRentModal = (tenant) => {
        if (rentModal) {
            changeParentKey(d => ++d)
        }
        openRentModal(d => d ? null : tenant)
    }

    const { hasAccess, hasPermission } = useAppContext()

    const tabs = [
        { value: 'details', title: 'Basic Details' },
        { value: 'guardians', title: 'Guardian Details' },
        { value: 'documents', title: 'Documents' },
        hasAccess(staffPermissions.ID_CARD) || hasPermission(ALL_USER_ROLES.OWNER) ? { value: 'id-card', title: 'Id Card' } : null,
        { value: 'rooms', title: 'Rooms' },
        { value: 'in-out-logs', title: 'In-Out Logs' },
        { value: 'food-logs', title: 'Food Logs' },
        hasAccess(staffPermissions.COLLECT_PAYMENT) || hasPermission(ALL_USER_ROLES.OWNER) ? { value: 'payments', title: 'Payments' } : null,
    ]

    const tenantRoom = useMemo(() => { return profileData?.unitTenants?.length ? profileData.unitTenants[0] : null }, [profileData])
    const propertyid = tenantRoom?.unit?.propertyId

    const { data: unitRoomTypes } = useUnitRoomTypes(propertyid, { propertyId: propertyid })

    const breadcrumbs = [
        { id: 1, title: 'Tenant List', src: '/tenants/1/0' },
        { id: 2, title: 'Tenant Profile', src: '#' }
    ]
    return (
        <Box>
            <ActionHeader title={'Tenant Profile'} />
            <br />
            <CustomContainer breadcrumbs={breadcrumbs} loading={loadingProfile || loadingAllProperties} >
                <Stack bg='white' borderRadius={'lg'} direction={{ base: 'column', sm: 'row' }}>
                    <Flex width={['100%', '100%', '100%', '20%']} wrap={'wrap'} flexDir='column' px={{ base: 4, lg: 2 }} borderRight='1px solid #EBEDEF'>
                        <VStack pb={6}>
                            <Avatar className='Image' objectFit={'cover'} flex={1}
                                src={userData.avatar} alt='Loading Image'
                                size={'xl'}
                            />
                            <Button colorScheme={'gray'} onClick={handleUpdateDp}>Update</Button>
                        </VStack>
                        <VStack spacing={{ base: 6, lg: 10 }} mb={4}>
                            <Tooltip label={profileData?.tenant?.name}>
                                <Text maxW={200} noOfLines={'2'} textAlign={"center"}>
                                    <Heading textAlign={'center'} fontWeight={'bold'} fontSize='xl'>{profileData?.tenant?.name}</Heading>
                                    {/* <Button mt={2} variant='outline'>Id card</Button> */}
                                </Text>
                            </Tooltip>
                            {tenantRoom ?
                                <VStack w='100%' justify={'space-between'} wrap='wrap' flexDir={{ base: 'row', lg: 'column' }} align={'center'} spacing={{ base: 2, lg: 4 }}>
                                    <ProfileUnderDetails icon={<BsBuilding fontSize={16} />} title={tenantRoom.unit.name} desc={'Room'} />
                                    <ProfileUnderDetails icon={<BiLayer fontSize={16} />} title={floorName(tenantRoom.unit.floorNo)} desc={'Floor'} />
                                    <ProfileUnderDetails icon={<MdPlace fontSize={16} />} title={allProperties ? find(allProperties, p => p.id === tenantRoom.unit.propertyId)?.name : null} desc={'Property'} />
                                    <ProfileUnderDetails icon={<BsDoorOpen fontSize={16} />} title={unitRoomTypes?.find(d => d.id == tenantRoom.unit.unitRoomTypeId)?.typeName} desc={'Room Type'} />
                                </VStack>
                                :
                                <>
                                    <Button onClick={handleAssignRoom}>Assign room</Button>
                                    <Text color='secondary'>No room assigned</Text>
                                </>
                            }
                        </VStack>
                    </Flex>
                    <Flex flex={1} w={['100%', '100%', '100%', '80%']} >
                        <VStack px={1} align='stretch' w='100%'>
                            <Tabs isLazy key={parentKey} index={parseFloat(tab)} onChange={handleChangeTab} size={'sm'} variant='enclosed'>
                                <TabList whiteSpace={'nowrap'} overflowX={'auto'} overflowY='hidden'>
                                    {tabs.map(tab =>
                                        tab ?
                                            <Tab key={tab.value}>{tab.title}</Tab>
                                            :
                                            null
                                    )}
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        <br />
                                        <Heading>Basic Details</Heading>
                                        <form onSubmit={handleUpdate}>
                                            <Flex w='100%' my={5} flexWrap='wrap' direction={{ base: 'column', md: 'row', lg: 'row' }}>
                                                <FromContainer>
                                                    <FormControl isDisabled={!hasAccess(staffPermissions.EDIT_TENANT_NAME)} isRequired>
                                                        <FormLabel>Name</FormLabel>
                                                        <Input name='name' value={userData.name} onChange={(e) => handleChangeValue('name', e.target.value)} placeholder='name' />
                                                    </FormControl>
                                                </FromContainer>
                                                {
                                                    hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ?
                                                        <FromContainer>
                                                            <FormControl isRequired>
                                                                <FormLabel>Contact</FormLabel>
                                                                <Input name='contact' value={userData.contact} pattern="[6789][0-9]{9}" maxLength={10} placeholder='contact' onChange={(e) => handleChangeValue('contact', e.target.value)} />
                                                            </FormControl>
                                                        </FromContainer>
                                                        : null
                                                }

                                                <FromContainer>
                                                    <FormControl isRequired>
                                                        <FormLabel>Registration Number</FormLabel>
                                                        <Input name='registrationNo' value={userData.registrationNo} placeholder='Registration No' onChange={(e) => handleChangeValue('registrationNo', e.target.value)} />
                                                    </FormControl>
                                                </FromContainer>
                                                <FromContainer>
                                                    <FormControl>
                                                        <FormLabel>Date of Birth</FormLabel>
                                                        <DatePicker style={{ fontSize: 14 }} value={userData.dob ? new Date(userData.dob) : null} name="dateOfJoining" type="date" onChange={e => handleChangeValue('dob', e)} />
                                                    </FormControl>
                                                </FromContainer>
                                                <FromContainer>
                                                    <FormControl>
                                                        <FormLabel>Gender</FormLabel>
                                                        <RadioGroup id="gender" value={userData.gender} onChange={(e) => handleChangeValue('gender', e)}>
                                                            <Stack direction='row'>
                                                                <Radio value={'MALE'}>Male</Radio>
                                                                <Radio value={'FEMALE'}>Female </Radio>
                                                            </Stack>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </FromContainer>
                                                <FromContainer>
                                                    <FormControl>
                                                        <FormLabel>Email</FormLabel>
                                                        <Input type={'email'} value={userData.email} placeholder='email@gmail.com' onChange={(e) => handleChangeValue('email', e.target.value)} />
                                                    </FormControl>
                                                </FromContainer>
                                                <FromContainer>
                                                    <FormControl isRequired>
                                                        <FormLabel>Username</FormLabel>
                                                        <Input disabled name='username' value={userData.username} placeholder='UserName' onChange={(e) => handleChangeValue('username', e.target.value)} />
                                                    </FormControl>
                                                </FromContainer>
                                                <VStack spacing={0} width={'100%'} align={'stretch'} pt={8}>
                                                    <Text color={'defaultColor.500'} fontWeight={'bold'}>Local Address</Text>
                                                    <Box width={'100%'} pb={4} pt={3}>
                                                        <FormControl>
                                                            <FormLabel>Address</FormLabel>
                                                            <Textarea placeholder='local address'
                                                                value={userData.localAddress}
                                                                onChange={(e) => handleChangeValue('localAddress', e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <HStack>
                                                        <FromContainer>
                                                            <FormControl>
                                                                <FormLabel>State</FormLabel>
                                                                <DropdownList placeholder={'select state'}
                                                                    onChange={e => handleChangeValue('localState', e.id)}
                                                                    data={allStates ? orderBy(allStates, 'name', 'asc') : []}
                                                                    textField='name'
                                                                    style={{ fontSize: 14 }}
                                                                    dataKey={'id'}
                                                                    value={userData.localState}
                                                                />
                                                            </FormControl>
                                                        </FromContainer>
                                                        <FromContainer>
                                                            <FormControl>
                                                                <FormLabel>District</FormLabel>
                                                                <DropdownList placeholder={'select district'}
                                                                    onChange={e => handleChangeValue('localDistrict', e.id)}
                                                                    style={{ fontSize: 14 }}
                                                                    value={userData.localDistrict}
                                                                    data={userData.localState && find(allStates, s => s.id === userData.localState)?.cities?.length ?
                                                                        orderBy(find(allStates, s => s.id === userData.localState).cities, 'name', 'asc')
                                                                        : []
                                                                    }
                                                                    textField='name'
                                                                    dataKey={'id'}
                                                                />
                                                            </FormControl>
                                                        </FromContainer>
                                                    </HStack>
                                                    <FromContainer>
                                                        <FormControl>
                                                            <FormLabel>Pincode</FormLabel>
                                                            <Input value={userData.localPincode} placeholder='pincode' onChange={(e) => handleChangeValue('localPincode', e.target.value)} />
                                                        </FormControl>
                                                    </FromContainer>
                                                </VStack>
                                                <VStack spacing={0} width={'100%'} pt={8} align={'stretch'}>
                                                    <Text color={'defaultColor.500'} fontWeight={'bold'}>Permanent Address</Text>
                                                    <Box width={'100%'} pt={3} pb={4}>
                                                        <FormControl>
                                                            <FormLabel>Address</FormLabel>
                                                            <Textarea placeholder='permanent address'
                                                                value={userData.permanentAddress}
                                                                onChange={(e) => handleChangeValue('permanentAddress', e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <HStack>
                                                        <FromContainer>
                                                            <FormControl>
                                                                <FormLabel>State</FormLabel>
                                                                <DropdownList placeholder={'select state'}
                                                                    onChange={e => handleChangeValue('permanentState', e.id)}
                                                                    data={allStates ? orderBy(allStates, 'name', 'asc') : []}
                                                                    textField='name'
                                                                    style={{ fontSize: 14 }}
                                                                    dataKey={'id'}
                                                                    value={userData.permanentState}
                                                                />
                                                            </FormControl>
                                                        </FromContainer>
                                                        <FromContainer>
                                                            <FormControl>
                                                                <FormLabel>District</FormLabel>
                                                                <DropdownList placeholder={'select district'}
                                                                    onChange={e => handleChangeValue('permanentDistrict', e.id)}
                                                                    style={{ fontSize: 14 }}
                                                                    value={userData.permanentDistrict}
                                                                    data={userData.permanentState && find(allStates, s => s.id === userData.permanentState)?.cities?.length ?
                                                                        orderBy(find(allStates, s => s.id === userData.permanentState).cities, 'name', 'asc')
                                                                        : []
                                                                    }
                                                                    textField='name'
                                                                    dataKey={'id'}
                                                                />
                                                            </FormControl>
                                                        </FromContainer>
                                                    </HStack>

                                                    <FromContainer>
                                                        <FormControl>
                                                            <FormLabel>Pincode</FormLabel>
                                                            <Input value={userData.permanentPincode} placeholder='pincode' onChange={(e) => handleChangeValue('permanentPincode', e.target.value)} />
                                                        </FormControl>
                                                    </FromContainer>
                                                </VStack>
                                            </Flex>
                                            <Box>
                                                <Button type='submit'>Update</Button>
                                            </Box>
                                        </form>
                                    </TabPanel>
                                    <TabPanel>
                                        <GuardianDetails currentTenant={profileData} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Box py={4}>
                                            <Heading>Tenant Document</Heading>
                                            <Box w='100%' my={5}>
                                                <TenantDocuments tenantData={profileData} />
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                    {hasAccess(staffPermissions.ID_CARD) &&
                                        <TabPanel>
                                            <Box py={4}>
                                                <Heading>Tenant Id Card</Heading>
                                                <Box w='100%' my={5}>
                                                    <TenantIdCard tenantData={profileData} dueDates={dueDates} />
                                                </Box>
                                            </Box>
                                        </TabPanel>}
                                    <TabPanel>
                                        <Box py={4}>
                                            <Heading>Rooms</Heading>
                                            <br />
                                            <TenantHistory tenantData={profileData} />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Box py={4}>
                                            <Heading>In-Out Logs</Heading>
                                            <br />
                                            <TenantInOutLogs tenantData={profileData} />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Box py={4}>
                                            <Heading>Food Logs</Heading>
                                            <br />
                                            <TenantFoodLogs tenantData={profileData} propertyId={propertyid} />
                                        </Box>
                                    </TabPanel>
                                    {hasAccess(staffPermissions.COLLECT_PAYMENT) &&
                                        <TabPanel>
                                            <Box py={4}>
                                                <TenantSlips tenantData={profileData} setDueDates={setDueDates} />
                                            </Box>
                                        </TabPanel>
                                    }
                                </TabPanels>
                            </Tabs>
                        </VStack>
                    </Flex>
                </Stack>
            </CustomContainer>
            {rentModal && <AddTenantRentModal tenant={profileData?.tenant} visible={rentModal} closeModal={handleOpenRentModal} />}
            {assignRoomModal && <AssignRoomModal mutateParentData={mutate} openRentModal={handleOpenRentModal} currentTenant={profileData} visible={assignRoomModal} closeModal={handleAssignRoom} />}
            {dpModal && <UpdateDPModal mutate={mutate} saveImage={d => changeUserData({ type: 'avatar', value: d?.url })} currentTenant={profileData} visible={dpModal} closeModal={handleUpdateDp} />}
        </Box>
    )
}

export const ProfileUnderDetails = ({ title, desc, icon }) => {
    return (
        <Box w={{ base: '100%', lg: '100%' }}>
            <HStack align={{ base: 'center', lg: 'start' }} spacing={2}>
                <Box mt={'5px'} color='defaultColor.500'>
                    <IconButton icon={icon} />
                </Box>
                <Flex align={{ base: 'center', lg: 'start' }} flexDir={{ base: 'row', lg: 'column' }} color={'gray.700'} fontSize={'default'}>
                    <Text as='span' fontWeight={'bold'}>{desc}</Text>
                    <Box px={{ base: 1, lg: 0 }} display={{ base: 'block', lg: 'none' }}>:</Box>
                    <Box mt={{ base: 0, lg: -1 }}>{title}</Box>
                </Flex>
            </HStack>
            {/* <Text display={{ base:'none', lg: 'block' }} color={'gray.600'}>{desc}</Text> */}
        </Box>
    )
}

const FromContainer = ({ children }) => {
    return (
        <Box width={['100%', '100%', '100%', '50%']} pr={8} pb={4}>
            {children}
        </Box>
    )
}
