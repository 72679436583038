import React, { useState, useMemo, useEffect, useCallback } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Table, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { filter, find, map, reduce } from "lodash";
import moment from "moment";
import { URIS } from "../../services/api";
import { useApiRequest } from "../../services/api/useApiRequest";
// import { useTenantProfiles } from "../../services/api/useTenantProfiles";
import { LoadingContainer } from "../../ui/LoadingContainer";
// import MessAttendance from "./messAttendance";
// import MessReport from "./messReport";
import { useGetProperties } from "../../services/api/useGetProperties";
import { useOrganisation } from "../../app/Context";
import { useGetPropertyMess } from "../../services/api/useMessData";
import { CopyFoodMenuModal } from "./CopyFoodMenuModal";

export default function FoodMenu({ currentProperty, keyPrefix, allPropertyMess, mutate }) {

    const propertyId = useMemo(() => currentProperty?.id, [currentProperty])
    // const { data: allTenants } = useTenantProfiles(currentProperty?.id, { propertyId: currentProperty?.id, limit: 500 })
    const [copyMessToProperty, setCopyMessToProperty] = useState(null);
    const [attendanceTime, openAttendanceList] = useState(null)
    const [messReport, openMessReport] = useState(null)

    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [day, setDay] = useState()

    const [foodMenu, openFoodMenu] = useState(null)

    const { request: setupMessRequest, loading: setupMessLoading } = useApiRequest(URIS.ADD_MESS, {
        onCompleted: useCallback((d) => {
            mutate()
            openFoodMenu(d.foodMenu)
        }, [mutate]),
        showAlert: true
    })

    const setUpMess = () => {
        setupMessRequest({
            method: "POST",
            data: {
                propertyId,
                organisationId: currentProperty.organisationId
            }
        })
    }

    useEffect(() => {
        setDay(moment(date, "YYYY-MM-DD").format("dddd") === "Sunday" ? "S"
            : moment(date, "YYYY-MM-DD").format("dddd") === "Monday" ? "M"
                : moment(date, "YYYY-MM-DD").format("dddd") === "Tuesday" ? "T"
                    : moment(date, "YYYY-MM-DD").format("dddd") === "Wednesday" ? "W"
                        : moment(date, "YYYY-MM-DD").format("dddd") === "Thursday" ? "Th"
                            : moment(date, "YYYY-MM-DD").format("dddd") === "Friday" ? "F"
                                : "Sa"
        )
    }, [date])


    const takeAttendance = (meal) => {
        openAttendanceList(meal)
    }

    const closeTakeAttendance = () => {
        openAttendanceList(null)
    }

    const handleFoodMenu = (foodMenu) => {
        openFoodMenu(foodMenu)
    }

    const handleMessReport = (mess) => {
        openMessReport(mess)
    }

    const closeFoodMenu = () => {
        openFoodMenu(null)
        openMessReport(null)
    }

    return (
        <LoadingContainer loading={setupMessLoading}>
            <Box>
                {allPropertyMess && !allPropertyMess.length ?
                    <Flex justifyContent={"center"} mt={15}>
                        <Button onClick={setUpMess} w="150px">Add Mess</Button>
                    </Flex>
                    :
                    map(allPropertyMess, (mess, i) => {
                        const b = find(mess.foodMenu, b => b.meal_type === "B")
                        const l = find(mess.foodMenu, l => l.meal_type === "L")
                        const s = find(mess.foodMenu, s => s.meal_type === "S")
                        const d = find(mess.foodMenu, d => d.meal_type === "D")
                        return (
                            <Box mt={5} key={i}>
                                <HStack justifyContent={"flex-end"}>
                                    <Button onClick={() => setCopyMessToProperty(mess)}>Copy Menu</Button>
                                    <Button onClick={() => handleFoodMenu(mess.foodMenu)}>Edit Food Menu</Button>
                                    <Button onClick={() => handleMessReport(mess)}>Mess Report</Button>
                                </HStack>
                                <Flex key={mess.id} justifyContent={"space-between"} align="center" mt={10}>
                                    <Tooltip label="Prev" placement="top">
                                        <ChevronLeftIcon fontSize={"5xl"} cursor="pointer" onClick={() => setDate(moment(date, "YYYY-MM-DD").add(-1, 'days').format('YYYY-MM-DD'))} />
                                    </Tooltip>
                                    <Box w="60%" border="1px solid #E2E8F0" borderRadius={10} p={5}>
                                        <Flex justifyContent={"center"} borderBottom="1px solid #E2E8F0">
                                            <Text fontSize={"lg"} fontWeight="bold" pb={3}>{moment(date, "YYYY-MM-DD").format("dddd")} {moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")}</Text>
                                        </Flex>
                                        <Box>
                                            {b?.isEnabled ?
                                                <Flex my={5}>
                                                    <Box flex={2}>
                                                        <Text fontWeight={"semibold"} mb={1}>Breakfast</Text>
                                                        <Text flex={2} cursor="default" flexWrap="wrap">{b.meal_content_json?.[day]}</Text>
                                                    </Box>
                                                    <Box align="right" flex={3}>
                                                        <Text fontWeight="semibold" mb={2}>{moment(b.startTime, "HH:mm:ss").format("hh:mm A")}-{moment(b.endTime, "HH:mm:ss").format("hh:mm A")}</Text>
                                                        <Button variant="outline" _focus="none" onClick={() => takeAttendance(b)}>Take Attendance</Button>
                                                    </Box>
                                                </Flex>
                                                :
                                                null
                                            }
                                            {l?.isEnabled ?
                                                <Flex my={5}>
                                                    <Box flex={2}>
                                                        <Text fontWeight={"semibold"} mb={1}>Lunch</Text>
                                                        <Text flex={2} cursor="default" flexWrap="wrap">{l.meal_content_json?.[day]}</Text>
                                                    </Box>
                                                    <Box align="right" flex={3}>
                                                        <Text fontWeight="semibold" mb={2}>{moment(l.startTime, "HH:mm:ss").format("hh:mm A")}-{moment(l.endTime, "HH:mm:ss").format("hh:mm A")}</Text>
                                                        <Button variant="outline" _focus="none" onClick={() => takeAttendance(l)}>Take Attendance</Button>
                                                    </Box>
                                                </Flex>
                                                :
                                                null
                                            }
                                            {s?.isEnabled ?
                                                <Flex my={5}>
                                                    <Box flex={2}>
                                                        <Text fontWeight={"semibold"} mb={1}>Snacks</Text>
                                                        <Text flex={2} cursor="default" flexWrap="wrap">{s.meal_content_json?.[day]}</Text>
                                                    </Box>
                                                    <Box align="right" flex={3}>
                                                        <Text fontWeight="semibold" mb={2}>{moment(s.startTime, "HH:mm:ss").format("hh:mm A")}-{moment(s.endTime, "HH:mm:ss").format("hh:mm A")}</Text>
                                                        <Button variant="outline" _focus="none" onClick={() => takeAttendance(s)}>Take Attendance</Button>
                                                    </Box>
                                                </Flex>
                                                :
                                                null
                                            }
                                            {d?.isEnabled ?
                                                <Flex my={5}>
                                                    <Box flex={2}>
                                                        <Text fontWeight={"semibold"} mb={1}>Dinner</Text>
                                                        <Text flex={2} cursor="default" flexWrap="wrap">{d.meal_content_json?.[day]}</Text>
                                                    </Box>
                                                    <Box align="right" flex={3}>
                                                        <Text fontWeight="semibold" mb={2}>{moment(d.startTime, "HH:mm:ss").format("hh:mm A")}-{moment(d.endTime, "HH:mm:ss").format("hh:mm A")}</Text>
                                                        <Button variant="outline" _focus="none" onClick={() => takeAttendance(d)}>Take Attendance</Button>
                                                    </Box>
                                                </Flex>
                                                :
                                                null
                                            }
                                        </Box>
                                    </Box>
                                    <Tooltip label="Next" placement="top">
                                        <ChevronRightIcon fontSize={"5xl"} cursor="pointer" onClick={() => setDate(moment(date, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD'))} />
                                    </Tooltip>
                                </Flex>
                            </Box>
                        )
                    })}
                {copyMessToProperty &&
                    <CopyMessModal isOpen={copyMessToProperty} onClose={() => setCopyMessToProperty(null)} messData={copyMessToProperty} />
                }
                {foodMenu && <FoodMenu1 foodMenu={foodMenu} mutate={mutate} closeModal={closeFoodMenu} />}
                {/* {attendanceTime && <MessAttendance meal={attendanceTime} date={date} currentProperty={currentProperty} allTenatnts={allTenants?.results || []} closeModal={closeTakeAttendance} />} */}
                {/* {messReport && <MessReport mess={messReport} allTenants={allTenants} currentProperty={currentProperty} date={date} closeModal={closeFoodMenu} />} */}
            </Box >
        </LoadingContainer>
    )
}

const CopyMessModal = ({ isOpen, onClose, messData, }) => {

    const [copyMessProperties, handleChangeCopyProperties] = useState();
    const { data: allProperties } = useGetProperties(true);
    const [successCopyMessProperty, setSuccessCopyMessProperty] = useState(null);


    const { data: allPropertyMess, mutate } = useGetPropertyMess(successCopyMessProperty, { propertyId: successCopyMessProperty })

    const filterProperties = useMemo(() => filter(allProperties, p => messData.propertyId !== p.id), [allProperties, messData])

    const organisation = useOrganisation()
    const { request: copyRequest, loading } = useApiRequest(URIS.COPY_MESS, {
        onCompleted: () => {
            setSuccessCopyMessProperty(copyMessProperties);
        },
        onError: () => { },
        showAlert: true
    })

    const handleCopyMess = useCallback(() => {
        let data = {
            fromMessId: messData.id,
            organisationId: organisation.id,
            toProperties: [{
                propertyId: copyMessProperties,
            }]
        }
        copyRequest({ method: 'post', data });
    }, [messData.id, copyRequest, copyMessProperties, organisation.id])

    useEffect(() => {
        if (successCopyMessProperty) {
            mutate();
            onClose();
        }
    }, [successCopyMessProperty, mutate])

    return (

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Copy Mess To Property</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>
                            <FormLabel>Properties</FormLabel>
                            <Select isRequired value={copyMessProperties} onChange={e => handleChangeCopyProperties(e.target.value)}>
                                <option value=''>select</option>
                                {
                                    map(filterProperties, p => <option key={p.id} value={p.id}>{p.name}</option>)
                                }
                            </Select>
                        </FormLabel>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button isLoading={loading} onClick={handleCopyMess}>Copy Mess</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const FoodMenu1 = ({ messData, mutate, allMessFoodMenuData, allMessData }) => {

    const { isOpen: copyMessFoodMenuModalIsOpen, onOpen: copyMessFoodMenuModalOnOpen, onClose: copyMessFoodMenuModalOnClose } = useDisclosure()

    const menu = reduce(allMessFoodMenuData, (obj, meal) => {
        obj[meal.meal_type + "_id"] = meal.id
        obj[meal.meal_type + "_startTime"] = meal.startTime
        obj[meal.meal_type + "_endTime"] = meal.endTime
        obj[meal.meal_type + "_isEnabled"] = meal.isEnabled
        map(meal.meal_content_json, (mealContent, key) => {
            obj[meal.meal_type + "_" + key] = mealContent;
        }
        )
        return obj;
    }, {})

    const { request: foodMenuRequest, loading: setupMessLoading } = useApiRequest(URIS.UPDATE_FOOD_MENU, {
        onCompleted: useCallback(() => {
            mutate()
        }, [mutate]),
        showAlert: false,
        showLoader:true
    })

    const [input, setInput] = useState(menu)

    useEffect(() => {
        setInput(menu)
    }, [allMessFoodMenuData])

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'B_isEnabled') {
            setInput((preval) => ({ ...preval, [name]: e.target.checked ? 1 : 0 }))
        }
        else if (name === 'L_isEnabled') {
            setInput((preval) => ({ ...preval, [name]: e.target.checked ? 1 : 0 }))
        }
        else if (name === 'S_isEnabled') {
            setInput((preval) => ({ ...preval, [name]: e.target.checked ? 1 : 0 }))
        }
        else if (name === 'D_isEnabled') {
            setInput((preval) => ({ ...preval, [name]: e.target.checked ? 1 : 0 }))
        }
        else {
            setInput((preval) => ({ ...preval, [name]: value }))
        }
    }

    const saveFoodMenu = () => {
        foodMenuRequest({
            method: "PATCH",
            data: {
                id: input.B_id,
                isEnabled: input.B_isEnabled || 0,
                startTime: input.B_startTime,
                endTime: input.B_endTime,
                attStartTime: input.B_startTime,
                attEndTime: input.B_endTime,
                meal_content_json: {
                    M: input.B_M,
                    T: input.B_T,
                    W: input.B_W,
                    Th: input.B_Th,
                    F: input.B_F,
                    Sa: input.B_Sa,
                    S: input.B_S
                }
            }
        })
        foodMenuRequest({
            method: "PATCH",
            data: {
                id: input.L_id,
                isEnabled: input.L_isEnabled || 0,
                startTime: input.L_startTime,
                endTime: input.L_endTime,
                attStartTime: input.L_startTime,
                attEndTime: input.L_endTime,
                meal_content_json: {
                    M: input.L_M,
                    T: input.L_T,
                    W: input.L_W,
                    Th: input.L_Th,
                    F: input.L_F,
                    Sa: input.L_Sa,
                    S: input.L_S
                }
            }
        })
        foodMenuRequest({
            method: "PATCH",
            data: {
                id: input.S_id,
                isEnabled: input.S_isEnabled || 0,
                startTime: input.S_startTime,
                endTime: input.S_endTime,
                attStartTime: input.S_startTime,
                attEndTime: input.S_endTime,
                meal_content_json: {
                    M: input.S_M,
                    T: input.S_T,
                    W: input.S_W,
                    Th: input.S_Th,
                    F: input.S_F,
                    Sa: input.S_Sa,
                    S: input.S_S
                }
            }
        })
        foodMenuRequest({
            method: "PATCH",
            data: {
                id: input.D_id,
                isEnabled: input.D_isEnabled || 0,
                startTime: input.D_startTime,
                endTime: input.D_endTime,
                attStartTime: input.D_startTime,
                attEndTime: input.D_endTime,
                meal_content_json: {
                    M: input.D_M,
                    T: input.D_T,
                    W: input.D_W,
                    Th: input.D_Th,
                    F: input.D_F,
                    Sa: input.D_Sa,
                    S: input.D_S
                }
            }
        })
    }

    const copyFoodMenuHandler = () => {
        copyMessFoodMenuModalOnOpen()
    }

    return (
        <Box>
            <HStack mx={5} justifyContent={"flex-end"}>
                <Button onClick={copyFoodMenuHandler} >Copy Menu</Button>
                {/* <Button>Mess Report</Button> */}
            </HStack>

            <Box p={5}>
                <Table variant={"simple"}>
                    <Thead bgColor="gray.200">
                        <Tr>
                            <Th>Food Menu</Th>
                            <Th>Monday</Th>
                            <Th>Tuesday</Th>
                            <Th>Wednesday</Th>
                            <Th>Thursday</Th>
                            <Th>Friday</Th>
                            <Th>Saturday</Th>
                            <Th>Sunday</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Switch name="B_isEnabled" onChange={inputHandler} isChecked={input.B_isEnabled === 1 ? true : false} colorScheme={"green"} />
                                <Box mt={3}>
                                    <Flex>Breakfast<br /></Flex>
                                    <Input type="time" name="B_startTime" value={input.B_startTime} onChange={inputHandler} variant={"flushed"} />
                                    <Input type="time" name="B_endTime" value={input.B_endTime} onChange={inputHandler} variant={"flushed"} />
                                </Box>
                            </Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_M" value={input.B_M} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_T" value={input.B_T} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_W" value={input.B_W} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_Th" value={input.B_Th} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_F" value={input.B_F} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_Sa" value={input.B_Sa} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="B_S" value={input.B_S} onChange={inputHandler} /></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Switch name="L_isEnabled" onChange={inputHandler} isChecked={input.L_isEnabled === 1 ? true : false} colorScheme={"green"} />
                                <Box mt={3}>
                                    <Flex>Lunch<br /></Flex>
                                    <Input type="time" name="L_startTime" value={input.L_startTime} onChange={inputHandler} variant={"flushed"} />
                                    <Input type="time" name="L_endTime" value={input.L_endTime} onChange={inputHandler} variant={"flushed"} />
                                </Box>
                            </Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_M" value={input.L_M} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_T" value={input.L_T} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_W" value={input.L_W} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_Th" value={input.L_Th} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_F" value={input.L_F} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_Sa" value={input.L_Sa} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="L_S" value={input.L_S} onChange={inputHandler} /></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Switch name="S_isEnabled" onChange={inputHandler} isChecked={input.S_isEnabled === 1 ? true : false} colorScheme={"green"} />
                                <Box mt={3}>
                                    <Flex>Snacks<br /></Flex>
                                    <Input type="time" name="S_startTime" value={input.S_startTime} onChange={inputHandler} variant={"flushed"} />
                                    <Input type="time" name="S_endTime" value={input.S_endTime} onChange={inputHandler} variant={"flushed"} />
                                </Box>
                            </Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_M" value={input.S_M} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_T" value={input.S_T} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_W" value={input.S_W} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_Th" value={input.S_Th} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_F" value={input.S_F} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_Sa" value={input.S_Sa} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="S_S" value={input.S_S} onChange={inputHandler} /></Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Switch name="D_isEnabled" onChange={inputHandler} isChecked={input.D_isEnabled === 1 ? true : false} colorScheme={"green"} />
                                <Box mt={3}>
                                    <Flex>Dinner<br /></Flex>
                                    <Input type="time" name="D_startTime" value={input.D_startTime} onChange={inputHandler} variant={"flushed"} />
                                    <Input type="time" name="D_endTime" value={input.D_endTime} onChange={inputHandler} variant={"flushed"} />
                                </Box>
                            </Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_M" value={input.D_M} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_T" value={input.D_T} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_W" value={input.D_W} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_Th" value={input.D_Th} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_F" value={input.D_F} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_Sa" value={input.D_Sa} onChange={inputHandler} /></Td>
                            <Td><Textarea variant="flushed" overflow={"hidden"} rows="6" name="D_S" value={input.D_S} onChange={inputHandler} /></Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Flex justifyContent={"end"} mt={3}>
                    <Button type="submit" isLoading={setupMessLoading} onClick={saveFoodMenu}>Save</Button>
                </Flex>
            </Box>
            <CopyFoodMenuModal isOpen={copyMessFoodMenuModalIsOpen} onClose={copyMessFoodMenuModalOnClose} messData={messData} allMessData={allMessData} mutate={mutate} allMessFoodMenuData={allMessFoodMenuData} />
        </Box>

    )
}