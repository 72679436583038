import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { ActionHeader } from "../../ui/ActionHeader";
import { useState } from "react";
import AddReasonModal from "./AddReasonModal";
import {
  AddIcon,
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { CustomContainer } from "../../ui/CustomContainer";
import { useTenantOutReasonsData } from "../../services/api/useTenantOutReasonsData";
import { useUserData } from "../../services/api/useUserData";
import { URIS } from "../../services/api";
import { LoadingContainer } from "../../ui/LoadingContainer";
import { EmptyPage } from "../../ui/EmptyPage";
import { useEffect } from "react";
import _ from "lodash";
import { useApiRequest } from "../../services/api/useApiRequest";

export default ({}) => {
  const [reasonList, setReasonList] = useState();
  const [addModal, openAddModal] = useState();
  const [editModal, openEditModal] = useState();
  const [deleteAlert, openDeleteAlert] = useState();
  const [deleted, setDeleted] = useState("");
  const cancelRef = useRef();

  const handleAddModal = () => {
    openAddModal((d) => !d);
  };

  const handleAlert = () => {
    openDeleteAlert((d) => !d);
  };
  const { organisation } = useUserData(true);

  const {
    data: allReasons,
    loading,
    mutate,
  } = useTenantOutReasonsData(
    URIS.GET_TENANT_OUT_REASONS,
    { organisationId: organisation.id },
    true
  );
  const resetReason = () => {
    mutate();
  };

  const { request: deleteReasonReaction } = useApiRequest(
    URIS.UPDATE_TENANT_OUT_REASON,
    {
      onCompleted: (d) => {
        resetReason();
      },
      showAlert: true,
      showLoader: true,
    }
  );

  useEffect(() => {
    setReasonList(allReasons);
  }, [allReasons]);

  const handleUpdateModal = (staff) => {
    openEditModal((d) => (d ? null : staff));
  };

  const handleFilter = (e) => {
    e.preventDefault();
  };
  const handleSearchChange = (e) => {
    let data = [...allReasons];
    const text = e.target.value;
    data = _.filter(data, (d) =>
      _.includes(_.lowerCase(d.reason), _.lowerCase(text))
    );
    setReasonList(data);
  };

  const handleDelete = (id) => {
    deleteReasonReaction({
      method: "DELETE",
      params: { id: deleted },
    });
    handleAlert()
  };
  return (
    <Box>
      <ActionHeader title={"Tenant Out Reasons"}>
        <HStack>
          <Button leftIcon={<AddIcon />} onClick={handleAddModal}>
            Add Reason
          </Button>
        </HStack>
      </ActionHeader>
      <br />
      <CustomContainer>
        <Box p={4}>
          <form onSubmit={handleFilter}>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Box width={"50%"} pr={2}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input
                    onChange={handleSearchChange}
                    placeholder="Search Name"
                  />
                </InputGroup>
              </Box>
              <IconButton
                type="submit"
                colorScheme={"gray"}
                borderRadius={0}
                icon={<SearchIcon />}
              />
            </Flex>
          </form>
        </Box>
        <Box>
          <LoadingContainer loading={loading}>
            {reasonList?.length ? (
              <Box>
                <br />
                <Box minWidth={200} overflow="auto">
                  <Table
                    className="responsiveTable"
                    variant={"simple"}
                    width={"80%"}
                  >
                    <Thead>
                      <Tr>
                        <Th w={"10%"}>Sr.NO.</Th>
                        <Th w={"50%"} alignContent={"center"}>
                          Reason
                        </Th>
                        <Th w={"20%"}>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {reasonList.map((reason, i) => (
                        <SingleReason
                          key={reason.id}
                          handleUpdateModal={handleUpdateModal}
                          reason={reason}
                          i={i}
                          handleAlert={handleAlert}
                          setDeleted={(id) => setDeleted(id)}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <br />
              </Box>
            ) : (
              <EmptyPage message="No Reason available" />
            )}
          </LoadingContainer>
        </Box>
      </CustomContainer>
      {deleteAlert && (
        <AlertDialog
          isOpen={deleteAlert}
          leastDestructiveRef={cancelRef}
          onClose={handleAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Are you sure you want to delete?
              </AlertDialogHeader>

              <AlertDialogFooter>
                <HStack>
                  <Button
                    colorScheme={"gray"}
                    ref={cancelRef}
                    onClick={handleAlert}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleDelete}>Confirm</Button>
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {addModal && (
        <AddReasonModal
          visible={addModal}
          closeModal={handleAddModal}
          resetReason={resetReason}
        />
      )}
      {editModal && (
        <AddReasonModal
          defaultData={editModal.id ? editModal : null}
          resetReason={resetReason}
          visible={editModal}
          closeModal={handleUpdateModal}
        />
      )}
    </Box>
  );
};

const SingleReason = ({
  reason,
  i,
  key,
  handleUpdateModal,
  handleAlert,
  setDeleted,
}) => {
  const _onDelete = (id) => {
    setDeleted(id);
    handleAlert();
  };
  return (
    <>
      {reason?.length !== 0 ? (
        <Tr key={key}>
          <Td>{++i}</Td>
          <Td>{reason?.reason}</Td>
          <Td>
            <Menu>
              <MenuButton
                as={Button}
                aria-label="Options"
                rightIcon={<ChevronDownIcon fontSize={20} />}
                variant="ghost"
              >
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  onClick={() => handleUpdateModal(reason)}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon />}
                  onClick={() => _onDelete(reason?.id)}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </Tr>
      ) : (
        <EmptyPage />
      )}
    </>
  );
};
