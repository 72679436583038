import React, { useState } from 'react'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { applicable_types, installment_types } from '../../Constants'
import { useDueCategories } from '../../services/api/useDueCategories'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { AddCategoryModal } from './AddCategoryModal'

export const OrganisationRentCategories = () => {

    const [addCategoryModal, openAddModal] = useState()
    const {data:allCategories, loading} = useDueCategories(true)

    const handleOpenAddModal = () => {
        openAddModal(d => !d)
    }

    return(
        <Box>
            <ActionHeader title='Payment Categories'>
                <Button leftIcon={<AddIcon/>} onClick={handleOpenAddModal}>Add Category</Button>
            </ActionHeader>
            <br/>
            <CustomContainer loading={loading}>
                <Box minH={300}>
                    {allCategories?.length ? 
                        <Table className='responsiveTable' variant={'simple'}>
                            <Thead>
                                <Tr>
                                    <Th>Category</Th>
                                    <Th>Installment Type</Th>
                                    <Th>Applicable On</Th>
                                    <Th>Bill Apply Day from 1st Day</Th>
                                    <Th>Bill Due Day from 1st Day</Th>
                                    <Th>CGST</Th>
                                    <Th>SGST</Th>
                                    <Th>IGST</Th>
                                    <Th>HSN/SAC</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {allCategories.map(category => {
                                    return (
                                        <SingleCategory key={category.id} category={category}/>
                                    )
                                })}
                            </Tbody>
                        </Table>
                        :
                        null
                    }
                    {addCategoryModal && <AddCategoryModal visible={addCategoryModal} closeModal={handleOpenAddModal}/>}
                </Box>
            </CustomContainer>
        </Box>
    )
}

const SingleCategory = ({category}) => {
    const [editModal, openEditModal] = useState()

    const handleEditModal = (data) => {
        openEditModal(d => d ? null : data)
    }

    const monthly = category.installmentType !== 'OT' 
    return(
        <>
        <Tr key={category.id}>
            <Td>{category.name}</Td>
            <Td>{installment_types[category.installmentType]?.title}</Td>
            <Td>{applicable_types[category.applicableType]?.title}</Td>
            <Td>{monthly && category.apply_from_from_day}</Td>
            <Td>{monthly && category.due_from_from_day}</Td>
            <Td>{ category.cgst || 0 }%</Td>
            <Td>{ category.sgst || 0 }%</Td>
            <Td>{ category.igst || 0 }%</Td>
            <Td>{ category.hsn || '' }</Td>
            <Td>
                <HStack>
                    <IconButton icon={<EditIcon/>} variant='ghost' onClick={() => handleEditModal(category)} />
                </HStack>
            </Td>
        </Tr>
        {editModal && <AddCategoryModal defaultData={editModal.id ? editModal : null} visible={editModal} closeModal={handleEditModal}/>}
        </>
    )
}