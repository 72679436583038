import React from 'react'
import { Box, Flex, Spinner, Text, VStack } from '@chakra-ui/react'
export const LoadingContainer = ({ loading = false, children, size, height }) => {
    return (
        <Box pos='relative' minH={height || 300}>
            {loading ?
                <Flex pos={'absolute'} top={0} left={0} right={0} bottom={0} justify='center' align={'center'}>
                    <VStack spacing={4}>
                        <Spinner size={size || 'lg'} />
                        <Text>Fetching...</Text>
                    </VStack>
                </Flex>
                :
                <Box>
                    {children}
                </Box>
            }
        </Box>
    )
}