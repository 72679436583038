import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useAttendanceRecords(q, params, revalidate) {

    const {data, error, loading, mutate} = useGetData(URIS.GET_ATTENDENCE_RECORDS+q, () => apis.getAttendanceRecordsApi(params), q, revalidate)

    return {
        data, error, loading, mutate
    }
}