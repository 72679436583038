import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, HStack, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, VStack, } from '@chakra-ui/react'
import _, { filter, isEmpty, map } from 'lodash'
import moment from 'moment'
import { DatePicker } from 'react-widgets/cjs'
import { INSTALLMENT_TYPE, installment_types, PAYMENT_STATUS, staffPermissions } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { usePropertyDueCategories } from '../../services/api/usePropertyDueCategories'
import { useSingleProperty } from '../../services/api/useSingleProperty'
import { useTenantProfiles } from '../../services/api/useTenantProfiles'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { useTenantWallet } from '../../services/api/useTenantWallet'
import { useUserData } from '../../services/api/useUserData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { useAppContext } from '../../app/Context'

const SERVER_DATE_FORMAT = "YYYY-MM-DD"

export const AddTenantRentModal = ({ visible, closeModal, tenantProfile, tenant }) => {
    const navigate = useNavigate()
    const { hasAccess } = useAppContext()

    const [tenantData, changeTenant] = useState({})
    const [rentCategories, setCategories] = useState({})
    const [extraDue, changeExtraDue] = useState({ apply: false })
    const [advancePayment, changeAdvancePayment] = useState({ apply: false })
    const [alertStatus, openAlert] = useState()

    const tenantUnit = tenantData?.unitTenants?.[0] || tenantData.unit
    const propertyId = tenantUnit?.unit.propertyId;
    const dueCategoryKey = tenantUnit ? tenantData.id + tenantUnit.id : false

    const { data: propertyDueCategories, loading: loadingPropertyCategories } = usePropertyDueCategories(dueCategoryKey,
        tenantUnit?.unit &&
        {
            propertyId: tenantUnit.unit.propertyId,
            sharing_type: tenantUnit.unit.sharing,
            unitRoomTypeId: tenantUnit.unit.unitRoomTypeId
        },
        true
    )

    const { organisation } = useUserData(true)
    const { mutate: mutateTenantDues, loading: loadingTenantSlips } = useTenantSlips(tenantData?.id, { tenantId: tenantData?.tenantId, organisationId: organisation.id })
    const { data: tenantsList } = useTenantProfiles("tenant" + tenant?.id, { tenantId: tenant?.id, organisationId: organisation.id }, true)
    const { data: tenantWallets, loading: loadingTenantWallet, mutate: mutateWallet } = useTenantWallet(tenantData?.id, { userId: tenantData.tenant?.id }, true, {
        onSuccess: tenantWallet => {
            if (!tenantWallet.data?.length)
                addWalletAction({ method: 'POST', data: { amount: 0, userId: tenantData?.tenant.id } })
        }
    })
    const { data: tenantProperty, loading: loadingProperty } = useSingleProperty(propertyId, { id: propertyId }, true)
    const tenantWallet = tenantWallets?.[0]

    const { request: addWalletAction } = useApiRequest(URIS.ADD_WALLET, {
        onCompleted: () => {
            mutateWallet()
        },
        showLoader: true
    })

    const { request: addTransaction, loading } = useApiRequest(URIS.ADD_TRANSACTION, {
        onCompleted: d => {
            mutateTenantDues()
            closeModal()
        },
        showAlert: true
    })

    const { request: addTenantDueAction, loading: loadingAddTenantDue } = useApiRequest(URIS.ADD_TENANT_DUE, {
        onCompleted: tenantDues => {

            if (advancePayment.apply) {
                const data = { type: 'credit', status: 'pending', walletId: tenantWallet.id, ..._.omit(advancePayment, 'apply') }
                addTransaction({ method: 'POST', data })
            } else {
                mutateTenantDues()
                closeModal()
            }

            if (tenantDues?.[0]?.unitTenant.tenant)
                navigate(`/tenant/profile/${tenantDues[0].unitTenant.tenant.id}/6`)
        },
        showAlert: true,
        showLoader: true,
    })

    const billingCycleFrom = useCallback((joiningDate) => {
        if (tenantProperty && joiningDate) {
            const isSameOrAfter = moment(joiningDate).isSameOrAfter(moment(), 'date')
            const isMonthSame = moment(joiningDate).month() === moment().month()

            const billing_cycle_from = tenantProperty?.meta_data_json?.billingCycleStartDay === 'jd' ?
                isSameOrAfter ?
                    moment(joiningDate).format(SERVER_DATE_FORMAT)
                    :
                    isMonthSame ?
                        moment(joiningDate).set('month', moment().month()).add(1, "month").format(SERVER_DATE_FORMAT)
                        : moment(joiningDate).set('month', moment().month()).format(SERVER_DATE_FORMAT)
                :
                moment().add(1, 'M').startOf('month').format(SERVER_DATE_FORMAT)
            return billing_cycle_from
        }
        return moment().add(1, 'M').startOf('month')
    }, [tenantProperty])

    useEffect(() => {
        if (propertyDueCategories?.length) {
            let tenantCategories = {}
            const allCategories = [...propertyDueCategories]
            let data = [...filter(allCategories, d => d.isEnabled)]

            const joiningDate = tenantUnit?.joiningDate
            const billing_cycle_from = billingCycleFrom(joiningDate)
            data.forEach(d => {
                const orgDue = d.dueCategory
                tenantCategories = {
                    ...tenantCategories,
                    [d.id]: {
                        next_amount: d?.amount,
                        name: orgDue.name,
                        installmentType: orgDue.installmentType,
                        billing_cycle_from: moment(billing_cycle_from).format(SERVER_DATE_FORMAT),
                        apply_from_from_day: orgDue.apply_from_from_day,
                        due_from_from_day: orgDue.due_from_from_day,
                        propertyDueCategoryId: d.id,
                        next_slip: d.installmentType === INSTALLMENT_TYPE.ONE_TIME ? false : true,
                        dueCategory: orgDue,
                        apply: true
                    }
                }
            })

            setCategories({ ...tenantCategories })
        }
    }, [billingCycleFrom, propertyDueCategories, tenantUnit?.joiningDate])


    useEffect(() => {
        if (tenantProfile)
            changeTenant(tenantProfile)
        else if (tenant && tenantsList?.results[0]) {
            changeTenant(tenantsList.results[0])
        }
    }, [tenantProfile, tenant, tenantsList])

    const handleAddDue = (type, value) => {
        let obj = { ...extraDue }
        obj = { ...obj, [type]: value }
        changeExtraDue(obj)
    }

    const handleAdvancePayment = (type, value) => {
        let obj = { ...advancePayment }
        obj = { ...obj, [type]: value }
        changeAdvancePayment(obj)
    }

    const handleOpenCategories = () => {
        navigate(`/properties/${propertyId}/2`)
        closeModal()
    }

    const handleOpenAlert = () => {
        openAlert(d => !d)
    }

    const handleChange = (type, value, category) => {
        let obj = { ...rentCategories }

        if (category.refundable) {
            obj[category.propertyDueCategoryId].paid_amount = 0
        }
        obj[category.propertyDueCategoryId][type] = value
        setCategories(obj)
    }

    const handleSave = () => {
        let finalData = [..._.map(_.filter(rentCategories, r => r.apply), d => {
            const billing_cycle_from = d.billing_cycle_from
            const generate = moment(moment().format(SERVER_DATE_FORMAT)).isSameOrAfter(billing_cycle_from, 'day', billing_cycle_from)

            const next_billing_cycle_from = generate ? moment(d.billing_cycle_from).add(1, 'M').format(SERVER_DATE_FORMAT) : moment(d.billing_cycle_from).format(SERVER_DATE_FORMAT)
            const next_billing_cycle_to = moment(next_billing_cycle_from, SERVER_DATE_FORMAT).add('months', 1).subtract('days', 1).format(SERVER_DATE_FORMAT)
            const next_due_date = next_billing_cycle_from ? moment(next_billing_cycle_from).add('day', parseInt(d.due_from_from_day)).format(SERVER_DATE_FORMAT) : null
            const next_apply_date = next_billing_cycle_from ? moment(next_billing_cycle_from).add('day', parseInt(d.apply_from_from_day)).format(SERVER_DATE_FORMAT) : null
            const next_amount = d.next_amount ? parseInt(d.next_amount) : 0

            const due_date = billing_cycle_from ? moment(billing_cycle_from).add('day', parseInt(d.due_from_from_day)).format(SERVER_DATE_FORMAT) : null
            const apply_date = billing_cycle_from ? moment(billing_cycle_from).add('day', parseInt(d.apply_from_from_day)).format(SERVER_DATE_FORMAT) : null
            const amount = d.next_amount ? parseInt(d.next_amount) : 0
            const to_date = moment(apply_date).add('months', 1).subtract('days', 1).format(SERVER_DATE_FORMAT)

            const obj = d.dueCategory.installmentType === INSTALLMENT_TYPE.ONE_TIME ?
                {
                    amount,
                    apply_date: moment().format(SERVER_DATE_FORMAT),
                    due_date: moment().format(SERVER_DATE_FORMAT),
                    status: PAYMENT_STATUS.GENERATED,
                    next_slip: false,
                }
                : moment(moment().format(SERVER_DATE_FORMAT)).isSameOrAfter(billing_cycle_from, 'day') ?
                    {
                        next_amount,
                        next_apply_date,
                        next_due_date,
                        next_billing_cycle_from,
                        from_date: apply_date,
                        to_date,
                        next_billing_cycle_to,
                        amount,
                        apply_date,
                        due_date,
                        status: PAYMENT_STATUS.GENERATED,
                        next_slip: true,
                    }
                    :
                    {
                        next_amount,
                        next_apply_date,
                        next_due_date,
                        next_billing_cycle_from,
                        next_billing_cycle_to,
                        next_slip: true,
                    }
            return { ...obj, unit_tenantId: tenantUnit.id, propertyId: propertyId, meta_data_json: { installmentType: d.installmentType }, property_due_categoryId: d.propertyDueCategoryId, }

        })]

        if (extraDue.apply) {
            let extra = {
                amount: parseInt(extraDue.amount || 0),
                due_date: moment().format(SERVER_DATE_FORMAT),
                apply_date: moment().format(SERVER_DATE_FORMAT),
                next_slip: false,
                status: PAYMENT_STATUS.GENERATED,
                remark: extraDue.remark,
                propertyId: propertyId,
                unit_tenantId: tenantUnit.id,
                meta_data_json: {
                    installmentType: INSTALLMENT_TYPE.ONE_TIME, name: 'Previous Due'
                }
            }

            finalData = [...finalData, extra]
        }
        addTenantDueAction({ method: 'POST', data: finalData })
    }

    return (
        <Modal size={'6xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Payment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <LoadingContainer loading={loadingTenantSlips || loadingPropertyCategories || loadingTenantWallet || loadingProperty}>
                        {!isEmpty(rentCategories) ?
                            <>
                                <br />
                                <Box>
                                    <Table className='responsiveTable' variant={'simple'}>
                                        <Thead>
                                            <Tr>
                                                <Th>Applicable Categories</Th>
                                                <Th>Amount to Pay (₹)</Th>
                                                <Th>Billing Start Day</Th>
                                                <Th>Apply</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {map(_.filter(rentCategories, d => !d.refundable), category => {
                                                const isDisabled = category.installmentType === INSTALLMENT_TYPE.MONTHLY;
                                                return (
                                                    <Tr key={category.propertyDueCategoryId + category.installmentType}>
                                                        <Td>
                                                            <Box>
                                                                <Text>{category.name}</Text>
                                                                <Text fontStyle={"italic"} fontSize="xs" color="red.400">{installment_types[category.installmentType]?.title}</Text>
                                                            </Box>
                                                        </Td>
                                                        <Td>
                                                            <InputGroup>
                                                                <InputLeftElement
                                                                    pointerEvents='none'
                                                                    children={'₹'}
                                                                />
                                                                <Input
                                                                    autoFocus bg={'blackAlpha.50'} type='number' value={category.next_amount}
                                                                    onChange={e => handleChange('next_amount', e.target.value, category)}
                                                                    readOnly={!hasAccess(staffPermissions.EDIT_CATEGORY_DUE_AMOUNT)}
                                                                />
                                                            </InputGroup>
                                                        </Td>
                                                        <Td>
                                                            {category.installmentType === INSTALLMENT_TYPE.ONE_TIME ? 'One Time' :
                                                                <DatePicker
                                                                    onChange={e => handleChange('billing_cycle_from', moment(e).format(SERVER_DATE_FORMAT), category)}
                                                                    min={moment().subtract(1, 'M').add(1, 'day')}
                                                                    style={{ fontSize: 14 }}
                                                                    value={category.billing_cycle_from ? new Date(category.billing_cycle_from) : null}
                                                                />
                                                            }
                                                        </Td>
                                                        <Td>
                                                            <Switch isDisabled={isDisabled} isChecked={category.apply} onChange={(e) => handleChange('apply', e.target.checked, category)} fontSize={'small'}>

                                                            </Switch>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                    <br />
                                    <Flex wrap={'wrap'} align={'start'} spacing={8}>
                                        <VStack flex={{ base: '', md: 1 }} align={'start'} mb={2} pr={4} spacing={2} justify='space-between'>
                                            <Switch isChecked={extraDue.apply} onChange={(e) => handleAddDue('apply', !extraDue.apply)} fontSize={'small'}>
                                                Previous Due
                                            </Switch>
                                            <Text color={'secondary'} >* till {moment(_.find(rentCategories, d => d.name === 'Rent')?.billing_cycle_from).format("DD MMM, YYYY")}</Text>
                                        </VStack>
                                        <VStack flex={{ base: '', md: 1 }} pr={2} mb={2} align={'start'}>
                                            <Text>Amount to Pay</Text>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={'₹'}
                                                />
                                                <Input
                                                    disabled={!extraDue.apply}
                                                    onChange={(e) => handleAddDue('amount', e.target.value)}
                                                    value={extraDue.amount}
                                                    type='number'
                                                    placeholder={'amount'}
                                                />
                                            </InputGroup>
                                        </VStack>
                                        <VStack flex={{ base: '', md: 2 }} mb={2} align={'start'}>
                                            <Text>Remark</Text>
                                            <Input disabled={!extraDue.apply} type='text' placeholder='remark' onChange={(e) => handleAddDue('remark', e.target.value)} />
                                        </VStack>
                                    </Flex>
                                    <br />
                                </Box>
                                <Box>

                                </Box>
                            </>
                            :
                            !propertyDueCategories ?
                                <LoadingContainer loading={true} /> :
                                <Alert>
                                    <HStack>
                                        <Text>Enable property's payment categories to add tenant payment configurations. </Text>
                                        <Button onClick={handleOpenCategories}>Payment Categories</Button>
                                    </HStack>
                                </Alert>
                        }
                        <ConfirmAlert
                            alertStatus={alertStatus}
                            advancePayment={advancePayment}
                            extraDue={extraDue}
                            rentCategories={rentCategories}
                            handleSave={handleSave}
                            closeAlert={handleOpenAlert}
                        />
                    </LoadingContainer>
                </ModalBody>
                <ModalFooter>
                    {!isEmpty(rentCategories) &&
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={loading || loadingAddTenantDue} onClick={handleSave}>Save</Button>
                        </HStack>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const ConfirmAlert = ({ alertStatus, closeAlert, advancePayment, extraDue, rentCategories, handleSave }) => {
    const cancelRef = useRef()

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={closeAlert}
            isOpen={alertStatus}
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>Confirm</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    <Flex align={'stretch'} wrap={'wrap'}>
                        {_.map(rentCategories, (cat, i) => {
                            return (
                                <HStack align={'stretch'} key={i} pr={4} pb={4}>
                                    <VStack align={'start'} border={'1px solid'} borderColor='blackAlpha.200' p={4} key={i} borderRadius={8}>
                                        <Text fontWeight={'bold'}>{cat.name}</Text>
                                        <HStack>
                                            <Text color={'secondary'}>Amount :</Text>
                                            <Text>{cat.next_amount}</Text>
                                        </HStack>
                                        {cat.dueCategory.installmentType === INSTALLMENT_TYPE.ONE_TIME ?
                                            <>
                                                <HStack>
                                                    <Text color={'secondary'}>Apply Date :</Text>
                                                    <Text>{moment().format('LL')}</Text>
                                                </HStack>
                                                <HStack>
                                                    <Text color={'secondary'}>Due Date :</Text>
                                                    <Text>{moment().format('LL')}</Text>
                                                </HStack>
                                            </>
                                            :
                                            <>
                                                <HStack>
                                                    <Text color={'secondary'}>Billing Date :</Text>
                                                    <Text>{moment(cat.billing_cycle_from).format('LL')}</Text>
                                                </HStack>
                                                <HStack>
                                                    <Text color={'secondary'}>Apply Date :</Text>
                                                    <Text>{moment(cat.billing_cycle_from).add('day', cat.apply_from_from_day).format('LL')}</Text>
                                                </HStack>
                                                <HStack>
                                                    <Text color={'secondary'}>Due Date :</Text>
                                                    <Text>{moment(cat.billing_cycle_from).add('day', cat.due_from_from_day).format('LL')}</Text>
                                                </HStack>
                                            </>
                                        }
                                    </VStack>
                                </HStack>
                            )
                        })}
                    </Flex>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button colorScheme={'gray'} ref={cancelRef} onClick={closeAlert}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} colorScheme={'green'} ml={3}>
                        Confirm and Save
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}