import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Select, Text, VStack } from '@chakra-ui/react'
import { filter, find, map } from 'lodash'
import { useDueCategories } from '../../services/api/useDueCategories'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { DueCategory } from './TenantSlips'
import { PAYMENT_STATUS } from '../../Constants'

export const FeesSummary = ({tenantData,tenantProperty}) => {
    const [dueCategories, setDueCategories] = useState([])
    const {data:tenantDues} = useTenantSlips(tenantData?.id)
    const {data:orgDueCategories} = useDueCategories(true);
    const [ filterBy, setFilterBy ] = useState();
    const FILTER_STATUS = filter(map(PAYMENT_STATUS, (s, key) =>
    {  
       return key !== 'PENDING' &&  ({ name: key, value : s }) 
    }
    ))
    useEffect(() => {
        if(tenantDues && orgDueCategories?.length){
            let data  = tenantDues?.length ? 
                tenantDues.map(category => {
                    const dueCategory = find(orgDueCategories,d => d.id === category.propertyDueCategory?.dueCategoryId)
                    const paidAmount = category.paid_amount || 0
                    return {...category, paidAmount, dueCategory}
                }) : [];
            data = filter(data, d => (!filterBy || d.status === filterBy) && d.status )
            setDueCategories(data)
        }
    }, [orgDueCategories, tenantDues, filterBy])

    return(
        <Box> 
           <Flex justify='space-between'>
            <Text fontWeight={'bold'} fontSize='default' color='secondary' >Fees Summary</Text>
            <Box>
                    <Select value={filterBy} onChange={ e => setFilterBy(e.target.value) } variant='filled'>
                        <option value=''>All</option>
                        {
                            map(FILTER_STATUS, (f) => <option key={f.name} value={f.value}>{f.name}</option> )
                        }
                    </Select>
                </Box>
            </Flex>   
            {dueCategories?.length ?
                <VStack align={'stretch'} mt={2} spacing={4}>
                    { 
                        dueCategories.map(category => {
                            return(
                                <DueCategory hideMenu={true} category={category} tenantData={tenantData} tenantPropertyId={tenantProperty?.id} key={category.id}/>
                            )
                        })
                    }
                </VStack>
                :
                <Box py={4}>
                    <Text color={'secondary'}>No fees history</Text>
                </Box>
            }
        </Box>
    )
}