import React, { useState } from 'react'
import { Step, Steps } from 'chakra-ui-steps'
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

import { LoadingContainer } from '../../ui/LoadingContainer'
import { SelectedFloors } from '../AddNewProperty/SelectedFloors'
import { AddUnits } from '../AddNewProperty/AddUnits';

export const AddFloorModal = ({visible, closeModal, currentProperty, defaultFloors}) => {
    const [activeStep, changeActiveStep] = useState(0)
    const [floorsList, setfloors] = useState()

    const handleNext = (step) => {
        changeActiveStep(step)
    }

    const handleSetFloors = (floors) => {
        setfloors(floors)
    }

    const steps = [
        {id:3, label: "Step 1", description: "Select Floors", 
            content:<SelectedFloors defaultFloors={defaultFloors} floorsCount={currentProperty?.noOfFloors} currentProperty={currentProperty} nextStep={handleNext} setFloors={handleSetFloors} /> 
        },
        {id:4, label: "Step 2", description: "Add Units", 
            content:<AddUnits update selectedFloors={floorsList} closeModal={closeModal} nextStep={handleNext} currentProperty={currentProperty} /> 
        },
    ]

    return(
        <Modal size={'5xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add New Floor</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <LoadingContainer>
                        <Steps size={'sm'} labelOrientation="vertical" activeStep={activeStep}>
                                {steps.map(({ label, description, content, id }, index) => (
                                    <Step label={label} key={label} description={description}>
                                        <LoadingContainer>
                                            <Box>
                                                <br/>
                                                <Box borderRadius={2} px={[4, 4, 16]} py={[4, 4, 12]} border='1px solid #E2E8F0'>
                                                    {content}
                                                </Box>
                                            </Box>
                                        </LoadingContainer>
                                    </Step>
                                ))}
                            </Steps>
                    </LoadingContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}