import React, { useState, useEffect } from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

import { UploadImage } from '../../ui/UploadImage'

export const UpdateGuestDP = ({ visible, closeModal, mutate, setGuest, propertyData, profileData ,handleImageURL}) => {
    const [image, changeImage] = useState()
    useEffect(()=>{
        changeImage()
    },[visible, setGuest])

    const handleImage = (img) => {
        changeImage(img)
    }
    const imageurl = () => {
        handleImageURL(image)
    }

    const handleSave = () => {
        imageurl()
        closeModal()
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Profile Picture</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <UploadImage UploadURL={setGuest} image={profileData?.[0]?.guest_review_json?.image_url} getImage={handleImage} />
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                        <Button onClick={handleSave} >Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}