import React, { useMemo, useReducer } from 'react'
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from '@chakra-ui/react'
import { sumBy } from 'lodash'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { FormReducer } from '../../utils/FormReducer'

export const TenantDueModal = ({ type, visible, closeModal, category, tenantData, tenantPropertyId }) => {
    const [dueData, changeDueData] = useReducer(FormReducer, { amount: 0 })

    const { mutate } = useTenantSlips(tenantData?.id)

    const { request: updateTenantDue, loading: loadingUpdateDue } = useApiRequest(URIS.UPDATE_TENANT_DUE, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true,
    })

    const { request: addConcession, loading: isLoading } = useApiRequest(URIS.ADD_CONCESSION, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const { amount, remark } = dueData;
        let data = {};
        if (type === 'concession') {
            data['concession'] = amount;
            data['remark'] = remark;
            addConcession({ method: 'POST', data: { ...data, propertyId: tenantPropertyId, tenantDuesId: category.id } })
        } else {
            data['amount'] = (category.amount || 0) + (parseInt(amount) || 0);
            data['due_amount'] = (category.due_amount || 0) + (parseInt(amount) || 0);
            updateTenantDue({ method: 'PATCH', data: { ...data, id: category.id } })
        }
    }

    const handleChange = (type, value) => {
        changeDueData({ type, value })
    }
    const categoryDueAmount = useMemo(() => {
        if (category)
            return category.amount - (category.paid_amount || 0) - (sumBy(category.concession, 'concession'))

    }, [category])

    return (
        <Modal isOpen={visible} onClose={() => closeModal()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add {type === 'concession' ? ' Concession' : ' Amount'}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>Amount</FormLabel>
                                <Input autoFocus name='amount' value={dueData.amount} type={'number'} onChange={(e) => handleChange('amount', e.target.value)} placeholder='Amount (₹)' />
                            </FormControl>
                            {
                                type === 'concession' &&
                                <FormControl isRequired>
                                    <FormLabel>Remark</FormLabel>
                                    <Input autoFocus name='amount' value={dueData.remark} type={'text'} onChange={(e) => handleChange('remark', e.target.value)} placeholder='Remark' />
                                </FormControl>
                            }
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={'gray'} onClick={() => closeModal()}>Cancel</Button>
                            <Button disabled={ type === 'addAmount' ? dueData.amount < 1 : type === 'concession' && parseInt(dueData.amount) > (categoryDueAmount || 0)} isLoading={loadingUpdateDue || isLoading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}