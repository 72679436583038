import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { DuePayments } from './DuePayments'
import { useAppContext } from '../../app/Context'
import { ALL_USER_ROLES, staffPermissions } from '../../Constants'
import { PaymentCollections } from './PaymentCollections'
import { ChequePaymentCollections } from './ChequePaymentCollection'
import { find } from 'lodash'
import { PaymentSummary } from './PaymentSummary'
import { useUserData } from '../../services/api/useUserData'


const tabs = [{ index: 0, title: 'Payment Summary', value: 'payment-summary' }, { index: 1, title: 'Due Payments', value: 'due-payments' }, { index: 2, title: 'Payment History', value: 'payment-history' }, { index: 3, title: 'Cheque Payment', value: 'cheque-history' }]

export const PaymentReports = () => {
    const navigate = useNavigate()
    const { tab } = useParams()
    const { hasAccess } = useAppContext()
    const { user } = useUserData(true)

    const handleTabChange = (e) => {
        const tab = find(tabs, t => t.index === e);
        navigate(`/payment-reports/${tab.value}`)
    }

    return (
        <Box>
            <ActionHeader title='Payment Reports' switchProperty={true} />
            <br />
            <CustomContainer>
                <Tabs variant={'enclosed'} index={find(tabs, tb => tb.value === tab)?.index} onChange={handleTabChange}>
                    <TabList>
                        {(user.role === ALL_USER_ROLES.OWNER) || (user.role === ALL_USER_ROLES.SUPPER_ACCOUNTANT) ?
                            <Tab key={0}>
                                Payment Summary
                            </Tab> : null}
                        <Tab key={1}>
                            Due Payments
                        </Tab>
                        {(hasAccess(staffPermissions.ALL_PAYMENTS) || hasAccess(staffPermissions.SELF_PAYMENTS)) &&
                            <Tab key={2}>
                                Payment History
                            </Tab>
                        }
                        {(hasAccess(staffPermissions.ALL_PAYMENTS) || hasAccess(staffPermissions.SELF_PAYMENTS)) &&
                            <Tab key={3}>
                                Cheque Payment
                            </Tab>
                        }
                    </TabList>
                    <TabPanels>
                        {(user.role === ALL_USER_ROLES.OWNER) || (user.role === ALL_USER_ROLES.SUPPER_ACCOUNTANT) ?
                            <TabPanel>
                                <PaymentSummary />
                            </TabPanel> : null
                        }
                        < TabPanel >
                            <DuePayments />
                        </TabPanel>
                        {(hasAccess(staffPermissions.ALL_PAYMENTS) || hasAccess(staffPermissions.SELF_PAYMENTS)) &&
                            <TabPanel>
                                <PaymentCollections />
                                {/* <PaymentHistory allPayments={hasAccess(staffPermissions.ALL_PAYMENTS)} /> */}
                            </TabPanel>
                        }
                        {(hasAccess(staffPermissions.ALL_PAYMENTS) || hasAccess(staffPermissions.SELF_PAYMENTS)) &&
                            <TabPanel>
                                <ChequePaymentCollections />
                                {/* <ChequeHistory allPayments={hasAccess(staffPermissions.ALL_PAYMENTS)} /> */}
                            </TabPanel>
                        }
                    </TabPanels>
                </Tabs>
            </CustomContainer>
        </Box >
    )
}