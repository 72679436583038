import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React, { useState } from 'react'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'

export const AddUnitTypeModal = ({ visible, closeModal, property, propertyId, mutate }) => {
    const [unitName, changeUnitName] = useState()

    const { request: addUnitAction, loading } = useApiRequest(URIS.ADD_UNIT_ROOM_TYPE, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: 'Room Type Added'
    })

    const handleNameChange = (e) => {
        changeUnitName(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        addUnitAction({ method: 'POST', data: { typeName: unitName, propertyId: property?.id || propertyId } })
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Room Type</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>Room Type</FormLabel>
                            <Input value={unitName} placeholder='room type' onChange={handleNameChange} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={loading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}