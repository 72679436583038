import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function usePaymentHistory(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_PAYMENT_HISTORY+q, () => apis.getPaymentHistoryApi(params), q, revalidate)
    return {
        data, error, loading, mutate
    }
}

export function usePaymentSummary(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_PAYMENT_SUMMARY+q, () => apis.getPaymentSummary(params), q, revalidate)
    return {
        data, error, loading, mutate
    }
}