import React, { useReducer, useState } from 'react'
import { AddIcon, ChevronDownIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Select, Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import _ from 'lodash'
import { FiCompass } from 'react-icons/fi'
import { AiOutlineReload, AiOutlineUnlock } from 'react-icons/ai'
import { MdInfo } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { URIS } from '../../services/api'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { FormReducer } from '../../utils/FormReducer'
import AddStaffModal from './AddStaffModal'
import { EmptyPage } from '../../ui/EmptyPage'
import { useUserData } from '../../services/api/useUserData'
import { useStaffData } from '../../services/api/useStaffData'
import { AssignPropertyModal } from './AssignPropertyModal'
import { PropertyPermissionModal } from './PropertyPremissionModal'
export const ManageStaff = () => {
    const navigate = useNavigate()

    const [filterIndex, changeFilterIndex] = useState(0)
    const [filters, changeFilters] = useReducer(FormReducer, {})
    const [statusvalue, setStatusValue] = useReducer(FormReducer, { isActive: null })
    const { organisation } = useUserData(true)

    const { data: allStaff, loading, mutate } = useStaffData(URIS.GET_STAFF + filterIndex, { ...filters, organisationId: organisation.id, staffAccessDetails: 1 }, true)

    const [addModal, openAddModal] = useState()
    const [editModal, openEditModal] = useState()
    const [propertyModal, openPropertyModal] = useState()
    const [propertypermission, openPropertyPermission] = useState()

    const handleUpdateModal = (staff) => {
        openEditModal(d => d ? null : staff)
    }
    const handlePropertyModal = (staff) => {
        openPropertyModal(d => d ? null : staff)

        if (propertyModal) {
            mutate()
        }
    }
    const handlePropertyPermission = (staff) => {
        openPropertyPermission(d => d ? null : staff)

        if (propertypermission) {
            mutate()
        }
    }
    const handleAddModal = () => {
        openAddModal(d => !d)
    }
    const handleFilter = (e) => {
        e.preventDefault()
        changeFilterIndex(i => ++i)
        navigate('/manage-staff')
    }
    const handleChangeFilter = (type, value) => {
        if (value === 'Active' ? value = '1' : value === 'InActive' ? value = '0' : value)
            changeFilters({ type, value })
        if (value === '1' ? value = 'Active' : value === '0' ? value = 'InActive' : null)
            setStatusValue({ type, value })
    }

    const handleReset = () => {
        changeFilterIndex(0)
        changeFilters({ type: 'reset', value: {} })
        setStatusValue({ type: 'reset', value: {} })
    }
    const resetstaff = () => {
        mutate()
    }

    return (
        <Box>
            <ActionHeader title='Manage Staff'>
                <HStack>
                    <Button leftIcon={<AddIcon />} onClick={handleAddModal}>Add Staff</Button>
                </HStack>
            </ActionHeader>
            <br />
            <CustomContainer>
                <Box>
                    <form key={filterIndex} onSubmit={handleFilter}>
                        <Flex p={3} wrap={'wrap'} mb={2}>
                            <Box pr={4} pb={2}>
                                <FormControl>
                                    <Input onChange={e => handleChangeFilter('name', e.target.value)} value={filters?.name} placeholder="Name" />
                                </FormControl>
                            </Box>
                            <Box pr={4} pb={2}>
                                <FormControl>
                                    <Select onChange={e => handleChangeFilter('isActive', e.target.value)} value={statusvalue?.isActive} placeholder="Status" >
                                        <option value={'Active'}>Active</option>
                                        <option value={'InActive'}>InActive</option>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box pr={4} pb={2}>
                                <FormControl>
                                    <Input onChange={e => handleChangeFilter('contact', e.target.value)} value={filters?.contact} placeholder="Contact" type='number' />
                                </FormControl>
                            </Box>
                            <Box pr={4} pb={2}>
                                <FormControl>
                                    <Input onChange={e => handleChangeFilter('code', e.target.value)} value={filters?.code} placeholder="Code" type='code' maxLength={6} />
                                </FormControl>
                            </Box>
                            <Box pr={4} pb={2}>
                                <HStack>
                                    <Button type="submit" variant={'outline'}>Apply</Button>
                                    <Button onClick={handleReset} leftIcon={<AiOutlineReload />} type="submit" variant={'outline'} colorScheme='gray'>Reset</Button>
                                </HStack>
                            </Box>
                        </Flex>
                    </form>

                    <LoadingContainer loading={loading}>
                        {allStaff?.length ?
                            <Box>
                                <br />
                                <Box minWidth={200} overflow='auto'>
                                    <Table className='responsiveTable' variant={'simple'} >
                                        <Thead>
                                            <Tr>
                                                <Th>Sr.NO.</Th>
                                                <Th>Status</Th>
                                                <Th>Name</Th>
                                                <Th>Contact</Th>
                                                <Th>Code</Th>
                                                <Th>Role</Th>
                                                <Th>UserName</Th>
                                                <Th>Property</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                allStaff.map((singlestaff, i) =>
                                                    <SingleStaff key={singlestaff.id} handleUpdateModal={handleUpdateModal} handlePropertyPermission={handlePropertyPermission} handlePropertyModal={handlePropertyModal} singlestaff={singlestaff} i={i} resetstaff={resetstaff} />
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </Box>
                                <br />
                            </Box>
                            :
                            <EmptyPage message='No staff available' />
                        }
                    </LoadingContainer>
                </Box>
            </CustomContainer>
            {addModal && <AddStaffModal allStaff={allStaff} resetstaff={resetstaff} visible={addModal} closeModal={handleAddModal} />}
            {editModal && <AddStaffModal defaultData={editModal.id ? editModal : null} singlestaff={editModal} resetstaff={resetstaff} visible={editModal} closeModal={handleUpdateModal} />}
            {propertyModal && <AssignPropertyModal singlestaffId={propertyModal} resetstaff={resetstaff} closeModal={handlePropertyModal} />}
            {propertypermission && <PropertyPermissionModal singlestaffId={propertypermission} resetstaff={resetstaff} closeModal={handlePropertyPermission} />}

        </Box>
    )
}
const SingleStaff = ({ singlestaff, i, key, handleUpdateModal, handlePropertyPermission, handlePropertyModal }) => {

    return (
        <>
            {singlestaff?.length !== 0 && singlestaff?.staff?.length != 0 ?
                <Tr key={key}>
                    <Td>{++i}</Td>
                    <Td><Tag bg={singlestaff.isActive == 1 ? 'blue.100' : 'red.200'}>{singlestaff.isActive == 1 ? 'Active' : 'InActive'}</Tag></Td>
                    <Td>{singlestaff?.staff?.name}</Td>
                    <Td>{singlestaff?.staff?.contact}</Td>
                    <Td>{singlestaff.code}</Td>
                    <Td>{singlestaff.staff?.role}</Td>
                    <Td>{singlestaff.staff?.username}</Td>
                    <Td>{_.map(singlestaff?.staffAccessDetails, (d, i) =>
                        <HStack key={i} mb={2} justifyContent='space-between' >
                            <Text>{d?.property?.name}</Text>
                            <Popover trigger='hover'>
                                <PopoverTrigger>
                                    <span><MdInfo /></span>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Permission</PopoverHeader>
                                    <PopoverBody>{_.map(d.accesses_json, (s, i) => <Tag key={i} m={1}>{s}</Tag>)}</PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </HStack>
                    )}
                    </Td>
                    <Td>
                        <Menu>
                            <MenuButton
                                as={Button}
                                aria-label='Options'
                                rightIcon={<ChevronDownIcon fontSize={20} />}
                                variant='ghost'
                            >Actions</MenuButton>
                            <MenuList>
                                <MenuItem icon={<EditIcon />} onClick={() => handleUpdateModal(singlestaff)}>
                                    Edit
                                </MenuItem>
                                <MenuItem icon={<FiCompass />} onClick={() => handlePropertyModal(singlestaff.staff.id)}>
                                    {singlestaff?.staffAccessDetails?.length ? 'Change Property' : 'Assign Property'}
                                </MenuItem>
                                {singlestaff?.staffAccessDetails?.length ?
                                    <MenuItem icon={<AiOutlineUnlock />} onClick={() => handlePropertyPermission(singlestaff.staff.id)}>
                                        Property Permission
                                    </MenuItem>
                                    :
                                    ''
                                }
                            </MenuList>
                        </Menu>
                    </Td>
                </Tr>
                :
                <EmptyPage />
            }
        </>
    )
}