import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Flex, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { URIS } from '../../services/api'
import { useTenantProfiles } from '../../services/api/useTenantProfiles'

export const UnitTenantsModal = ({ visible, closeModal, currentUnit }) => {
    const unitTenants = currentUnit.unitTenants?.length ? currentUnit.unitTenants : []
    const unitTenantIds = unitTenants?.length ? _.join(unitTenants.map(d => d.tenant.id), ',') : null
    const { data: tenantProfiles } = useTenantProfiles(URIS.GET_TENANT + unitTenantIds, { tenantIds: unitTenantIds })

    const results = tenantProfiles?.results

    return (
        <Modal size={'2xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Tenants</ModalHeader>
                <ModalCloseButton />
                <ModalBody minH={200}>
                    <Box>
                        {unitTenants?.length ?
                            <HStack align={'stretch'}>
                                {unitTenants.map(tenant => {
                                    const profile = results?.length ? _.find(results, r => r.tenant.id === tenant.tenant.id) : null
                                    return (
                                        <>
                                            <TenantCard profile={profile} tenant={tenant} />
                                        </>
                                    )
                                }
                                )}
                            </HStack>
                            :
                            <Text color='secondary'>No tenants available</Text>
                        }
                        <br />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const TenantCard = ({ tenant, profile }) => {
    const navigate = useNavigate()
    return (
        <Box px={4} width={300} flex={6} height='full' py={6}>
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}
                rounded={'lg'}
                p={4}
                textAlign={'center'}>
                <Avatar size={'xl'} src={tenant.tenant.avatar} alt={'Avatar Alt'} mb={4} pos={'relative'} />
                <Heading fontSize={'lg'} fontFamily={'body'}>
                    {tenant.tenant.name}
                </Heading>
                <Text fontWeight={600} color={'gray.500'} mb={4}>{tenant.tenant.contact}</Text>

                <Box pb={5}>
                    <Button onClick={() => navigate('/tenant/profile/' + profile.tenant?.id + '/0')} variant={'ghost'} background={'blue.50'} colorScheme={'blue'}>View Profile</Button>
                </Box>
            </Box>
        </Box>
    )
}