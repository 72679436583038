import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "../Constants/Routes";
import { SidebarWithHeader } from "../ui/SidebarHeader";
import { ManageProperties } from "./ManageProperties";
import { AddProperty } from "./AddProperty";
import ManageTenant from "./ManageTenant";
import { Dashboard } from "./Dashboard";
import { TenantProfile } from "./TenantProfile";
import { Box } from "@chakra-ui/react";
import { MarkAttendance } from "./MarkAttendance";
import WebsiteDesign from "./WebsiteDesign";
import { VisitorManagement } from "./InOutManagement";
import { CompanyManagement } from "./CompanyManagement";
import { AdminProfile } from "./AdminProfile";
import WebHomePage from "./WebsiteUI.js/WebsiteHomepage";
import { useCurrentProperty } from "../app/Context";
import { AddNewProperty } from "./AddNewProperty";
import { OrganisationRentCategories } from "./OrganisationRentCategories";
import { PropertyRent } from "./PropertyRent";
import { ManageStaff } from "./ManageStaff";
import { size } from "lodash";
import { PaymentReports } from "./PaymentReports";
import '../index.css'
import ComplaintReport from "./Complaint";
import TenantOutReasons from "./TenantOutReasons";
import { Mess } from "./Mess";
import { ManageMess } from "./ManageMess";
import { Devices } from "./Device";
import { ManageDevice } from "./ManageDevice";

export const DashboardRoutes = (props) => {
  const { currentProperty, properties } = useCurrentProperty();

  return (
    <Box pt={2} key={currentProperty?.id}>
      {properties ? (
        !size(properties) ? (
          <Routes>
            <Route
              path="*"
              element={<Navigate to={ROUTES.ADD_FIRST_PROPERTIE} replace />}
            />
            <Route
              path={ROUTES.ADD_FIRST_PROPERTIE}
              element={<AddNewProperty firstProperty />}
            />
          </Routes>
        ) : (
          <SidebarWithHeader>
            <Routes>
              <Route
                path="*"
                element={<Navigate to={ROUTES.PROPERTIES} replace />}
              />
              <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
              <Route path={ROUTES.ADD_PROPERTIES} element={<AddProperty />} />
              <Route path={ROUTES.PROPERTIES} element={<ManageProperties />} />
              <Route path={ROUTES.TENANT} element={<ManageTenant />} />
              <Route path={ROUTES.TENANTPROFILE} element={<TenantProfile />} />
              <Route
                path={ROUTES.MARK_ATTENDANCE}
                element={<MarkAttendance />}
              />
              <Route path={ROUTES.WEBSITE} element={<WebsiteDesign />} />
              <Route
                path={ROUTES.VISITOR_MANAGEMENT}
                element={<VisitorManagement />}
              />
              <Route path={ROUTES.COMPANY} element={<CompanyManagement />} />
              <Route path={ROUTES.ADMIN_PROFILE} element={<AdminProfile />} />
              <Route path={ROUTES.WEBSITEHOMEPAGE} element={<WebHomePage />} />
              <Route
                path={ROUTES.ORGANISATION_RENT_CATEGORIES}
                element={<OrganisationRentCategories />}
              />
              <Route path={ROUTES.PROPERTY_RENT} element={<PropertyRent />} />
              <Route path={ROUTES.MANAGE_STAFF} element={<ManageStaff />} />
              <Route
                path={ROUTES.PAYMENT_REPORTS}
                element={<PaymentReports />}
              />
              <Route path={ROUTES.COMPLAINT_REPORT} element={<ComplaintReport />} />
              <Route path={ROUTES.TENANT_OUT_REASONS} element={<TenantOutReasons />} />

              <Route path={ROUTES.MESS} element={<Mess />} />
              <Route path={ROUTES.MANAGE_MESS} element={<ManageMess />} />
              <Route path={ROUTES.MANAGE_BIOMETRIC_DEVICE} element={<Devices />} />
              <Route path={ROUTES.MANAGE_DEVICE} element={<ManageDevice />} />
            </Routes>
          </SidebarWithHeader>
        )
      ) : null}
    </Box>
  );
};
