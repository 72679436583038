import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useMessData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.GET_PROPERTY_MESS + q, () => apis.getPropertyMessApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}


export function useSingleMessData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.ADD_MESS + q, () => apis.getSingleMessApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

export function useMessDeviceData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.MESS_DEVICE_ALL + q, () => apis.getMessDeviceApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

export function useMessPropertyData(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.MESS_PROPERTY_ALL + q, () => apis.getMessPropertyApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}


export function useMessFoodMenu(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.FOOD_MENU_ALL + q, () => apis.getMessFoodMenuApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}


export function useMessDeviceTenants(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.MESS_DEVICE_USERS + q, () => apis.getMessDeviceTenantsApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}


export function useMessAttendanceReport(q, params, revalidate) {
    const { data, error, loading, mutate } = useGetData(URIS.MESS_ATTENDANCE_REPORT + q, () => apis.getMessAttendanceReportApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}

