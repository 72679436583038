import { Box, Flex, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, } from "@chakra-ui/react"
import React from "react"
import { ActionHeader } from "../../ui/ActionHeader"
import { CustomContainer } from '../../ui/CustomContainer'
import { ManageProperty } from "./ManageProperty"
import { BiometricDevice } from "./BiometricDevice"
import { useMessDeviceData, useSingleMessData, useMessData, useMessPropertyData, useMessFoodMenu } from "../../services/api/useMessData1"
import { URIS } from "../../services/api"
import { useUserData } from "../../services/api/useUserData"
import { useParams } from "react-router-dom"
import { FoodMenu1 } from "./FoodMenu"
import { useMemo } from "react"
import { LoadingContainer } from "../../ui/LoadingContainer"
import { AttendanceReport } from "./AttendanceReport"

export const ManageMess = () => {

    const { id } = useParams();
    const { organisation } = useUserData(true)

    //all neccessary apis call and send data in tabs component
    const { data: allMessData, mutate: allMessMutate } = useMessData(URIS.GET_PROPERTY_MESS, { organisationId: organisation.id }, true)
    const { data: messData, loading } = useSingleMessData(id, { id }, true)
    const { data: messDeviceData, mutate: deviceMutate } = useMessDeviceData(URIS.MESS_DEVICE_ALL, { organisationId: organisation.id }, true)
    const { data: allMessPropertyData, mutate: messProteryMutate } = useMessPropertyData(URIS.MESS_PROPERTY_ALL, { organisationId: organisation.id }, true)
    const { data: allMessFoodMenuData, mutate: messFoodMenuMutate } = useMessFoodMenu(id ? id : null, { organisationId: organisation.id, messId: id }, true)
    // const { data: allUnits } = useUnitsData(property.id, { propertyId: property.id }, true)

    const singleMessDevice = useMemo(() => {
        if (messData && messData?.id) {
            return messDeviceData?.find(data => data?.messId === messData?.id)
        }
    }, [messDeviceData, messData])

    const resetMess = () => {
        deviceMutate()
        allMessMutate()
    }

    const resetFoodMenu = () => {
        messFoodMenuMutate()
    }

    return (
        <Box>
            <ActionHeader title='Manage Mess'>
            </ActionHeader>
            <br />
            <CustomContainer>
                <LoadingContainer loading={loading}>
                    <Flex mb={{ base: '15px', lg: 0 }} justify='space-between'>
                        {loading ? <Spinner size="sm" /> :
                            <Text fontSize={'large'} color='defaultColor.500' fontWeight='bold'>{messData?.name}</Text>}
                    </Flex>
                    <br />
                    <Tabs variant='soft-rounded' >
                        <TabList mb={3}>
                            <Tab>Food Menu</Tab>
                            <Tab>Properties</Tab>
                            <Tab>Biometric Device</Tab>
                            <Tab>Mess Report</Tab>
                        </TabList>
                        <hr />
                        <TabPanels>
                            <TabPanel>
                                <FoodMenu1 messData={messData} mutate={resetFoodMenu} allMessFoodMenuData={allMessFoodMenuData} allMessData={allMessData} />
                            </TabPanel>
                            <TabPanel>
                                <ManageProperty messData={messData} allMessPropertyData={allMessPropertyData} mutate={messProteryMutate} />
                            </TabPanel>
                            <TabPanel>
                                <BiometricDevice messDeviceData={singleMessDevice} messData={messData} resetMess={resetMess} allMessPropertyData={allMessPropertyData} />
                            </TabPanel>
                            <TabPanel>
                                <AttendanceReport messData={messData} allMessFoodMenuData={allMessFoodMenuData} allMessPropertyData={allMessPropertyData} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </LoadingContainer>
            </CustomContainer>
        </Box>
    )
}