import { useUserData } from "../../services/api/useUserData";
import { useParams } from "react-router-dom";
import { useDeviceData, useDeviceProperties, useDeviceTenants, useSingleDeviceData } from "../../services/api/useDeviceData";
import { URIS } from "../../services/api";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure, Text, Spacer, HStack, VStack, Icon, Tooltip, Tab, TabList, Tabs, TabPanels, TabPanel, Table, Thead, Tr, Th, Tbody, Td, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { EmptyPage } from "../../ui/EmptyPage";
// import { AddBiometricDeviceModal } from "./AddBiometricDevice";
import { GrPowerReset } from "react-icons/gr";
import _, { compact, find, join } from "lodash";
import { apis } from "../../services/api/apis";
import { useGetData } from "../../utils/useGetData";
import { useUnitsData } from "../../services/api/useUnitsData";
import { BiLayer } from "react-icons/bi";
import { BsDoorOpen } from "react-icons/bs";
import { RiHotelBedFill, RiHotelBedLine } from "react-icons/ri";
import { useGetProperties } from "../../services/api/useGetProperties";
import { AiFillCaretRight } from "react-icons/ai";
import { SelectPropertyModal } from "../ManageProperties/SelectPropertyModal";
// import { useDeviceTenants } from "../../services/api/useMessData1";
import { useApiRequest } from "../../services/api/useApiRequest";
import Pagination from 'rc-pagination'
import { ActionHeader } from "../../ui/ActionHeader";
import { CustomContainer } from "../../ui/CustomContainer";
import { AssignPropertyModal } from "./AssignPropertyModal";

export const ManageDevice = () => {

    const { id } = useParams();

    const { data: deviceData, mutate: deviceMutate } = useSingleDeviceData(id, { id: id }, true)

    return <Box>
        <ActionHeader title='Manage Biometric Device'>
        </ActionHeader>
        <br />
        <CustomContainer>
            <BiometricDevice deviceData={deviceData} />
        </CustomContainer>
    </Box>
}


export const BiometricDevice = ({ deviceData }) => {

    const { isOpen: assignPropertyModalIsOpen, onOpen: assignPropertyModalOnOpen, onClose: assignPropertyModalOnClose } = useDisclosure()

    const { data: allProperties } = useGetProperties(true);

    const assignPropertyHandler = () => {
        assignPropertyModalOnOpen()
    }

    const { organisation } = useUserData(true);
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1)
    const [selectedProperty, setProperty] = useState();

    const { data: allDevicePropertyData, loading: allDevicePropertyLoading, mutate: allDevicePropertyMutate } = useDeviceProperties(deviceData?.id ? deviceData.id + page : null, { organisationId: organisation.id, biometric_deviceId: deviceData?.id }, true)

    useEffect(() => {
        if (allDevicePropertyData?.length > 0) {
            setProperty(allDevicePropertyData[0])
        }
    }, [allDevicePropertyData])

    //device all tenants
    const { data: allDeviceTenants, loading } = useDeviceTenants((selectedProperty?.id && deviceData) ? selectedProperty.id + page : null, { organisationId: organisation.id, inOutPropertyId: selectedProperty?.propertyId, biometric_deviceId: deviceData?.id, boxIdNo: deviceData?.boxIdNo, page, limit: 20, isPending: 0 }, true)

    const { data: allDeviceTenantsSynced, loading: allDeviceTenantsloadingSynced } = useDeviceTenants(selectedProperty?.id ? selectedProperty.id + page1 + "synced" : null, { organisationId: organisation.id, inOutPropertyId: selectedProperty?.propertyId, biometric_deviceId: deviceData?.id, boxIdNo: deviceData?.boxIdNo, page: page1, limit: 20, isPending: 1 }, true)

    useEffect(() => {
        setPage(1)
        setPage1(1)
    }, [selectedProperty])

    const handleSelectProperty = (prop) => {
        setProperty(prop)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }
    const handlePageChange1 = (page) => {
        setPage1(page)
    }

    return <Box>
        <Flex>
            {deviceData ?
                <Box mb={{ base: '15px', lg: 0 }} >
                    <Text fontSize={'large'} color='black.500' fontWeight='bold'>{deviceData?.name}</Text>
                    <Text fontSize={'md'} color='black.500' >{`STG ID: ${deviceData?.boxIdNo}`}</Text>

                </Box>
                : <EmptyPage message='No Biometric Device Added' />}
            <Spacer />
            <Flex justifyContent={"end"}>
                <Button leftIcon={<AddIcon />} onClick={assignPropertyHandler}>Assign Property</Button>
            </Flex>
        </Flex>

        <Box mt={5}>
            <Box>
                <Flex>
                    {allDevicePropertyLoading ? <Spinner /> :
                        <VStack display={{ base: 'none', lg: 'block' }} w={"30%"} align={'stretch'}>
                            {allDevicePropertyData?.length > 0 && deviceData ?
                                allDevicePropertyData?.map((property, i) => {
                                    const selected = property.id === selectedProperty?.id
                                    return (
                                        <Flex key={property.id} w={"100%"} alignItems={"center"}>
                                            <PropertyCard onClick={() => handleSelectProperty(property)} key={property.id}
                                                selected={selected} property={property?.property} mutate={allDevicePropertyMutate} deviceData={deviceData}
                                            />
                                        </Flex>
                                    )
                                }) : deviceData ? <EmptyPage message="No Property Assigned" /> : null
                            }
                        </VStack>}

                    {
                        deviceData && selectedProperty ? <Box width={{ base: '100%', lg: '70%' }} py={{ lg: 2 }} px={{ lg: 4 }}>
                            <Box boxShadow={{ lg: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }} p={{ base: 2, lg: 4 }} minH={400} borderRadius={8}
                            >
                                <Flex>
                                    <Tabs isFitted variant="enclosed" w={"full"}>
                                        <TabList>
                                            <Tab onClick={() => setPage1(1)}>Synced Tenants</Tab>
                                            <Tab onClick={() => setPage(1)}>Not Synced Tenants</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <Table variant="simple">
                                                    <Thead bgColor={"gray.100"}>
                                                        <Tr>
                                                            <Th>Sr. no.</Th>
                                                            <Th>Name</Th>
                                                            <Th>contact</Th>
                                                        </Tr>
                                                    </Thead>
                                                    {allDeviceTenantsloadingSynced ? <Tbody><Spinner /></Tbody> :
                                                        <Tbody>
                                                            {allDeviceTenantsSynced?.results?.length > 0 ?
                                                                allDeviceTenantsSynced?.results?.map((tenantData, i) => {
                                                                    return <Tr key={tenantData?.id}>
                                                                        <Td>{(20 * (page - 1)) + i + 1}</Td>
                                                                        <Td>{tenantData?.tenant?.name}</Td>
                                                                        <Td>{tenantData?.tenant?.contact}</Td>
                                                                    </Tr>
                                                                }) : <Tr><Td>No Data Found</Td></Tr>}
                                                        </Tbody>}
                                                </Table>
                                                {allDeviceTenantsSynced?.results?.length > 0 ?
                                                    <HStack mt={4} position={"relative"} bottom={"0px"} justifyContent={'center'}>
                                                        <Pagination
                                                            total={allDeviceTenantsSynced?.total || 20}
                                                            pageSize={allDeviceTenantsSynced?.limit || 20}
                                                            onChange={handlePageChange1}
                                                            current={parseInt(page1)}
                                                        />
                                                    </HStack> : null
                                                }
                                            </TabPanel>

                                            <TabPanel>
                                                <Table variant="simple">
                                                    <Thead bgColor={"gray.100"}>
                                                        <Tr>
                                                            <Th>Sr. no.</Th>
                                                            <Th>Name</Th>
                                                            <Th>contact</Th>
                                                        </Tr>
                                                    </Thead>
                                                    {loading ? <Tbody><Spinner /></Tbody> :
                                                        <Tbody>
                                                            {allDeviceTenants?.results?.length > 0 ?
                                                                allDeviceTenants?.results?.map((tenantData, i) => {
                                                                    return <Tr key={tenantData?.id}>
                                                                        <Td>{(20 * (page - 1)) + i + 1}</Td>
                                                                        <Td>{tenantData?.tenant?.name}</Td>
                                                                        <Td>{tenantData?.tenant?.contact}</Td>
                                                                    </Tr>
                                                                }) : <Tr><Td>No Data Found</Td></Tr>}
                                                        </Tbody>}
                                                </Table>
                                                {allDeviceTenants?.results?.length > 0 ?
                                                    <HStack mt={4} position={"relative"} bottom={"0px"} justifyContent={'center'}>
                                                        <Pagination
                                                            total={allDeviceTenants?.total || 20}
                                                            pageSize={allDeviceTenants?.limit || 20}
                                                            onChange={handlePageChange}
                                                            current={parseInt(page)}
                                                        />
                                                    </HStack> : null
                                                }
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Flex>
                            </Box>
                        </Box> : null
                    }
                </Flex>
            </Box>
        </Box>
        <AssignPropertyModal isOpen={assignPropertyModalIsOpen} onClose={assignPropertyModalOnClose} deviceData={deviceData} allProperties={allProperties} allDeviceProperties={allDevicePropertyData} mutate={allDevicePropertyMutate} />
    </Box>
}


// export const ManageProperty = ({ deviceData, setAllDeviceProperties }) => {



//     return 
// }

const PropertyCard = ({ property, selected, onClick, mutate, allDeviceTenantsMutateSynced, deviceData }) => {

    const { organisation } = useUserData(true)
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allUnits } = useUnitsData(property.id, { propertyId: property.id }, true)

    const [time, setTime] = useState(false)

    const state = allStates?.length ? find(allStates, state => state.id === property.state) : null
    const city = state?.cities?.length ? find(state.cities, city => city.id === property.district) : null

    const allRooms = useMemo(() => {
        if (allUnits?.length)
            return _.chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if (allUnits?.length) {
            return _.chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    //sync button handler
    const { request: syncPropertyOnDevice, loading: asycPropertyOnDeviceIsLoading } = useApiRequest(URIS.DEVICE_PROPERTY_SYNC, {
        onCompleted: d => {
            mutate()
            allDeviceTenantsMutateSynced()
            setTime(true)
        },
        showAlert: true,
        showLoader: true,
    })

    const syncPropertyHandler = (property) => {
        if (property?.id && deviceData?.boxIdNo)
            syncPropertyOnDevice({
                method: 'POST', data: {
                    boxIdNo: deviceData.boxIdNo,
                    organisationId: organisation.id,
                    propertyId: property.id
                }
            })

        setTime(true);
        setTimeout(() => {
            setTime(false);
        }, 10 * 60 * 1000);
    }

    return (
        <HStack w={"100%"} align={'stretch'} alignItems={"center"}>
            <Box flex={1} onClick={onClick} p={4} border='1px solid' borderColor={'gray.100'} borderRadius={6}
                boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                {...selected ? {
                    borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                } : { borderLeft: '6px solid', borderLeftColor: 'white' }}
                cursor={'pointer'}
                borderRightColor={'#fff'}
                transition='all .2s'
            >
                <Flex alignItems={"center"}>
                    <VStack align={'left'}>
                        <Text fontSize={'xs'} color={'gray.500'} noOfLines={2} wordBreak={'break-all'}>{join(compact([state?.name, city?.name, property.town, property.streetAddress, property.pincode]), ', ')}</Text>
                        <Text color={selected && 'defaultColor.500'} fontSize={{ base: 'sm', xl: 'large' }}>{property.name}</Text>
                        <Flex wrap={'wrap'}>
                            <FacilityCount selected={selected} count={property.noOfFloors} label={'Total Floors'} icon={BiLayer} />
                            <FacilityCount selected={selected} count={allUnits?.length || 0} label={'Total Rooms'} icon={BsDoorOpen} />
                            <FacilityCount selected={selected} count={allRooms} label={'Total Beds'} icon={RiHotelBedLine} />
                            <FacilityCount selected={selected} count={occupiedBeds} label={'Occupied Beds'} icon={RiHotelBedFill} />
                        </Flex>
                    </VStack>
                    <Spacer />
                    {selected ?
                        <Tooltip label={time ? "You can again sync after 10 mins" : "Sync all tenant of the property"}>
                            <Button ml={5} leftIcon={<GrPowerReset />} isDisabled={time}
                                isLoading={asycPropertyOnDeviceIsLoading}
                                onClick={() => syncPropertyHandler(property)}
                            >Sync</Button>
                        </Tooltip> : null}

                </Flex>
            </Box>
            <HStack pos={'relative'} width={4}>
                <Box>
                    {selected ?
                        <AiFillCaretRight color='#D6DBDF' fontSize={30}
                            boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}
                        />
                        :
                        null
                    }
                </Box>
            </HStack>
        </HStack>
    )
}

const FacilityCount = ({ count, label, icon, selected }) => {
    return (
        <Box pr={5} pb={2}>
            <Tooltip label={label}>
                <HStack spacing={1}>
                    <Icon as={icon} color={selected ? 'defaultColor.500' : 'gray'} />
                    <Text color={selected ? 'defaultColor.500' : 'gray.600'}>{count}</Text>
                </HStack>
            </Tooltip>
        </Box>
    )
}