import { Box, Button, FormControl, FormLabel, HStack, Radio, RadioGroup, Select, VStack } from '@chakra-ui/react'
import { forEach, omit } from 'lodash'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useCompanyData } from '../../services/api/useCompanyData'
import { useGetProperties } from '../../services/api/useGetProperties'
import { LoadingContainer } from '../../ui/LoadingContainer'

export const PaymentSettings = ({ currentProperty }) => {
    const params = useParams()

    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)
    const { mutate: mutateProperties } = useGetProperties(true)


    const { register, control, handleSubmit, reset } = useForm()

    useEffect(() => {
        if (currentProperty) {
            const data = {
                companyId: currentProperty.companyId,
                billingCycleStartDay: currentProperty.meta_data_json?.billingCycleStartDay ? 'true' : 'false',
                sendNewSlipOnWhatsapp: currentProperty.meta_data_json?.sendNewSlipOnWhatsapp ? 'true' : 'false',
                sendPaymentReceiptOnWhatsapp: currentProperty.meta_data_json?.sendPaymentReceiptOnWhatsapp ? 'true' : 'false',
                instantSlipPrint: currentProperty.meta_data_json?.instantSlipPrint ? 'true' : 'false',
            }
            reset(data)
        }
    }, [currentProperty, reset])

    const { request: updatePropertyRequest, loading: loadingUpdateProperty } = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted: () => {
            mutateProperties()
        },
        onError: () => { },
        showAlert: true
    })

    const _submit = (data) => {
        let finalData = {}
        forEach(data, (value, key) => finalData = { ...finalData, [key]: value === 'true' ? 1 : 0 })
        updatePropertyRequest({ method: 'PATCH', data: { meta_data_json: omit(finalData, 'companyId'), companyId: data.companyId, id: params.id } })
    }

    return (
        <Box>
            <LoadingContainer loading={loadingCompanies}>
                <form onSubmit={handleSubmit(_submit)}>
                    <VStack align={'stretch'} spacing={4}>
                        <FormControl isRequired>
                            <FormLabel>Company</FormLabel>
                            <Select {...register('companyId')} placeholder='select company'>
                                {allCompanies?.length ?
                                    allCompanies.map(company =>
                                        <option value={company.id} key={company.id}>{company.name}</option>
                                    )
                                    :
                                    null
                                }
                            </Select>
                        </FormControl>
                        <HStack>
                            <FormControl>
                                <FormLabel>Select Billing Start Day</FormLabel>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup defaultValue={'true'} {...field}>
                                            <HStack>
                                                <Radio value={'true'}>Yes</Radio>
                                                <Radio value={'false'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    name="billingCycleStartDay"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Send Due Slips On Whatsapp</FormLabel>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup defaultValue={'true'} {...field}>
                                            <HStack>
                                                <Radio value={'true'}>Yes</Radio>
                                                <Radio value={'false'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    name="sendNewSlipOnWhatsapp"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Send Payment Receipts On Whatsapp </FormLabel>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup defaultValue={'true'} {...field}>
                                            <HStack>
                                                <Radio value={'true'}>Yes</Radio>
                                                <Radio value={'false'}>No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    )}
                                    name="sendPaymentReceiptOnWhatsapp"
                                />
                            </FormControl>
                        </HStack>
                        {/* <FormControl>
                            <FormLabel>Instant Slip Print</FormLabel>
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup defaultValue={'true'} {...field}>
                                        <HStack>
                                            <Radio value={'true'}>Yes</Radio>
                                            <Radio value={'false'}>No</Radio>
                                        </HStack>
                                    </RadioGroup>
                                )}
                                name="instantSlipPrint"
                            />
                        </FormControl> */}
                        <br />
                        <Button
                            isLoading={loadingUpdateProperty}
                            type='submit'
                            variant={'outline'}
                        >
                            Update
                        </Button>
                    </VStack>
                </form>
            </LoadingContainer>
        </Box>
    )
}