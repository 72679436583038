import React, { useEffect, useMemo, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, VStack } from '@chakra-ui/react'
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { ActionHeader } from '../../ui/ActionHeader'
import { CustomContainer } from '../../ui/CustomContainer'
import { EmptyPage } from '../../ui/EmptyPage'
import { useGetData } from '../../utils/useGetData'
import { AiFillCaretRight } from "react-icons/ai";
import { useNavigate, useParams } from 'react-router-dom'
import _, { compact, filter, find, join } from 'lodash'
import { AddNewPropertyModal } from '../AddNewProperty'
import { BiLayer } from "react-icons/bi";
import { useGetProperties } from '../../services/api/useGetProperties'
import { RiHotelBedFill, RiHotelBedLine } from "react-icons/ri";
import { useUnitsData } from '../../services/api/useUnitsData'
import { BsDoorOpen } from "react-icons/bs";
import { PropertyDetails } from './PropertyDetails'
import { SingleProperty } from '../SingleProperty'
import { PropertyRent } from '../PropertyRent'
import { AddTenantRentModal } from '../TenantPayment/AddTenantRentModal'
import { SelectPropertyModal } from './SelectPropertyModal'
import { useGetPropertyMess } from '../../services/api/useMessData'
import { PaymentSettings } from './PaymentSettings'
import { useUserData } from '../../services/api/useUserData'
import { ALL_USER_ROLES } from '../../Constants'

export const ManageProperties = () => {
    const { user } = useUserData(true)

    const navigate = useNavigate()
    const params = useParams()
    const currentPropertyId = params.id

    const managePropertiesKeyPrefix = 'manage-properties-Page'
    const [addModal, openAddModal] = useState()
    const [selectedProperty, setProperty] = useState()
    const [rentModal, openRentModal] = useState()
    const [showPropertyModal, setShowPropertyModal] = useState(false);
    const { data: allProperties } = useGetProperties(true)

    const tabs = [
        { indx: 0, type: 'details', title: 'Details' },
        { indx: 1, type: 'manage-rooms', title: 'Manage Rooms' },
        { indx: 2, type: 'payment-categories', title: 'Payment Categories' },
        { indx: 3, type: 'payment-settings', title: 'Payment Settings' }
    ]

    useEffect(() => {
        if (allProperties?.length) {
            if (!currentPropertyId || currentPropertyId === ':id') {
                navigate(`/properties/${allProperties[0].id}/0`)
                setProperty(allProperties[0])
            }
            else if (currentPropertyId) {
                const property = _.find(allProperties, p => p.id === currentPropertyId)
                setProperty(property)
            }
        }
    }, [allProperties, currentPropertyId, navigate])

    const handleAddModal = () => {
        openAddModal(d => !d)

        if (addModal) {
            mutate(URIS.GET_PROPERTIES + true)
            mutate(URIS.ME)
        }
    }

    const handleSelectProperty = (prop) => {
        navigate(`/properties/${prop.id}/${params.tab}`)
        setProperty(prop)
    }

    const handleTabChange = (indx) => {
        navigate(`/properties/${params.id}/${indx}`)
    }

    const handleOpenRent = (tenant) => {
        openRentModal(tenant)
    }

    const { data: allPropertyMess, mutate } = useGetPropertyMess(selectedProperty?.id, { propertyId: selectedProperty?.id })

    const filterProperties = useMemo(() => {
        return filter(allProperties, p => {
            if (user.role === ALL_USER_ROLES.OWNER) return true;
            return find(user?.staffProfile?.staffAccessDetails, propery => propery.propertyId === p.id) ? true : false;
        })
    }, [allProperties, user])

    return (
        <Box>
            <ActionHeader title={'Properties'}>
                <HStack>
                    <Button leftIcon={<AddIcon />} onClick={handleAddModal}>Add Property</Button>
                </HStack>
            </ActionHeader>
            <br />
            <CustomContainer>
                <Box minW={200}>
                    {allProperties?.length ?
                        <>
                            <HStack flexDir={{ base: 'column', lg: 'row' }} align={'start'}>
                                <VStack display={{ base: 'none', lg: 'block' }} width={'25%'} align={'stretch'}>
                                    {filterProperties.map((property, i) => {
                                        const selected = property.id === selectedProperty?.id
                                        return (
                                            <PropertyCard
                                                onClick={() => handleSelectProperty(property)} property={property} key={property.id}
                                                selected={selected}
                                            />
                                        )
                                    })
                                    }
                                </VStack>
                                {
                                    showPropertyModal &&
                                    <SelectPropertyModal allProperties={allProperties} onClose={() => setShowPropertyModal(false)} selectedPropertyId={selectedProperty.id} selectProperty={(p) => { handleSelectProperty(p); setShowPropertyModal(false); }} />
                                }
                                {selectedProperty &&
                                    <Box width={{ base: '100%', lg: '75%' }} py={{ lg: 2 }} px={{ lg: 4 }}>
                                        <Box boxShadow={{ lg: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }} p={{ base: 2, lg: 4 }} minH={400} borderRadius={8}
                                        >
                                            <Flex mb={{ base: '15px', lg: 0 }} justify='space-between'>
                                                <Text fontSize={'large'} color='defaultColor.500' fontWeight='bold'>{selectedProperty.name}</Text>
                                                <Box display={{ base: 'block', lg: 'none' }}>
                                                    <Button onClick={() => setShowPropertyModal(true)}>Change Property</Button>
                                                </Box>
                                            </Flex>
                                            <br />
                                            <Tabs isLazy index={parseInt(params.tab)} onChange={handleTabChange} p={0} variant={'soft-rounded'}>
                                                <TabList dir='column'>
                                                    {tabs.map(tab =>
                                                        <Tab>{tab.title}</Tab>
                                                    )}
                                                </TabList>
                                                <TabPanels key={currentPropertyId} borderTop={'1px solid'} borderTopColor='gray.200' mt={3}>
                                                    <TabPanel px={{ base: 0, lg: 2 }}>
                                                        <Text fontSize={'default'} fontWeight='bold' color={'secondary'}>Details</Text>
                                                        <PropertyDetails selectedProperty={selectedProperty} />
                                                    </TabPanel>
                                                    <TabPanel px={{ base: 0, lg: 2 }}>
                                                        <Text fontSize={'default'} fontWeight='bold' color={'secondary'}>Manage Rooms</Text>
                                                        <br />
                                                        <SingleProperty openRentModal={handleOpenRent} keyPrefix={managePropertiesKeyPrefix} />
                                                    </TabPanel>
                                                    <TabPanel px={{ base: 0, lg: 2 }}>
                                                        <Text fontSize={'default'} fontWeight='bold' color={'secondary'}>Payment Categories</Text>
                                                        <br />
                                                        <PropertyRent currentProperty={selectedProperty} keyPrefix={managePropertiesKeyPrefix} />
                                                    </TabPanel>
                                                    {/* <TabPanel px={{ base: 0, lg: 2 }} >
                                                        <Text fontSize={'default'} fontWeight='bold' color={'secondary'}>Setup Mess</Text>
                                                        <br />
                                                        <SetupMess allPropertyMess={allPropertyMess} currentProperty={selectedProperty} mutate={mutate} keyPrefix={managePropertiesKeyPrefix} />
                                                    </TabPanel> */}
                                                    <TabPanel px={{ base: 0, lg: 2 }}>
                                                        <Text fontSize={'default'} fontWeight='bold' color={'secondary'}>Payment Settings</Text>
                                                        <br />
                                                        <PaymentSettings currentProperty={selectedProperty} />
                                                    </TabPanel>
                                                </TabPanels>
                                            </Tabs>
                                        </Box>
                                    </Box>
                                }
                            </HStack>
                            <br />
                        </>
                        :
                        <EmptyPage />
                    }
                </Box>
            </CustomContainer>
            {rentModal && <AddTenantRentModal tenant={rentModal} visible={rentModal} closeModal={() => openRentModal(false)} />}
            {addModal ?
                <AddNewPropertyModal visible={addModal} defaultData={addModal?.id ? addModal : null} closeModal={handleAddModal} />
                :
                null
            }
        </Box>
    )
}

const PropertyCard = ({ property, selected, onClick }) => {
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allUnits } = useUnitsData(property.id, { propertyId: property.id }, true)

    const state = allStates?.length ? find(allStates, state => state.id === property.state) : null
    const city = state?.cities?.length ? find(state.cities, city => city.id === property.district) : null

    const allRooms = useMemo(() => {
        if (allUnits?.length)
            return _.chain(allUnits).map(d => d.sharing).sum().value()
        return 0
    }, [allUnits])

    const occupiedBeds = useMemo(() => {
        if (allUnits?.length) {
            return _.chain(allUnits).flatMap(d => d.unitTenants).compact().value().length
        } return 0
    }, [allUnits])

    return (
        <HStack align={'stretch'} px={1}>
            <Box flex={1} onClick={onClick} p={4} border='1px solid' borderColor={'gray.100'} borderRadius={6}
                boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                {...selected ? {
                    borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                } : { borderLeft: '6px solid', borderLeftColor: 'white' }}
                cursor={'pointer'} _hover={{
                    background: 'gray.100', borderLeft: '6px solid', borderLeftColor: 'defaultColor.500',
                    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
                }}
                borderRightColor={'#fff'}
                transition='all .2s'
            >
                <VStack align={'left'}>
                    <Text fontSize={'xs'} color={'gray.500'} noOfLines={2} wordBreak={'break-all'}>{join(compact([state?.name, city?.name, property.town, property.streetAddress, property.pincode]), ', ')}</Text>
                    <Text color={selected && 'defaultColor.500'} fontSize={{ base: 'sm', xl: 'large' }}>{property.name}</Text>
                    <Flex wrap={'wrap'}>
                        <FacilityCount selected={selected} count={property.noOfFloors} label={'Total Floors'} icon={BiLayer} />
                        <FacilityCount selected={selected} count={allUnits?.length || 0} label={'Total Rooms'} icon={BsDoorOpen} />
                        <FacilityCount selected={selected} count={allRooms} label={'Total Beds'} icon={RiHotelBedLine} />
                        <FacilityCount selected={selected} count={occupiedBeds} label={'Occupied Beds'} icon={RiHotelBedFill} />
                    </Flex>
                </VStack>
            </Box>
            <HStack pos={'relative'} width={4}>
                <Box>
                    {selected ?
                        <AiFillCaretRight color='#D6DBDF' fontSize={30}
                            boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}
                        />
                        :
                        null
                    }
                </Box>
            </HStack>
        </HStack>
    )
}

const FacilityCount = ({ count, label, icon, selected }) => {
    return (
        <Box pr={5} pb={2}>
            <Tooltip label={label}>
                <HStack spacing={1}>
                    <Icon as={icon} color={selected ? 'defaultColor.500' : 'gray'} />
                    <Text color={selected ? 'defaultColor.500' : 'gray.600'}>{count}</Text>
                </HStack>
            </Tooltip>
        </Box>
    )
}