import React from 'react'
import { Box, Flex, FormControl, FormLabel, Input, Switch, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { find, map } from 'lodash';
import { RoomPayAmount } from '.';
import { installment_types } from '../../Constants';

export function MobileViewPaymenCategory(props) {
    const { dueCategories, categories, handleEnable, unitRoomTypes, changeSharingTypeAmount, handleCreatePropertyDue } = props;
    return (
        <Box>
            {
                map(dueCategories, (cat, catId) => {
                    const category = categories[catId]
                    return (
                        <Box mt={6}>
                            <Box>
                                <Flex mb={2} justify='space-between'>
                                    <Box>
                                        <Box fontWeight='bold'>{category?.name}</Box>
                                        <Box color='red.400' fontStyle={"italic"} fontSize="xs">{installment_types[category?.installmentType]?.title}</Box>
                                    </Box>
                                    <Flex mr={2} align='center'>
                                        <FormControl display='flex' alignItems='center'>
                                            <FormLabel mb='0'>
                                                active
                                            </FormLabel>
                                            <Switch isChecked={cat.isEnabled} onChange={e => handleEnable(e.target.checked, catId, cat.propertyDueStatus?.id)} ></Switch>
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Table variant='simple'>
                                    <Tbody>
                                        {
                                            map(cat.unitRoomTypes, (unitType, typeId) => {
                                                const unitRoomType = find(unitRoomTypes, t => t.id === typeId)
                                                const roomTypeData = dueCategories[catId].unitRoomTypes[typeId];
                                                return (
                                                    <Tr>
                                                        <Td>
                                                            <Text color={'defaultColor.500'}>{unitRoomType?.typeName}</Text>
                                                        </Td>
                                                        <Td p={0}>
                                                            <Table>
                                                                {
                                                                    unitType.sharing_types.map((sharing) => {
                                                                        return (
                                                                            <RoomPayAmount cat={cat} sharing={sharing} changeSharingTypeAmount={changeSharingTypeAmount} catId={catId} typeId={typeId} dueCategories={dueCategories} handleCreatePropertyDue={handleCreatePropertyDue} />
                                                                        )
                                                                    })
                                                                }
                                                            </Table>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}
