import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Avatar, Badge, Box, Button, Flex, FormControl, Heading, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import _ from 'lodash'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useCurrentProperty } from '../../app/Context'
import { SHARINGS } from '../../Constants'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useSearchTenant } from '../../services/api/useSearchTenant'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { useUserData } from '../../services/api/useUserData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { floorName } from '../../utils/Helper'
import AddNewTenant from '../ManageTenant/AddNewTenant'
import { RoomModal } from '../ManageTenant/RoomModal'

export const SelectTenantModal = ({ visible, closeModal, currentUnit, mutate, property, openRentModal }) => {
    const [contact, setContact] = useState('')
    const [roomModal, openRoomModal] = useState()
    const [filterIndx, changeFilterIndx] = useState(0)

    const { organisation } = useUserData(true)
    const { currentProperty } = useCurrentProperty()
    const { data: allTenant, loading: loadingTenant } = useSearchTenant(filterIndx && URIS.GET_SEARCHED_TENANTS + currentUnit?.id + filterIndx,
        { contact: contact, limit: 100, organisationId: organisation?.id, propertyId: currentProperty?.id },
        true,
        {
            onSuccess: d => !d.data?.length ? closeModal({ ...currentUnit, contact }) : null
        }
    )
    const { data: unitRoomTypes } = useUnitRoomTypes(property?.id, { propertyId: property?.id })

    const { request: assingRoomAction } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            mutate()
            openRoomModal()
            closeModal()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })
    const handleChangeContact = (e) => {
        setContact(e.target.value)
    }

    const handleSearch = (e) => {
        e.preventDefault()
        changeFilterIndx(d => ++d)
    }

    const handleAddTenantModal = () => {
        closeModal({ ...currentUnit, contact })
    }

    const handleAssingModal = (tenant) => {
        openRoomModal(d => d ? null : tenant)
    }

    const handleAssign = () => {
        const data = { unitId: currentUnit.id, tenantId: roomModal.tenant?.id }
        assingRoomAction({ method: "POST", data })
    }

    const unitRoomType = useMemo(() => {
        if (unitRoomTypes?.length) {
            return _.find(unitRoomTypes, t => t.id === currentUnit.unitRoomTypeId)
        } return null
    }, [unitRoomTypes, currentUnit])

    return (
        <Modal size={'2xl'} isOpen={visible} onClose={() => closeModal()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Tenant</ModalHeader>
                <ModalCloseButton />
                <ModalBody minH={300}>
                    <Box>
                        <HStack>
                            <Box width={100}>
                                <Text>Property : </Text>
                            </Box>
                            <Text color={'defaultColor.500'}>{`${currentUnit.property?.name}`}</Text>
                        </HStack>
                        <Flex wrap={'wrap'}>
                            <HStack flex={1}>
                                <Box width={100}>
                                    <Text>Floor : </Text>
                                </Box>
                                <Text color={'defaultColor.500'}>{`${floorName(currentUnit.floorNo)}`}</Text>
                            </HStack>
                            <HStack flex={1}>
                                <Box width={100}>
                                    <Text>Room : </Text>
                                </Box>
                                <Text color={'defaultColor.500'}>{`${currentUnit.name}`}</Text>
                            </HStack>
                        </Flex>
                        <Flex wrap={'wrap'}>
                            <HStack flex={1}>
                                <Box width={100}>
                                    <Text>Room Type : </Text>
                                </Box>
                                <Text color={'defaultColor.500'}>{unitRoomType?.typeName}</Text>
                            </HStack>
                            <HStack flex={1}>
                                <Box width={100}>
                                    <Text>Sharing Type : </Text>
                                </Box>
                                <Text color={'defaultColor.500'}>{SHARINGS[currentUnit.sharing].title}</Text>
                            </HStack>
                        </Flex>
                        <br />
                        <form onSubmit={handleSearch}>
                            <FormControl isRequired>
                                <HStack mb={2}>
                                    <Input
                                        autoFocus
                                        onChange={handleChangeContact}
                                        name='contact'
                                        maxLength={10}
                                        pattern='[6-9]{1}[0-9]{9}'
                                        size={'md'} placeholder='Tenant Contact'
                                    />
                                    <IconButton isLoading={loadingTenant} type='submit' size={'md'} icon={<AiOutlineArrowRight />} />
                                </HStack>
                                <Text color={'secondary'} fontSize='xs'>* contact of 10 digits</Text>
                            </FormControl>
                        </form>
                        <br />
                        <LoadingContainer loading={loadingTenant}>
                            <HStack justifyContent={'center'} pt={6}>
                                <Button onClick={handleAddTenantModal} leftIcon={<AddIcon />}>Add New and Assign</Button>
                            </HStack>
                            <Flex wrap={'wrap'} spacing={4} alignItems={'stretch'}>
                                {allTenant?.length ?
                                    allTenant.map(tenant => {
                                        const unit = tenant.unitTenants?.[0]
                                        const room = unit ? `Room: ${floorName(unit.unit.floorNo)} (${unit.unit.name})` : null
                                        return (
                                            <>
                                                <TenantCard handleAssingModal={() => handleAssingModal(tenant)} unit={unit} room={room} tenant={tenant} />
                                            </>
                                        )
                                    }
                                    )
                                    :
                                    null
                                }
                            </Flex>
                        </LoadingContainer>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme={'gray'} onClick={() => closeModal()}>Cancel</Button>
                    </HStack>
                </ModalFooter>
                {roomModal && <RoomModal property={property} handleAssign={handleAssign} defaultData={currentUnit} visible={roomModal} closeModal={handleAssingModal} />}
            </ModalContent>
        </Modal>
    )
}

const TenantCard = ({ tenant, room, unit, handleAssingModal }) => {
    const { allProperties } = useUserData(true)
    const property = _.find(allProperties, p => p.id === unit?.unit.propertyId)

    return (
        <HStack minW={300} alignItems={'stretch'} px={4} flex={6} height='full' py={6}>
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}
                rounded={'lg'}
                p={4}
                textAlign={'center'}>
                <Avatar size={'xl'} src={tenant.tenant.avatar} alt={'Avatar Alt'} mb={4} pos={'relative'} />
                <Heading fontSize={'lg'} fontFamily={'body'}>
                    {tenant.tenant.name}
                </Heading>
                <Text fontWeight={600} color={'gray.500'} mb={4}>{tenant.tenant.contact}</Text>
                {room ?
                    <Text
                        textAlign={'center'}
                        color={'gray.700'}
                        px={3}
                    >
                        {`${property?.name} - ${room}`}
                    </Text>
                    :
                    <Box pb={5}>
                        <Text
                            textAlign={'center'}
                            color={'red.700'}
                            px={3}
                        >
                            Not Assigned
                        </Text>
                    </Box>
                }

                <Stack mt={8} direction={'row'} spacing={4}>
                    {room ?
                        <Button
                            flex={1}
                            disabled
                            colorScheme='green'
                            fontSize={'sm'}
                            rounded={'full'}
                        >
                            Room Already Assigned
                        </Button>
                        :
                        <Button
                            onClick={handleAssingModal}
                            flex={1}
                            fontSize={'sm'}
                            rounded={'full'}
                        >
                            Assign Room
                        </Button>
                    }
                </Stack>
            </Box>
        </HStack>
    )
}