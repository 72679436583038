import { Box, Button, Flex, Heading, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { LoadingContainer } from "../../../ui/LoadingContainer"
import { DueCategory } from "../../TenantPayment/TenantSlips"
import { PaymentModal } from "../../TenantPayment/PaymentModal"
import { size } from "lodash"


export const TenantDueAmounts = (props) => {

    const { loading, generatedDueCategories, tenantProperty, orgDueCategories, propertyDueCategories, unitTenant, tenant, mutate, allProperties } = props;

    const { onOpen, onClose, isOpen } = useDisclosure()

    const _close = () => {
        onClose()
        mutate()
    }

    return <Box>
        <LoadingContainer loading={loading}>

            <Box my={2} px={3} >
                <Flex justifyContent={'space-between'} alignItems='center' >
                    <Box></Box>
                    <Button isDisabled={size(generatedDueCategories) === 0} colorScheme={'red'} size="md" onClick={onOpen}>Pay Bill</Button>
                </Flex>
                <br />
                {size(generatedDueCategories) > 0 ?
                    <VStack align={'stretch'} mt={2} spacing={4} overflowY={'auto'} h={'70vh'} >
                        {
                            generatedDueCategories.map(category => {
                                return (
                                    <DueCategory
                                        category={category}
                                        tenantPropertyId={tenantProperty?.id}
                                        key={category.id}
                                        tenantProperty={tenantProperty}
                                        orgDueCategories={orgDueCategories}
                                        propertyDueCategories={propertyDueCategories}
                                        allProperties={allProperties}
                                        isTenant={true}
                                    />
                                )
                            })
                        }
                    </VStack>
                    :
                    <Box py={4}>
                        <Text color={'secondary'}>No due payments available</Text>
                    </Box>
                }
            </Box>
        </LoadingContainer>
        {isOpen && <PaymentModal
            visible={isOpen}
            closeModal={_close}
            tenantData={tenant}
            unitTenant={unitTenant}
            tenantProperty={tenantProperty}
            isTenant={true}
            tenant={tenant?.tenant ?? {}}
        />}
    </Box>
}