import { Box, Button, Flex, HStack, Tag, Text } from "@chakra-ui/react"
import _, { find, map } from "lodash";
import { displayDateFormat, webView } from '../../../utils/Helper';
import { MdSync } from "react-icons/md";
import { useState } from "react";
import { useApiRequest } from "../../../services/api/useApiRequest";
import { URIS } from "../../../services/api";


export const OnlinePayments = ({ onlinePayments, tenantDues, orgDueCategories, refetch2 }) => {

    const [refetching, setRefetching] = useState({})

    const { request, loading } = useApiRequest(URIS.REFETCH_ONLINE_PAYMENT, {
        onCompleted: d => {
            refetch2()
        },
        onError: d => { },
    })

    const _refetch = (op) => {
        setRefetching(op)
        if (op) {
            request({ method: 'POST', data: { onlinePaymentId: op.id } })
        }
    }

    return <Box py={3} >
        {map(onlinePayments, op => {
            const payment = op.meta;
            const paymentCategories = payment.receipts?.map(ins => {
                const tenantDue = find(tenantDues, d => d.id === ins.tenantDuesId)
                const dueCategory = find(orgDueCategories, d => d.id === tenantDue?.propertyDueCategory?.dueCategoryId) || ins.paymentDetails_json
                return { ...dueCategory, fromDate: tenantDue?.from_date, installment: ins }
            })
            return <OnlinePaymentCard
                op={op}
                paymentCategories={paymentCategories}
                loading={loading}
                refetching={refetching}
                _refetch={_refetch}
            />
        })}
    </Box>
}

const OnlinePaymentCard = ({ op, paymentCategories, refetching, loading, _refetch }) => {
    const payment = op.meta ?? {}
    return <Box bg={'white'} key={op.id} py={2} px={4} border='1px solid' borderRadius={'6'}
        boxShadow='rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' borderColor={'gray.100'} mb={4}
    >
        <HStack width={'100%'} mb={2} justifyContent='space-between'>
            <Text color={'gray.600'} fontWeight='bold'>{_.join(paymentCategories.map(c => c.name), ', ')}</Text>
        </HStack>

        <Flex wrap={'wrap'} spacing={4} color='secondary'>
            <HStack>
                <Text>Total Amount: </Text>
                <Text color={'defaultColor.500'}>₹ {payment.amount}</Text>
            </HStack>
            <Box px={2} {...webView}>
                <Text>|</Text>
            </Box>
            <Text>Date of Payment: <b>{displayDateFormat(payment.paymentDate, 'LL')}</b></Text>
        </Flex>

        <Flex wrap={'wrap'} justify={'space-between'} mt={5}>
            <Flex wrap={'wrap'}>
                <Tag colorScheme={op.status === 'success' ? 'green' : op.status === "failed" ? 'red' : 'orange'} >{op.status}</Tag>
                {op.status === 'pending' && <Box>
                    <Button
                        ml={3} leftIcon={<MdSync />}
                        isLoading={loading && refetching.id === op.id}
                        onClick={() => _refetch(op)}
                    >Refetch</Button>
                </Box>}
            </Flex>
        </Flex>
    </Box>
}