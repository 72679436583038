import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'


export function useTenantProfile(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.SINGLE_TENANT_PROFILE+q, () => apis.getSingleTenantProfile(params), q, revalidate)

    return {
        data, error, loading, mutate
    }
}