import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Avatar, Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useGuardianData } from '../../services/api/useGuardianData'
import { LoadingContainer } from '../../ui/LoadingContainer'
import { UploadImage } from '../../ui/UploadImage'
import { useAppContext } from '../../app/Context'
import { ALL_USER_ROLES, staffPermissions } from '../../Constants'

export const GuardianDetails = ({ currentTenant }) => {
    const [allGuardians, setGuardians] = useState([{}])
    const [openUploadProfile, setOpenUploadProfile] = useState(null)

    const { hasAccess, hasPermission } = useAppContext()

    const {data:guardianData, loading:loadingGet, mutate} = useGuardianData(currentTenant?.tenantId, {tenantId:currentTenant?.tenantId})
    
    const {request:addGuardianAction, loading:loadingAdd} = useApiRequest(URIS.ADD_GUARDIAN, {
        onCompleted:d => {
            mutate()
        },
        onError:d => {},
        showAlert:true,
        showLoader:true
    })

    useEffect(() => {
        if(guardianData?.length){
            const data = guardianData.map(grd => ({id:grd.id , avatar: grd.avatar, name:grd.name, contact:grd.contact, type:grd.type, relation:grd.relation, gender:grd.gender, occupation:grd.occupation, address:grd.address}))
            setGuardians(data)
        }
    }, [guardianData])

    const handleChange = (indx, type, value) => {
        const guardians = [...allGuardians]
        guardians[indx][type] = value
        setGuardians(guardians)
    }
    
    const handleSubmit = (e, indx) => {
        e.preventDefault()
        if(allGuardians[indx].id){
            const data = {...allGuardians[indx]}
            addGuardianAction({method:'PATCH', data})
        }
        else{
            const data = {...allGuardians[indx], tenantId:currentTenant.tenantId}
            addGuardianAction({method:'POST', data})
        }
    }

    const handleAddMore = () => {
        setGuardians(d => [...d, {}])
    }

    const uploadProfile = (e) => {
        setOpenUploadProfile(e)
    }

    return(
        <Box py={4} >
            <LoadingContainer loading={loadingGet}>
                <Flex align={'center'} justifyContent={'space-between'}>
                    <Heading>Guardian Details</Heading>
                    <Button onClick={handleAddMore} leftIcon={<AddIcon/>} >Add More</Button>
                </Flex>
                {allGuardians.map((guardian, i) =>
                    <Flex key={i} w='100%' align={'start'} my={5} p={2} flexWrap='wrap' justifyContent='space-between'>
                        <Box pt={[6, 6, 0]} w={['100%', '100%', '100%']}>
                            <form onSubmit={(e) => handleSubmit(e, i)}>
                                <Flex>
                                    <Box px={5} align="center" mt={5}>
                                        <Avatar size={"lg"} src={guardian?.avatar} />
                                        <Button mt={2} onClick={() => uploadProfile({ guardian, i })}>Upload</Button>
                                    </Box>
                                    <Flex flexWrap='wrap'>
                                        <FromContainer>
                                            <FormControl isDisabled={!hasAccess(staffPermissions.EDIT_TENANT_NAME)} isRequired>
                                                <FormLabel>Name</FormLabel>
                                                <Input name='name' placeholder='Name' onChange={e => handleChange(i, 'name', e.target.value)} value={guardian.name} />
                                            </FormControl>
                                        </FromContainer>
                                        {
                                            hasAccess(staffPermissions.VIEW_TENANT_CONTACT) || hasPermission(ALL_USER_ROLES.OWNER) ? 
                                                <FromContainer>
                                                    <FormControl isRequired>
                                                        <FormLabel>Contact</FormLabel>
                                                        <Input name='contact' placeholder='contact' maxLength={10} pattern='[6-9]{1}[0-9]{9}'
                                                            onChange={e => handleChange(i, 'contact', e.target.value)}
                                                            value={guardian.contact}
                                                        />
                                                    </FormControl>
                                                </FromContainer>
                                                : null
                                        }
                                        <FromContainer>
                                            <FormControl isDisabled={!hasAccess(staffPermissions.EDIT_TENANT_NAME)} >
                                                <FormLabel>Guardian Type</FormLabel>
                                                <Select placeholder='select guardian type'
                                                    onChange={e => handleChange(i, 'type', e.target.value)}
                                                    value={guardian.type}
                                                >
                                                    <option value="LOCAL_GUARDIAN">Local Guardian</option>
                                                    <option value="GUARDIAN">Guardian</option>
                                                </Select>
                                            </FormControl>
                                        </FromContainer>
                                        <FromContainer>
                                            <FormControl>
                                                <FormLabel>Relation</FormLabel>
                                                <Select placeholder='select relation'
                                                    onChange={e => handleChange(i, 'relation', e.target.value)}
                                                    value={guardian.relation}
                                                >
                                                    <option value="FATHER">Father</option>
                                                    <option value="MOTHER">Mother</option>
                                                    <option value="BROTHER">Brother</option>
                                                    <option value="SISTER">Sister</option>
                                                    <option value="OTHER">OTHER</option>
                                                </Select>
                                            </FormControl>
                                        </FromContainer>
                                        <FromContainer>
                                            <FormControl>
                                                <FormLabel>Gender</FormLabel>
                                                <RadioGroup id="gender" w={['96%', '90%', '90%', '90%']}
                                                    onChange={(e) => handleChange(i, 'gender', e)}
                                                    value={guardian.gender}
                                                >
                                                    <Stack direction='row'>
                                                        <Radio value={'MALE'}>Male</Radio>
                                                        <Radio value={'FEMALE'}  >Female </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            </FormControl>
                                        </FromContainer>
                                        <FromContainer>
                                            <FormControl>
                                                <FormLabel>Occupation</FormLabel>
                                                <Input placeholder='occupation'
                                                    onChange={(e) => handleChange(i, 'occupation', e.target.value)}
                                                    value={guardian.occupation}
                                                />
                                            </FormControl>
                                        </FromContainer>
                                        <HStack width={'100%'} justifyContent={'right'} mt={6}>
                                            <Button loading={loadingAdd} type='submit'>Save</Button>
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </form>
                        </Box>
                    </Flex>
                )}
                {openUploadProfile && <UploadGuardianProfile visible={openUploadProfile} uploadImage={handleChange} closeModal={() => setOpenUploadProfile(null)} />}
            </LoadingContainer>
        </Box>
    )
}

const FromContainer = ({children}) => {
    return(
        <Box width={['100%', '100%', '33%']} pr={8} pb={4}>
            {children}
        </Box>
    )
}

export const UploadGuardianProfile = ({ visible, closeModal, uploadImage }) => {
    const [avatar, changeAvatar] = useState(visible?.guardian?.avatar)
    const handleSave = () => {
        uploadImage(visible.i, 'avatar', avatar)
        closeModal()
    }

    const handleChange = (e) => {
        changeAvatar(e?.url);
    }
    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload Guardian Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Choose Image</FormLabel>
                        <UploadImage defaultUrl={avatar} getImage={handleChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}