import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, VStack, FormControl, FormLabel, Input, Select,
} from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import { useUserData } from "../../services/api/useUserData";
import { useEffect } from "react";
import { useStaffData } from "../../services/api/useStaffData";


export const AddMessModal = ({ isOpen, onClose, messData, resetMess, allStaff }) => {

    const { organisation } = useUserData(true)

    //react-hook-form
    const { handleSubmit, register, reset, setValue } = useForm()

    useEffect(() => {
        if (messData) {
            setValue("name", messData?.name)
            setValue("remark", messData?.remark)
            setValue("inchargeId", messData?.inchargeId)
        }
    }, [messData])

    const { request: createMessMutate, } = useApiRequest(URIS.ADD_MESS, {
        onCompleted: d => {
            resetMess()
        },
        showAlert: true,
        showLoader: true,
    })


    //Add staff main submit function
    const _submit = (data) => {
        if (messData && messData?.id) {
            createMessMutate({
                method: 'PATCH', data: {
                    id: messData.id,
                    name: data?.name,
                    remark: data?.remark,
                    inchargeId: data?.inchargeId ? data?.inchargeId : null,
                    organisationId: organisation.id,
                }
            })
        } else {
            createMessMutate({
                method: 'POST', data: {
                    name: data?.name,
                    remark: data?.remark,
                    inchargeId: data?.inchargeId ? data?.inchargeId : null,
                    organisationId: organisation.id,
                }
            })
        }
        modalOnCloseHandler()
    }

    function modalOnCloseHandler() {
        reset()
        onClose()
    }

    useEffect(() => {
        if (resetMess)
            resetMess()
    }, [])

    return <Modal isOpen={isOpen} onClose={modalOnCloseHandler}>
        <ModalOverlay />
        <ModalContent>
            <form onSubmit={handleSubmit(_submit)}>
                <ModalHeader>{messData ? "Edit" : "Add New"} Mess</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w="100%" spacing={4}>

                        <FormControl w="100%" mb={2} isRequired>
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <Input
                                style={{ width: "100%" }}
                                id="name" type="text"
                                {...register('name')}
                                placeholder="Name"

                            />
                        </FormControl>

                        <FormControl w="100%" mb={2} isRequired>
                            <FormLabel htmlFor="remark">Remark</FormLabel>
                            <Input
                                style={{ width: "100%" }}
                                id="remark" type="text"
                                {...register('remark')}
                                placeholder="Remark"

                            />
                        </FormControl>

                        <FormControl w="100%" mb={2} >
                            <FormLabel htmlFor="remark">Select Incharge</FormLabel>
                            <Select {...register('inchargeId')} placeholder="-select-">
                                {allStaff?.map(staff => {
                                    return <option key={staff?.staffId} value={staff?.staffId}>{staff?.staff?.name}</option>
                                })}
                            </Select>
                        </FormControl>

                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} colorScheme="gray" onClick={modalOnCloseHandler}>
                        Close
                    </Button>
                    <Button type="submit">{messData ? "Update" : "Save"}</Button>
                </ModalFooter>
            </form>
        </ModalContent>
    </Modal >
}