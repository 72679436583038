import React, { useState } from 'react'
import { Alert, AlertIcon, Button, FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, VStack } from '@chakra-ui/react'
import { find } from 'lodash'
import moment from 'moment'
import { DatePicker } from 'react-widgets/cjs'
import { SHARINGS } from '../../Constants'
import { useUnitRoomTypes } from '../../services/api/useUnitRoomTypes'
import { useUserData } from '../../services/api/useUserData'
import { floorName } from '../../utils/Helper'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'

export const RoomModal = ({ visible, closeModal, defaultData, handleAssign, assignedUnitTenant, property, assignedProperty, loading }) => {
    const [joiningDate, setdate] = useState(new Date())
    const [remark, changeRemark] = useState()

    const assignedUnit = assignedUnitTenant?.unit

    const { organisation } = useUserData(true)
    const { data: unitRoomTypes } = useUnitRoomTypes(organisation?.id, { organisationId: organisation?.id })

    const handleAssignRoom = () => {
        handleAssign(moment().format('YYYY/MM/DD'))
    }

    const { request: leaveRoomAction } = useApiRequest(URIS.ASSING_TENANR, {
        onCompleted: d => {
            handleAssignRoom()
        },
        onError: d => { },
        showLoader: true,
        showAlert: true,
    })


    const handleSubmit = () => {

        if (assignedUnit) {
            const data = { id: assignedUnitTenant.id, remark, leavingDate: moment().format('YYYY/MM/DD') }
            leaveRoomAction({ method: 'PATCH', data })
        } else {
            handleAssign(joiningDate ? moment(joiningDate).format('YYYY/MM/DD') : '')
        }
    }

    const handleDate = (date) => {
        setdate(date)
    }

    const handleChangeRemark = (e) => {
        changeRemark(e.target.value)
    }

    return (
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{assignedUnit ? 'Change Room' : 'Assign To'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align={'stretch'}>
                        {!assignedUnit ?
                            <>
                                <HStack>
                                    <Text>Property: </Text>
                                    <Text color={'defaultColor.500'}>{property.name}</Text>
                                </HStack>
                                <HStack>
                                    <Text>Floor: </Text>
                                    <Text color={'defaultColor.500'}>{floorName(defaultData.floorNo)}</Text>
                                </HStack>
                                <HStack>
                                    <Text>Room Type: </Text>
                                    <Text color={'defaultColor.500'}>
                                        {find(unitRoomTypes, t => t.id === defaultData.unitRoomTypeId)?.typeName}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text>Room No: </Text>
                                    <Text color={'defaultColor.500'}>{defaultData.name}</Text>
                                </HStack>
                                <HStack>
                                    <Text>Sharing: </Text>
                                    <Text val color={'defaultColor.500'}>{SHARINGS[defaultData.sharing]?.title}</Text>
                                </HStack>
                                <FormControl pt={4}>
                                    <FormLabel>Joining Date</FormLabel>
                                    <DatePicker
                                        style={{ fontSize: 14 }}
                                        value={joiningDate}
                                        onChange={handleDate}
                                    />
                                </FormControl>
                            </>
                            :
                            <>
                                <HStack>
                                    <Text>Current Room: </Text>
                                    <Text val color={'defaultColor.500'}>
                                        {`${assignedProperty.name} ${floorName(assignedUnit.floorNo)} ( ${assignedUnit.name} ) ( ${find(unitRoomTypes, t => t.id === assignedUnit.unitRoomTypeId)?.typeName} )`}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text>New Room: </Text>
                                    <Text val color={'defaultColor.500'}>
                                        {`${property.name} ${floorName(defaultData.floorNo)} ( ${defaultData.name} ) ( ${find(unitRoomTypes, t => t.id === defaultData.unitRoomTypeId)?.typeName} )`}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <FormControl isRequired>
                                        <FormLabel>Remark</FormLabel>
                                        <Textarea placeholder='Remark for changing room' onChange={handleChangeRemark} />
                                    </FormControl>
                                </HStack>
                            </>

                        }
                    </VStack>
                    {assignedUnit ?
                        <Alert mt={4} fontSize='sm' status='info'>
                            <AlertIcon fontSize={'sm'} />
                            Room will be reassigned from current room to new room.
                        </Alert>
                        :
                        null
                    }
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button colorScheme={'gray'} onClick={closeModal}>Cancel</Button>
                        <Button disabled={assignedUnit && !remark} isLoading={loading} onClick={handleSubmit}>Assign</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}