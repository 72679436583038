import React, { useEffect, useReducer, useState, useMemo, useRef } from 'react'
import { Box, Button, Center, Divider, Flex, HStack, IconButton, Input, InputGroup, InputLeftAddon, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Text, Textarea, Tooltip, useToast, VStack } from '@chakra-ui/react'
import _, { compact, filter, find, groupBy, map, sumBy } from 'lodash'
import { DatePicker } from 'react-widgets/cjs'
import { allPaymentModes, staffPermissions } from '../../Constants'
import { FormReducer } from '../../utils/FormReducer'
import { dateFormat } from '../../utils/Helper'
import { useUserData } from '../../services/api/useUserData'
import { useApiRequest } from '../../services/api/useApiRequest'
import { URIS } from '../../services/api'
import { useNextSlipNo } from '../../services/api/useNextSlipNo'
import { useGetProperties } from '../../services/api/useGetProperties'
import { useTenantSlips } from '../../services/api/useTenantSlips'
import { useRefundHistory } from '../../services/api/useRefundHistory'
import { usePaymentDetails } from '../../services/api/usePaymentDetails'
import { useReactToPrint } from 'react-to-print'
import { Receipt } from './Receipt'
import moment from 'moment'
import { useAppContext } from '../../app/Context'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { EditIcon } from '@chakra-ui/icons'

export const RefundPaymentModal = ({ visible, closeModal, tenantData }) => {

    const { hasAccess } = useAppContext()
    const slipNoInputRef = useRef()
    const toast = useToast()

    const { data: allProperties } = useGetProperties(true);
    const unitTenant = tenantData?.unitTenants?.[0]

    const tenantProperty = useMemo(() => {
        if (unitTenant && allProperties?.length) {
            return find(allProperties, p => p.id === unitTenant.unit.propertyId)
        } else return null
    }, [unitTenant, allProperties])
    const [paymentData, changePaymentData] = useReducer(FormReducer, { amount: 0, date: new Date(), mode: 'offline' })
    const { organisation } = useUserData(true);

    const [selectedCategory, setSelectedCategory] = useState();
    const [printReciept, setPrintReciept] = useState(null);
    const [propertyDueCategories, setPropertyDueCategory] = useState([]);
    const [editSlipNo, setEditSlipNo] = useState()

    const selectCategory = (c) => {
        changePaymentData({ type: 'amount', value: '' })
        if (selectedCategory === c) {
            setSelectedCategory()
        } else {
            setSelectedCategory(c)
        }
    }

    const selectCategoryData = useMemo(() => {
        if (propertyDueCategories && selectedCategory) {
            return find(propertyDueCategories, c => c.id === selectedCategory)
        }
    }, [propertyDueCategories, selectedCategory])


    const { data: nextSlipNo } = useNextSlipNo(tenantProperty, { propertyId: tenantProperty?.id, prefix: tenantProperty?.company?.refund_prefix }, true)

    const { data: tenantDues } = useTenantSlips(tenantData?.id)
    const { mutate: mutateRefundHistory, data: refundHistory } = useRefundHistory(tenantData?.tenantId, { tenantId: tenantData.tenantId }, true)

    const { mutate: mutatePaymentDetails } = usePaymentDetails(tenantData?.id)
    const { user } = useUserData(true)

    const { request: refundAction, loading: loadingRefund } = useApiRequest(URIS.REFUND_PAYMENT, {
        onCompleted: d => {
            // mutate();
            mutateRefundHistory();
            mutatePaymentDetails();
            setPrintReciept(d);
            // closeModal();
        },
        onError: d => { },
        showAlert: true

    })

    const formatBillNo = (sequence) => {
        const no = parseInt(sequence) || 0
        return String(no).padStart(4, '0')
    }

    useEffect(() => {
        if (nextSlipNo)
            changePaymentData({ type: 'slipSequence', value: formatBillNo(nextSlipNo?.sequenceCounter) })
    }, [nextSlipNo])

    const handleChangeData = (type, value) => {
        if (type === 'amount' && value > selectCategoryData?.totalAmount) {
            return null
        }
        else {
            changePaymentData({ type, value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { slipSequence, cheque_no, bank_date, bank_name, mode, payment_details, amount, remark, date } = paymentData

        const data = {
            paymentDetailsData: {
                amount: amount ? parseInt(amount) : 0,
                remark: remark,
                payment_type: 'credit',
                payment_mode: mode,
                organisationId: organisation.id,
                propertyId: unitTenant?.unit.propertyId,
                tenantId: tenantData.tenant.id,
                receiptCounter: parseInt(slipSequence),
                paymentDate: moment(date).format('YYYY-MM-DD HH:mm'),
                paymentDetails_json: {
                    slipNo: tenantProperty?.company.refund_prefix + formatBillNo(slipSequence),
                    prefix: tenantProperty?.company.refund_prefix,
                    cheque_no, payment_details, bank_date: dateFormat(bank_date), bank_name
                },
                createdById: user.id,
                companyId: tenantProperty?.company.id,
                forceSlipNo: slipSequence !== formatBillNo(nextSlipNo?.sequenceCounter) ? 1 : 0
            },
            propertyId: unitTenant?.unit.propertyId,
            tenantId: tenantData.tenant.id,
            remark,
            amount: amount ? parseInt(amount) : 0,
            property_due_categoryId: selectedCategory,
        }

        if (!slipSequence) {
            return toast({
                status: "warning",
                title: "slip number required",
            })
        } else {
            refundAction({ method: 'POST', data })
        }
    }

    useEffect(() => {
        let newCategories = groupBy(tenantDues, 'property_due_categoryId');
        newCategories = compact(map(newCategories, (c, k) => {
            if (k && k !== 'null') {
                const existingRefundData = filter(refundHistory, r => r.property_due_categoryId === k && r.tenantId === tenantData?.tenantId && r.status !== 'cancelled');
                const existingRefundAmount = sumBy(existingRefundData, 'amount')
                const totalAmount = (sumBy(c, 'amount') ?? 0) - (sumBy(c, 'due_amount') ?? 0) - existingRefundAmount;
                return {
                    id: k,
                    name: c[0]?.propertyDueCategory?.dueCategory.name,
                    totalAmount: totalAmount < 0 ? 0 : totalAmount,
                }
            }
        }))
        setPropertyDueCategory(newCategories);
    }, [tenantDues, refundHistory, tenantData])

    const handleCloseRecieptModal = () => {
        setPrintReciept(null);
        closeModal();
    }

    const selectedDueCategoryData = useMemo(() => {
        if (selectedCategory) {
            const categoryData = find(tenantDues, c => c.propertyDueCategory?.id === selectedCategory)
            return categoryData?.propertyDueCategory.dueCategory
        }
    }, [selectedCategory, tenantDues])

    const _editSlipNo = () => {
        setEditSlipNo(d => !d)

        if (editSlipNo) {
            changePaymentData({ type: 'slipSequence', value: formatBillNo(nextSlipNo?.sequenceCounter) })
        } else {
            slipNoInputRef.current.focus()
        }
    }

    const disabled = !paymentData.amount || !paymentData.date || !paymentData.mode || !selectedCategory;

    return (
        <Box>
            <Modal size={'4xl'} isOpen={visible && !printReciept} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Refund Amount</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Flex wrap={'wrap'}>
                                <Box mr={6} minW={195}>
                                    <Box>Categories</Box>
                                    <Box mt={4}>
                                        {
                                            map(propertyDueCategories, c => {
                                                const selected = selectedCategory === c.id;
                                                return (
                                                    <VStack mb={2} _hover={{ borderLeft: '4px', borderLeftColor: 'defaultColor.500' }} transition='all .3s' cursor={'pointer'} border='1px solid' boxShadow={'md'} borderColor={'gray.200'} p={2} px={4} key={c.id}
                                                        onClick={() => selectCategory(c.id)}
                                                        background={selected && 'gray.100'}
                                                        align='start'
                                                        borderLeft={'4px'} borderLeftColor='white'
                                                        {...selected ? { background: 'gray.100', borderLeft: '4px', borderLeftColor: 'defaultColor.500' } : {}}
                                                    >
                                                        <Text fontWeight={'bold'}>{c.name}</Text>
                                                    </VStack>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                                <VStack spacing={4} align={'stretch'} mb={6}>
                                    <InputData required label={'Date'}
                                        input={
                                            <DatePicker readOnly value={paymentData.date} style={{ fontSize: 14 }} onChange={e => handleChangeData('date', e)} placeholder='date' />
                                        }
                                    />
                                    <InputData label={'Amount'} required
                                        input={
                                            <>
                                                <InputGroup mb={2}>
                                                    <InputLeftElement
                                                        pointerEvents='none'
                                                        children={'₹'}
                                                    />
                                                    <Input min={0}
                                                        onChange={e => handleChangeData('amount', e.target.value ? parseInt(e.target.value) : '')}
                                                        value={paymentData.amount} type='number'
                                                        placeholder={`max amount ${selectCategoryData?.totalAmount ?? ''}`}
                                                    />
                                                </InputGroup>
                                            </>
                                        }
                                    />
                                    <InputData label={'Slip Number'}
                                        input={
                                            <HStack align={'start'}>
                                                <Box>
                                                    <InputGroup>
                                                        <InputLeftAddon children={tenantProperty?.company?.refund_prefix} />
                                                        <Input
                                                            value={paymentData.slipSequence}
                                                            onChange={e => handleChangeData('slipSequence', e.target.value)}
                                                            type='number'
                                                            placeholder='slip no'
                                                            ref={slipNoInputRef}
                                                            readOnly={!editSlipNo}
                                                        />
                                                    </InputGroup>
                                                    <HStack justify={'right'}>
                                                        <Text color={'gray.400'} fontSize='xs'>*autogenerated</Text>
                                                    </HStack>
                                                </Box>
                                                {hasAccess(staffPermissions.EDIT_SLIP_NUMBER) ?
                                                    editSlipNo ?
                                                        <Tooltip label='Cancel'>
                                                            <IconButton onClick={_editSlipNo} colorScheme={'red'} variant={'ghost'} icon={<AiOutlineCloseCircle fontSize={20} />} />
                                                        </Tooltip>
                                                        :
                                                        <Tooltip label='Edit'>
                                                            <IconButton onClick={_editSlipNo} colorScheme={'blackAlpha'} variant={'ghost'} icon={<EditIcon />} />
                                                        </Tooltip>
                                                    :
                                                    null
                                                }
                                            </HStack>
                                        }
                                    />
                                    <InputData label={'Payment Mode'} required
                                        input={
                                            <RadioGroup value={paymentData.mode} onChange={e => handleChangeData('mode', e)}>
                                                <Flex wrap={'wrap'}>
                                                    {_.map(allPaymentModes, mode =>
                                                        <Box pr={2}>
                                                            <Radio key={mode.id} value={mode.type}>{mode.name}</Radio>
                                                        </Box>
                                                    )}
                                                </Flex>
                                            </RadioGroup>
                                        }
                                    />
                                    {paymentData.mode === 'dd' || paymentData.mode === 'neft' || paymentData.mode === 'rtgs' || paymentData.mode === 'online' || paymentData.mode === 'other' ?
                                        <>
                                            <InputData label={'Payment Method Details'}
                                                input={
                                                    <Textarea value={paymentData.payment_details} onChange={e => handleChangeData('payment_details', e.target.value)} type='number' placeholder='payment method details' />
                                                }
                                            />
                                        </>
                                        :
                                        null
                                    }
                                    {paymentData.mode === 'cheque' ?
                                        <>
                                            <InputData label={'Cheque Number'}
                                                input={
                                                    <Input value={paymentData.cheque_no} onChange={e => handleChangeData('cheque_no', e.target.value)} type='number' placeholder='6 digit cheque bumber' />
                                                }
                                            />
                                        </>
                                        :
                                        null
                                    }
                                    {paymentData.mode === 'cheque' || paymentData.mode === 'dd' ?
                                        <>
                                            <InputData label={'Bank Date'}
                                                input={
                                                    <DatePicker value={paymentData.bank_date} style={{ fontSize: 14 }} onChange={e => handleChangeData('bank_date', e)} placeholder='bank date' />
                                                }
                                            />
                                            <InputData label={'Bank Name'}
                                                input={
                                                    <Input value={paymentData.bank_name} onChange={e => handleChangeData('bank_name', e.target.value)} placeholder='bank name' />
                                                }
                                            />
                                        </>
                                        :
                                        null
                                    }
                                    <InputData label={'Remark'}
                                        input={
                                            <Textarea value={paymentData.remark} onChange={e => handleChangeData('remark', e.target.value)} type='number' placeholder='remark' />
                                        }
                                    />
                                </VStack>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <HStack>
                                <Button onClick={closeModal} colorScheme='gray'>Cancel</Button>
                                <Button disabled={disabled || loadingRefund} isLoading={loadingRefund} type='submit'>Save</Button>
                            </HStack>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
            {
                printReciept &&
                <PrintRecieptModal selectedDueCategoryData={selectedDueCategoryData} tenantData={tenantData} reciept={printReciept} onClose={handleCloseRecieptModal} />
            }
        </Box>
    )
}

const InputData = ({ label, input, required }) => {
    return (
        <Flex wrap={'wrap'}>
            <Box width={150} flex={1}>
                <Text _after={required && { content: '" *"', color: 'red' }}>{label}</Text>
            </Box>
            <Text> : &nbsp;&nbsp; </Text>
            <HStack flex={1}>
                <Box>
                    {input}
                </Box>
            </HStack>
        </Flex>
    )
}

const PrintRecieptModal = ({ tenantData, reciept, onClose, selectedDueCategoryData }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Modal isOpen={reciept} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box pb={4} textAlign='center' fontSize={18} fontWeight='bold'>
                        Print payment Reciept
                    </Box>
                    <Box display={'none'}>
                        <Receipt tenantData={tenantData} paymentCategories={[{ ...selectedDueCategoryData, installment: { amount: reciept.amount } }]} payment={reciept.paymentDetails} ref={componentRef} />
                    </Box>
                    <Divider />
                    <Center mt={6} mb={16}>
                        <Button colorScheme='blue' onClick={handlePrint}>Print Reciept</Button>
                    </Center>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}