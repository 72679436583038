import React, { useState } from 'react'
import { Box, Button, Checkbox, CheckboxGroup, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import _ from 'lodash';
import { useEffect } from 'react';
import { URIS } from '../../services/api';
import { useApiRequest } from '../../services/api/useApiRequest';
import { useGetProperties } from '../../services/api/useGetProperties';
import { AiOutlineDelete } from 'react-icons/ai';
import { useMemo } from 'react';
import { useStaffData } from '../../services/api/useStaffData';

export const AssignPropertyModal = ({ closeModal, singlestaffId, resetstaff }) => {
    const [selectproperty, setPropertyValue] = useState([])
    const [deleteModal, openDeleteModal] = useState()
    const { data: allProperties } = useGetProperties(true)

    const { data: singlestaff, mutate } = useStaffData(URIS.GET_STAFF, { staffId: singlestaffId, staffAccessDetails: 1 }, true)
    const { request: addRequest, loading: addLoading } = useApiRequest(URIS.ADD_ASSIGN_PROPERTY, {
        onCompleted: (d) => {
            mutate();
            closeModal()
        },
        onError: () => { },
        showAlert: true
    })

    let aa = _.map(selectproperty, d => ({ propertyId: d, staffId: singlestaff?.[0]?.staff.id }))
    useEffect(() => {
        if (singlestaff && singlestaff?.[0]?.staffAccessDetails?.propertyId?.length) {
            setPropertyValue(singlestaff[0].staffAccessDetails.propertyId)
        }
    }, [singlestaff])

    const handleSubmit = (e) => {
        e.preventDefault()
        addRequest({ method: 'POST', data: [...aa] })
    }

    const handleDeleteModal = (isDelete) => {
        openDeleteModal(isDelete)
    };
    const ff = useMemo(() => {
        if (allProperties?.length) {
            if (singlestaff?.[0]?.staffAccessDetails?.length)
                return _.differenceBy(allProperties.map(p => ({ ...p, propertyId: p.id })), singlestaff[0].staffAccessDetails, 'propertyId')
            else
                return allProperties
        } else return []
    }, [allProperties, singlestaff])

    return (
        <>
            <Modal isOpen={true} onClose={closeModal} size="4xl">
                <ModalOverlay />
                <form onSubmit={handleSubmit}>
                    <ModalContent>
                        <ModalHeader>{singlestaff?.[0]?.staff.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box border='1px solid #E5E8E8' >
                                <Text fontWeight='semibold' borderBottom={'1px solid #E5E8E8'} p={2} >Assigned Properties:</Text>
                                {singlestaff?.[0]?.staffAccessDetails.length ?
                                    <Box overflowX="scroll" className='scrollbar-1'>
                                        <Stack spacing={2} direction={['row']} p={3}  >
                                            {_.map(singlestaff[0].staffAccessDetails, d =>
                                                <HStack key={d?.id} spacing={4} border={'1px solid #E5E8E8'} p={1}>
                                                    <Box value={d?.property?.id}  >
                                                        {d?.property?.name}
                                                    </Box>
                                                    <Tooltip placement='top' label='Delete'>
                                                        <span><AiOutlineDelete onClick={() => handleDeleteModal(d?.id)} /></span>
                                                    </Tooltip>
                                                </HStack>
                                            )}
                                        </Stack>
                                    </Box>
                                    :
                                    <Box m={4}>No Data Assigned</Box>
                                }

                                {allProperties.length !== singlestaff?.[0]?.staffAccessDetails.length ?
                                    <>
                                        <Box borderTop={'1px solid #E5E8E8'}></Box>
                                        <Box maxH="60vh" overflowY="scroll" className='scrollbar-1' >
                                            <Text fontWeight='semibold' p={2}>Select Property</Text>
                                            <CheckboxGroup onChange={(value) => setPropertyValue(value)} value={selectproperty} >
                                                {ff.length ?
                                                    <>
                                                        {
                                                            _.map(ff, d =>
                                                                <Stack p={2} spacing={2} direction={['row', 'column']}>
                                                                    <Checkbox value={d.id}>{d.name}</Checkbox>
                                                                </Stack>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            _.map(allProperties, d =>
                                                                <Stack p={2} spacing={2} direction={['row', 'column']}>
                                                                    <Checkbox value={d.id}>{d.name}</Checkbox>
                                                                </Stack>
                                                            )
                                                        }
                                                    </>
                                                }

                                            </CheckboxGroup>
                                        </Box>
                                    </>
                                    :
                                    ''
                                }
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='submit' mr={3} isLoading={addLoading} >Submit</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
            {deleteModal && <ConfirmationModal visible={deleteModal} mutate={mutate} resetstaff={resetstaff} deleteModal={deleteModal} closeModal={handleDeleteModal} />}
        </>
    )
}

const ConfirmationModal = ({ visible, closeModal, deleteModal, resetstaff, mutate }) => {
    const { request: deleteStaffacessApi, } = useApiRequest(URIS.DELETE_ASSIGN_PROPERTY, {
        onCompleted: d => {
            closeModal(null);
            mutate();
        },
        showAlert: true,
        showLoader: true,
    })
    const confirmDelete = (id) => {
        deleteStaffacessApi({ method: 'DELETE', params: { id: id } })
    }
    const modalclose = () => {
        closeModal()
    }
    return (
        <Modal isOpen={visible} onClose={modalclose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmation!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to unAssign this property?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={modalclose}>
                        Close
                    </Button>
                    <Button variant='ghost' onClick={() => confirmDelete(deleteModal)}>Delete</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}