import { Avatar, Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { map } from "lodash"


export const TenantsModal = ({ isOpen, onClose, allTenants, _selectTenant, selectedTenant }) => {

    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay css={{
            backdropFilter: "blur(10px)",
        }} />
        <ModalContent>

            <ModalHeader>Select User</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
                <Box>
                    {map(allTenants, tenant => {
                        const tenantData = tenant.tenant;
                        return <Box key={tenantData.id} mb={3} >
                            <Flex alignItems={'center'}
                                cursor={'pointer'}
                                p={3}
                                border={'1px solid'}
                                borderColor={'gray.100'}
                                onClick={() => _selectTenant(tenant)}
                                _hover={tenant.id !== selectedTenant?.id ? { bg: "blue.100" } : {}}
                                bg={tenant.id === selectedTenant?.id ? 'blue.200' : ''}
                            >
                                <Box mr={3} >
                                    <Avatar src={tenantData?.avatar} size="md" name={tenantData.name} />
                                </Box>
                                <Box>
                                    <Box fontWeight={'bold'} >{tenantData.name}</Box>
                                    <Box>{tenant.registrationNo}</Box>
                                </Box>
                            </Flex>
                        </Box>
                    })}
                </Box>
            </ModalBody>

            <ModalFooter>
                {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button variant='ghost'>Secondary Action</Button> */}
            </ModalFooter>
        </ModalContent>
    </Modal>
}