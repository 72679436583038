import { Box, Button, Flex, Text, VStack, Image, Spacer, Stack, Skeleton } from "@chakra-ui/react";
import React, { useMemo, useRef } from "react";
import IdCardBgImage from "../../Image/IdCard-hostel.png"
import NoImage from "../../Image/no-image.jpg"
import QRCode from 'react-qr-code';
import { useGetProperties } from "../../services/api/useGetProperties";
import { AiOutlinePrinter } from 'react-icons/ai';
import { useReactToPrint } from 'react-to-print';
import { useApiRequest } from "../../services/api/useApiRequest";
import { URIS } from "../../services/api";
import { useIdCardData } from "../../services/api/useIdCardStore";
import { useUserData } from "../../services/api/useUserData";
import { useStaffData } from "../../services/api/useStaffData";
import moment from "moment";


export const TenantIdCard = ({ tenantData, dueDates }) => {

  const { organisation, user } = useUserData(true)
  const { data: allProperties } = useGetProperties(true);

  const { data: allIdCardHistory, loading, mutate } = useIdCardData(tenantData?.tenant?.id ? tenantData.tenant.id : null, { organisationId: organisation.id, tenantId: tenantData?.tenant?.id }, true)

  const { data: allStaff } = useStaffData(URIS.GET_STAFF, { organisationId: organisation.id, staffAccessDetails: 1 }, true)

  const { request: createIdCardHistoryMutate, } = useApiRequest(URIS.POST_ID_CARD_HISTORY, {
    onCompleted: d => {
      mutate()
    },
    // showAlert: true,
    // showLoader: true,
  })

  //id carrd data
  let idCardData = useMemo(() => {
    let data = {}

    data.avatar = tenantData?.tenant?.avatar ?? ""
    data.registrationNo = tenantData?.registrationNo ?? ""
    data.name = tenantData?.tenant?.name ?? "-"

    if (tenantData?.unitTenants?.length > 0) {
      data.hostal = allProperties?.find(d => d.id === tenantData?.unitTenants[0]?.unit?.propertyId)?.name ?? "-"
    }

    tenantData?.guardians?.forEach((d) => {
      if (d?.relation === "FATHER") {
        data.faName = d?.name ?? "-"
      }
    })
    if (!(data?.faName)) { data.faName = "-" }
    return data
  }, [tenantData])

  //crate hisotry of id card when prnit 
  const onPrint = () => {
    createIdCardHistoryMutate({
      method: 'POST', data: {
        documentType: "ID_CARD",
        entryDate: moment(dueDates).format("YYYY-MM-DD"),
        tenantId: tenantData?.tenant?.id,
        printedById: user?.id,
        organisationId: organisation.id,
      }
    })
  }

  //print id card 
  const idCard = useRef();
  const handlePrintIdCard = useReactToPrint({
    content: () => idCard.current,
  });

  const _handlePrint = () => {
    handlePrintIdCard();
    onPrint();
  };

  return <Box >
    <Flex>
      <Box w="40%" p={5}>
        <Box ref={idCard}>
          <IdCard idCardData={idCardData} dueDates={dueDates} />
        </Box>
        <Box textAlign="start">
          <Button mt={5} w={"80%"}
            isLoading={loading}
            colorScheme={'blue'}
            onClick={_handlePrint}
            leftIcon={<AiOutlinePrinter />}
          >Print</Button>
        </Box>
      </Box>

      <Box w={{ sm: "0%", md: "2%", lg: '10%', xl: "10%" }}></Box>

      <Box w="40%" p={2}>
        <Text size="lg" fontWeight="semibold" color="" >Print Entry Card History</Text>
        <Box py={3}>
          {loading ? <Stack>
            <Skeleton height='30px' />
            <Skeleton height='30px' />
            <Skeleton height='30px' />
          </Stack> :
            allIdCardHistory?.map(history => {
              let staffName = allStaff?.find(d => d?.id === history.id)?.name ?? "";
              let printByName = history?.printedById === user?.id ? user?.name : staffName;
              return <Flex key={history?.id} mt={3} p={1} borderBottom={"1px solid"} borderColor={"gray.300"} >
                <Box>
                  <Text>{moment(history?.createdAt).format('DD MMM YYYY hh:mm A')}</Text>
                  <Text fontSize={"15px"} fontWeight={"semibold"} color={"pink.500"} >ED: {moment(history?.entryDate).format("DD MMMM YYYY")}</Text>
                </Box>
                <Spacer />
                <Flex textAlign={"center"}>
                  <Text> {"By:"}&nbsp;</Text>
                  <Text fontWeight={"semibold"}>{printByName}</Text>
                </Flex>
              </Flex>
            })
          }
        </Box>
      </Box>
    </Flex>
  </Box>
}


const IdCard = ({ idCardData, dueDates }) => {

  return <Box pos='relative'>
    <Box pos='absolute' zIndex={1} top={'75pt'}
      w={'145pt'} h={'160pt'} overflow='hidden' left={'60pt'} bg='green.400'>
      <Image
        w='100%'
        h='100%'
        objectFit='cover'
        src={idCardData?.avatar ? idCardData.avatar : NoImage}
      />
    </Box>
    <Box
      w="96mm"
      h="138mm"
      align="left"
      bgImage={IdCardBgImage}
      bgSize="contain"
      bgRepeat={'no-repeat'}
      pos='relative'
      zIndex={2}
    >
      <Box h="212pt">
      </Box>
      <Box ml={"164pt"} mt={"-4pt"}>
        <Text fontSize={'10pt'} >
          {idCardData.registrationNo ?? '-'}
        </Text>
        <Text fontSize={'10pt'} mt={"3pt"} >
          {idCardData.name ?? '-'}
        </Text>
        <Text fontSize={'10pt'} mt={"2pt"}>
          {idCardData.faName ?? '-'}
        </Text>
      </Box>
      {/* <VStack alignItems={"start"} ml={"164pt"} gap={"8pt"} color="black" mt={"-4pt"} spacing={'-7pt'} >
      
      </VStack> */}


      {/* <VStack
        mt={'37pt'}
        align={'left'}
        pl={'35pt'}
        spacing={'-1pt'}
        color="black"
      >
       
      </VStack> */}



      <Box pos="absolute" top="292pt">
        <Box mt={'0pt'} pl={'35pt'}>
          <Text fontSize={'11pt'} fontWeight={"semibold"} >
            {dueDates || '-'}
          </Text>
        </Box>

      </Box>

      <Box pos='absolute' top="310pt" left={"10pt"} right={0}>
        <Flex w="100%">
          <Box mt={"12pt"} w="100%" flex={1} ml={3}>
            <Text fontSize={"12pt"} fontWeight={"semibold"} >Hostel</Text>
            <Text fontSize={'12pt'} >
              {idCardData.hostal || '-'}
            </Text>
          </Box>

          <Box flex={1} >
          </Box>

        </Flex>
      </Box>

      <Box  pos='absolute' top="280pt" left={"140pt"} right={0}>
      {idCardData?.registrationNo && <QRCode style={{ width: "106pt", height: "91pt", mt:"-30pt", marginLeft: "15pt" }} value={idCardData.registrationNo} />}

      </Box>

    </Box></Box>
}