import React, { useState, useMemo } from 'react';
import { Box, HStack, Input, Button, FormLabel, Table, Thead, Tbody, Th, Tr, Td, Heading, Divider } from '@chakra-ui/react';
import { usePaymentSummary } from '../../services/api/usePaymentHistory';
import { useStaffData } from '../../services/api/useStaffData';
import { useCompanyData } from '../../services/api/useCompanyData';
import {  allPaymentModes } from '../../Constants'
import { find, groupBy, map, sumBy } from 'lodash'
import moment from 'moment';

export const PaymentSummary = () => {
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    const [ tempDates, setTempDates ] = useState({ startDate: startDate,endDate: endDate }); 
    const [ dates, setDates ] = useState({ startDate, endDate }); 
    const uniqKey = useMemo( () => dates.startDate && dates.endDate ? dates.startDate + dates.endDate : null,[dates]);
    const { data: paymentSummary, loading: loadingPaymentSummary, mutate: mutateList } = usePaymentSummary(uniqKey,{ ...dates });
    const { data: allStaff, loading: loadingStaff } = useStaffData(true)
    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)
    
    const handleApply = () => {
        setDates({...tempDates})
    }
    
    return (
        <Box mt={4}>
            <Box>
                <HStack align='end'>
                    <Box>
                        <FormLabel mb={2}>Start Date</FormLabel>
                        <Input value={ tempDates.startDate } onChange={ e => setTempDates( p => ({ ...p, startDate: e.target.value }) ) } type='date'/>
                    </Box>
                    <Box>
                        <FormLabel mb={2}>End Date</FormLabel>
                        <Input value={ tempDates.endDate } onChange={ e => setTempDates( p => ({ ...p, endDate: e.target.value }) ) } type='date'/>
                    </Box>
                    <Button isLoading={loadingPaymentSummary} onClick={handleApply}>Apply</Button>
                </HStack>
            </Box>
            <Box boxShadow='lg' px={2}>

                {/* <AllList data={paymentSummary} allCompanies={allCompanies} allStaff={allStaff}/> */}

                <PaymentModeTable data={paymentSummary} />
                
            <Divider my={4} borderColor='gray.400'/>
                <CompanyTable data={paymentSummary} allCompanies={allCompanies}/>
                
            <Divider my={4} borderColor='gray.400'/>
                <StaffTable data={paymentSummary} allCompanies={allCompanies} allStaff={allStaff}/>
            
            </Box>
        </Box>
    )
}

const AllList = ({ data, allCompanies, allStaff }) => {
    return (
        <Box mt={4} px={2} py={4} borderRadius={2}> 
            <Heading mb={4}>Payment Summary</Heading>
            <Table>
                <Thead>
                    <Th>Staff Name</Th>
                    <Th>Comany</Th>
                    <Th>Amount</Th>
                    <Th>Payment Mode</Th>
                </Thead>
                <Tbody>
                    {
                        map(data, p => {
                            const staff = find(allStaff, s => s.staffId === p.createdById)
                            const comany = find(allCompanies, c => c.id === p.companyId)
                        return (<Tr>
                            <Td>{ staff?.staff?.name }</Td>
                            <Td>{comany.name}</Td>
                            <Td>{p.amount}</Td>
                            <Td>{allPaymentModes[p.payment_mode]?.name}</Td>
                        </Tr> )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )
}

const StaffTable =  ({ data, allStaff, allCompanies }) => {
    return (
        <Box mt={4} px={2} py={4} borderRadius={2}> 
            <Heading fontSize={20}>Staff Summary</Heading>
            <Box mt={0} fontSize='sm' mb={4} color='gray.600'>Payments group by staff</Box>
            <Box pl={2}>
                    {
                        map(groupBy(data, 'createdById'), (StaffPayments,staffId) => {
                            const staff = find(allStaff, s => s.staffId === staffId)
                            // if(!staff) 
                            // console.log({allStaff, staffId, staff})

                            const totalStaffAmount = sumBy(StaffPayments, p => p.amount ? parseFloat(p.amount) : 0 )
                            const methodStaffPayments = groupBy(StaffPayments, 'payment_mode');
                            console.log("staff",staff, allStaff, staffId)
                            return (
                        <Box mb={4}>
                            <Heading size='sm' color='gray.500' pb={4}>Staff Name: {staff?.staff?.name ?? " - "}</Heading>
                            {/* {console.log({StaffPayments, "staff?.staff?.name": staff?.staff?.name})} */}
                                            
                                        <Table variant='simple'>
                                            <Thead bg='gray.50'>
                                                <Th>Comany Name</Th>
                                                <Th>Total Amount</Th>
                                                {
                                                    map(allPaymentModes, p => <Th key={p.type}>{p.name}</Th>)
                                                }
                                            </Thead>
                                            <Tbody>
                                                {
                                                    <Tr>
                                                        <Td>(All Companies)</Td>
                                                        <Td>{totalStaffAmount}</Td>
                                                        {
                                                            map(allPaymentModes, p => <Td>{ methodStaffPayments[p.type] ? sumBy(methodStaffPayments[p.type], methodPayment => Number(methodPayment.amount)) : '-' }</Td>)
                                                        }
                                                    </Tr>
                                                }
                                            {
                                                map(groupBy(StaffPayments, 'companyId'), (companyPayments, companyId) => {
                                                    const comany = find(allCompanies, c => c.id === companyId)
                                                    const totalAmount = sumBy(companyPayments, p => p.amount ? parseFloat(p.amount) : 0 )
                                                    const methodPayments = groupBy(companyPayments, 'payment_mode');
                                                    // console.log("methodPayments",methodPayments)
                                                    return (
                                                    <Tr>
                                                        <Td>{ comany?.name }</Td>
                                                        <Td>{totalAmount}</Td>
                                                        {
                                                            map(allPaymentModes, p => <Td>{ methodPayments[p.type] ? sumBy(methodPayments[p.type], methodPayment => Number(methodPayment.amount)) : '-' }</Td>)
                                                        }
                                                    </Tr>)
                                                })
                                            }
                                </Tbody>
                            </Table> 
                        </Box>
                    )})
                    }
            </Box>
        </Box>
    )
}

const PaymentModeTable =  ({ data }) => {
    return (
        <Box mt={4} px={2} py={4} borderRadius={2}> 
            <Heading fontSize={20}>Payment Mode Summary</Heading>
            <Box mt={0} fontSize='sm' mb={4} color='gray.600'>Payments group by mode</Box>
            <Table variant='simple'>
                <Thead bg='gray.50'>
                    <Th>Payment Mode</Th>
                    <Th>Amount</Th>
                </Thead>
                <Tbody>
                    {
                        map(groupBy(data, 'payment_mode'), (payments,payment_mode) => {
                            const totalAmount = sumBy(payments, p => p.amount ? parseFloat(p.amount) : 0 )
                        return (<Tr>
                            <Td>{ allPaymentModes[payment_mode]?.name }</Td>
                            <Td>{totalAmount}</Td>
                        </Tr> )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )
}


const CompanyTable =  ({ data, allCompanies }) => {
    return (
        <Box mt={4} px={2} py={4} borderRadius={2}> 
            <Heading fontSize={20}>Company Summary</Heading>
            <Box mt={0} fontSize='sm' mb={4} color='gray.600'>Payments group by company</Box>
            <Table  variant='simple'>
                <Thead bg='gray.50'>
                    <Th>Company Name</Th>
                    <Th>Total Amount</Th>
                    {
                        map(allPaymentModes, p => <Th key={`com${p.type}`}>{p.name}</Th>)
                    }
                </Thead>
                <Tbody>
                    {
                        map(groupBy(data, 'companyId'), (payments,companyId) => {
                            const comany = find(allCompanies, c => c.id === companyId)
                            const totalAmount = sumBy(payments, p => p.amount ? parseFloat(p.amount) : 0 )
                            const methodPayments = groupBy(payments, 'payment_mode');
                            // console.log("methodPayments",methodPayments)
                            // console.log("payments",payments)
                        return (<Tr>
                            <Td>{ comany?.name }</Td>
                            <Td>{totalAmount}</Td>
                            {
                                map(allPaymentModes, p => <Td>{ methodPayments[p.type] ? sumBy(methodPayments[p.type], methodPayment => Number(methodPayment.amount)) : '-' }</Td>)
                            }
                        </Tr> )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )
}