import React, { useEffect, useReducer, useState } from 'react'
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Textarea, VStack } from '@chakra-ui/react'
import _, { find, omit, sortBy } from 'lodash'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useStates } from '../../services/api/useStates'
import { useUserData } from '../../services/api/useUserData'
import { UploadImage } from '../../ui/UploadImage'
import { FormReducer } from '../../utils/FormReducer'
import { isCompanyGstOn } from '../../utils/Helper'

export const AddCompanyModal = ({ visible, closeModal, mutate, defaultData }) => {
    const { organisation } = useUserData(true)

    const { data: allStates } = useStates(true)
    const [gstEnableStatus, enableGst] = useState()
    const [companyData, changeCompanyData] = useReducer(FormReducer, {})

    const { request: addCompanyAction, loading } = useApiRequest(URIS.ADD_COMPANY, {
        onCompleted: d => {
            mutate()
            closeModal()
        },
        showAlert: true
    })

    useEffect(() => {
        if (defaultData) {
            const { name, address, pan, gstin, state_code, invoice_prefix, refund_prefix, logo, cin, place_of_service, slipCancelContact } = defaultData
            const data = { name, address: address.streetAddress, state: _.find(allStates, s => s.id === address.state), district: address.district, pincode: address.pincode, pan, gstin, state_code, invoice_prefix, refund_prefix, logo, cin, place_of_service, slipCancelContact }
            changeCompanyData({ type: 'reset', value: data })
            if (isCompanyGstOn(defaultData))
                enableGst(true)
        }
    }, [allStates, defaultData])

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = { organisationId: organisation?.id, address: { streetAddress: companyData.address, state: companyData.state?.id, district: companyData.district, pincode: companyData.pincode }, ...omit(companyData, ['address', 'state', 'district', 'pincode']), logo: companyData.logo || '' }

        if (defaultData)
            addCompanyAction({ method: 'PATCH', data: { ...data, id: defaultData.id } })
        else
            addCompanyAction({ method: 'POST', data })
    }

    const handleImage = (img) => {
        changeCompanyData({ type: 'logo', value: img?.url })
    }

    const handleEnableGst = (e) => {
        enableGst(e.target.checked)
        if (!e.target.checked)
            changeCompanyData({ type: 'merge', value: { gstin: '', state_code: '', cin: '', place_of_service: '' } })
    }

    const handleChangeData = (type, value) => {

        if (type === 'state') {
            const state = find(allStates, s => s.id == value)
            changeCompanyData({ type, value: state })
        } else
            changeCompanyData({ type, value })
    }

    return (
        <Modal size={'2xl'} isOpen={visible} onClose={closeModal}>
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
                <ModalContent>
                    <ModalHeader>{defaultData ? 'Update' : 'Add'} Company</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align={'stretch'} spacing={6}>
                            <FormControl isRequired>
                                <FormLabel>Company Name</FormLabel>
                                <Input value={companyData.name} onChange={(e) => handleChangeData('name', e.target.value)} placeholder='name' />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Company Address (shown on fees receipt header)</FormLabel>
                                <Textarea value={companyData.address} onChange={(e) => handleChangeData('address', e.target.value)} placeholder='company address' />
                            </FormControl>
                            <FormControl>
                                <FormLabel>State</FormLabel>
                                <Select placeholder='select state' value={companyData.state?.id} onChange={(e) => handleChangeData('state', e.target.value)}>
                                    {allStates?.length ?
                                        sortBy(allStates, d => d.name).map(state =>
                                            <option key={state.id} value={state.id}>{state.name}</option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            </FormControl>
                            {companyData.state &&
                                <FormControl>
                                    <FormLabel>District</FormLabel>
                                    <Select value={companyData.district} placeholder='select district' onChange={(e) => handleChangeData('district', e.target.value)}>
                                        {companyData.state.cities?.length ?
                                            sortBy(companyData.state.cities, d => d.name).map(state =>
                                                <option key={state.id} value={state.id}>{state.name}</option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                </FormControl>
                            }
                            <FormControl>
                                <FormLabel >Pincode</FormLabel>
                                <Input value={companyData.pincode} min={0} onChange={(e) => handleChangeData('pincode', e.target.value)} type='number' placeholder='pincode' />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Company Pan No</FormLabel>
                                <Input value={companyData.pan} onChange={(e) => handleChangeData('pan', e.target.value)} placeholder='pan no' />
                            </FormControl>
                            <FormControl>
                                <FormLabel>GST Applicable</FormLabel>
                                <Switch isChecked={gstEnableStatus} onChange={handleEnableGst} />
                            </FormControl>
                            {gstEnableStatus &&
                                <HStack spacing={6}>
                                    <FormControl>
                                        <FormLabel>GSTIN</FormLabel>
                                        <Input value={companyData.gstin} onChange={(e) => handleChangeData('gstin', e.target.value)} placeholder='gstin' />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>CIN</FormLabel>
                                        <Input value={companyData.cin} onChange={(e) => handleChangeData('cin', e.target.value)} placeholder='cin' />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Place Of Service</FormLabel>
                                        <Input value={companyData.place_of_service} onChange={(e) => handleChangeData('place_of_service', e.target.value)} placeholder='service' />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>State Code</FormLabel>
                                        <Input value={companyData.state_code} onChange={(e) => handleChangeData('state_code', e.target.value)} placeholder='state code' />
                                    </FormControl>
                                </HStack>
                            }
                            <HStack spacing={6}>
                                <FormControl isRequired >
                                    <FormLabel>Slip Prefix</FormLabel>
                                    <Input value={companyData.invoice_prefix} onChange={(e) => handleChangeData('invoice_prefix', e.target.value)} placeholder='slip prefix' />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Refund Prefix</FormLabel>
                                    <Input value={companyData.refund_prefix} onChange={(e) => handleChangeData('refund_prefix', e.target.value)} placeholder='refund prefix' />
                                </FormControl>
                            </HStack>
                            <FormControl isRequired>
                                <FormLabel>Slip Cancel Contact Number</FormLabel>
                                <Input value={companyData.slipCancelContact} onChange={(e) => handleChangeData('slipCancelContact', e.target.value)} placeholder='contact number' />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Company Logo</FormLabel>
                                <UploadImage defaultUrl={companyData.logo} getImage={handleImage} />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                            <Button isLoading={loading} type='submit'>Save</Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}