import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useTenantsInOut(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_TENANTS_IN_OUT+q, () => apis.getTenantsInOutApi(params), q, revalidate)
    return {
        data,
        error, loading, mutate
    }
}